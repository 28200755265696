import styled, { css } from 'styled-components'
import { width } from 'styled-system'
import { flexbox } from '@styled-system/flexbox'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { logoExtendedGlyph } from 'Assets/Svg'
import linesGlyph from 'Assets/Svg/lines.svg'

const transparentContainerCss = ({ transparent }) =>
  transparent &&
  css`
    background-color: transparent;
  `

export const Container = styled.section`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: ${themeGet('colors.bg.secondary')};
  overflow-y: auto;

  ${transparentContainerCss};
  ${flexbox};
`

export const Info = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 42px;
  overflow: hidden;
`

export const Name = styled.div`
  height: 30px;
  font-size: 24px;
  line-height: normal;
  color: ${themeGet('colors.bg.primary')};

  ${margin}
`

export const Text = styled.div`
  max-width: 420px;
  font-size: 14px;
  color: ${themeGet('colors.bg.primary')};
`

export const Lines = styled(linesGlyph)`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: ${themeGet('space.4')}px ${themeGet('space.6')}px;
  background-color: ${themeGet('colors.bg.primary')};

  ${width};
`

export const Slogan = styled.div`
  z-index: 1;
  position: relative;
  color: ${themeGet('colors.white')};
  font-size: 50px;
  line-height: 68px;
  font-weight: ${themeGet('fontWeights.3')};
  text-transform: uppercase;
  max-width: 520px;
`

export const LogoExtendedIcon = styled(logoExtendedGlyph)``
