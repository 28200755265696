import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div.attrs({
  as: 'section',
})`
  display: flex;
  background-color: ${themeGet('colors.bg.primary')};
  width: 100%;
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 20px;
`
