import { KIND } from 'Constants/goals'

const KIND_TEXT = {
  [KIND.VEHICLE]: 'data.goal.kind.vehicle',
  [KIND.VACATION]: 'data.goal.kind.vacation',
  [KIND.CASH]: 'data.goal.kind.cash',
  [KIND.HOUSE]: 'data.goal.kind.house',
  [KIND.RENOVATION]: 'data.goal.kind.renovation',
  [KIND.EDUCATION]: 'data.goal.kind.education',
  [KIND.HEALTHCARE]: 'data.goal.kind.healthcare',
  [KIND.WEDDING]: 'data.goal.kind.wedding',
  [KIND.RETIREMENT]: 'data.goal.kind.retirement',
  [KIND.OTHER]: 'data.goal.kind.other',
}

function goalKindText(goal) {
  return KIND_TEXT[goal?.kind] || 'data.goal.kind.unknown'
}

export default {
  goalKindText,
}
