import { useMemo } from 'react'

import { v4 as uuid } from 'uuid'

import { SUPPORT_WIDGET } from 'Config'

import { useScript } from 'Hooks'

const WIDGET_TOKEN = uuid()

export function useSupportWidget() {
  const empty = useMemo(() => ({}), [])

  return window?.WorklairWidget || empty
}

export function useInitializeSupportWidget() {
  useScript(
    `https://files.worklair.io/support-widget/index.js?token=${WIDGET_TOKEN}`,
    {
      id: 'worklair-support-widget-script',
      type: 'module',
      async: true,
      'data-project-id': SUPPORT_WIDGET.PROJECT_ID,
    },
  )
}
