import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFlexLayout, usePagination, useTable } from 'react-table'

import { useLazyQuery } from '@apollo/client'

import get from 'lodash/get'
import map from 'lodash/map'

import {
  EditUserTransactionModal,
  UserTransactionModal,
} from 'Components/Blocks/Admin/Modals'
import { Row, Select } from 'Components/UI'
import { Pagination, Table } from 'Components/UI/Admin'
import { InputLabels } from 'Components/UI/Forms'

import { KIND, STATE } from 'Constants/userTransactions'

import adminUserTransactionsQuery from 'GraphQL/Queries/Admin/adminUserTransactions.graphql'

import { useColumns } from './columns'
import TableContext from './context'
import { Container, Content } from './styles'

const ALL = 'all'

function valueToOption(value) {
  return {
    label: value,
    value,
  }
}

function buildOptions(values) {
  return map([ALL, ...Object.values(values)], valueToOption)
}

const STATE_OPTIONS = buildOptions(STATE)
const KIND_OPTIONS = buildOptions(KIND)

function UserTransactionsTable() {
  const [approveModal, setApproveModal] = useState({
    isOpen: false,
    entityId: null,
  })
  const [editModal, setEditModal] = useState({
    isOpen: false,
    entityId: null,
  })
  const [state, setState] = useState(valueToOption(STATE.PENDING))
  const [kind, setKind] = useState(KIND_OPTIONS[0])

  const [loadUserTransactions, { data, loading }] = useLazyQuery(
    adminUserTransactionsQuery,
  )

  const transactions = get(data, 'adminUserTransactions') || {}

  const tableData = useMemo(() => get(transactions, 'rows') || [], [
    transactions,
  ])

  const columns = useColumns()

  const tableProps = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: get(transactions, 'pages') || 0,
    },
    usePagination,
    useFlexLayout,
  )

  const {
    state: { pageIndex, pageSize },
  } = tableProps

  const fetchData = useCallback(() => {
    const variables = { page: pageIndex, limit: pageSize }

    if (state.value !== ALL) {
      variables.state = [state.value]
    }

    if (kind.value !== ALL) {
      variables.kind = [kind.value]
    }

    loadUserTransactions({ variables })
  }, [loadUserTransactions, state, kind, pageIndex, pageSize])

  useEffect(() => {
    tableProps.gotoPage(0)
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kind, state])

  useEffect(() => {
    fetchData()
  }, [fetchData, pageIndex, pageSize])

  const handleOpenEditModal = useCallback(entity => {
    setEditModal({ isOpen: true, entity })
  }, [])

  const handleCloseEditModal = useCallback(() => {
    setEditModal({ isOpen: false, entity: null })
  }, [])

  const handleOpenApproveModal = useCallback(entity => {
    setApproveModal({ isOpen: true, entity })
  }, [])

  const handleCloseApproveModal = useCallback(() => {
    setApproveModal({ isOpen: false, entity: null })
  }, [])

  return (
    <TableContext.Provider
      value={{ onEdit: handleOpenEditModal, onApprove: handleOpenApproveModal }}
    >
      <Container>
        <Content>
          <Row borderBottom mb={2} pb={3}>
            <InputLabels title="State" width={160}>
              <Select
                options={STATE_OPTIONS}
                small
                value={state}
                onChange={setState}
              />
            </InputLabels>
            <InputLabels ml={3} title="Kind" width={160}>
              <Select
                options={KIND_OPTIONS}
                small
                value={kind}
                onChange={setKind}
              />
            </InputLabels>
          </Row>

          <Table {...tableProps} loading={loading} />

          <Row borderTop mt={2} pt={3}>
            <Pagination
              {...tableProps}
              total={get(transactions, ['count']) || 0}
            />
          </Row>

          <UserTransactionModal
            isOpen={approveModal.isOpen}
            transactionId={approveModal.entity?.id}
            onClose={handleCloseApproveModal}
          />

          <EditUserTransactionModal
            isOpen={editModal.isOpen}
            transaction={editModal.entity}
            onClose={handleCloseEditModal}
          />
        </Content>
      </Container>
    </TableContext.Provider>
  )
}

export default UserTransactionsTable
