import React from 'react'
import PropTypes from 'prop-types'

import { Text } from 'Components/UI'

import { CheckIcon, StepItem, StepStatus } from './styles'

function Step({ active, passed, children }) {
  return (
    <StepItem>
      <StepStatus active={active} passed={passed}>
        {passed && <CheckIcon height={16} viewBox="0 0 24 24" width={16} />}
      </StepStatus>
      <Text actionSmall>{children}</Text>
    </StepItem>
  )
}

Step.defaultProps = {
  active: false,
  passed: false,
  children: null,
}

Step.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  passed: PropTypes.bool,
}

export default Step
