import join from 'lodash/join'

import { RISK_ASSESSMENT } from 'Constants/ids'

function fullName(profile) {
  return join([profile?.firstName, profile?.lastName], ' ')
}

const RISK_ASSESSMENT_TITLE_TEXTS = {
  [RISK_ASSESSMENT.INVESTING_EXPERIENCE]:
    'data.riskAssessment.investingExperience.title',
  [RISK_ASSESSMENT.RISK_TOLERANCE]: 'data.riskAssessment.riskTolerance.title',
  [RISK_ASSESSMENT.INVESTMENT_HORIZON]:
    'data.riskAssessment.investmentHorizon.title',
  [RISK_ASSESSMENT.TOTAL_NET_WORTH]: 'data.riskAssessment.totalNetWorth',
  [RISK_ASSESSMENT.TOTAL_INVESTMENTS]: 'data.riskAssessment.totalInvestments',
  [RISK_ASSESSMENT.TAX_RESIDENCE]: 'data.kyc.taxResidence.title',
  [RISK_ASSESSMENT.US_CITIZEN]: 'data.kyc.usCitizen',
  [RISK_ASSESSMENT.INDUSTRIES]: 'data.kyc.industries',
  [RISK_ASSESSMENT.PROFESSION]: 'data.kyc.profession',
  [RISK_ASSESSMENT.NET_MONTHLY_INCOME]: 'data.kyc.netMonthlyIncome',
  [RISK_ASSESSMENT.FUND_SOURCE]: 'data.kyc.fundSource.title',
}

function riskAssessmentTitle(key) {
  return RISK_ASSESSMENT_TITLE_TEXTS[key] || 'data.riskAssessment.unknown'
}

export default { fullName, riskAssessmentTitle }
