import React, { useMemo } from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { BiBitcoin } from 'react-icons/bi'
import {
  FiAward,
  FiBarChart,
  FiDollarSign,
  FiFileText,
  FiGrid,
  FiHash,
  FiLock,
  FiSettings,
  FiShoppingBag,
  FiUsers,
} from 'react-icons/fi'

import { useQuery } from '@apollo/client'

import { Header } from 'Components/Blocks/Admin'
import { Badge, Flex, Link } from 'Components/UI'

import {
  BREAKOUT_ACCESS_EMAILS,
  SUPER_ADMIN_MAIN_EMAILS,
} from 'Constants/values'

import adminInfoQuery from 'GraphQL/Queries/Admin/adminInfo.graphql'

import { useAppContext } from 'Hooks'

import {
  ADMIN_ASSET_STATS,
  ADMIN_CRYPTO_TOKENS,
  ADMIN_ORDERS,
  ADMIN_PORTFOLIO_TEMPLATE_GROUPS,
  ADMIN_PORTFOLIO_TEMPLATES,
  ADMIN_ROOT,
  ADMIN_SETTINGS,
  ADMIN_TRANSACTIONS,
  ADMIN_USER_KYC,
  ADMIN_USER_PORTFOLIOS,
  ADMIN_USER_TRANSACTIONS,
  ADMIN_USERS,
} from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import {
  Container,
  Content,
  LogoExtendedIcon,
  Menu,
  MenuLink,
  PageContent,
  PageContentLimit,
} from './styles'

const DetailedRole = {
  FullAccess: 'fullAccess',
  BreakoutAccess: 'breakoutAccess',
  FundManager: 'fundManager',
}

function Admin({ route }) {
  const s = useScopedI18n('menu')
  const { data } = useQuery(adminInfoQuery)
  const { me } = useAppContext()

  const links = useMemo(() => {
    let role = SUPER_ADMIN_MAIN_EMAILS.includes(me?.emailCredentials[0]?.email)
      ? DetailedRole.FullAccess
      : DetailedRole.FundManager

    if (BREAKOUT_ACCESS_EMAILS.includes(me?.emailCredentials[0]?.email))
      role = DetailedRole.BreakoutAccess

    switch (role) {
      case DetailedRole.FullAccess:
        return (
          <Flex flexDirection="column">
            <MenuLink to={ADMIN_USERS}>
              <FiUsers /> {s('users')}
            </MenuLink>
            <MenuLink to={ADMIN_USER_TRANSACTIONS}>
              <FiHash /> {s('userRequests')}{' '}
              <Badge count={data?.adminInfo?.userTransactionsCount} ml={1} />
            </MenuLink>
            <MenuLink to={ADMIN_USER_KYC}>
              <FiLock /> {s('userKyc')}{' '}
              <Badge count={data?.adminInfo?.userKYCCount} ml={1} />
            </MenuLink>
            <MenuLink to={ADMIN_TRANSACTIONS}>
              <FiDollarSign /> {s('transactions')}
            </MenuLink>
            <MenuLink to={ADMIN_ORDERS}>
              <FiFileText /> {s('orders')}
            </MenuLink>
            <MenuLink to={ADMIN_PORTFOLIO_TEMPLATES}>
              <FiAward /> {s('expertPortfolios')}
            </MenuLink>
            <MenuLink to={ADMIN_PORTFOLIO_TEMPLATE_GROUPS}>
              <FiGrid /> {s('portfolioGroups')}
            </MenuLink>
            <MenuLink to={ADMIN_USER_PORTFOLIOS}>
              <FiShoppingBag /> {s('userPortfolios')}
            </MenuLink>
            <MenuLink to={ADMIN_ASSET_STATS}>
              <FiBarChart /> {s('assetStats')}
            </MenuLink>
            <MenuLink to={ADMIN_CRYPTO_TOKENS}>
              <BiBitcoin /> {s('cryptoTokens')}
            </MenuLink>
            <MenuLink to={ADMIN_SETTINGS}>
              <FiSettings /> {s('settings')}
            </MenuLink>
          </Flex>
        )
      case DetailedRole.BreakoutAccess:
        return (
          <Flex flexDirection="column">
            <MenuLink to={ADMIN_PORTFOLIO_TEMPLATES}>
              <FiAward /> {s('expertPortfolios')}
            </MenuLink>
            <MenuLink to={ADMIN_PORTFOLIO_TEMPLATE_GROUPS}>
              <FiGrid /> {s('portfolioGroups')}
            </MenuLink>
            <MenuLink to={ADMIN_USER_PORTFOLIOS}>
              <FiShoppingBag /> {s('userPortfolios')}
            </MenuLink>
            <MenuLink to={ADMIN_ASSET_STATS}>
              <FiBarChart /> {s('assetStats')}
            </MenuLink>
            <MenuLink to={ADMIN_CRYPTO_TOKENS}>
              <BiBitcoin /> {s('cryptoTokens')}
            </MenuLink>
          </Flex>
        )
      case DetailedRole.FundManager:
        return (
          <Flex flexDirection="column">
            <MenuLink to={ADMIN_PORTFOLIO_TEMPLATES}>
              <FiAward /> {s('expertPortfolios')}
            </MenuLink>
            <MenuLink to={ADMIN_PORTFOLIO_TEMPLATE_GROUPS}>
              <FiGrid /> {s('portfolioGroups')}
            </MenuLink>
          </Flex>
        )

      default:
        return null
    }
  }, [me, s, data])

  return (
    <Container>
      <Menu>
        <Link to={ADMIN_ROOT}>
          <LogoExtendedIcon />
        </Link>
        {links}
      </Menu>
      <Content>
        <Header />
        <PageContent>
          <PageContentLimit>{renderRoutes(route.routes)}</PageContentLimit>
        </PageContent>
      </Content>
    </Container>
  )
}

Admin.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Admin
