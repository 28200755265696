import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { themeGet } from '@styled-system/theme-get'

import { toasts } from './styles'

import 'react-toastify/dist/ReactToastify.min.css'

export default createGlobalStyle`
  ${styledNormalize}

  html {
    height: 100%;
    width: 100%;
    overflow: hidden;
   }

  body {
    color: ${themeGet('colors.font.primary')};
    background-color: ${themeGet('colors.bg.primary')};
    font-size: 14px;
    font-family: ${themeGet('font')};
    line-height: 20px;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    padding: 0;
    overflow: auto;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
  }

  h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
  }

  button {
    padding: 0;
    margin: 0;
    box-shadow: unset;
    border: 0;
    background-color: unset;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ${toasts}
`
