import React from 'react'
import { useLocation } from 'react-router'

import { pick } from '@styled-system/props'

import StepProgress from 'Components/Blocks/StepProgress'

import {
  AUTH_SIGN_UP,
  ONBOARDING_KYC,
  ONBOARDING_PORTFOLIO,
  ONBOARDING_RISK_ASSESSMENT,
  ONBOARDING_ROOT,
} from 'Router/routes'

const STEPS_LIST = {
  [AUTH_SIGN_UP]: {
    label: 'onboarding.progressBar.createAccount',
    step: 1,
  },
  [ONBOARDING_ROOT]: {
    label: 'onboarding.progressBar.selectPlan',
    step: 2,
  },
  [ONBOARDING_RISK_ASSESSMENT]: {
    label: 'onboarding.progressBar.investorProfile',
    step: 3,
  },
  [ONBOARDING_PORTFOLIO]: {
    label: 'onboarding.progressBar.firstInvestment',
    step: 4,
  },
  [ONBOARDING_KYC]: {
    label: 'onboarding.progressBar.identityVerification',
    step: 5,
  },
}

function ProgressBar({ ...rest }) {
  const location = useLocation()
  const currentStep = STEPS_LIST[location.pathname]?.step

  return (
    <StepProgress
      {...pick(rest)}
      lastStep={ONBOARDING_KYC}
      step={currentStep}
      steps={STEPS_LIST}
    />
  )
}

export default ProgressBar
