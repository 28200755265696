import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { overflow } from 'styled-system'
import { flexbox } from '@styled-system/flexbox'
import { layout } from '@styled-system/layout'
import { margin, padding, space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { gap } from 'Theme/system'

export default styled.div`
  display: flex;

  ${flexbox}
  ${space}
  ${layout}
`

const alignEndCss = ({ alignEnd }) =>
  alignEnd &&
  css`
    align-items: flex-end;
  `

const alignStartCss = ({ alignStart }) =>
  alignStart &&
  css`
    align-items: flex-start;
  `

const borderBottomCss = ({ borderBottom }) =>
  borderBottom &&
  css`
    border-bottom: solid 1px ${themeGet('colors.border.primary')};
  `

const borderTopCss = ({ borderTop }) =>
  borderTop &&
  css`
    border-top: solid 1px ${themeGet('colors.border.primary')};
  `

const centerCss = ({ center }) =>
  center &&
  css`
    align-items: center;
  `

const fullHeightCss = ({ fullHeight }) =>
  fullHeight &&
  css`
    height: 100%;
  `

const fullWidthCss = ({ fullWidth }) =>
  fullWidth &&
  css`
    width: 100%;
  `

const justifyCenterCss = ({ justifyCenter }) =>
  justifyCenter &&
  css`
    justify-content: center;
  `

const justifyEndCss = ({ justifyEnd }) =>
  justifyEnd &&
  css`
    justify-content: flex-end;
  `

const noShrinkCss = ({ noShrink }) =>
  noShrink &&
  css`
    flex-shrink: 0;
  `

const spaceAroundCss = ({ spaceAround }) =>
  spaceAround &&
  css`
    justify-content: space-around;
  `

const spaceBetweenCss = ({ spaceBetween }) =>
  spaceBetween &&
  css`
    justify-content: space-between;
  `

const squeezeCss = ({ squeeze }) =>
  squeeze &&
  css`
    width: fit-content;
  `

const relativeCss = ({ relative }) =>
  relative &&
  css`
    position: relative;
  `

const hiddenCss = ({ hidden }) =>
  hidden &&
  css`
    display: none !important;
  `

const DEFAULT_PROPS = {
  alignEnd: undefined,
  borderBottom: undefined,
  borderTop: undefined,
  center: undefined,
  fullHeight: undefined,
  fullWidth: undefined,
  justifyCenter: undefined,
  justifyEnd: undefined,
  noShrink: undefined,
  spaceAround: undefined,
  spaceBetween: undefined,
  squeeze: undefined,
  hidden: undefined,
}

const PROP_TYPES = {
  alignEnd: PropTypes.bool,
  borderBottom: PropTypes.bool,
  borderTop: PropTypes.bool,
  center: PropTypes.bool,
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hidden: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  justifyEnd: PropTypes.bool,
  noShrink: PropTypes.bool,
  spaceAround: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  squeeze: PropTypes.bool,
}

const Element = styled.div`
  ${layout}
  ${margin}
  ${padding}
  ${gap}
  ${flexbox}


  ${alignEndCss}
  ${alignStartCss}
  ${borderBottomCss}
  ${borderTopCss}
  ${centerCss}
  ${fullHeightCss}
  ${fullWidthCss}
  ${justifyCenterCss}
  ${justifyEndCss}
  ${noShrinkCss}
  ${spaceAroundCss}
  ${spaceBetweenCss}
  ${squeezeCss}
  ${relativeCss}
  ${hiddenCss}
  ${overflow}
`

export const Column = styled(Element)`
  display: flex;
  flex-direction: column;
`

Column.defaultProps = DEFAULT_PROPS
Column.propTypes = PROP_TYPES

export const Row = styled(Element)`
  display: flex;
  flex-shrink: 0;

  ${flexbox}
`

Row.defaultProps = DEFAULT_PROPS
Row.propTypes = PROP_TYPES
