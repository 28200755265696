import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { themeGet } from '@styled-system/theme-get'
import { typography } from '@styled-system/typography'

export const Clear = styled.div`
  font-size: 14px;
  color: ${themeGet('layout.primary')};
  cursor: pointer;
  line-height: 10px;
`

export const Dropdown = styled.div`
  font-size: ${mapToTheme('selects.optionFontSize')}px;
  cursor: pointer;

  svg {
    color: ${themeGet('colors.text100')};
    transition: all 0.2s ease;
  }
`

export const ControlWrapper = styled.div`
  height: ${mapToTheme('spacing.controlHeight')}px !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: ${mapToTheme('selects.borderRadius')}px !important;
  border-color: ${themeGet('colors.text20')} !important;
  background-color: white !important;

  &:hover {
    border-color: ${themeGet('layout.primary')} !important;
  }

  &.react-select__control--menu-is-open {
    ${Dropdown} {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  &.react-select__control--is-disabled {
    background-color: ${themeGet('colors.disabled')} !important;
    ${Dropdown} {
      svg {
        color: ${themeGet('colors.gray50')};
      }
    }
  }
`

export const GroupWrapper = styled.div``

export const GroupHeadingWrapper = styled.div``

export const Indicators = styled.div`
  display: flex;
  flex-shrink: 0;
  padding-right: 8px;

  svg {
    color: black;
  }
`

export const Separator = styled.div``

export const InputWrapper = styled.div`
  height: 16px;
`

export const MenuWrapper = styled.div``

export const MenuListWrapper = styled.div`
  background-color: white;
  border-radius: 4px;
`

export const Item = styled.div`
  padding: 8px 16px !important;

  &.react-select__option--is-focused {
    background-color: ${mapToTheme('selects.dropdown.active.backgroundColor')};
    color: ${mapToTheme('selects.dropdown.active.color')};
  }

  &.react-select__option--is-selected {
    background-color: ${mapToTheme(
      'selects.dropdown.selected.backgroundColor',
    )};
    color: ${mapToTheme('selects.dropdown.selected.color')};
  }

  ${typography.textAlign};
`

export const MultiValueWrapper = styled.div``

export const MultiValueContainerWrapper = styled.div``

export const MultiValueLabelWrapper = styled.div`
  padding: 0 4px;
`

export const PlaceholderWrapper = styled.div``

export const SelectContainerWrapper = styled.div`
  outline: none !important;
  box-shadow: none !important;
  line-height: 16px;
`

export const Value = styled.div``

export const ValueContainerWrapper = styled.div`
  padding: 0 12px !important;
  font-size: ${mapToTheme('selects.valueFontSize')}px;
  height: 100%;
  line-height: 20px;

  .react-select__multi-value {
    &__label {
      padding: 0 4px;
    }
    &__remove {
      cursor: pointer;
    }
  }
`
