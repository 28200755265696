import React from 'react'

import { UsersTable } from 'Components/Blocks/Admin'
import { Row } from 'Components/UI'

function Users() {
  return (
    <Row p={2}>
      <UsersTable />
    </Row>
  )
}

export default Users
