import React from 'react'
import { Field } from 'react-final-form'
import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'

import Utils from 'Utils'

import { QuillContainer } from './styles'

import { Label } from '../styles'

import 'react-quill/dist/quill.snow.css'

function EditorField({ checkErrorIfDirty, displayError, format, name, parse }) {
  return (
    <Field format={format} name={name} parse={parse}>
      {({ input: { value, onChange: handleChange }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <QuillContainer>
            <ReactQuill theme="snow" value={value} onChange={handleChange} />
            {displayError && hasError && (
              <Label error mt={1}>
                {error}
              </Label>
            )}
          </QuillContainer>
        )
      }}
    </Field>
  )
}

EditorField.defaultProps = {
  checkErrorIfDirty: false,
  displayError: true,
  format: undefined,
  parse: undefined,
}

EditorField.propTypes = {
  checkErrorIfDirty: PropTypes.bool,
  displayError: PropTypes.bool,
  format: PropTypes.func,
  name: PropTypes.string.isRequired,
  parse: PropTypes.func,
}

export default EditorField
