import styled from 'styled-components'
import { margin, padding } from '@styled-system/space'

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${margin}
  ${padding}
`

export const Content = styled.div`
  width: 670px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin: 0 auto;
`
