import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'
import { pick } from '@styled-system/props'

import { Row, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { Container, Content } from './styles'
import Table from './Table'

function Holdings({ assets, contentMaxHeight, ...rest }) {
  const theme = useTheme()
  const s = useScopedI18n('blocks.expertPortfolio.assets.holdings')
  return (
    <Container {...pick(rest)}>
      <Content maxHeight={contentMaxHeight} mt="20px">
        <Row center>
          <Text fontWeight={2} heading5>
            {s('title')}
          </Text>
          <Text color={theme.colors.text70} medium ml={1}>
            ({assets?.length})
          </Text>
        </Row>

        <Table assets={assets} mt={3} />
      </Content>
    </Container>
  )
}

Holdings.defaultProps = {
  contentMaxHeight: undefined,
  assets: [],
}

Holdings.propTypes = {
  assets: PropTypes.array,
  contentMaxHeight: PropTypes.number,
}

export default Holdings
