import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { FiTrash2, FiUpload } from 'react-icons/fi'

import noop from 'lodash/noop'

import { Button, Container, Controls, Line } from './styles'

const ICON = {
  SIZE: 18,
  STROKE_WIDTH: 1.8,
}

function ImageOverlay({ className, onDelete, onUpload }) {
  const handleDelete = useCallback(
    event => {
      event.stopPropagation()
      onDelete()
    },
    [onDelete],
  )

  const handleUpload = useCallback(
    event => {
      event.stopPropagation()
      onUpload()
    },
    [onUpload],
  )

  return (
    <Container className={className}>
      <Controls>
        <Button onClick={handleDelete}>
          <FiTrash2 size={ICON.SIZE} strokeWidth={ICON.STROKE_WIDTH} />
        </Button>
        <Line ml={3} />
        <Button ml={3} onClick={handleUpload}>
          <FiUpload size={ICON.SIZE} strokeWidth={ICON.STROKE_WIDTH} />
        </Button>
      </Controls>
    </Container>
  )
}

ImageOverlay.defaultProps = {
  className: undefined,
  onDelete: noop,
  onUpload: noop,
}

ImageOverlay.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
  onUpload: PropTypes.func,
}

export default ImageOverlay
