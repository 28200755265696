import React from 'react'

import { AssetStatsTable } from 'Components/Blocks/Admin'
import { Row } from 'Components/UI'

function AssetStats() {
  return (
    <Row p={2}>
      <AssetStatsTable />
    </Row>
  )
}

export default AssetStats
