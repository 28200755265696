import React from 'react'
import PropTypes from 'prop-types'

import { ExternalLink } from './styles'

function LinkCell({ value }) {
  return (
    <ExternalLink href={value} rel="noopener noreferrer" target="_blank">
      {value}
    </ExternalLink>
  )
}

LinkCell.defaultProps = {
  value: '',
}

LinkCell.propTypes = {
  value: PropTypes.string,
}

export default LinkCell
