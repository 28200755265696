import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import forEach from 'lodash/forEach'

import { Button, Loader, Row } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms'

import adminUpdateSettingMutation from 'GraphQL/Mutations/Admin/adminUpdateSetting.graphql'

import { useAppContext } from 'Hooks'

import { Container, Content } from './styles'

const FIELDS = {
  BINANCE_WALLET: 'binanceWallet',
  SYSTEM_FEE: 'systemFee',
  INTEREST_RATE: 'interestRate',

  ADVISOR_NAME: 'advisorName',
  ADVISOR_PHONE: 'advisorPhone',
  ADVISOR_EMAIL: 'advisorEmail',
  ADVISOR_CALENDLY: 'advisorCalendly',
  ADVISOR_AVATAR: 'advisorAvatar',
}

function Settings() {
  const [loading, setLoading] = useState(false)
  const { settings } = useAppContext()

  const [adminUpdateSetting] = useMutation(adminUpdateSettingMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELDS.BINANCE_WALLET]: {
        presence: {
          required: true,
        },
      },
      [FIELDS.SYSTEM_FEE]: {
        presence: {
          required: true,
        },
      },
      [FIELDS.INTEREST_RATE]: {
        presence: {
          required: true,
        },
      },
      [FIELDS.ADVISOR_AVATAR]: {
        presence: {
          required: true,
        },
      },
      [FIELDS.ADVISOR_CALENDLY]: {
        presence: {
          required: true,
        },
      },
      [FIELDS.ADVISOR_PHONE]: {
        presence: {
          required: true,
        },
      },
      [FIELDS.ADVISOR_EMAIL]: {
        presence: {
          required: true,
        },
      },
      [FIELDS.ADVISOR_NAME]: {
        presence: {
          required: true,
        },
      },
    }),
    [],
  )

  const submit = useCallback(
    async (values, { getState }) => {
      setLoading(true)
      const promises = []

      const { modified, errors } = getState()

      forEach(values, (value, name) => {
        if (modified[name] && !errors[name]) {
          promises.push(adminUpdateSetting({ variables: { name, value } }))
        }
      })

      await Promise.all(promises)

      setLoading(false)
    },
    [adminUpdateSetting],
  )

  return (
    <Container>
      <Form
        initialValues={settings}
        render={({ handleSubmit }) => (
          <Content>
            <InputLabels title="Metamask Deposit Wallet">
              <InputField name={FIELDS.BINANCE_WALLET} />
            </InputLabels>

            <InputLabels mt={3} title="System Fee (0.3% = 0.003)">
              <InputField name={FIELDS.SYSTEM_FEE} />
            </InputLabels>

            <InputLabels mt={3} title="Interest Rate (0.1% = 0.001)">
              <InputField name={FIELDS.INTEREST_RATE} />
            </InputLabels>

            <InputLabels mt={3} title="Advisor Name">
              <InputField name={FIELDS.ADVISOR_NAME} />
            </InputLabels>

            <InputLabels mt={3} title="Advisor Email">
              <InputField name={FIELDS.ADVISOR_EMAIL} />
            </InputLabels>

            <InputLabels mt={3} title="Advisor Phone">
              <InputField name={FIELDS.ADVISOR_PHONE} />
            </InputLabels>

            <InputLabels mt={3} title="Advisor Calendly">
              <InputField name={FIELDS.ADVISOR_CALENDLY} />
            </InputLabels>

            <InputLabels mt={3} title="Advisor Avatar URL">
              <InputField name={FIELDS.ADVISOR_AVATAR} />
            </InputLabels>

            <Row center mt={4}>
              <Button onClick={handleSubmit}>Save</Button>
              {loading && (
                <>
                  <Loader /> Saving...
                </>
              )}
            </Row>
          </Content>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Container>
  )
}

export default Settings
