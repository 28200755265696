import { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { useLocationQueryParams } from 'Hooks'

import EventBus from 'Services/EventBus'

const ACTIONS = {
  PRESENT: 'oauth/present',
  DISMISS: 'oauth/dismiss',
}
const ANIMATION_FRAME_DELAY = 100

function windowParams(width = 0, height = 0) {
  const left =
    get(window, 'screenX', 0) + (get(window, 'outerWidth', 0) - width) / 2
  const top =
    get(window, 'screenY', 0) + (get(window, 'outerHeight', 0) - height) / 2

  return `width=${width},height=${height},left=${left},top=${top}`
}

function OAuthPopup({
  dismissAutomatically,
  url,
  windowHeight,
  windowWidth,
  onFinish,
  onMount,
}) {
  const locationQueryParams = useLocationQueryParams()
  const popupWindow = useRef(null)
  const animationFrame = useRef(null)

  const dismiss = useCallback(() => {
    if (!popupWindow.current) return
    popupWindow.current.close()
  }, [])

  const tick = useCallback(() => {
    try {
      // TODO: might not work with this hook
      const token = get(locationQueryParams, 'token')
      // --
      if (token) {
        if (onFinish) {
          onFinish(token)
        }

        if (dismissAutomatically) {
          dismiss()
        }
      }
    } catch (error) {
      //
    } finally {
      setTimeout(() => {
        if (!popupWindow.current.closed) {
          window.requestAnimationFrame(tick)
        }
      }, ANIMATION_FRAME_DELAY)
    }
  }, [dismiss, dismissAutomatically, locationQueryParams, onFinish])

  const present = useCallback(
    params => {
      if (!window) return

      const windowURL = get(params, 'url') || url
      if (!windowURL) {
        throw new Error('No URL provided')
      }

      popupWindow.current = window.open(
        windowURL,
        '_blank',
        windowParams(windowWidth, windowHeight),
      )

      animationFrame.current = requestAnimationFrame(tick)
    },
    [tick, url, windowHeight, windowWidth],
  )

  useEffect(() => {
    EventBus.on(ACTIONS.PRESENT, present)
    EventBus.on(ACTIONS.DISMISS, dismiss)

    return () => {
      EventBus.off(ACTIONS.PRESENT)
      EventBus.off(ACTIONS.DISMISS)
    }
  }, [dismiss, present])

  useEffect(() => {
    onMount({
      dismiss,
      present,
    })
  }, [dismiss, present, onMount])

  return null
}

OAuthPopup.defaultProps = {
  dismissAutomatically: true,
  url: null,
  windowHeight: 600,
  windowWidth: 500,
  onFinish: noop,
  onMount: noop,
}

OAuthPopup.propTypes = {
  dismissAutomatically: PropTypes.bool,
  url: PropTypes.string,
  windowHeight: PropTypes.number,
  windowWidth: PropTypes.number,
  onFinish: PropTypes.func,
  onMount: PropTypes.func,
}

OAuthPopup.present = (...args) => EventBus.trigger(ACTIONS.PRESENT, ...args)
OAuthPopup.dismiss = (...args) => EventBus.trigger(ACTIONS.DISMISS, ...args)

export default OAuthPopup
