import {
  FUND_SOURCE,
  INVESTING_EXPERIENCE,
  INVESTMENT_HORIZON,
  RISK_TOLERANCE,
} from 'Constants/ids'

const INVESTING_EXPERIENCE_TEXTS = {
  [INVESTING_EXPERIENCE.NONE]: 'data.riskAssessment.investingExperience.none',
  [INVESTING_EXPERIENCE.LOW]: 'data.riskAssessment.investingExperience.low',
  [INVESTING_EXPERIENCE.MEDIUM]:
    'data.riskAssessment.investingExperience.medium',
  [INVESTING_EXPERIENCE.HIGH]: 'data.riskAssessment.investingExperience.high',
}

function investingExperienceText(value) {
  return (
    INVESTING_EXPERIENCE_TEXTS[value] ||
    'data.riskAssessment.investingExperience.unknown'
  )
}

const RISK_TOLERANCE_TEXTS = {
  [RISK_TOLERANCE.CONSERVATIVE]: 'data.riskAssessment.riskTolerance.low',
  [RISK_TOLERANCE.MODERATE]: 'data.riskAssessment.riskTolerance.medium',
  [RISK_TOLERANCE.AGGRESSIVE]: 'data.riskAssessment.riskTolerance.high',
}

function riskToleranceText(priority) {
  return (
    RISK_TOLERANCE_TEXTS[priority] ||
    'data.riskAssessment.riskTolerance.unknown'
  )
}

const INVESTMENT_HORIZON_TEXTS = {
  [INVESTMENT_HORIZON.LOW]: 'data.riskAssessment.investmentHorizon.low',
  [INVESTMENT_HORIZON.MEDIUM]: 'data.riskAssessment.investmentHorizon.medium',
  [INVESTMENT_HORIZON.HIGH]: 'data.riskAssessment.investmentHorizon.high',
}

function investmentHorizonText(horizon) {
  return (
    INVESTMENT_HORIZON_TEXTS[horizon] ||
    'data.riskAssessment.investmentHorizon.unknown'
  )
}

const FUND_SOURCE_TEXTS = {
  [FUND_SOURCE.SALARY]: 'data.kyc.fundSource.salary',
  [FUND_SOURCE.PROPERTY_SALES]: 'data.kyc.fundSource.propertySale',
  [FUND_SOURCE.SAVINGS]: 'data.kyc.fundSource.savings',
  [FUND_SOURCE.INVESTMENT_GAINS]: 'data.kyc.fundSource.investmentGains',
  [FUND_SOURCE.DONATION]: 'data.kyc.fundSource.donation',
  [FUND_SOURCE.LOAN]: 'data.kyc.fundSource.bankLoan',
  [FUND_SOURCE.LEGACY]: 'data.kyc.fundSource.legacy',
  [FUND_SOURCE.GAMBLING]: 'data.kyc.fundSource.gambling',
  [FUND_SOURCE.DIVIDENDS]: 'data.kyc.fundSource.dividends',
  [FUND_SOURCE.OTHER]: 'data.kyc.fundSource.other',
}

function fundSourceText(value) {
  return FUND_SOURCE_TEXTS[value] || 'data.kyc.fundSource.unknown'
}

export default {
  investingExperienceText,
  investmentHorizonText,
  riskToleranceText,
  fundSourceText,
}
