import styled from 'styled-components'
import { margin } from '@styled-system/space'

export const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  ${margin}
`
