import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { color } from '@styled-system/color'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'
import { typography } from '@styled-system/typography'

const linkEllipsisCss = ({ ellipsis }) =>
  ellipsis &&
  css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

const Link = styled(NavLink)`
  display: flex;
  text-decoration: none;
  font-size: 16px;
  color: ${themeGet('colors.primary')};

  ${color}
  ${layout.width}
  ${layout.height}
  ${margin}
  ${typography}


  ${linkEllipsisCss};
`

Link.defaultProps = {
  color: undefined,
  target: undefined,
}

Link.propTypes = {
  color: PropTypes.string,
  target: PropTypes.string,
  to: PropTypes.string.isRequired,
}

export default Link
