import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 400px;
  padding: ${themeGet('space.4')}px ${themeGet('space.4')}px
    ${themeGet('space.5')}px;
`

export const Subtitle = styled.div`
  ${Styles.text(14)}
  ${margin}
`

export const Text = styled.div`
  ${Styles.text()}
  ${margin}
`
