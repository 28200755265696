import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { DateTime } from 'luxon'
import numeral from 'numeral'

import { Column, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { CapitalizedFirstLetter, Detail } from './styles'

function ExpertPortfolioDetails({ expertPortfolio, ...rest }) {
  const s = useScopedI18n('blocks.expertPortfolio.info.details')

  return (
    <Column {...pick(rest)}>
      <Text fontWeight={2} heading5>
        {s('title')}
      </Text>

      <Column mt={3}>
        <Detail>
          <Text fontWeight={1} heading={6}>
            {s('inceptionDate')}
          </Text>
          <Text fontWeight={1} heading={6}>
            {DateTime.fromISO(expertPortfolio?.createdAt).toFormat(
              'MMMM d, yyyy',
            )}
          </Text>
        </Detail>

        <Detail>
          <Text fontWeight={1} heading={6}>
            {s('category')}
          </Text>
          <Text fontWeight={1} heading={6}>
            {expertPortfolio?.categories?.join(', ')}
          </Text>
        </Detail>

        <Detail>
          <Text fontWeight={1} heading={6}>
            {s('marketCapitalization')}
          </Text>
          <Text fontWeight={1} heading={6}>
            {expertPortfolio?.marketCapitalization}
          </Text>
        </Detail>

        <Detail>
          <Text fontWeight={1} heading={6}>
            {s('averageMarketCapitalization')}
          </Text>
          <Text fontWeight={1} heading={6}>
            {expertPortfolio?.averageMarketCapitalization}
          </Text>
        </Detail>

        <Detail>
          <Text fontWeight={1} heading={6}>
            {s('returnYTD')}
          </Text>
          <Text fontWeight={1} heading={6}>
            {expertPortfolio?.returnYtd}
          </Text>
        </Detail>

        <Detail>
          <Text fontWeight={1} heading={6}>
            {s('managementFee')}
          </Text>
          <Text fontWeight={1} heading={6}>
            {numeral(expertPortfolio?.managementFee).format('0.00')}% AUM per
            year
          </Text>
        </Detail>

        <Detail>
          <Text fontWeight={1} heading={6}>
            {s('performanceFee')}
          </Text>
          <Text fontWeight={1} heading={6}>
            {numeral(expertPortfolio?.performanceFee).format('0.00')}% AUM per
            year
          </Text>
        </Detail>
        <Detail>
          <Text fontWeight={1} heading={6}>
            {s('minimumInvestment')}
          </Text>
          <Text fontWeight={1} heading={6}>
            ${expertPortfolio?.minimumInvestment}
          </Text>
        </Detail>
        <Detail>
          <Text fontWeight={1} heading={6}>
            {s('withdrawalFrequency')}
          </Text>

          <CapitalizedFirstLetter>
            <Text fontWeight={1} heading={6}>
              {expertPortfolio?.withdrawFrequency}
            </Text>
          </CapitalizedFirstLetter>
        </Detail>
      </Column>
    </Column>
  )
}

ExpertPortfolioDetails.defaultProps = {
  expertPortfolio: {},
}

ExpertPortfolioDetails.propTypes = {
  expertPortfolio: PropTypes.object,
}
export default ExpertPortfolioDetails
