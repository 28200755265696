import exchangeIntegrationsQuery from 'GraphQL/Queries/ExchangeIntegrations/exchangeIntegrations.graphql'

import Utils from 'Utils'

function add(store, data) {
  try {
    Utils.GraphQL.updateList(
      store,
      exchangeIntegrationsQuery,
      data?.data?.addExchangeIntegration,
      'exchangeIntegrations',
    )
  } catch (error) {
    //
  }
}

function remove(id) {
  return function update(store) {
    try {
      Utils.GraphQL.removeFromList(
        store,
        exchangeIntegrationsQuery,
        id,
        'exchangeIntegrations',
      )
    } catch (error) {
      //
    }
  }
}

export default { add, remove }
