import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'

import { Button } from 'Components/UI'
import { InputField, InputLabels, PhoneInputField } from 'Components/UI/Forms'

import updateOnboardingStepsMutation from 'GraphQL/Mutations/User/updateOnboardingSteps.graphql'
import updateProfileMutation from 'GraphQL/Mutations/User/updateProfile.graphql'

import { useAppContext } from 'Hooks'

import { ONBOARDING_PORTFOLIO } from 'Router/routes'

import _ from 'Services/I18n'

import Utils from 'Utils'

import { Container, FormContent } from './styles'

const FIELDS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
}
const PHONE_VALIDATOR = Utils.Form.phoneValidator()

function Profile() {
  const [loading, setLoading] = useState(false)
  const { me } = useAppContext()
  const history = useHistory()

  const [updateProfile] = useMutation(updateProfileMutation)
  const [updateOnboardingSteps] = useMutation(updateOnboardingStepsMutation)

  const renderForm = useCallback(
    ({ handleSubmit }) => (
      <FormContent>
        <InputLabels title={_('onboarding.profile.firstName')}>
          <InputField
            input={{
              placeholder: _('onboarding.profile.firstNamePlaceholder'),
            }}
            name={FIELDS.FIRST_NAME}
          />
        </InputLabels>
        <InputLabels mt={4} title={_('onboarding.profile.lastName')}>
          <InputField
            input={{
              placeholder: _('onboarding.profile.lastNamePlaceholder'),
            }}
            name={FIELDS.LAST_NAME}
          />
        </InputLabels>
        <InputLabels mt={4} title={_('onboarding.profile.phone')}>
          <PhoneInputField
            input={{ placeholder: _('onboarding.profile.phonePlaceholder') }}
            name={FIELDS.PHONE}
          />
        </InputLabels>
        <Button disabled={loading} mt={6} width={162} onClick={handleSubmit}>
          {_('onboarding.profile.action')}
        </Button>
      </FormContent>
    ),
    [loading],
  )

  const initialValues = useMemo(
    () => ({
      [FIELDS.FIRST_NAME]: get(me, ['profile', 'firstName']),
      [FIELDS.LAST_NAME]: get(me, ['profile', 'lastName']),
      [FIELDS.PHONE]: get(me, ['profile', 'phone']),
    }),
    [me],
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.FIRST_NAME]: {
        presence: {
          presence: true,
          message: `^${_('onboarding.profile.firstNameRequired')}`,
        },
      },
      [FIELDS.LAST_NAME]: {
        presence: {
          presence: true,
          message: `^${_('onboarding.profile.lastNameRequired')}`,
        },
      },
      [FIELDS.PHONE]: {
        [PHONE_VALIDATOR]: {
          [PHONE_VALIDATOR]: true,
          message: `^${_('onboarding.profile.phoneInvalid')}`,
        },
      },
    }),
    [],
  )

  const handleSubmit = useCallback(
    async values => {
      try {
        setLoading(true)

        await updateProfile({
          variables: {
            firstName: get(values, FIELDS.FIRST_NAME),
            lastName: get(values, FIELDS.LAST_NAME),
            phone: get(values, FIELDS.PHONE),
          },
        })

        await updateOnboardingSteps({
          variables: {
            profile: true,
          },
        })

        history.push(ONBOARDING_PORTFOLIO)
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
        setLoading(false)
      }
    },
    [updateProfile, updateOnboardingSteps, history],
  )

  return (
    <Container>
      <Form
        initialValues={initialValues}
        render={renderForm}
        validate={values => validate(values, formConstraints)}
        onSubmit={handleSubmit}
      />
    </Container>
  )
}

export default Profile
