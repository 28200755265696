import React from 'react'

import TransactionsComponent from 'Components/Blocks/Transactions'
import { Column, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { BlockContainer } from './styles'

function Transactions() {
  const s = useScopedI18n('transactions')

  return (
    <BlockContainer>
      <Column>
        <Text fontWeight={2} heading3>
          {s('title')}
        </Text>
      </Column>

      <TransactionsComponent mt={5} />
    </BlockContainer>
  )
}

export default Transactions
