import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import { Row, Select } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms/Input'

import { REBALANCE_TYPE } from 'Constants/portfolios'

import _ from 'Services/I18n'

import Utils from 'Utils'

const FIELDS = {
  REBALANCE_TYPE: 'rebalanceType',
  REBALANCE_VALUE: 'rebalanceValue',
}

function PortfolioRebalanceForm({
  disabled,
  portfolio,
  onChange,
  onFinish,
  onMount,
  ...rest
}) {
  const formRef = useRef(null)

  const rebalanceOptions = useMemo(
    () => [
      {
        label: _(
          Utils.UserPortfolios.rebalanceTypeText({
            rebalanceType: REBALANCE_TYPE.THRESHOLD,
          }),
        ),
        value: REBALANCE_TYPE.THRESHOLD,
      },
      {
        label: _(
          Utils.UserPortfolios.rebalanceTypeText({
            rebalanceType: REBALANCE_TYPE.PERIOD,
          }),
        ),
        value: REBALANCE_TYPE.PERIOD,
      },
    ],
    [],
  )

  const initialValues = useMemo(() => {
    if (!portfolio) return undefined

    return {
      [FIELDS.REBALANCE_TYPE]:
        rebalanceOptions[
          portfolio.rebalanceType === REBALANCE_TYPE.THRESHOLD ? 0 : 1
        ],
      [FIELDS.REBALANCE_VALUE]: portfolio.rebalanceValue,
    }
  }, [portfolio, rebalanceOptions])

  const renderForm = useCallback(
    ({ form, values }) => {
      if (!formRef.current) {
        formRef.current = form
      }

      let rebalanceValueAccessory
      const rebalanceType = values[FIELDS.REBALANCE_TYPE]?.value
      if (rebalanceType === REBALANCE_TYPE.PERIOD) {
        rebalanceValueAccessory = _('general.days')
      }
      if (rebalanceType === REBALANCE_TYPE.THRESHOLD) {
        rebalanceValueAccessory = '%'
      }

      return (
        <>
          <FormSpy
            subscription={{ valid: true, values: true }}
            onChange={onChange}
          />
          <InputLabels mt={3} title={_('portfolio.info.rebalance')} width={1}>
            <Row gap="20px" width={1}>
              <Row width="70%">
                <Field
                  defaultValue={rebalanceOptions[0]}
                  name={FIELDS.REBALANCE_TYPE}
                >
                  {({ input: { value, onChange: handleChange } }) => (
                    <Select
                      isDisabled={disabled}
                      options={rebalanceOptions}
                      value={value}
                      withPortal
                      onChange={handleChange}
                    />
                  )}
                </Field>
              </Row>

              <InputField
                displayError={false}
                input={{
                  accessory: rebalanceValueAccessory,
                  accessoryWidth: 45,
                  disabled,
                }}
                name={FIELDS.REBALANCE_VALUE}
              />
            </Row>
          </InputLabels>
        </>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rebalanceOptions, onChange, onMount],
  )

  useEffect(() => {
    onMount(formRef.current)
  }, [onMount])

  return (
    <Row {...pick(rest)}>
      <Form
        initialValues={initialValues}
        render={renderForm}
        onSubmit={onFinish}
      />
    </Row>
  )
}

PortfolioRebalanceForm.defaultProps = {
  disabled: false,
  portfolio: null,
  onChange: noop,
  onFinish: noop,
  onMount: noop,
}

PortfolioRebalanceForm.propTypes = {
  disabled: PropTypes.bool,
  portfolio: PropTypes.object,
  onChange: PropTypes.func,
  onFinish: PropTypes.func,
  onMount: PropTypes.func,
}

export default PortfolioRebalanceForm
