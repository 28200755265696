export const CRYPTO_TOKEN_KIND = {
  STABLE_COIN: 'stableCoin',
  COIN: 'coin',
  ALT_COIN: 'altCoin',
}

export const BANNED_TOKENS_SET = new Set([
  'XMR',
  'ZEC',
  'ROSE',
  'WLD',
  'SNT',
  'ZEN',
  'MOB',
  'RLC',
  'SCRT',
  'DUSK',
  'FIRO',
  'PIVX',
])
