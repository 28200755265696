import DayPicker from 'react-day-picker'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Picker = styled(DayPicker)`
  background-color: ${themeGet('colors.bg.primary')};
  box-shadow: ${themeGet('shadow')};

  .DayPicker-Day {
    border-radius: 0 !important;

    :hover & {
      background-color: ${themeGet('colors.primary')}17;
    }
  }

  .DayPicker-Day--start,
  .DayPicker-Day--end {
    background-color: ${themeGet('colors.primary')} !important;
    color: ${themeGet('colors.bg.primary')} !important;
  }

  .DayPicker-Day--selected {
    border: none !important;
    outline: none !important;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${themeGet('colors.primary')}17 !important;
    color: ${themeGet('colors.primary')};
  }
`
