import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

const width = mapToTheme('switches.width')
const height = mapToTheme('switches.height')
const handleSize = mapToTheme('switches.handleSize')
const handleOffset = mapToTheme('switches.handleOffset')

export const StyledSwitch = styled.div`
  border-radius: ${mapToTheme('switches.borderRadius')}px;
  cursor: pointer;
  transition: all ${themeGet('transitionTime.default')};
  width: ${width}px;
  height: ${height}px;

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    width: ${handleSize}px;
    height: ${handleSize}px;
    border-radius: calc(${handleSize}px / 2);
    transition: all ${themeGet('transitionTime.default')};
  }
`

export const InvisibleCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  background: none;
  border: none;
  opacity: 0;
  pointer-events: none;
`

export const Container = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  gap: ${themeGet('space.2')}px;
  width: max-content;

  > input {
    &:not(:checked) ~ ${StyledSwitch} {
      &:after {
        transform: translate(${handleOffset}px, -50%);
        background-color: ${mapToTheme('switches.buttonColor')};
      }
    }

    &:checked ~ ${StyledSwitch} {
      &:after {
        --transform: calc(${width}px - ${handleSize}px - ${handleOffset}px);
        transform: translate(var(--transform), -50%);
        background-color: ${mapToTheme('switches.buttonColor')};
      }
    }

    &:enabled:not(:checked) ~ ${StyledSwitch} {
      background-color: ${mapToTheme('switches.unselected.backgroundColor')};

      &:hover {
        background-color: ${mapToTheme('switches.unselected.backgroundColor')};
      }

      &:focus {
        background-color: ${mapToTheme(
          'switches.unselected.focus.backgroundColor',
        )};
        box-shadow: 0 0 0 3px
          ${mapToTheme('switches.unselected.focus.boxShadow')};
      }
    }

    &:enabled:checked ~ ${StyledSwitch} {
      background-color: ${mapToTheme('switches.selected.backgroundColor')};

      &:hover {
        background-color: ${mapToTheme(
          'switches.selected.hover.backgroundColor',
        )};
      }

      &:focus {
        background-color: ${mapToTheme(
          'switches.selected.focus.backgroundColor',
        )};
        box-shadow: 0 0 0 3px ${mapToTheme('switches.selected.focus.boxShadow')};
      }
    }

    &:disabled ~ ${StyledSwitch} {
      cursor: default;
      background-color: ${mapToTheme(
        'switches.unselected.disabled.backgroundColor',
      )};
    }

    &:disabled:checked ~ ${StyledSwitch} {
      background-color: ${mapToTheme('switches.disabled.backgroundColor')};
    }
  }

  ${margin}
`
