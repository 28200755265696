import { RiLoader4Line } from 'react-icons/ri'

import styled, { keyframes } from 'styled-components'
import { layout } from '@styled-system/layout'
import { themeGet } from '@styled-system/theme-get'

const animation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const AnimatedIcon = styled(RiLoader4Line)`
  animation: ${animation} 1.75s infinite linear;
  color: ${themeGet('colors.primary50')};

  ${layout.size}
`
