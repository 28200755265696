import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'
import { createBrowserHistory } from 'history'
import { StoreContext } from 'storeon/react'

import apiCall from 'Services/Api'
import { createApolloClient } from 'Services/Apollo'
import { initTranslations } from 'Services/I18n'
import PortfolioService from 'Services/Portfolio'
import shared from 'Services/Shared'
import { createStore } from 'Services/Store'

import { theme, ThemeProvider } from 'Theme'

import App from './App'

shared.setApi(apiCall())
shared.setPortfolioService(new PortfolioService())

const store = createStore()

initTranslations({ store })

const history = createBrowserHistory()

const client = createApolloClient({ history })

export default function Container() {
  return (
    <ApolloProvider client={client}>
      <StoreContext.Provider value={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter history={history}>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </StoreContext.Provider>
    </ApolloProvider>
  )
}
