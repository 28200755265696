import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'
import Add2FACoordinator from 'Coordinators/Add2FA'
import { DateTime } from 'luxon'

import get from 'lodash/get'

// import CurrencyDropdown from 'Components/Blocks/CurrencyDropdown'
import LanguageDropdown from 'Components/Blocks/LanguageDropdown'
import { Disable2FAModal } from 'Components/Blocks/Modals/2FA'
import Dialog from 'Components/Blocks/Modals/Dialog'
import { Button, Column, Dropzone, Loader, Row, Text } from 'Components/UI'

import { KYC_STATUS } from 'Constants/ids'

import resetPasswordMutation from 'GraphQL/Mutations/Auth/resetPassword.graphql'
import updateProfileMutation from 'GraphQL/Mutations/User/updateProfile.graphql'

import { useAppContext } from 'Hooks'

import { KYC, SIGN_OUT } from 'Router/routes'

import FileService from 'Services/File'
import _, { useScopedI18n } from 'Services/I18n'

import Utils from 'Utils'

import Form from './Form'
import RiskTolerance from './RiskTolerance'
import { BlockContainer, CancelIcon, CheckIcon, Divider } from './styles'

// TODO: uncomment everything when the design and logic of the rest are confirmed
function Profile() {
  const history = useHistory()
  const s = useScopedI18n('profile')
  const { me } = useAppContext()

  const [updateProfile] = useMutation(updateProfileMutation)
  const [resetPassword] = useMutation(resetPasswordMutation)

  const [imageUploading, setImageUploading] = useState(false)
  const [
    resetPasswordDialogPresented,
    setResetPasswordDialogPresented,
  ] = useState(false)
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)

  const [show2FADisableModal, setShow2FADisableModal] = useState(false)

  const handleDropzoneChange = useCallback(
    async ({ file }) => {
      try {
        let photoUrl = null

        if (file) {
          setImageUploading(true)
          const { url } = await FileService.uploadProfileImage(file)
          if (url) {
            photoUrl = url
          }
        }

        await updateProfile({ variables: { photoUrl } })
        toast.success(s('toasts.success'))
      } catch (error) {
        toast.error(error?.message || _('error.generic'))
      } finally {
        setImageUploading(false)
      }
    },
    [updateProfile, s],
  )

  const handleResetPasswordConfirmation = useCallback(
    async confirmation => {
      if (!confirmation) return

      try {
        setResetPasswordLoading(true)

        await resetPassword({
          variables: { email: get(me, ['emailCredentials', 0, 'email']) },
        })

        history.push(SIGN_OUT)
      } catch (error) {
        setResetPasswordLoading(false)
        toast.error(error?.message || _('error.generic'))
      }
    },
    [history, me, resetPassword],
  )

  const handle2FAClick = useCallback(() => {
    if (me?.verificationTOTPEnabled) {
      setShow2FADisableModal(true)
      return
    }
    Add2FACoordinator.start()
  }, [me])

  const handleVerifyIdentity = useCallback(() => {
    history.push(KYC)
  }, [history])

  const kyc = me?.kyc

  return (
    <Row alignStart fullHeight gap={4}>
      <BlockContainer height="100%">
        <Column center width="300px">
          <Dropzone
            height="100px"
            imageURL={me?.profile?.photoUrl}
            loading={imageUploading}
            width="100px"
            onChange={handleDropzoneChange}
          />
          <Form mt={4} />
          <RiskTolerance
            mt={4}
            value={me?.profile?.riskAssessment?.riskTolerance}
          />
        </Column>
      </BlockContainer>

      <BlockContainer width={1}>
        <Column>
          <Text fontWeight={600} heading5>
            {s('status.title')}
          </Text>
          <Row center gap={1} mt={3}>
            {Utils.UserKYC.getStatus(kyc?.state) !== KYC_STATUS.SUCCESS ? (
              <Column gap={3}>
                <Row>
                  <CancelIcon />
                  <Text actionMedium color="dangerPrimary">
                    {s('status.kyc.notVerified')}
                  </Text>
                </Row>
                <Button outline onClick={handleVerifyIdentity}>
                  <Text heading6>{s('status.kyc.verifyYourIdentity')}</Text>
                </Button>
              </Column>
            ) : (
              <>
                <CheckIcon />
                <Text actionMedium color="primary50">
                  {s('status.kyc.verified')}
                </Text>
              </>
            )}
          </Row>
        </Column>

        <Divider my={4} />

        <Text fontWeight={600} heading5>
          {s('settings.title')}
        </Text>

        <Row gap={3} mt={4}>
          <LanguageDropdown gap={1} width="200px" />
          {/* <CurrencyDropdown width="100%" /> */}
        </Row>

        <Divider my={4} />

        <Text fontWeight={600} heading5>
          {s('passwordAuth.title')}
        </Text>

        <Column gap={3}>
          {/* <Row gap={4} mt={4}>
            <Input disabled value={me?.emailCredentials?.[0]?.email} />
            <Button outline width="162px">
              <Text heading6>{s('passwordAuth.changeEmail')}</Text>
            </Button>
          </Row> */}

          <Row gap={4} mt={4}>
            <Button
              disabled={resetPasswordLoading}
              outline
              onClick={() => setResetPasswordDialogPresented(true)}
            >
              <Text heading6>{s('passwordAuth.changePassword')}</Text>
              {resetPasswordLoading && <Loader />}
            </Button>
          </Row>
        </Column>

        <Column mt={3}>
          <Text fontWeight={600} heading5>
            {s('twoFactorAuth.title')}
          </Text>
          <Text extraSmall mt={1}>
            {s('twoFactorAuth.description')}
          </Text>
          <Button mt={3} outline width="162px" onClick={handle2FAClick}>
            <Text heading6>
              {me?.verificationTOTPEnabled
                ? s('twoFactorAuth.actions.disable')
                : s('twoFactorAuth.actions.enable')}
            </Text>
          </Button>
        </Column>

        <Divider my={4} />

        <Text fontWeight={600} heading5>
          {s('activity.title')}
        </Text>

        <Column gap={1} mt={4}>
          <Row center gap={1}>
            <Text color="text70" extraSmall>
              {s('activity.lastLogin')}
            </Text>
            <Text extraSmall>
              {me &&
                DateTime.fromISO(me.lastLoginAt).toLocaleString(
                  DateTime.DATETIME_SHORT,
                )}
            </Text>
          </Row>
          {/* <Row center gap={1}>
            <Text extraSmall>{s('activity.suspiciousActivity')}</Text>
            <Button small text>
              <Text extraSmall>{s('activity.actions.disable')}</Text>
            </Button>
          </Row> */}
        </Column>

        {/* <Button
            disabled={resetPasswordLoading}
            mt={3}
            // width={BUTTONS_WIDTH}
            onClick={() => setResetPasswordDialogPresented(true)}
          >
            {s('resetPassword.action')}
          </Button> */}

        <Dialog
          confirmText={s('resetPassword.action')}
          isOpen={resetPasswordDialogPresented}
          title={s('resetPassword.title')}
          onClose={() => setResetPasswordDialogPresented(false)}
          onFinish={handleResetPasswordConfirmation}
        />

        {show2FADisableModal && (
          <Disable2FAModal
            isOpen
            onClose={() => setShow2FADisableModal(false)}
            onFinish={() => setShow2FADisableModal(false)}
          />
        )}
      </BlockContainer>
    </Row>
  )
}

export default Profile
