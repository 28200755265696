import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Board = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${themeGet('colors.bg.main')};
  border-radius: 12px;
  margin-top: 20px;
  padding: 20px;
`
