import { FiCheck } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { width } from 'styled-system'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

// TODO: map to theme
export const Icon = styled(FiCheck)`
  display: none;
  position: absolute;
  width: 12px;
  height: 12px;
  stroke-width: 3px;
  color: ${mapToTheme('checkboxes.color')};
  left: 2px;
  pointer-events: none;
`

export const Background = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;

  background-color: ${mapToTheme('checkboxes.backgroundColor')};
  border: 1px solid ${mapToTheme('checkboxes.borderColor')};
  border-radius: ${mapToTheme('checkboxes.borderRadius')}px;
  cursor: pointer;
  user-select: none;
`

export const Container = styled.label`
  --size: 16px;

  position: relative;
  display: flex;
  align-items: center;
  min-height: var(--size);
  user-select: none;

  > input {
    width: var(--size);
    height: var(--size);
    margin: 0;
    opacity: 0;
    cursor: pointer;

    :hover ~ ${Background} {
      border-color: ${mapToTheme('checkboxes.hover.borderColor')};
    }

    :focus ~ ${Background} {
      border-color: ${mapToTheme('checkboxes.focus.borderColor')};
      box-shadow: 0 0 0 3px ${mapToTheme('checkboxes.focus.boxShadow')};
    }

    :checked ~ ${Icon} {
      display: block;
    }

    :checked ~ ${Background} {
      background-color: ${mapToTheme('checkboxes.checked.backgroundColor')};
      border-color: transparent;
    }

    :disabled:not(:checked) ~ ${Background} {
      background-color: ${mapToTheme('checkboxes.disabled.backgroundColor')};
      border-color: ${mapToTheme('checkboxes.disabled.borderColor')};
      border-width: ${mapToTheme('checkboxes.disabled.borderWidth')}px;
    }

    :disabled:checked ~ ${Background} {
      background-color: ${mapToTheme(
        'checkboxes.checked.disabled.backgroundColor',
      )};
      border-color: ${mapToTheme('checkboxes.checked.disabled.borderColor')};
    }
  }

  ${margin}
  ${width}
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  ${margin}
`

const textErrorCss = ({ error }) =>
  error &&
  css`
    color: ${themeGet('colors.negative')};
  `

export const Text = styled.div`
  min-height: 16px;

  ${margin}
  ${Styles.text()}
  ${textErrorCss}
`
