import React, { useCallback } from 'react'
import { Field } from 'react-final-form'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import numeral from 'numeral'

import { Button, Column, Loader, Row, Select, Text } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms'

import { PRIMARY_CURRENCY } from 'Constants/currencies'

import { useAppContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import {
  FIELD,
  WITHDRAW_CRYPTO_NETWORK_OPTIONS,
  WITHDRAW_CRYPTO_TOKENS_BY_NETWORK,
} from './constants'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  FormContainer,
  TetherIcon,
} from './styles'

const CURRENCY_OPTIONS = [
  {
    label: (
      <Row center>
        <TetherIcon mr={2} />
        <Text small> ${PRIMARY_CURRENCY} (Tether)</Text>
      </Row>
    ),
    value: PRIMARY_CURRENCY,
  },
]

function formatOption(option) {
  return (
    <Column>
      <Text fontWeight={2} small>
        {option.label}
      </Text>

      {WITHDRAW_CRYPTO_TOKENS_BY_NETWORK[option.value] && (
        <Text fontSize={1} small>
          {WITHDRAW_CRYPTO_TOKENS_BY_NETWORK[option.value]}
        </Text>
      )}
    </Column>
  )
}

function FormFields({ handleSubmit, valid, loading, balance }) {
  const history = useHistory()
  const s = useScopedI18n('withdrawal.crypto.form')
  const theme = useTheme()
  const { me } = useAppContext()

  const handleBack = useCallback(
    event => {
      event.preventDefault()
      history.goBack()
    },
    [history],
  )

  return (
    <FormContainer gap={4}>
      <Column>
        <InputLabels title={`${s('currencyLabel')}`}>
          <Field defaultValue={CURRENCY_OPTIONS[0]} name={FIELD.CURRENCY}>
            {({ input: { value, onChange: handleChange } }) => (
              <Select
                options={CURRENCY_OPTIONS}
                value={value}
                withPortal
                onChange={handleChange}
              />
            )}
          </Field>
        </InputLabels>

        <InputLabels mt={4} title={`${s('networkLabel')}`}>
          <Field
            defaultValue={WITHDRAW_CRYPTO_NETWORK_OPTIONS[0]}
            name={FIELD.NETWORK}
          >
            {({ input: { value, onChange: handleChange } }) => (
              <Select
                formatOptionLabel={formatOption}
                options={WITHDRAW_CRYPTO_NETWORK_OPTIONS}
                value={value}
                withPortal
                onChange={handleChange}
              />
            )}
          </Field>
        </InputLabels>

        <InputLabels mt={4} title={`${s('addressLabel')}`}>
          <InputField
            input={{ placeholder: s('addressPlaceholder') }}
            name={FIELD.ADDRESS}
          />
        </InputLabels>

        <InputLabels mt={4} title={`${s('amountLabel')}`}>
          <InputField
            input={{
              placeholder: s('amountPlaceholder'),
              renderAfter: (
                <Row center mr={2}>
                  <Text small>USDT</Text>
                  <TetherIcon ml={2} />
                </Row>
              ),
            }}
            name={FIELD.AMOUNT}
          />
          <Row mt={1}>
            <Text color={theme.colors.text70} extraSmall>
              {s('availableBalance')}: <b>{numeral(balance).format('0.00')}</b>
              (USDT)
            </Text>
          </Row>
        </InputLabels>

        {me?.verificationTOTPEnabled && (
          <InputLabels mt={4} title={`${s('codeLabel')}`}>
            <InputField
              input={{
                placeholder: s('codePlaceholder'),
              }}
              name={FIELD.CODE}
            />
          </InputLabels>
        )}
      </Column>

      <Row fullWidth spaceBetween>
        <Button gap={3} outline onClick={handleBack}>
          <ArrowLeftIcon height={14} viewBox="0 0 24 24" width={14} />
          {s('back')}
        </Button>
        <Button disabled={!valid || loading} gap={3} onClick={handleSubmit}>
          {s('action')}
          <ArrowRightIcon height={14} viewBox="0 0 24 24" width={14} />
          {loading && <Loader ml={1} />}
        </Button>
      </Row>
    </FormContainer>
  )
}

FormFields.propTypes = {
  balance: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
}

export default FormFields
