import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { noDataGlyph } from 'Assets/Svg'

import { gap } from 'Theme/system'

export const Block = styled.div`
  display: flex;
  padding: 20px;
  gap: ${themeGet('space.3')}px;
  border-radius: 12px;
  background-color: ${themeGet('colors.bg.main')};
  flex-direction: column;

  ${margin};
`

export const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${themeGet('colors.divider.light')};

  ${gap}
`

const tabActiveCss = ({ active }) =>
  active &&
  css`
    color: ${themeGet('colors.text100')};
    border-bottom-color: ${themeGet('colors.primary50')};
  `

export const Tab = styled.button`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: ${themeGet('space.3')}px ${themeGet('space.3')}px;
  border-bottom: 2px solid transparent;
  color: ${themeGet('colors.text70')};
  display: flex;
  justify-content: center;
  cursor: pointer;

  :hover {
    color: ${themeGet('colors.text100')};
    border-bottom-color: ${themeGet('colors.primary50')};
  }

  ${tabActiveCss}
`

export const NoAssetsIcon = styled(noDataGlyph)``
