import styled from 'styled-components'
import { margin } from 'styled-system'
import themeGet from '@styled-system/theme-get'

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${themeGet('colors.divider.light')};

  ${margin};
`

export default Divider
