export const FIELD = {
  CURRENCY: 'currency',
  NETWORK: 'network',
  ADDRESS: 'address',
  AMOUNT: 'amount',
  CODE: 'code',
}

const WITHDRAW_CRYPTO_NETWORKS = {
  BSC: 'BSC',
  ETH: 'ETH',
  TRX: 'TRX',
  MATIC: 'MATIC',
}

export const WITHDRAW_CRYPTO_TOKENS_BY_NETWORK = {
  [WITHDRAW_CRYPTO_NETWORKS.BSC]: 'BNB Smart Chain (BEP20)',
  [WITHDRAW_CRYPTO_NETWORKS.ETH]: 'Ethereum (ERC20)',
  [WITHDRAW_CRYPTO_NETWORKS.TRX]: 'Tron (TRC 20)',
  [WITHDRAW_CRYPTO_NETWORKS.MATIC]: 'Polygon',
}

export const WITHDRAW_CRYPTO_NETWORK_OPTIONS = [
  { label: WITHDRAW_CRYPTO_NETWORKS.BSC, value: WITHDRAW_CRYPTO_NETWORKS.BSC },
  { label: WITHDRAW_CRYPTO_NETWORKS.ETH, value: WITHDRAW_CRYPTO_NETWORKS.ETH },
  { label: WITHDRAW_CRYPTO_NETWORKS.TRX, value: WITHDRAW_CRYPTO_NETWORKS.TRX },
  {
    label: WITHDRAW_CRYPTO_NETWORKS.MATIC,
    value: WITHDRAW_CRYPTO_NETWORKS.MATIC,
  },
]
