import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { noop } from 'lodash'

import Utils from 'Utils'

import Input from './Input'

import { Label } from '../styles'

// TODO: might be better to move error label to input component
//  this will also help with displaying error inconsistency
function InputField({
  checkErrorIfDirty,
  displayError,
  format,
  input,
  name,
  parse,
  onBlur,
  ...rest
}) {
  return (
    <Field format={format} name={name} parse={parse}>
      {({ input: { value, onChange: handleChange }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <>
            <Input
              error={!!hasError}
              value={value}
              onBlur={onBlur}
              {...input}
              {...pick(rest)}
              onChange={handleChange}
            />
            {displayError && hasError && (
              <Label error mt={1}>
                {error}
              </Label>
            )}
          </>
        )
      }}
    </Field>
  )
}

InputField.defaultProps = {
  checkErrorIfDirty: false,
  displayError: true,
  format: undefined,
  input: null,
  parse: undefined,
  onBlur: noop,
}

InputField.propTypes = {
  checkErrorIfDirty: PropTypes.bool,
  displayError: PropTypes.bool,
  format: PropTypes.func,
  input: PropTypes.shape(Input.propTypes),
  name: PropTypes.string.isRequired,
  parse: PropTypes.func,
  onBlur: PropTypes.func,
}

export default InputField
