/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import numeral from 'numeral'

import values from 'lodash/values'

import CurrencyIcon from 'Components/Blocks/CurrencyIcon'
import { Column, Popover, Row, Table, Text } from 'Components/UI'

import _, { useScopedI18n } from 'Services/I18n'

import { theme } from 'Theme'

import { GainCell } from './Cells'
import { ArrowInfoIcon, Content } from './styles'

function ExternalAssetsTable({ portfolio, currency }) {
  const s = useScopedI18n('blocks.externalPortfolio.table')

  const assets = useMemo(() => {
    return values(portfolio?.assets)
  }, [portfolio])

  const columns = useMemo(() => {
    return [
      {
        Header: () => (
          <Text
            color={theme.colors.font.secondary}
            extraSmall
            fontWeight={0}
            px={3}
          >
            {s('name')}
          </Text>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const original = row?.original
          const base = original?.base
          const baseName = original?.baseName

          return (
            <Row center flexShrink={1} px={3}>
              <CurrencyIcon currency={base} />
              <Text fontWeight={1} ml={2}>
                {baseName}
              </Text>
              <Text color={theme.colors.text70} fontWeight={1} ml={1}>
                {base}
              </Text>
            </Row>
          )
        },
        accessor: 'baseName',
        width: 90,
      },
      {
        Header: (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {s('holding')}
          </Text>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const original = row?.original
          const base = original?.base
          const cost = original?.cost
          const amount = original?.amount

          return (
            <Column alignEnd>
              <Text fontWeight={1} heading6>
                {currency}
                {numeral(cost?.toFixed(2)).format('0,0.[00]')}
              </Text>
              <Text extraSmall>
                {amount?.toFixed(8)} {base}
              </Text>
            </Column>
          )
        },
        accessor: 'cost',
        width: 70,
        cellRight: true,
        headerRight: true,
      },
      {
        Header: (
          <Row center>
            <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
              {s('24hChange')}
            </Text>
            <Popover
              content={s('performance24')}
              offset={[0, 10]}
              placement="right"
              showArrow
            >
              <Row>
                <ArrowInfoIcon
                  height={14}
                  ml={1}
                  viewBox="0 0 24 24"
                  width={14}
                />
              </Row>
            </Popover>
          </Row>
        ),

        Cell: ({ row }) => <GainCell row={row} />,
        accessor: 'last24hPercentage',
        width: 70,
        cellRight: true,
        headerRight: true,
      },
      {
        Header: (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {s('weight')}
          </Text>
        ),
        Cell: ({ value }) => (
          <Text heading6>{numeral(value?.toFixed(3)).format('0.00')}%</Text>
        ),
        accessor: 'percentage',
        width: 72,
        headerCenter: true,
        cellCenter: true,
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, portfolio])

  return (
    <Content>
      <Table
        columns={columns}
        data={assets}
        noData={
          <Text color={theme.colors.text70} fontWeight={1} heading6 p={4}>
            {_('general.noData')}
          </Text>
        }
        rowHeight={44}
        sortable
      />
    </Content>
  )
}

ExternalAssetsTable.defaultProps = {
  currency: '$',
}

ExternalAssetsTable.propTypes = {
  currency: PropTypes.string,
  portfolio: PropTypes.object.isRequired,
}

export default ExternalAssetsTable
