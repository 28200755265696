import {
  FUND_SOURCE,
  INVESTING_EXPERIENCE,
  INVESTMENT_HORIZON,
  PROFESSION,
  RISK_TOLERANCE,
} from 'Constants/ids'

import { FIELDS } from 'Pages/Onboarding/RiskAssessment/constants'

export const STEPS = {
  EXPERIENCE: 1,
  RISK_TOLERANCE: 2,
  INVESTMENT_HORIZON: 3,
  TOTAL_NET_WORTH: 4,
  TOTAL_INVESTMENTS: 5,
  TAX_RESIDENCE: 6,
  US_CITIZEN: 7,
  INDUSTRY: 8,
  PROFESSION: 9,
  NET_MONTHLY_INCOME: 10,
  FUND_SOURCE: 11,
}

export const QUESTIONS = {
  [STEPS.RISK_TOLERANCE]: {
    name: FIELDS.RISK_TOLERANCE,
    title: 'onboarding.riskAssessment.questions.riskTolerance.title',
    answers: [
      {
        value: RISK_TOLERANCE.CONSERVATIVE,
        label: 'onboarding.riskAssessment.questions.riskTolerance.conservative',
        shortDescription:
          'onboarding.riskAssessment.questions.riskTolerance.conservativeDescription',
      },
      {
        value: RISK_TOLERANCE.MODERATE,
        label: 'onboarding.riskAssessment.questions.riskTolerance.moderate',
        shortDescription:
          'onboarding.riskAssessment.questions.riskTolerance.moderateDescription',
      },
      {
        value: RISK_TOLERANCE.AGGRESSIVE,
        label: 'onboarding.riskAssessment.questions.riskTolerance.aggressive',
        shortDescription:
          'onboarding.riskAssessment.questions.riskTolerance.aggressiveDescription',
      },
    ],
  },
  [STEPS.TOTAL_NET_WORTH]: {
    name: FIELDS.TOTAL_NET_WORTH,
    title: 'onboarding.riskAssessment.questions.totalNetWorth.title',
    answers: [
      {
        label: 'onboarding.riskAssessment.questions.totalNetWorth.under50',
        value: { from: 0, to: 50000 },
      },
      {
        label:
          'onboarding.riskAssessment.questions.totalNetWorth.between50-100',
        value: { from: 50000, to: 100000 },
      },
      {
        label:
          'onboarding.riskAssessment.questions.totalNetWorth.between100-250',
        value: {
          from: 100000,
          to: 250000,
        },
      },
      {
        label:
          'onboarding.riskAssessment.questions.totalNetWorth.between250-1m',
        value: { from: 250000, to: 1000000 },
      },
      {
        label: 'onboarding.riskAssessment.questions.totalNetWorth.more1m',
        value: { from: 1000000 },
      },
    ],
  },
  [STEPS.TOTAL_INVESTMENTS]: {
    name: FIELDS.TOTAL_INVESTMENTS,
    title: 'onboarding.riskAssessment.questions.totalInvestments.title',
    answers: [
      {
        label: 'onboarding.riskAssessment.questions.totalInvestments.less1000',
        value: { from: 0, to: 1000 },
      },
      {
        label:
          'onboarding.riskAssessment.questions.totalInvestments.between1-5',
        value: { from: 1000, to: 5000 },
      },
      {
        label:
          'onboarding.riskAssessment.questions.totalInvestments.between5-20',
        value: { from: 5000, to: 20000 },
      },
      {
        label:
          'onboarding.riskAssessment.questions.totalInvestments.between20-100',
        value: { from: 20000, to: 100000 },
      },
      {
        label: 'onboarding.riskAssessment.questions.totalInvestments.more100k',
        value: { from: 100000 },
      },
    ],
  },
  [STEPS.INVESTMENT_HORIZON]: {
    name: FIELDS.INVESTMENT_HORIZON,
    title: 'onboarding.riskAssessment.questions.investmentHorizon.title',
    answers: [
      {
        value: INVESTMENT_HORIZON.LOW,
        label: 'onboarding.riskAssessment.questions.investmentHorizon.short',
      },
      {
        value: INVESTMENT_HORIZON.MEDIUM,
        label: 'onboarding.riskAssessment.questions.investmentHorizon.average',
      },
      {
        value: INVESTMENT_HORIZON.HIGH,
        label: 'onboarding.riskAssessment.questions.investmentHorizon.long',
      },
    ],
  },
  [STEPS.EXPERIENCE]: {
    name: FIELDS.INVESTING_EXPERIENCE,
    title: 'onboarding.riskAssessment.questions.investingExperience.title',
    answers: [
      {
        value: INVESTING_EXPERIENCE.LOW,
        label: 'onboarding.riskAssessment.questions.investingExperience.low',
      },
      {
        value: INVESTING_EXPERIENCE.MEDIUM,
        label: 'onboarding.riskAssessment.questions.investingExperience.medium',
      },
      {
        value: INVESTING_EXPERIENCE.HIGH,
        label: 'onboarding.riskAssessment.questions.investingExperience.high',
      },
    ],
  },

  [STEPS.TAX_RESIDENCE]: {
    name: FIELDS.TAX_RESIDENCE,
    title: 'onboarding.riskAssessment.questions.taxResidence.title',
  },
  [STEPS.US_CITIZEN]: {
    name: FIELDS.US_CITIZEN,
    title: 'onboarding.riskAssessment.questions.usCitizen.title',
    answers: [
      {
        label: 'onboarding.riskAssessment.questions.usCitizen.yes',
        value: true,
      },
      {
        label: 'onboarding.riskAssessment.questions.usCitizen.no',
        value: false,
      },
    ],
  },
  [STEPS.INDUSTRY]: {
    name: FIELDS.INDUSTRY,
    title: 'onboarding.riskAssessment.questions.industry.title',
    answers: [
      {
        label: 'onboarding.riskAssessment.questions.industry.yes',
        value: true,
      },
      {
        label: 'onboarding.riskAssessment.questions.industry.no',
        value: false,
      },
    ],
  },
  [STEPS.PROFESSION]: {
    name: FIELDS.PROFESSION,
    title: 'onboarding.riskAssessment.questions.profession.title',
    answers: [
      {
        label: 'onboarding.riskAssessment.questions.profession.unemployed',
        value: PROFESSION.UNEMPLOYED,
      },
      {
        label: 'onboarding.riskAssessment.questions.profession.notInEmployment',
        value: PROFESSION.NOT_IN_EMPLOYMENT,
      },
      {
        label: 'onboarding.riskAssessment.questions.profession.retired',
        value: PROFESSION.RETIRED,
      },
      {
        label: 'onboarding.riskAssessment.questions.profession.farmer',
        value: PROFESSION.FARMER,
      },
      {
        label: 'onboarding.riskAssessment.questions.profession.artisan',
        value: PROFESSION.ARTISAN,
      },
      {
        label: 'onboarding.riskAssessment.questions.profession.selfEmployed',
        value: PROFESSION.SELF_EMPLOYED,
      },
      {
        label:
          'onboarding.riskAssessment.questions.profession.privateSectorProfession',
        value: PROFESSION.PRIVATE_SECTOR_PROFESSION,
      },
      {
        label:
          'onboarding.riskAssessment.questions.profession.publicSectorProfession',
        value: PROFESSION.PUBLIC_SECTOR_PROFESSION,
      },
      {
        label:
          'onboarding.riskAssessment.questions.profession.privateSectorSupervisor',
        value: PROFESSION.PRIVATE_SECTOR_SUPERVISOR,
      },
      {
        label:
          'onboarding.riskAssessment.questions.profession.merchantAndRelated',
        value: PROFESSION.MERCHANT_AND_RELATED,
      },
      {
        label: 'onboarding.riskAssessment.questions.profession.businessOwner',
        value: PROFESSION.BUSINESS_OWNER,
      },
      {
        label:
          'onboarding.riskAssessment.questions.profession.privateSectorEmployee',
        value: PROFESSION.PRIVATE_SECTOR_EMPLOYEE,
      },
      {
        label:
          'onboarding.riskAssessment.questions.profession.publicSectorEmployee',
        value: PROFESSION.PUBLIC_SECTOR_EMPLOYEE,
      },
      {
        label:
          'onboarding.riskAssessment.questions.profession.privateSectorManager',
        value: PROFESSION.PRIVATE_SECTOR_MANAGER,
      },
      {
        label:
          'onboarding.riskAssessment.questions.profession.publicSectorManager',
        value: PROFESSION.PUBLIC_SECTOR_MANAGER,
      },
      {
        label: 'onboarding.riskAssessment.questions.profession.student',
        value: PROFESSION.STUDENT,
      },
      {
        label:
          'onboarding.riskAssessment.questions.profession.legalProfessional',
        value: PROFESSION.LEGAL_PROFESSIONAL,
      },
      {
        label:
          'onboarding.riskAssessment.questions.profession.medicalProfessional',
        value: PROFESSION.MEDICAL_PROFESSIONAL,
      },
      {
        label: 'onboarding.riskAssessment.questions.profession.other',
        value: PROFESSION.OTHER,
      },
    ],
  },
  [STEPS.NET_MONTHLY_INCOME]: {
    name: FIELDS.NET_MONTHLY_INCOME,
    title: 'onboarding.riskAssessment.questions.netMonthlyIncome.title',
    subtitle: 'onboarding.riskAssessment.questions.netMonthlyIncome.subtitle',
    answers: [
      {
        label: 'onboarding.riskAssessment.questions.netMonthlyIncome.less3000',
        value: { from: 0, to: 3000 },
      },
      {
        label:
          'onboarding.riskAssessment.questions.netMonthlyIncome.between3-5',
        value: { from: 3000, to: 5000 },
      },
      {
        label:
          'onboarding.riskAssessment.questions.netMonthlyIncome.between5-10',
        value: { from: 5000, to: 10000 },
      },
      {
        label:
          'onboarding.riskAssessment.questions.netMonthlyIncome.between10-20',
        value: { from: 10000, to: 20000 },
      },
      {
        label: 'onboarding.riskAssessment.questions.netMonthlyIncome.more20k',
        value: { from: 20000 },
      },
    ],
  },
  [STEPS.FUND_SOURCE]: {
    name: FIELDS.FUND_SOURCE,
    title: 'onboarding.riskAssessment.questions.fundSource.title',
    answers: [
      {
        value: FUND_SOURCE.SALARY,
        label: 'onboarding.riskAssessment.questions.fundSource.salary',
      },
      {
        value: FUND_SOURCE.PROPERTY_SALES,
        label: 'onboarding.riskAssessment.questions.fundSource.propertySale',
      },
      {
        value: FUND_SOURCE.SAVINGS,
        label: 'onboarding.riskAssessment.questions.fundSource.savings',
      },
      {
        value: FUND_SOURCE.INVESTMENT_GAINS,
        label: 'onboarding.riskAssessment.questions.fundSource.investmentGains',
      },
      {
        value: FUND_SOURCE.DONATION,
        label: 'onboarding.riskAssessment.questions.fundSource.donation',
      },
      {
        value: FUND_SOURCE.LOAN,
        label: 'onboarding.riskAssessment.questions.fundSource.bankLoan',
      },
      {
        value: FUND_SOURCE.LEGACY,
        label: 'onboarding.riskAssessment.questions.fundSource.legacy',
      },
      {
        value: FUND_SOURCE.GAMBLING,
        label: 'onboarding.riskAssessment.questions.fundSource.gambling',
      },
      {
        value: FUND_SOURCE.DIVIDENDS,
        label: 'onboarding.riskAssessment.questions.fundSource.dividends',
      },
      {
        value: FUND_SOURCE.OTHER,
        label: 'onboarding.riskAssessment.questions.fundSource.other',
      },
    ],
  },
}
