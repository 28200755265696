import React from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'
import numeral from 'numeral'

import get from 'lodash/get'

import { TooltipContent } from 'Components/Blocks/Charts/styles'
import { Column, Dot, Row, Text } from 'Components/UI'

import { PRIMARY_CURRENCY } from 'Constants/currencies'

import { useScopedI18n } from 'Services/I18n'

import { theme } from 'Theme'

import { ArrowDownIcon, ArrowUpIcon, Counter } from './styles'

const ARROW_SIZE = 12

function formatValue(value, currency = '$') {
  return numeral(value).format(`${currency}0,0.[00]`)
}

function formatPercentage(percentage) {
  return numeral(percentage).format('0.0[0]')
}

function arrow(value) {
  if (value > 0) {
    return (
      <ArrowUpIcon
        color={theme.colors.primary}
        height={14}
        size={ARROW_SIZE}
        viewBox="0 0 24 24"
        width={14}
      />
    )
  }
  if (value < 0) {
    return (
      <ArrowDownIcon
        color={theme.colors.dangerLight}
        height={14}
        size={ARROW_SIZE}
        viewBox="0 0 24 24"
        width={14}
      />
    )
  }

  return null
}

function Tooltip({
  active,
  colors,
  currency,
  holdPercentageKey,
  payload,
  percentageKey,
  valueKey,
}) {
  const s = useScopedI18n('blocks.backtesting.tooltip')
  const payloadData = get(payload, [0, 'payload'])
  const percentage = get(payloadData, percentageKey)
  const value = get(payloadData, valueKey)
  const holdPercentage = get(payloadData, holdPercentageKey)
  const hold = get(payloadData, 'hold')

  if (!active) return null

  return (
    <TooltipContent>
      <Text fontWeight={1} heading>
        {DateTime.fromSeconds(get(payloadData, 'time') || 0).toLocaleString(
          DateTime.DATE_FULL,
        )}
      </Text>

      <Row center mt={2}>
        <Column>
          <Row center>
            <Dot color={colors[1]} />
            <Text extraSmall ml={1}>
              {s('rebalanced')}{' '}
            </Text>
          </Row>
          <Row gap={1}>
            <Row center>
              <Counter negative={percentage < 0} positive={percentage > 0}>
                {formatValue(value, currency)}
              </Counter>
            </Row>
            /
            <Row center>
              {arrow(percentage)}
              <Counter negative={percentage < 0} positive={percentage > 0}>
                {formatPercentage(percentage)}%
              </Counter>
            </Row>
          </Row>
        </Column>
      </Row>

      <Row center mt="10px">
        <Column>
          <Row center>
            <Dot color={colors[0]} />
            <Text extraSmall ml={1}>
              {s('hodling')}
            </Text>
          </Row>
          <Row gap={1}>
            <Row center>
              <Counter
                negative={holdPercentage < 0}
                positive={holdPercentage > 0}
              >
                {formatValue(hold, currency)}
              </Counter>
            </Row>
            /
            <Row center>
              {arrow(holdPercentage)}
              <Counter
                negative={holdPercentage < 0}
                positive={holdPercentage > 0}
              >
                {formatPercentage(holdPercentage)}%
              </Counter>
            </Row>
          </Row>
        </Column>
      </Row>
    </TooltipContent>
  )
}

Tooltip.defaultProps = {
  active: false,
  colors: [theme.colors.text70, theme.colors.primary50],
  currency: PRIMARY_CURRENCY,
  holdPercentageKey: 'holdChange',
  payload: undefined,
  percentageKey: 'change',
  valueKey: 'value',
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string),
  currency: PropTypes.string,
  holdPercentageKey: PropTypes.string,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  percentageKey: PropTypes.string,
  valueKey: PropTypes.string,
}

export default Tooltip
