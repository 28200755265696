import { REBALANCE_TYPE, STATE } from 'Constants/portfolios'

const REBALANCE_TYPE_TEXT = {
  [REBALANCE_TYPE.PERIOD]: 'data.portfolio.rebalance.period',
  [REBALANCE_TYPE.THRESHOLD]: 'data.portfolio.rebalance.threshold',
}

function rebalanceTypeText(userPortfolio) {
  return (
    REBALANCE_TYPE_TEXT[userPortfolio?.rebalanceType] ||
    'data.portfolio.rebalance.unknown'
  )
}

const STATE_COLORS = {
  [STATE.INITIAL]: '#ffbc00',
  [STATE.FUNDED]: '#06BA63',
  [STATE.FILLED]: '#0676ed',
  [STATE.CLOSING]: '#ff8468',
  [STATE.CLOSED]: '#df2900',
}

function stateColor(portfolio) {
  return STATE_COLORS[portfolio?.state]
}

const STATE_TEXT = {
  [STATE.INITIAL]: 'data.portfolio.state.initial',
  [STATE.FUNDED]: 'data.portfolio.state.funded',
  [STATE.FILLED]: 'data.portfolio.state.filled',
  [STATE.CLOSING]: 'data.portfolio.state.closing',
  [STATE.CLOSED]: 'data.portfolio.state.closed',
}

function stateText(userPortfolio) {
  return STATE_TEXT[userPortfolio?.state] || 'data.portfolio.state.unknown'
}

export default {
  rebalanceTypeText,
  stateColor,
  stateText,
}
