/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import numeral from 'numeral'

import { Button, Table, Text } from 'Components/UI'

import _ from 'Services/I18n'

import { theme } from 'Theme'

import { NameCell } from './Cells'
import { ArrowDownIcon, ArrowUpIcon, Content } from './styles'

function NetworthAssetsTable({ assets, currency }) {
  const columns = useMemo(() => {
    return [
      {
        Header: () => (
          <Text
            color={theme.colors.font.secondary}
            extraSmall
            fontWeight={0}
            px={3}
          >
            {_('widgets.netWorth.table.name')}
          </Text>
        ),

        Cell: ({ row }) => <NameCell row={row} />,
        id: 'name',
        width: 100,
      },
      {
        Header: (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {_('widgets.netWorth.table.holdings')}
          </Text>
        ),
        Cell: ({ value }) => (
          <Text heading6>
            {currency}
            {numeral(value?.toFixed(5)).format('0,0.00')}
          </Text>
        ),
        accessor: 'cost',
        width: 70,
      },
      {
        Header: (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {_('widgets.netWorth.table.weight')}
          </Text>
        ),
        Cell: ({ value }) => (
          <Text heading6>{numeral(value?.toFixed(3)).format('0.00')}%</Text>
        ),
        accessor: 'weight',
        width: 70,
      },
      {
        id: 'expander',
        Cell: ({ row }) =>
          row.canExpand ? (
            <Button
              icon
              outline
              secondary
              xSmall
              {...row.getToggleRowExpandedProps()}
            >
              {row.isExpanded ? (
                <ArrowUpIcon height={24} viewBox="0 0 24 24" width={24} />
              ) : (
                <ArrowDownIcon height={24} viewBox="0 0 24 24" width={24} />
              )}
            </Button>
          ) : null,
        width: 20,
        cellCenter: true,
      },
    ]
  }, [currency])

  return (
    <Content>
      <Table
        columns={columns}
        data={assets}
        noData={
          <Text color={theme.colors.text70} fontWeight={1} heading6 p={4}>
            {_('general.noData')}
          </Text>
        }
        rowHeight={50}
        sortable
      />
    </Content>
  )
}

NetworthAssetsTable.defaultProps = {
  currency: '$',
}

NetworthAssetsTable.propTypes = {
  assets: PropTypes.array.isRequired,
  currency: PropTypes.string,
}

export default NetworthAssetsTable
