import React, { useMemo } from 'react'
import { useExpanded, useFlexLayout, useSortBy, useTable } from 'react-table'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import get from 'lodash/get'

import { Row } from 'Components/UI/Flex'

import Cells from './Cells'
import {
  ArrowAsc,
  ArrowDesc,
  Body,
  Header,
  HeaderCell,
  RowCell,
  StyledTable,
  TableRow,
} from './styles'

function Table({
  columns,
  contentOffset,
  data,
  defaultColumn,
  headerSeparator,
  noData,
  rowGap,
  rowHeight,
  headerRowHeight,
  sortable,
  initialState,
  ...rest
}) {
  const innerDefaultColumn = useMemo(
    () => defaultColumn,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: innerDefaultColumn,
      disableSortBy: !sortable,
      initialState,
      getSubRows: row => row?.subRows || [],
    },
    useSortBy,
    useFlexLayout,
    useExpanded,
  )

  return (
    <StyledTable {...getTableProps()} {...pick(rest)} className="table">
      <Header className="header" contentOffset={contentOffset}>
        {headerGroups.map(headerGroup => (
          <TableRow
            {...headerGroup.getHeaderGroupProps()}
            className="row"
            height={headerRowHeight}
          >
            {headerGroup.headers.map(column => (
              <HeaderCell
                {...column.getHeaderProps(column.getSortByToggleProps())}
                center={get(column, 'headerCenter')}
                right={get(column, 'headerRight')}
              >
                {column.render('Header')}
                {sortable &&
                  column.isSorted &&
                  (column.isSortedDesc ? (
                    <ArrowDesc ml="2px" />
                  ) : (
                    <ArrowAsc ml="2px" />
                  ))}
              </HeaderCell>
            ))}
          </TableRow>
        ))}
      </Header>

      {headerSeparator}

      <Body {...getTableBodyProps()} rowGap={rowGap}>
        {rows?.length === 0 && <Row justifyCenter>{noData}</Row>}

        {rows.map(row => {
          prepareRow(row)

          return (
            <TableRow className="row" {...row.getRowProps()} height={rowHeight}>
              {row.cells.map(cell => (
                <RowCell
                  {...cell.getCellProps()}
                  center={get(cell, ['column', 'cellCenter'])}
                  right={get(cell, ['column', 'cellRight'])}
                >
                  {cell.render('Cell')}
                </RowCell>
              ))}
            </TableRow>
          )
        })}
      </Body>
    </StyledTable>
  )
}

Table.defaultProps = {
  contentOffset: undefined,
  defaultColumn: {
    minWidth: 30,
    width: 150,
    maxWidth: 200,
  },
  headerSeparator: null,
  initialState: {},
  noData: null,
  rowGap: undefined,
  rowHeight: undefined,
  headerRowHeight: undefined,
  sortable: false,
}

Table.propTypes = {
  columns: PropTypes.any.isRequired,
  contentOffset: PropTypes.number,
  data: PropTypes.any.isRequired,
  defaultColumn: PropTypes.shape({
    minWidth: PropTypes.number,
    width: PropTypes.number,
    maxWidth: PropTypes.number,
  }),
  headerRowHeight: PropTypes.number,
  headerSeparator: PropTypes.element,
  initialState: PropTypes.object,
  noData: PropTypes.element,
  rowGap: PropTypes.number,
  rowHeight: PropTypes.number,
  sortable: PropTypes.bool,
}

Table.Cells = Cells

export default Table
