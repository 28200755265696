import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { Row } from 'Components/UI/Flex'

import { AnimatedIcon } from './styles'

function Loader({ fullHeight, fullWidth, size, ...rest }) {
  return (
    <Row
      center
      fullHeight={fullHeight}
      fullWidth={fullWidth}
      justifyCenter
      squeeze={!fullWidth}
      {...pick(rest)}
    >
      <AnimatedIcon size={size} />
    </Row>
  )
}

Loader.defaultProps = {
  fullHeight: undefined,
  fullWidth: undefined,
  size: 24,
}

Loader.propTypes = {
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Loader
