import { KYC_STATUS } from 'Constants/ids'

function getStatus(state) {
  switch (state) {
    case 'requested':
      return KYC_STATUS.REVIEW
    case 'approved':
      return KYC_STATUS.SUCCESS
    case 'declined':
      return KYC_STATUS.FAILURE
    default:
      return null
  }
}

export default {
  getStatus,
}
