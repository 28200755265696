import { HttpLink, split } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'

import { API } from 'Config'

export default split(
  operation => operation.getContext().batch === true,
  new BatchHttpLink({
    uri: `${API.URL}/graphql`,
    headers: { batch: 'true' },
    fetchOptions: {
      mode: 'no-cors',
    },
  }),
  new HttpLink({
    uri: `${API.URL}/graphql`,
  }),
)
