import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { FiCheckCircle, FiEdit3 } from 'react-icons/fi'

import { Button, Flex, Popover } from 'Components/UI'

import { STATE } from 'Constants/userTransactions'

import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const { onApprove, onEdit } = useTableContext()

  const transaction = row?.original || {}

  const handleApprove = useCallback(() => {
    onApprove(transaction)
  }, [onApprove, transaction])

  const handleEdit = useCallback(() => {
    onEdit(transaction)
  }, [onEdit, transaction])

  return (
    <Flex>
      {transaction.state === STATE.PENDING && (
        <>
          <Popover
            content="Approve transaction"
            delay={Popover.delay}
            showArrow
          >
            <Button
              icon
              outline
              secondary
              small
              squared
              onClick={handleApprove}
            >
              <FiCheckCircle />
            </Button>
          </Popover>
          <Popover content="Edit transaction" delay={Popover.delay} showArrow>
            <Button
              icon
              ml={1}
              outline
              secondary
              small
              squared
              onClick={handleEdit}
            >
              <FiEdit3 />
            </Button>
          </Popover>
        </>
      )}
    </Flex>
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
