import styled from 'styled-components'
import { margin } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'
import positionGlyph from 'iconoir/icons/position.svg'
import starGlyph from 'iconoir/icons/star.svg'

export const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  border-radius: 12px;
  background: ${themeGet('colors.white')};

  ${margin}
`

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary50')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StarIcon = styled(starGlyph)`
  color: white;
`

export const PositionIcon = styled(positionGlyph)`
  color: white;
`

export const FieldDescription = styled.div`
  display: flex;
  padding: 16px 24px 24px 24px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 0 0 12px 12px;
  background: ${themeGet('colors.cultured')};
`

export const DescriptionLabel = styled.div`
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${themeGet('colors.primary60')};
  color: ${themeGet('colors.primary60')};
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
`

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`
