import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { expertPortfolioInfoPieChartGlyph, logoGlyph } from 'Assets/Svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 206px;
  height: 206px;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${margin}
`

export const InfoPieChartIcon = styled(expertPortfolioInfoPieChartGlyph)`
  position: absolute;
`

export const LogoImage = styled.img`
  max-width: 146px;
  height: 40px;
  object-fit: contain;
`

export const LogoIcon = styled(logoGlyph)``

const lightCircleCss = ({ light }) =>
  light &&
  css`
    background-color: ${themeGet('colors.primary20')};
  `

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary50')};

  ${lightCircleCss};
`
