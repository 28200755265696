import React from 'react'
import PropTypes from 'prop-types'

import { StateContainer } from './styles'

function StateCell({ value }) {
  return <StateContainer state={value}>{value}</StateContainer>
}

StateCell.defaultProps = {
  value: '',
}

StateCell.propTypes = {
  value: PropTypes.string,
}

export default StateCell
