import portfoliosQuery from 'GraphQL/Queries/Portfolios/portfolios.graphql'

import Utils from 'Utils'

function add(store, data) {
  try {
    Utils.GraphQL.updateList(
      store,
      portfoliosQuery,
      data?.data?.addPortfolio,
      'portfolios',
    )
  } catch (error) {
    //
  }
}

function remove(id) {
  return function update(store) {
    try {
      Utils.GraphQL.removeFromList(store, portfoliosQuery, id, 'portfolios')
    } catch (error) {
      //
    }
  }
}

export default { add, remove }
