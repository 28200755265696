import styled from 'styled-components'
import { margin } from '@styled-system/space'

import Styles from 'Components/Styles'

export const Text = styled.div`
  ${margin};
  ${Styles.text()};
  ${Styles.conditional.text.negative};
  ${Styles.conditional.text.positive};
`
