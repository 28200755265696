import React from 'react'
import { hydrate, render } from 'react-dom'

import { loadableReady } from '@loadable/component'
import * as Sentry from '@sentry/react'

import { SENTRY } from 'Config'

import Container from './Container'

Sentry.init({
  dsn: SENTRY.DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const root = document.getElementById('root')

if (root.hasChildNodes() === true) {
  loadableReady(() => hydrate(<Container />, root))
} else {
  render(<Container />, root)
}
