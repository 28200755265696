import i18n from 'i18n-js'

import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'

import { I18N_DEFAULTS } from 'Constants/defaults'
import { I18N_ACTIONS, I18N_STATE } from 'Constants/store'

import { validLanguages } from 'Services/I18n'

export default function i18nState(store) {
  store.on('@init', () => ({
    [I18N_STATE]: I18N_DEFAULTS,
  }))

  store.on(I18N_ACTIONS.SET, (state, variables) => {
    const values = pickBy(variables, (value, name) => {
      switch (name) {
        case 'locale':
          return validLanguages.includes(value)
        default:
          return false
      }
    })

    const nextI18n = { ...state[I18N_STATE], ...values }

    if (isEqual(nextI18n, state[I18N_STATE])) return null

    i18n.locale = nextI18n.locale

    return { [I18N_STATE]: nextI18n }
  })
}
