import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { renderRoutes } from 'react-router-config'
import { ToastContainer } from 'react-toastify'

import { FiX } from 'react-icons/fi'

import { useQuery } from '@apollo/client'
import { useStoreon } from 'storeon/react'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import FullPageLoader from 'Components/Blocks/FullPageLoader'

import { APP } from 'Constants/ids'
import { AUTH_STATE, I18N_STATE } from 'Constants/store'

import settingsQuery from 'GraphQL/Queries/Settings/settings.graphql'
import meQuery from 'GraphQL/Queries/User/me.graphql'

import router, { Director } from 'Router'

import { AppContext } from 'Services/AppContext'
import { LocaleRenderer } from 'Services/I18n'
import {
  useInitializeSupportWidget,
  useSupportWidget,
} from 'Services/SupportWidget'

import { GlobalStyle, theme } from 'Theme'

export default function App() {
  const { auth, i18n } = useStoreon(AUTH_STATE, I18N_STATE)

  const locale = get(i18n, 'locale')

  const settingsData = useQuery(settingsQuery)

  const meData = useQuery(meQuery, {
    skip: !get(auth, 'accessToken'),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const me = meData.data?.me
  const { loading, refetch } = meData

  const settings = get(settingsData, ['data', 'settings']) || {}

  const routes = useMemo(() => router(me), [me])

  useInitializeSupportWidget()

  const supportWidget = useSupportWidget()

  useEffect(() => {
    if (isEmpty(supportWidget) || !me) return

    supportWidget.authorize({
      email: me.emailCredentials[0].email,
      fullName: me.profile?.lastName
        ? `${me.profile?.firstName} ${me.profile?.lastName}`
        : me.profile?.firstName,
    })
  }, [supportWidget, me])

  if (!me && loading) {
    return <FullPageLoader />
  }

  return (
    <LocaleRenderer key={locale}>
      <AppContext.Provider value={{ me, settings, locale, refetchMe: refetch }}>
        <Helmet>
          <title>{APP.NAME}</title>
          {theme.webfonts.map(font => (
            <link
              href={`https://fonts.googleapis.com/css?family=${font}`}
              key={font}
              rel="stylesheet"
            />
          ))}
          <link
            href="https://unpkg.com/react-day-picker@7.4.8/lib/style.css"
            rel="stylesheet"
          />
        </Helmet>
        <GlobalStyle />
        <Director />

        {renderRoutes(routes)}

        <ToastContainer
          className="toast-container"
          closeButton={() => <FiX size={10} />}
          progressClassName="toast-progress"
          toastClassName="toast"
        />
      </AppContext.Provider>
    </LocaleRenderer>
  )
}
