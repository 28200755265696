import { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const absoluteFullSize = (inset = 0) => css`
  left: ${inset}px;
  right: ${inset}px;
  top: ${inset}px;
  bottom: ${inset}px;
`

const border = (radius = 8) => css`
  border: solid 1px ${themeGet('colors.border.primary')};
  border-radius: ${radius}px;
`

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const text = (size = 12) => css`
  font-size: ${size}px;
  line-height: normal;
  color: ${themeGet('colors.font.primary')};
`

const conditional = {
  text: {
    bold: ({ bold }) =>
      bold &&
      css`
        font-weight: 600;
      `,
    colored: ({ colored }) =>
      colored &&
      css`
        color: ${themeGet('colors.font.colored')};
      `,
    negative: ({ negative }) =>
      negative &&
      css`
        color: ${themeGet('colors.negative')};
      `,
    positive: ({ positive }) =>
      positive &&
      css`
        color: ${themeGet('colors.positive')};
      `,
    secondary: ({ secondary }) =>
      secondary &&
      css`
        color: ${themeGet('colors.font.secondary')};
      `,
  },
}

export default {
  absoluteFullSize,
  border,
  flexCenter,
  text,
  conditional,
}
