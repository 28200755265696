import Data from './Data'
import Exchanges from './Exchanges'
import Form from './Form'
import Goals from './Goals'
import GraphQL from './GraphQL'
import Language from './Language'
import Me from './Me'
import Numbers from './Numbers'
import RiskAssessment from './RiskAssessment'
import Strings from './Strings'
import UserKYC from './UserKYC'
import UserPortfolios from './UserPortfolios'
import UserProfiles from './UserProfiles'
import UserTransactions from './UserTransactions'

export default {
  Data,
  RiskAssessment,
  Exchanges,
  Form,
  Goals,
  GraphQL,
  Language,
  Me,
  Numbers,
  Strings,
  UserPortfolios,
  UserProfiles,
  UserTransactions,
  UserKYC,
}
