import { persistState } from '@storeon/localstorage'
import { createStoreon } from 'storeon'

import camelCase from 'lodash/camelCase'

import { APP_NAME } from 'Config'

import {
  AUTH_STATE,
  DASHBOARD_STATE,
  EXPERT_PORTFOLIO_STATE,
  I18N_STATE,
  PORTFOLIO_TIPS_STATE,
  SIDEBAR_STATE,
} from 'Constants/store'

import shared from 'Services/Shared'

import authState from './auth'
import dashboardState from './dashboard'
import expertPortfolioState from './expertPortfolio'
import i18nState from './i18n'
import portfolioTipsState from './portfolioTips'
import sidebarState from './sidebar'

export function createStore() {
  const store = createStoreon([
    authState,
    i18nState,
    expertPortfolioState,
    portfolioTipsState,
    dashboardState,
    sidebarState,
    persistState(
      [
        AUTH_STATE,
        I18N_STATE,
        EXPERT_PORTFOLIO_STATE,
        PORTFOLIO_TIPS_STATE,
        DASHBOARD_STATE,
        SIDEBAR_STATE,
      ],
      {
        key: camelCase(APP_NAME),
      },
    ),
  ])

  shared.setStore(store)

  return store
}
