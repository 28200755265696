import { appearance } from 'Config'

import GlobalStyle from './GlobalStyle'
import ThemeProvider from './Provider'
import themes from './themes'

const theme = themes[appearance.theme]

export { GlobalStyle, theme, ThemeProvider }

export default themes
