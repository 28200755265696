import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { maxWidth, width } from 'styled-system'
import { color } from '@styled-system/color'
import { margin, padding } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'
import { typography } from '@styled-system/typography'

const heading1Css = ({ heading1 }) =>
  heading1 &&
  css`
    font-size: 36px;
    font-weight: ${themeGet('weight.2')};
    line-height: 44px;
    letter-spacing: 0;
  `

const heading2Css = ({ heading2 }) =>
  heading2 &&
  css`
    font-size: 32px;
    font-weight: ${themeGet('weight.2')};
    line-height: 40px;
    letter-spacing: 0;
  `

const heading3Css = ({ heading3 }) =>
  heading3 &&
  css`
    font-size: 24px;
    font-weight: ${themeGet('weight.2')};
    line-height: 32px;
    letter-spacing: 0;
  `

const heading4Css = ({ heading4 }) =>
  heading4 &&
  css`
    font-size: 20px;
    font-weight: ${themeGet('weight.2')};
    line-height: 28px;
    letter-spacing: 0;
  `

const heading5Css = ({ heading5 }) =>
  heading5 &&
  css`
    font-size: 16px;
    font-weight: ${themeGet('weight.2')};
    line-height: 20px;
    letter-spacing: 0;
  `

const heading6Css = ({ heading6 }) =>
  heading6 &&
  css`
    font-size: 14px;
    font-weight: ${themeGet('weight.2')};
    line-height: 18px;
    letter-spacing: 0;
  `

const extraLargeCss = ({ extraLarge }) =>
  extraLarge &&
  css`
    font-weight: ${themeGet('weight.0')};
    font-size: 24px;
    line-height: 32px;
  `

const largeCss = ({ large }) =>
  large &&
  css`
    font-weight: ${themeGet('weight.0')};
    font-size: 20px;
    line-height: 28px;
  `

const mediumCss = ({ medium }) =>
  medium &&
  css`
    font-weight: ${themeGet('weight.0')};
    font-size: 16px;
    line-height: 24px;
  `

const smallCss = ({ small }) =>
  small &&
  css`
    font-weight: ${themeGet('weight.0')};
    font-size: 14px;
    line-height: 20px;
  `

const extraSmallCss = ({ extraSmall }) =>
  extraSmall &&
  css`
    font-weight: ${themeGet('weight.0')};
    font-size: 12px;
    line-height: 16px;
  `

const actionMediumCss = ({ actionMedium }) =>
  actionMedium &&
  css`
    font-weight: ${themeGet('weight.1')};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
  `

const actionSmallCss = ({ actionSmall }) =>
  actionSmall &&
  css`
    font-weight: ${themeGet('weight.1')};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
  `

const actionExtraSmallCss = ({ actionExtraSmall }) =>
  actionExtraSmall &&
  css`
    font-weight: ${themeGet('weight.1')};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
  `

const underlineCss = ({ underline }) =>
  underline &&
  css`
    text-decoration: underline;
  `

const captionCss = ({ caption }) =>
  caption &&
  css`
    text-transform: uppercase;
  `

const preWrapCss = ({ preWrap }) =>
  preWrap &&
  css`
    white-space: pre-wrap;
  `

const firstCapitalCss = ({ firstCapital }) =>
  firstCapital &&
  css`
    &:first-letter {
      text-transform: uppercase;
    }
  `

const ellipsisCss = ({ ellipsis }) =>
  ellipsis &&
  css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

const Text = styled.span`
  ${heading1Css};
  ${heading2Css};
  ${heading3Css};
  ${heading4Css};
  ${heading5Css};
  ${heading6Css};

  ${extraLargeCss};
  ${largeCss};
  ${mediumCss};
  ${smallCss};
  ${extraSmallCss};

  ${actionMediumCss};
  ${actionSmallCss};
  ${actionExtraSmallCss};

  ${underlineCss};
  ${captionCss};
  ${preWrapCss}
  ${firstCapitalCss};

  ${ellipsisCss};

  ${color};
  ${typography};
  ${width};
  ${maxWidth};
  ${margin};
  ${padding};
`

Text.propTypes = {
  actionExtraSmall: PropTypes.bool,
  actionMedium: PropTypes.bool,
  actionSmall: PropTypes.bool,
  caption: PropTypes.bool,
  ellipsis: PropTypes.bool,
  extraLarge: PropTypes.bool,
  extraSmall: PropTypes.bool,
  firstCapital: PropTypes.bool,
  heading1: PropTypes.bool,
  heading2: PropTypes.bool,
  heading3: PropTypes.bool,
  heading4: PropTypes.bool,
  heading5: PropTypes.bool,
  heading6: PropTypes.bool,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  preWrap: PropTypes.bool,

  small: PropTypes.bool,
  underline: PropTypes.bool,
}

export default Text
