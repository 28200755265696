import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import { Column } from 'Components/UI/Flex'

import { Container, InvisibleCheckbox, StyledSwitch } from './styles'

function Switch({
  checked,
  disabled,
  error,
  hasError,
  label,
  labelPosition,
  name,
  onBlur,
  onChange,
  onFocus,

  ...rest
}) {
  return (
    <Column>
      <Container {...pick(rest)}>
        <InvisibleCheckbox
          checked={checked}
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />

        {label && labelPosition === 'left' && <div>{label}</div>}

        <StyledSwitch />

        {label && labelPosition === 'right' && <div>{label}</div>}
      </Container>

      {hasError && <div>{error}</div>}
    </Column>
  )
}

Switch.defaultProps = {
  checked: undefined,
  disabled: undefined,
  error: undefined,
  hasError: undefined,
  label: undefined,
  labelPosition: 'right',
  name: undefined,
  onBlur: noop,
  onChange: noop,
  onFocus: noop,
}

Switch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hasError: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelPosition: PropTypes.oneOf(['left', 'right']),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}

export default Switch
