import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import arrowLeftGlyph from 'iconoir/icons/arrow-left.svg'
import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'

import { tetherIconGlyph } from 'Assets/Svg'

import { gap } from 'Theme/system'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${themeGet('colors.bg.main')};
  padding: 20px;
  max-width: 670px;
  width: 100%;
  flex-shrink: 0;

  ${margin};
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${margin};
  ${gap}
`

export const ArrowRightIcon = styled(arrowRightGlyph)``

export const ArrowLeftIcon = styled(arrowLeftGlyph)``

export const TetherIcon = styled(tetherIconGlyph)`
  ${margin};
`
