export const STATE = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined',
  CANCELED: 'canceled',
}

export const KIND = {
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal',
}

export const PAYMENT_METHOD = {
  CRYPTO: 'crypto',
  BANK: 'bank',
}
