import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'

import { Button, Column, Link, Loader } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms'

import resetPasswordMutation from 'GraphQL/Mutations/Auth/resetPassword.graphql'

import { ROOT } from 'Router/routes'

import _ from 'Services/I18n'

import {
  Card,
  ControlsRow,
  FormContent,
  IconWrapper,
  Info,
  MailIcon,
  SendMailIcon,
  SubText,
  Text,
  Title,
} from '../styles'

const FIELDS = {
  EMAIL: 'email',
}

function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [resetDone, setResetDone] = useState(false)
  const [email, setEmail] = useState(null)

  const [resetPassword] = useMutation(resetPasswordMutation)

  const renderForm = useCallback(
    ({ handleSubmit }) => {
      return (
        <FormContent>
          <Title>{_('auth.resetPassword.title')}</Title>
          <SubText mt={2}>{_('auth.resetPassword.subtitle')}</SubText>
          <InputLabels mt={5} title={`${_('auth.shared.email')}*`}>
            <InputField
              input={{
                placeholder: _('auth.shared.emailPlaceholder'),
                renderBefore: (
                  <MailIcon height={16} viewBox="0 0 24 24" width={16} />
                ),
              }}
              name={FIELDS.EMAIL}
            />
          </InputLabels>

          <Button disabled={loading} mt={5} onClick={handleSubmit}>
            {_('auth.resetPassword.action')}
            {loading && <Loader ml={1} />}
          </Button>
        </FormContent>
      )
    },
    [loading],
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.EMAIL]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.emailRequired')}`,
        },
        email: {
          email: true,
          message: `^${_('auth.shared.emailInvalid')}`,
        },
      },
    }),
    [],
  )

  const handleSubmit = useCallback(
    async values => {
      try {
        const emailValue = values[FIELDS.EMAIL]

        setLoading(true)

        await resetPassword({
          variables: {
            email: emailValue,
          },
        })

        setEmail(emailValue)
        setResetDone(true)
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
      } finally {
        setLoading(false)
      }
    },
    [resetPassword],
  )

  return (
    <>
      <ControlsRow>
        <Text>
          {_('auth.resetPassword.backTo')}{' '}
          <Link fontWeight={600} to={ROOT}>
            {_('auth.resetPassword.signIn')}
          </Link>
        </Text>
      </ControlsRow>

      {resetDone ? (
        <Column center height="100%" justifyCenter width={1}>
          <Card>
            <IconWrapper>
              <SendMailIcon height={24} viewBox="0 0 24 24" width={24} />
            </IconWrapper>

            <Info mt={5}>
              <Title>{_('auth.resetPassword.successTitle')}</Title>
              <SubText mt={2}>
                {_('auth.resetPassword.success')} <span>{email}</span>
              </SubText>
            </Info>
          </Card>
        </Column>
      ) : (
        <Column center height="100%" justifyCenter width={1}>
          <Card>
            <Form
              render={renderForm}
              validate={values => validate(values, formConstraints)}
              onSubmit={handleSubmit}
            />
          </Card>
        </Column>
      )}
    </>
  )
}

export default ResetPassword
