import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import trashGlyph from 'iconoir/icons/trash.svg'

import Styles from 'Components/Styles'

export const Content = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${themeGet('space.1')}px;
  padding: ${themeGet('space.2')}px;

  .table {
    padding-bottom: 10px;
    .header {
      background-color: ${themeGet('colors.bg.overlayLight')};
      padding: 10px 0;
      border-radius: 6px 6px 0 0;
      margin-bottom: 12px;
      border-bottom: 1px solid ${themeGet('colors.divider.light')};
    }
    .row {
      border-bottom: 1px solid ${themeGet('colors.divider.light')};
    }
  }
`

export const Text = styled.div`
  ${margin}
  ${Styles.text(14)}
`

export const TrashIcon = styled(trashGlyph)``

export const DeleteButton = styled.button`
  width: 20px;
  height: 20px;
  cursor: pointer;
`
