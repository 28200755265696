import React, { useEffect, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'

import sumBy from 'lodash/sumBy'

import DepositAndWithdraw from 'Components/Blocks/DepositAndWithdraw/DepositAndWithdraw'
import { Column, Loader, Row, Text } from 'Components/UI'

import { CRYPTO_TOKEN_KIND } from 'Constants/cryptoTokens'

import userAssetsQuery from 'GraphQL/Queries/UserAssets/userAssets.graphql'

import { useAppContext } from 'Hooks'

import { ASSETS_GROUP, TAB } from 'Pages/App/Assets/constants'
import NoAssets from 'Pages/App/Assets/NoAssets'

import { useScopedI18n } from 'Services/I18n'
import shared from 'Services/Shared'

import Info from './Info'
import { Block } from './styles'
import AssetsTable from './Table'
import { assetsByGroup, populateAssets } from './utils'

function Assets() {
  const s = useScopedI18n('assets')
  const { me } = useAppContext()

  const [tab, setTab] = useState(TAB.ALL)
  const [tickers, setTickers] = useState()
  const [isLoadingTickers, setLoadingTickers] = useState(true)

  useEffect(() => {
    shared
      .getPortfolioService()
      .getTickers()
      .then(data => {
        setTickers(data)
        setLoadingTickers(false)
      })
  }, [])

  const { data: userAssetsData, loading: userAssetsLoading } = useQuery(
    userAssetsQuery,
    {
      variables: {
        onlyPortfolioAssets: true,
      },
      fetchPolicy: 'cache-and-network',
    },
  )

  const userAssets = useMemo(() => userAssetsData?.userAssets || [], [
    userAssetsData,
  ])

  const platformBalance = useMemo(() => me?.balances?.[0]?.amount || 0, [me])
  const portfolioAssetsBalance = sumBy(userAssets, asset => asset?.cost || 0)

  const balanceByTab = useMemo(() => {
    switch (tab) {
      case TAB.ALL:
        return platformBalance + portfolioAssetsBalance
      case TAB.BALANCE:
        return platformBalance
      case TAB.PORTFOLIO:
        return portfolioAssetsBalance
      default:
        return 0
    }
  }, [tab, portfolioAssetsBalance, platformBalance])

  const assetsByTab = useMemo(() => {
    // HARDCODE - no data in the markets
    const balanceAsset = platformBalance
      ? {
          currency: 'USDT',
          cost: platformBalance,
          amount: platformBalance,
          cryptoTokenKind: CRYPTO_TOKEN_KIND.STABLE_COIN,
          percentage: null,
        }
      : {}

    switch (tab) {
      case TAB.ALL:
        return [...userAssets, balanceAsset]

      case TAB.BALANCE:
        return [balanceAsset]
      case TAB.PORTFOLIO:
        return userAssets
      default:
        return []
    }
  }, [platformBalance, tab, userAssets])

  const assetsData = useMemo(() => {
    const filteredAssets = assetsByTab?.filter(asset => asset.cost > 0.01)

    const populatedAssets = populateAssets(filteredAssets, tickers)

    return assetsByGroup(populatedAssets)
  }, [assetsByTab, tickers])

  const isLoading = userAssetsLoading || isLoadingTickers
  const showNoAssets =
    assetsData[ASSETS_GROUP.STABLE_COINS].length === 0 &&
    assetsData[ASSETS_GROUP.CRYPTO_CURRENCIES].length === 0 &&
    assetsData[ASSETS_GROUP.ALT_COINS].length === 0

  if (isLoading)
    return (
      <Column center fullWidth justifyCenter>
        <Loader />
      </Column>
    )

  return (
    <Column fullWidth>
      <Block>
        <Row fullWidth spaceBetween>
          <Text as="h3" fontWeight={2} heading3>
            {s('title')}
          </Text>

          <DepositAndWithdraw />
        </Row>

        <Info balance={balanceByTab} tab={tab} onSelectTab={setTab} />
      </Block>

      {showNoAssets && <NoAssets mt="20px" />}

      {assetsData[ASSETS_GROUP.STABLE_COINS].length > 0 && (
        <Block mt="20px">
          <AssetsTable
            assets={assetsData[ASSETS_GROUP.STABLE_COINS]}
            showHoldingsAmount={false}
            title={s('table.stableCoins')}
          />
        </Block>
      )}

      {assetsData[ASSETS_GROUP.CRYPTO_CURRENCIES].length > 0 && (
        <Block mt="20px">
          <AssetsTable
            assets={assetsData[ASSETS_GROUP.CRYPTO_CURRENCIES]}
            title={s('table.cryptoCurrencies')}
          />
        </Block>
      )}

      {assetsData[ASSETS_GROUP.ALT_COINS].length > 0 && (
        <Block mt="20px">
          <AssetsTable
            assets={assetsData[ASSETS_GROUP.ALT_COINS]}
            title={s('table.altCoins')}
          />
        </Block>
      )}
    </Column>
  )
}

export default Assets
