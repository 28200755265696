import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, Loader, Modal, Row } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms'

import adminUpdateEmailCredentialMutation from 'GraphQL/Mutations/Admin/adminUpdateEmailCredential.graphql'

import _, { useScopedI18n } from 'Services/I18n'

import { Content, Subtitle } from './styles'

const FIELDS = {
  EMAIL: 'email',
}

function EditEmailModal({ email, userId, onRefetch, ...rest }) {
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const s = useScopedI18n('admin.modal.editEmail')
  const authS = useScopedI18n('auth.shared')

  const [adminUpdateEmailCredential] = useMutation(
    adminUpdateEmailCredentialMutation,
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.EMAIL]: {
        presence: {
          presence: true,
          message: `^${authS('emailRequired')}`,
        },
        email: {
          email: true,
          message: `^${authS('emailInvalid')}`,
        },
      },
    }),
    [authS],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await adminUpdateEmailCredential({
          variables: {
            userId,
            email: values[FIELDS.EMAIL],
          },
        })

        toast.success(s('success'))

        setLoading(false)

        onRefetch()

        close.current()
      } catch (error) {
        // TODO: API error formatter here
        toast.error(error?.message)

        setLoading(false)
      }
    },
    [adminUpdateEmailCredential, onRefetch, s, userId],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        initialValues={{ [FIELDS.EMAIL]: email }}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Subtitle mt={1}>{s('subtitle')}</Subtitle>

              <InputLabels mt={3} title={authS('email')}>
                <InputField
                  input={{ placeholder: authS('emailPlaceholder') }}
                  name={FIELDS.EMAIL}
                  required
                  type="email"
                />
              </InputLabels>
            </Content>

            <Row borderTop center height={56} justifyCenter>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button
                    secondary
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {_('general.cancel')}
                  </Button>
                  <Button ml={3} width={130} onClick={handleSubmit}>
                    {s('action')}
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

EditEmailModal.defaultProps = {
  email: null,
  userId: null,
  onRefetch: noop,
}

EditEmailModal.propTypes = {
  email: PropTypes.string,
  userId: PropTypes.string,
  onRefetch: PropTypes.func,
}

export default EditEmailModal
