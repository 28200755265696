import styled, { css } from 'styled-components'
import { width } from 'styled-system'
import { flexbox } from '@styled-system/flexbox'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import arrowDownGlyph from 'iconoir/icons/arrow-down.svg'
import arrowUpGlyph from 'iconoir/icons/arrow-up.svg'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 20px;

  ${margin};
  ${width};
  ${flexbox};
`

const negativeValueCss = ({ negative }) =>
  negative &&
  css`
    color: ${themeGet('colors.dangerLight')};
  `
const positiveValueCss = ({ positive }) =>
  positive &&
  css`
    color: ${themeGet('colors.primary50')};
  `

export const Value = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${themeGet('colors.primary50')};

  ${positiveValueCss};
  ${negativeValueCss};
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${themeGet('space.3')}px;
  padding: ${themeGet('space.4')}px;
  border: 1px solid ${themeGet('colors.primary50')};
  background: ${themeGet('colors.white')};
`

export const ArrowUpIcon = styled(arrowUpGlyph)`
  stroke-width: 3px;
`
export const ArrowDownIcon = styled(arrowDownGlyph)`
  stroke-width: 3px;
`
