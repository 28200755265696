import React, { useCallback, useState } from 'react'

import { useStoreon } from 'storeon/react'

import { Button, Column, Row, Text } from 'Components/UI'
import { Checkbox } from 'Components/UI/Forms'

import { EXPERT_PORTFOLIO_ACTIONS } from 'Constants/store'

import { useScopedI18n } from 'Services/I18n'

import {
  ChatBubbleQuestionIcon,
  Container,
  IconWrapper,
  Step,
  StepNumber,
} from './styles'

const STEPS = [
  {
    number: 1,
    label: 'steps.1',
  },
  {
    number: 2,
    label: 'steps.2',
  },
  {
    number: 3,
    label: 'steps.3',
  },
  {
    number: 4,
    label: 'steps.4',
  },
  {
    number: 5,
    label: 'steps.5',
  },
  {
    number: 6,
    label: 'steps.6',
  },
  {
    number: 7,
    label: 'steps.7',
  },
  {
    number: 8,
    label: 'steps.8',
  },
]

function ExpertPortfolioFAQ() {
  const s = useScopedI18n('blocks.expertPortfolio.faq')
  const { dispatch } = useStoreon()

  const [isDontShowMore, setDontShowMore] = useState(false)

  const handleCheck = useCallback(event => {
    setDontShowMore(event.target.checked)
  }, [])

  const handleSubmit = useCallback(() => {
    if (isDontShowMore)
      dispatch(EXPERT_PORTFOLIO_ACTIONS.SET, { showFAQ: false })
  }, [dispatch, isDontShowMore])

  return (
    <Container>
      <Row fullWidth justifyCenter>
        <IconWrapper>
          <ChatBubbleQuestionIcon />
        </IconWrapper>
      </Row>

      <Column mt="20px">
        <Text
          as="h3"
          fontWeight={2}
          heading3
          mb="20px"
          textAlign="center"
          width="100%"
        >
          {s('title')}
        </Text>

        <Column gap={3}>
          {STEPS.map(step => (
            <Step key={step.number}>
              <StepNumber>{step.number}</StepNumber>
              <Text fontWeight={2} heading5>
                {s(step.label)}
              </Text>
            </Step>
          ))}
        </Column>
      </Column>

      <Row center mt="20px" spaceBetween>
        <Checkbox
          checked={isDontShowMore}
          label={<Text small>{s('dontShowMore')}</Text>}
          onChange={handleCheck}
        />

        <Button small onClick={handleSubmit}>
          {s('action')}
        </Button>
      </Row>
    </Container>
  )
}

export default ExpertPortfolioFAQ
