import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { FiEdit3, FiTrash } from 'react-icons/fi'
import { MdOutlineWorkHistory } from 'react-icons/md'

import { Button, Flex, Popover } from 'Components/UI'

import { ADMIN_PORTFOLIO_TEMPLATE_LOGS } from 'Router/routes'

import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const { onDelete, onEdit } = useTableContext()
  const history = useHistory()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const template = row?.original || {}

  const handleDelete = useCallback(() => {
    onDelete(template)
  }, [onDelete, template])

  const handleEdit = useCallback(() => {
    onEdit(template)
  }, [onEdit, template])

  const handleViewLogs = useCallback(() => {
    history.push(ADMIN_PORTFOLIO_TEMPLATE_LOGS(template.id))
  }, [history, template])

  return (
    <Flex>
      <Popover content="View Logs" delay={Popover.delay} showArrow>
        <Button icon outline secondary small squared onClick={handleViewLogs}>
          <MdOutlineWorkHistory />
        </Button>
      </Popover>
      <Popover content="Edit template" delay={Popover.delay} showArrow>
        <Button icon outline secondary small squared onClick={handleEdit}>
          <FiEdit3 />
        </Button>
      </Popover>
      <Popover content="Delete template" delay={Popover.delay} showArrow>
        <Button
          icon
          ml={1}
          outline
          secondary
          small
          squared
          onClick={handleDelete}
        >
          <FiTrash />
        </Button>
      </Popover>
    </Flex>
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
