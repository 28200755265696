export const FIELDS = {
  DEPOSIT: 'deposit',
}

export const VALUES = {
  CRYPTO_DEPOSIT: 'cryptoDeposit',
  BANK_DEPOSIT: 'bankDeposit',
}

export const STEPS = {
  DEPOSIT_SELECTION: 'depositSelection',
  ACCOUNTS: 'accounts',
  CONNECT_ACCOUNT: 'connectAccount',
  BANK_DEPOSIT: 'bankDeposit',
  CRYPTO_DEPOSIT: 'cryptoDeposit',
}

export const BANK_DETAILS = {
  BENEFICIARY: 'Upwealth Digital Asset Management',
  BENEFICIARY_ADDRESS: '31 Avenue de Ségur, 75007 Paris, France',
  BANK_NAME: 'Olkypay',
  BANK_ADDRESS: '64 rue Anatole France, 92300 Levallois-Perret, France',
  ACCOUNT: '01000029820',
  SWIFT: 'OPSPFR21OLK',
  IBAN: 'FR7619733000010100002982096',
}
