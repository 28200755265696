import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { DateTime } from 'luxon'
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'

import forEach from 'lodash/forEach'

import { YTick } from 'Components/Blocks/Charts/Ticks'

import { theme } from 'Theme'

import { Container } from './styles'
import TooltipComponent from './Tooltip'
import { exampleData } from './utils'

const DATE_FORMAT = {
  day: 'numeric',
  month: 'short',
}

function AssetsLineChart({
  data,
  displayExampleData,
  height,
  yAxisWidth,
  ...rest
}) {
  const processedData = useMemo(() => {
    if (displayExampleData) {
      return exampleData()
    }

    const result = []
    const initialValue = data?.[0]?.value || 0

    forEach(data, ({ date, value }) => {
      result.push({
        date,
        value,
        change: (value - initialValue) / Math.max(initialValue, 1),
      })
    })

    return result
  }, [data, displayExampleData])

  return (
    <Container {...pick(rest)} debounce={300} height={height}>
      <LineChart
        data={processedData}
        margin={{ top: 1, right: 0, bottom: 0, left: 0 }}
      >
        <YAxis
          axisLine={false}
          dataKey="value"
          tick={<YTick xOffset={2} yOffset={4} />}
          tickLine={{ stroke: theme.colors.white }}
          tickSize={yAxisWidth}
          width={yAxisWidth}
        />
        <XAxis
          axisLine={false}
          dataKey="date"
          height={22}
          padding={{ right: 20, left: 50 }}
          tick={{ fill: theme.colors.font.secondary }}
          tickFormatter={tick =>
            DateTime.fromSeconds(tick).toLocaleString(DATE_FORMAT)
          }
          tickLine={false}
        />
        <Tooltip
          content={<TooltipComponent />}
          cursor={{
            stroke: theme.colors.primary30,
            strokeDasharray: '6, 4',
          }}
          useTranslate3d
        />
        <Line
          activeDot={{
            fill: theme.colors.primary50,
            r: 2,
            stroke: theme.colors.primary50,
            strokeWidth: 1.5,
          }}
          dataKey="value"
          dot={false}
          stroke={theme.colors.primary50}
          type="monotone"
        />
      </LineChart>
    </Container>
  )
}

AssetsLineChart.defaultProps = {
  data: [],
  displayExampleData: false,
  height: 200,
  yAxisWidth: 40,
}

AssetsLineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number,
      value: PropTypes.number,
    }),
  ),
  displayExampleData: PropTypes.bool,
  height: PropTypes.number,
  yAxisWidth: PropTypes.number,
}

export default AssetsLineChart
