export const TAB = {
  ALL: 'all',
  BALANCE: 'balance',
  PORTFOLIO: 'portfolio',
}

export const ASSETS_GROUP = {
  STABLE_COINS: 'stableCoins',
  CRYPTO_CURRENCIES: 'cryptoCurrencies',
  ALT_COINS: 'altCoins',
}
