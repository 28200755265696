import { useMemo } from 'react'

import { DateTime } from 'luxon'

import map from 'lodash/map'

import { ActionsCell, EmailCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'profile',
        Cell: ({ value }) =>
          `${value?.firstName || '—'} ${value?.lastName || '—'}`,
        width: 20,
      },
      {
        Header: 'Email',
        Cell: EmailCell,
        accessor: 'emailCredentials',
        width: 35,
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: 10,
      },
      {
        Header: 'Portfolio Groups',
        accessor: 'portfolioGroups',
        width: 15,
        Cell: ({ value }) => map(value, group => group.name).join(', '),
      },
      {
        Header: 'Platform Balance',
        Cell: ({ value }) =>
          map(
            value,
            item => `${item.amount?.toFixed(2)} ${item.currency}`,
          ).join(', '),
        accessor: 'balances',
        width: 15,
      },
      {
        Header: 'Total Balance',
        accessor: 'totalUSDTBalance',
        Cell: ({ value }) => `${value?.toFixed(2)} USDT`,
        width: 15,
      },
      {
        Header: 'Last logged in',
        Cell: ({ value }) =>
          value
            ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
            : '—',
        accessor: 'lastLoginAt',
        width: 20,
      },
      {
        Header: 'Actions',
        Cell: ActionsCell,
        width: 25,
      },
    ],
    [],
  )
}
