import React from 'react'
import PropTypes from 'prop-types'

import { FiExternalLink } from 'react-icons/fi'

import { Popover } from 'Components/UI'

import Utils from 'Utils'

import { Container, Link } from './styles'

function Hash({ value }) {
  return (
    <Container>
      {Utils.Strings.trimMiddle(value)}{' '}
      <Popover content="Link to Etherscan" delay={Popover.delay} showArrow>
        <Link
          href={`https://etherscan.io/tx/${value}`}
          ml={1}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FiExternalLink />
        </Link>
      </Popover>
    </Container>
  )
}

Hash.defaultProps = {
  value: '',
}

Hash.propTypes = {
  value: PropTypes.string,
}

export default Hash
