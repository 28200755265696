import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'

import shared from 'Services/Shared'

import { authLink, errorLink, requestLink } from './Links'

export function createApolloClient({ history }) {
  const cache = new InMemoryCache({
    typePolicies: {
      CryptoToken: {
        keyFields: ['ticker'],
      },
    },
  })

  const links = [errorLink(history), authLink(), requestLink(cache)]

  const clientOptions = {
    link: ApolloLink.from(links),
    connectDevTools: true,
    cache,
  }

  const client = new ApolloClient(clientOptions)

  shared.setClient(client)

  return client
}
