import React from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Button, Column, Row, Text } from 'Components/UI'

import { ItemContainer, Logo } from './styles'

function Item({ action, title, description, logo, onClick, outlineButton }) {
  return (
    <ItemContainer>
      <Row center>
        <Logo>{logo}</Logo>
        <Column justifyCenter ml={3}>
          <Text firstCapital heading4>
            {title}
          </Text>
          <Text mt={1}>{description}</Text>
        </Column>
      </Row>

      <Button
        ml="auto"
        outline={outlineButton}
        width="116px"
        xSmall
        onClick={onClick}
      >
        {action}
      </Button>
    </ItemContainer>
  )
}

Item.defaultProps = {
  action: null,
  description: null,
  logo: null,
  outlineButton: false,
  title: null,
  onClick: noop,
}

Item.propTypes = {
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.string,
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  outlineButton: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
}

export default Item
