export const FIELDS = {
  RISK_TOLERANCE: 'riskTolerance',
  TOTAL_NET_WORTH: 'totalNetWorth',
  TOTAL_INVESTMENTS: 'totalInvestments',
  INVESTMENT_HORIZON: 'investmentHorizon',
  INVESTING_EXPERIENCE: 'investingExperience',
  TAX_RESIDENCE: 'taxResidence',
  US_CITIZEN: 'usCitizen',
  INDUSTRY: 'industry',
  PROFESSION: 'profession',
  NET_MONTHLY_INCOME: 'netMonthlyIncome',
  FUND_SOURCE: 'fundSource',
}
