import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFlexLayout, usePagination, useTable } from 'react-table'

import { useLazyQuery } from '@apollo/client'

import get from 'lodash/get'

import { Row, Select } from 'Components/UI'
import { Pagination, Table } from 'Components/UI/Admin'
import { InputLabels } from 'Components/UI/Forms'

import { STATE } from 'Constants/portfolios'

import adminUserPortfoliosQuery from 'GraphQL/Queries/Admin/adminUserPortfolios.graphql'

import { useColumns } from './columns'
import { Container, Content } from './styles'

const ALL = 'all'

function valueToOption(value) {
  return {
    label: value,
    value,
  }
}

function buildOptions(values) {
  return [ALL, ...Object.values(values)].map(valueToOption)
}

const STATE_OPTIONS = buildOptions(STATE)

function UserPortfoliosTable() {
  const [loadData, { data, loading }] = useLazyQuery(adminUserPortfoliosQuery)

  const [state, setState] = useState(valueToOption(ALL))

  const items = useMemo(() => get(data, 'adminUserPortfolios') || {}, [data])

  const tableData = useMemo(() => get(items, 'rows') || [], [items])

  const columns = useColumns()

  const tableProps = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: get(items, 'pages') || 0,
    },
    usePagination,
    useFlexLayout,
  )

  const {
    state: { pageIndex, pageSize },
  } = tableProps

  const fetchData = useCallback(() => {
    const variables = {
      page: pageIndex,
      limit: pageSize,
      sort: [{ column: 'createdAt', order: 'desc' }],
    }

    if (state.value !== ALL) {
      variables.state = state.value
    }

    console.log({ variables })

    loadData({ variables, fetchPolicy: 'cache-and-network' })
  }, [loadData, state.value, pageIndex, pageSize])

  useEffect(
    () => {
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, pageSize, state.value],
  )

  return (
    <Container>
      <Content>
        <Row borderBottom mb={2} pb={3}>
          <InputLabels title="State" width={160}>
            <Select
              options={STATE_OPTIONS}
              small
              value={state}
              onChange={setState}
            />
          </InputLabels>
        </Row>

        <Table {...tableProps} loading={loading} />

        <Row borderTop mt={2} pt={3}>
          <Pagination {...tableProps} total={get(items, ['count']) || 0} />
        </Row>
      </Content>
    </Container>
  )
}

export default UserPortfoliosTable
