import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import calendarGlyph from 'iconoir/icons/calendar.svg'

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: ${mapToTheme('inputs.height')}px;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid ${themeGet('colors.divider.light')};
  background: ${themeGet('colors.bg.main')};
  padding: 0 8px;

  ${layout.width}
  ${margin}
`

export const CalendarIcon = styled(calendarGlyph)`
  flex-shrink: 0;
`
