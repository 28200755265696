import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Button, Column, Row, Text } from 'Components/UI'

import { KYC_STATUS } from 'Constants/ids'

import { useScopedI18n } from 'Services/I18n'

import {
  ArrowRightIcon,
  CheckIcon,
  Container,
  HourGlassIcon,
  IconContainer,
  WarningIcon,
} from './styles'

const CONTENT = {
  [KYC_STATUS.SUCCESS]: {
    title: 'success.title',
    description: null,
  },
  [KYC_STATUS.FAILURE]: {
    title: 'failure.title',
    description: 'failure.description',
  },
  [KYC_STATUS.REVIEW]: {
    title: 'review.title',
    description: 'review.description',
  },
}

function Status({ status, onContinue, onGoToKYC }) {
  const s = useScopedI18n('kyc.statuses')

  const icon = useMemo(() => {
    switch (status) {
      case KYC_STATUS.REVIEW:
        return <HourGlassIcon height={40} width={40} />
      case KYC_STATUS.FAILURE:
        return <WarningIcon height={40} viewBox="0 0 24 24" width={40} />
      case KYC_STATUS.SUCCESS:
        return <CheckIcon height={40} viewBox="0 0 24 24" width={40} />
      default:
        return null
    }
  }, [status])

  const buttons = useMemo(() => {
    switch (status) {
      case KYC_STATUS.REVIEW:
        return (
          <Column>
            <Row justifyCenter>
              <Button onClick={onContinue}>
                {s('common.actions.continue')} <ArrowRightIcon />
              </Button>
            </Row>
          </Column>
        )
      case KYC_STATUS.FAILURE:
        return (
          <Column>
            <Row gap={6} justifyCenter>
              <Button onClick={onGoToKYC}>
                {s('failure.actions.goToKYC')} <ArrowRightIcon />
              </Button>
              <Button onClick={onContinue}>
                {s('common.actions.continue')}
              </Button>
            </Row>
          </Column>
        )
      case KYC_STATUS.SUCCESS:
        return (
          <Row justifyCenter>
            <Button onClick={onContinue}>
              {s('common.actions.continue')} <ArrowRightIcon />
            </Button>
          </Row>
        )
      default:
        return null
    }
  }, [status, onGoToKYC, onContinue, s])

  return (
    <Container>
      <IconContainer error={status === KYC_STATUS.FAILURE}>
        {icon}
      </IconContainer>

      <Text fontWeight={2} heading4 mt={4} textAlign="center">
        {s(CONTENT[status].title)}
      </Text>

      <Text heading4 mb={5} mt={2} small textAlign="center">
        {CONTENT[status].description && s(CONTENT[status].description)}
      </Text>

      {buttons}
    </Container>
  )
}

Status.propTypes = {
  status: PropTypes.oneOf(Object.values(KYC_STATUS)).isRequired,
  onContinue: PropTypes.func.isRequired,
  onGoToKYC: PropTypes.func.isRequired,
}
export default Status
