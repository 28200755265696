import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { FiCheckCircle } from 'react-icons/fi'

import { Button, Popover, Row } from 'Components/UI'

import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const { onApprove } = useTableContext()

  const item = row?.original || {}

  const handleApprove = useCallback(() => {
    onApprove(item)
  }, [item, onApprove])

  return (
    <Row>
      <Popover content="Approve KYC Request" delay={Popover.delay} showArrow>
        <Button icon outline secondary small squared onClick={handleApprove}>
          <FiCheckCircle />
        </Button>
      </Popover>
    </Row>
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
