import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import { Container, InvisibleRadio, StyledRadioButton } from './styles'

function RadioButton({
  checked,
  defaultChecked,
  disabled,
  label,
  name,
  small,
  value,
  id,
  onChange,
  onFocus,
  onBlur,
  ...rest
}) {
  const handleClick = useCallback(() => {
    if (!checked) onChange(value)
  }, [value, checked, onChange])

  return (
    <Container htmlFor={id} {...pick(rest)}>
      {label && <div>{label}</div>}

      <InvisibleRadio
        defaultChecked={checked}
        disabled={disabled}
        id={id}
        name={name}
        value={value}
        onBlur={onBlur}
        onClick={handleClick}
        onFocus={onFocus}
      />
      <StyledRadioButton className="radio-button" small={small} />
    </Container>
  )
}

RadioButton.defaultProps = {
  checked: false,
  defaultChecked: undefined,
  disabled: undefined,
  id: undefined,
  label: undefined,
  value: undefined,
  small: false,
  onBlur: noop,
  onChange: noop,
  onFocus: noop,
}

RadioButton.propTypes = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}

export default RadioButton
