import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi'

import noop from 'lodash/noop'

import { NavButton, NavButtons, PageSize, PaginationContainer } from './styles'

const SIZES = [10, 25, 50, 100]

function Pagination({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageCount,
  pageOptions,
  previousPage,
  setPageSize,
  state: { pageSize, pageIndex },
  total,
}) {
  const handleChangeSize = useCallback(
    ({ target: { value } }) => {
      setPageSize(Number(value))
    },
    [setPageSize],
  )

  return (
    <PaginationContainer>
      <NavButtons>
        <NavButton
          disabled={!canPreviousPage}
          mr={1}
          onClick={() => gotoPage(0)}
        >
          <FiChevronsLeft />
        </NavButton>
        <NavButton disabled={!canPreviousPage} mr={1} onClick={previousPage}>
          <FiChevronLeft />
        </NavButton>
        <NavButton disabled={!canNextPage} mr={1} onClick={nextPage}>
          <FiChevronRight />
        </NavButton>
        <NavButton
          disabled={!canNextPage}
          onClick={() => gotoPage(pageCount - 1)}
        >
          <FiChevronsRight />
        </NavButton>
      </NavButtons>

      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>{' '}
        (total <strong>{total}</strong> items)
      </span>

      <PageSize value={pageSize} onChange={handleChangeSize}>
        {SIZES.map(size => (
          <option key={size} value={size}>
            Show {size}
          </option>
        ))}
      </PageSize>
    </PaginationContainer>
  )
}

Pagination.defaultProps = {
  canNextPage: false,
  canPreviousPage: false,
  gotoPage: noop,
  nextPage: noop,
  pageCount: 0,
  pageOptions: [],
  previousPage: noop,
  setPageSize: noop,
  state: {},
  total: 0,
}

Pagination.propTypes = {
  canNextPage: PropTypes.bool,
  canPreviousPage: PropTypes.bool,
  gotoPage: PropTypes.func,
  nextPage: PropTypes.func,
  pageCount: PropTypes.number,
  pageOptions: PropTypes.array,
  previousPage: PropTypes.func,
  setPageSize: PropTypes.func,
  state: PropTypes.object,
  total: PropTypes.number,
}

export default Pagination
