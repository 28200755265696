import styled, { css } from 'styled-components'
import { margin } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import checkGlyph from 'iconoir/icons/check.svg'

export const Container = styled.div`
  display: flex;
  width: 270px;
  padding: ${themeGet('space.6')}px;
  flex-direction: column;
  border-radius: 12px;
  background: ${themeGet('colors.white')};
  flex-shrink: 0;
  align-self: flex-start;

  ${margin}
`
export const StepItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${themeGet('space.3')}px;
`

const activeStepStatusCss = ({ active }) =>
  active &&
  css`
    border-radius: 16px;
    border: 8px solid ${themeGet('colors.primary50')};
    background: ${themeGet('colors.white')};
    box-shadow: 0 0 0 3px ${themeGet('colors.aquaMist')};
  `

export const passedStepStatusCss = ({ passed }) =>
  passed &&
  css`
    border: 0;
    background: ${themeGet('colors.primary')};
  `

export const StepStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${themeGet('colors.white')};
  background: ${themeGet('colors.white')};
  border: 8px solid ${themeGet('colors.text20')};

  ${activeStepStatusCss};
  ${passedStepStatusCss};
`

export const Connector = styled.div`
  width: 2px;
  height: 14px;
  border-radius: 2px;
  background: ${themeGet('colors.divider.light')};
  margin: 4px 0 4px 11px;
`

export const EmptyCircle = styled.div``
export const CheckIcon = styled(checkGlyph)``
