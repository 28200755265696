import React from 'react'

import { CryptoTokensTable } from 'Components/Blocks/Admin'
import { Row } from 'Components/UI'

function CryptoTokens() {
  return (
    <Row p={2}>
      <CryptoTokensTable />
    </Row>
  )
}

export default CryptoTokens
