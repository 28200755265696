import React, { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import ConnectAccount from 'Components/Blocks/ConnectAccount'
import { Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { Container } from './styles'

function AddToNetWorth({ initialKind }) {
  const history = useHistory()
  const formRef = useRef()
  const s = useScopedI18n('blocks.netWorth.addToNetWorth')

  useEffect(() => {
    if (initialKind && formRef?.current?.submit) formRef.current.submit()
  }, [initialKind])

  const handleBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Container>
      <Text as="h3" fontWeight={2} heading3>
        {s('title')}
      </Text>
      <Text as="h6" fontWeight={1} heading6 mt={3}>
        {s('subTitle')}
      </Text>

      <ConnectAccount initialKind={initialKind} onBack={handleBack} />
    </Container>
  )
}

AddToNetWorth.defaultProps = {
  initialKind: undefined,
}

AddToNetWorth.propTypes = {
  initialKind: PropTypes.string,
}

export default AddToNetWorth
