import React from 'react'
import PropTypes from 'prop-types'

import { Container, LogoIcon } from './styles'

function Logo({ inverse, size }) {
  return (
    <Container size={size}>
      <LogoIcon inverse={inverse ? 1 : 0} />
    </Container>
  )
}

Logo.defaultProps = {
  inverse: false,
  size: 50,
}

Logo.propTypes = {
  inverse: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Logo
