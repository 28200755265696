import React, { useEffect, useMemo } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'

import FullPageLoader from 'Components/Blocks/FullPageLoader'

import { USER_ROLE } from 'Constants/ids'

import { useAppContext } from 'Hooks'

import Utils from 'Utils'

import { ADMIN_ROOT, APP_ROOT, ONBOARDING_ROOT, ROOT } from './routes'

function Director() {
  const { me } = useAppContext()
  const history = useHistory()
  const location = useLocation()

  const isAppRoutes = !!useRouteMatch(APP_ROOT)
  const isOnboardingRoutes = !!useRouteMatch(ONBOARDING_ROOT)
  const isRootRoute = location.pathname === ROOT

  const onboardingPassed = useMemo(() => Utils.Me.isOnboardingPassed(me), [me])

  useEffect(() => {
    if (!me?.role) {
      return
    }

    if (me?.role !== USER_ROLE.USER) {
      if (isAppRoutes) {
        history.replace(ADMIN_ROOT)
      }
    }

    if (isAppRoutes && !onboardingPassed && me?.role === USER_ROLE.USER) {
      history.replace(ONBOARDING_ROOT)
      return
    }

    if (isOnboardingRoutes && onboardingPassed) {
      history.replace(APP_ROOT)
    }

    if (isRootRoute && me?.role) {
      history.replace(APP_ROOT)
    }
  }, [
    onboardingPassed,
    isAppRoutes,
    isOnboardingRoutes,
    isRootRoute,
    history,
    me,
  ])

  if (isRootRoute && me?.role) return <FullPageLoader />

  return null
}

export default Director
