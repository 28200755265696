import Accounts from './Accounts'
import AddNetWorth from './AddNetWorth'
import Assets from './Assets'
import Deposit from './Deposit'
import Funding from './Funding'
import Profile from './Profile'
import Root from './Root'
import Transactions from './Transactions'
import Withdrawal from './Withdrawal'

export default {
  Deposit,
  Profile,
  Root,
  Transactions,
  Funding,
  AddNetWorth,
  Accounts,
  Assets,
  Withdrawal,
}
