import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { AUTH_TOKEN_DEFAULTS } from 'Constants/defaults'
import { AUTH_ACTIONS, AUTH_STATE } from 'Constants/store'

import shared from 'Services/Shared'

export function getAuth() {
  return get(shared.getStore().get(), AUTH_STATE)
}

export function getAccessToken() {
  return get(getAuth(), 'accessToken')
}

export function getRefreshToken() {
  return get(getAuth(), 'refreshToken')
}

export function setAuth(variables) {
  return shared.getStore().dispatch(AUTH_ACTIONS.SET, variables)
}

export function clearAuth() {
  return shared.getStore().dispatch(AUTH_ACTIONS.CLEAR)
}

export default function authState(store) {
  store.on('@init', () => ({
    [AUTH_STATE]: AUTH_TOKEN_DEFAULTS,
  }))

  store.on(AUTH_ACTIONS.SET, (state, variables) => {
    const values = pick(variables, Object.keys(AUTH_TOKEN_DEFAULTS))

    const nextAuth = { ...state[AUTH_STATE], ...values }

    if (isEqual(nextAuth, state[AUTH_STATE])) return null

    return { [AUTH_STATE]: nextAuth }
  })

  store.on(AUTH_ACTIONS.CLEAR, () => ({ [AUTH_STATE]: AUTH_TOKEN_DEFAULTS }))
}
