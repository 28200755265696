
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MyProfileFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserProfile"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserProfileFields"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"onboardingSteps"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OnboardingStepsFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"riskAssessment"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RiskAssessmentFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":325}};
    doc.loc.source = {"body":"#import \"GraphQL/Fragments/OnboardingSteps.graphql\"\n#import \"GraphQL/Fragments/RiskAssessment.graphql\"\n#import \"GraphQL/Fragments/UserProfile.graphql\"\n\nfragment MyProfileFields on UserProfile {\n  ...UserProfileFields\n\n  onboardingSteps {\n    ...OnboardingStepsFields\n  }\n\n  riskAssessment {\n    ...RiskAssessmentFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("GraphQL/Fragments/OnboardingSteps.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("GraphQL/Fragments/RiskAssessment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("GraphQL/Fragments/UserProfile.graphql").definitions));


      module.exports = doc;
    
