import { useMemo } from 'react'

import { DateTime } from 'luxon'

import { ActionsCell, StateCell, WalletsCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'Amount',
        Cell: ({ row }) =>
          `${row?.original?.amount} ${row?.original?.currency}`,
        width: 100,
      },
      {
        Header: 'State',
        accessor: 'state',
        Cell: StateCell,
        width: 70,
      },
      {
        Header: 'Kind',
        accessor: 'kind',
        width: 70,
      },
      // {
      //   Header: 'Hash',
      //   accessor: 'hash',
      //   Cell: HashCell,
      //   width: 5,
      // },
      {
        Header: 'User wallet (input by user)',
        accessor: 'address',
        width: 380,
        // minWidth: 360,
      },
      {
        Header: 'All user wallets (Vezgo)',
        accessor: 'user.userCryptoWallets',
        // minWidth: 370,
        width: 380,
        Cell: WalletsCell,
      },
      {
        Header: 'User name',
        accessor: 'user',
        Cell: ({ value }) =>
          `${value.profile?.firstName} ${value.profile?.lastName}`,
        width: 70,
      },
      {
        Header: 'Created',
        Cell: ({ value }) =>
          value
            ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
            : '—',
        accessor: 'createdAt',
        width: 70,
      },
      {
        Header: 'Action',
        Cell: ActionsCell,
        width: 50,
      },
    ],
    [],
  )
}
