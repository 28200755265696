import palette from 'Theme/Main/palette'

export default {
  size: {
    default: 20,
    small: 16,
  },
  borderRadius: {
    default: 16,
  },
  borderWidth: {
    unselected: 2,
    selected: 6,
  },

  enabled: {
    borderColor: {
      default: palette.text20,
    },
    backgroundColor: {
      default: palette.white,
    },
    hover: {
      borderColor: {
        default: palette.primary60,
      },
    },
    focus: {
      boxShadow: {
        default: palette.aquaMist,
      },
      borderColor: {
        default: palette.primary50,
      },
    },
  },

  selected: {
    borderColor: {
      default: palette.primary50,
    },
    borderWidth: {
      default: 6,
    },
    backgroundColor: {
      default: palette.white,
      borderColor: {
        default: palette.primary50,
      },
    },
    disabled: {
      borderColor: {
        default: palette.primary30,
      },
      backgroundColor: {
        default: palette.white,
      },
    },
  },

  disabled: {
    borderColor: {
      default: palette.gray20,
    },
    backgroundColor: {
      default: palette.gray10,
    },
  },
}
