import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

export const Container = styled.div`
  width: 100%;

  div[role='table'] {
    overflow: auto;
  }

  ${Styles.border(4)};
`

export const Content = styled.div`
  padding: ${themeGet('space.3')}px;
`
