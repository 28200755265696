import { binance as Binance } from 'ccxt'

import first from 'lodash/first'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'

import cryptocurrencies from 'Assets/cryptocurrencies.json'

import { BANNED_TOKENS_SET } from 'Constants/cryptoTokens'
import { PRIMARY_CURRENCY } from 'Constants/currencies'

class PortfolioService {
  tickersPromise = null

  tickers = {}

  async loadTickers(forceReload = false) {
    if (!isEmpty(this.tickers) && !forceReload) {
      return this.tickers
    }

    const binance = new Binance()
    binance.fetchOHLCV()
    const result = {}
    const loadSymbols = []
    const quoteName = get(cryptocurrencies, PRIMARY_CURRENCY)
    const markets = await binance.loadMarkets()

    forEach(markets, market => {
      const quote = get(market, 'quote')

      if (quote !== PRIMARY_CURRENCY || !get(market, 'active')) {
        return
      }

      const symbol = get(market, 'symbol')
      const base = get(market, 'base')

      if (BANNED_TOKENS_SET.has(base)) return

      loadSymbols.push(symbol)
      result[base] = {
        base,
        baseName: get(cryptocurrencies, base) || base,
        quote,
        quoteName,
      }
    })

    const loadedTickers = await binance.fetchTickers(loadSymbols)

    forEach(loadedTickers, (ticker, symbol) => {
      const base = first(split(symbol, '/'))

      if (!base) return

      result[base].ask = get(ticker, 'ask')
      result[base].bid = get(ticker, 'bid')
      result[base].perecentage = get(ticker, 'percentage')
    })

    this.tickers = result

    return result
  }

  async getTickers() {
    if (this.tickersPromise) {
      return this.tickersPromise
    }

    this.tickersPromise = this.loadTickers()

    return this.tickersPromise
  }

  static portfoliosSummary(portfolios, currency = PRIMARY_CURRENCY) {
    let amount = 0
    const uniqueAssets = new Set()

    forEach(portfolios, portfolio => {
      if (get(portfolio, 'currency') !== currency) {
        // NOTE: skipping for now. we can introduce currency conversion in the future
        return
      }

      forEach(get(portfolio, 'assets'), asset => {
        uniqueAssets.add(get(asset, 'asset'))
      })

      amount += get(portfolio, 'amount') || 0
    })

    return {
      amount,
      assets: Array.from(uniqueAssets),
      currency,
    }
  }

  async populatePortfolios(portfolios, currency = PRIMARY_CURRENCY) {
    const tickers = await this.getTickers()
    const result = []

    forEach(portfolios, ({ assets, ...portfolio }) => {
      // if (get(portfolio, 'currency') !== currency) {
      //   // NOTE: skipping for now. we can introduce currency conversion in the future
      //   return
      // }

      let portfolioCost = 0
      const resultAssets = []

      forEach(assets, asset => {
        const tickerBase = get(asset, 'asset')
        const ticker = get(tickers, tickerBase)

        portfolioCost += asset.cost || 0
        resultAssets.push({
          ...asset,
          base: ticker?.base || tickerBase,
          baseName: ticker?.baseName || tickerBase,
          quote: currency,
          ask: ticker?.ask,
          percentage: asset.percentage,
          cost: asset.cost,
          amount: asset.amount,
          last24hPercentage: ticker?.perecentage,
        })
      })

      result.push({
        ...portfolio,
        cost: portfolioCost,
        assets: resultAssets,
      })
    })

    return result
  }

  // NOTE: percentages are potentially incorrect here
  // function should be reworked taking order execution price and current price into account
  async buildGlobalPortfolio(portfolios, currency = PRIMARY_CURRENCY, state) {
    if (isEmpty(portfolios)) return null

    const summary = this.constructor.portfoliosSummary(portfolios, currency)
    const tickers = await this.getTickers()

    const resultAssets = {}
    let totalHold = 0

    forEach(portfolios, portfolio => {
      if (get(portfolio, 'currency') !== currency) {
        // NOTE: skipping for now. we can introduce currency conversion in the future
        return
      }

      if (state && portfolio.state !== state) {
        return
      }

      const portfolioId = get(portfolio, 'id')
      const amount = get(portfolio, 'amount')

      forEach(get(portfolio, 'assets') || [], asset => {
        const tickerBase = get(asset, 'asset')
        const ticker = get(tickers, tickerBase)
        if (!ticker) {
          // NOTE: noting we can do here ATM
          return
        }

        const rawPercentage = get(asset, 'percentage')
        const percentage = get(asset, 'percentage') / 100
        const ask =
          asset.cost && asset.amount
            ? asset.cost / asset.amount
            : get(ticker, 'ask')

        if (!resultAssets[tickerBase]) {
          resultAssets[tickerBase] = {
            base: get(ticker, 'base'),
            baseName: get(ticker, 'baseName'),
            quote: currency,
            ask,
            balance: 0,
            total: 0,
            percentage: 0,
            portfolios: {},
          }
        }

        const balance = asset.amount || amount / ask
        const total = asset.cost || amount * percentage

        totalHold += total
        resultAssets[tickerBase].balance += balance
        resultAssets[tickerBase].total += total
        resultAssets[tickerBase].percentage +=
          rawPercentage * (amount / summary.amount)

        resultAssets[tickerBase].portfolios[portfolioId] = {
          portfolioName: get(portfolio, 'name'),
          ask,
          balance,
          total,
          percentage: rawPercentage,
        }
      })
    })

    return {
      ...summary,
      assets: resultAssets,
      hold: totalHold,
      holdChange: ((totalHold - summary.amount) / summary.amount) * 100,
    }
  }
}

export default PortfolioService
