/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'

import { DateTime } from 'luxon'

import { Button, Link, Row, Text } from 'Components/UI'

import { SIDE } from 'Constants/orders'
import { KIND } from 'Constants/transactions'

import { PORTFOLIO_ROOT } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import { CopyIcon, LinkIcon } from './styles'

export const COLUMNS = {
  DATE: 'date',
  KIND: 'kind',
  DEPOSIT_WALLET: 'depositWallet',
  WITHDRAW_WALLET: 'withdrawWallet',
  PORTFOLIO_NAME: 'portfolioName',
  PAIR: 'pair',
  SIDE: 'side',
  PRICE: 'price',
  EXECUTED: 'executed',
  FEE: 'fee',
  TOTAL: 'total',
  ASSET: 'asset',
  AMOUNT: 'amount',
  DESTINATION: 'destination',
  TXID: 'txid',
  STATUS: 'status',
}

const DATE_FORMAT = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
}

export function useColumns() {
  const s = useScopedI18n('transactions.table')

  const handleCopy = useCallback(
    async (text, translateText) => {
      if (!navigator.clipboard) return
      try {
        await navigator.clipboard.writeText(text)
        toast.success(s(translateText))
      } catch (error) {
        toast.error(s('toasts.errors.copy'))
      }
    },
    [s],
  )

  return useMemo(
    () => [
      {
        Header: s('rows.time'),
        Cell: ({ value }) =>
          DateTime.fromISO(value).toLocaleString(DATE_FORMAT),
        accessor: COLUMNS.DATE,
        width: '156',
        disableSortBy: true,
      },
      {
        Header: s('rows.kind'),
        Cell: ({ value }) => value,
        accessor: COLUMNS.KIND,
        width: '120',
        disableSortBy: true,
      },
      {
        Header: s('rows.depositWallet'),
        Cell: ({ value }) => value,
        accessor: COLUMNS.DEPOSIT_WALLET,
        width: '156',
        disableSortBy: true,
      },
      {
        Header: s('rows.withdrawWallet'),
        Cell: ({ value }) => value,
        accessor: COLUMNS.WITHDRAW_WALLET,
        width: '156',
        disableSortBy: true,
      },
      {
        Header: s('rows.portfolioName'),
        Cell: ({ value, row }) => {
          return (
            <Row maxWidth="138px">
              <Link ellipsis to={PORTFOLIO_ROOT(row.original.portfolioId)}>
                <Text extraSmall>{value || <>&mdash;</>}</Text>
              </Link>
            </Row>
          )
        },
        accessor: COLUMNS.PORTFOLIO_NAME,
        width: '142',
        disableSortBy: true,
      },
      {
        Header: s('rows.asset'),
        Cell: ({ value }) => value || <>&mdash;</>,
        accessor: COLUMNS.ASSET,
        width: '100',
        disableSortBy: true,
      },
      {
        Header: s('rows.amount'),
        Cell: ({ value }) => (
          <Text ellipsis maxWidth="154px">
            {value || <>&mdash;</>}
          </Text>
        ),
        accessor: COLUMNS.AMOUNT,
        width: '110',
        disableSortBy: true,
      },
      {
        Header: s('rows.destination'),
        Cell: ({ value }) => (
          <Row center gap={1} maxWidth={156}>
            <Text ellipsis>{value || <>&mdash;</>}</Text>

            <LinkIcon height={15} viewBox="0 0 24 24" width={15} />

            <Button
              color="text100"
              p="2px"
              text
              onClick={() =>
                handleCopy(value, 'toasts.success.destinationCopy')
              }
            >
              <CopyIcon height={15} viewBox="0 0 24 24" width={15} />
            </Button>
          </Row>
        ),
        accessor: COLUMNS.DESTINATION,
        width: '156',
        disableSortBy: true,
      },
      {
        Header: s('rows.txid'),
        Cell: ({ value }) => (
          <Row center gap={1} maxWidth={156}>
            <Text ellipsis>{value || <>&mdash;</>}</Text>

            <LinkIcon height={15} viewBox="0 0 24 24" width={15} />

            <Button
              color="text100"
              p="2px"
              text
              onClick={() => handleCopy(value, 'toasts.success.addressCopy')}
            >
              <CopyIcon height={15} viewBox="0 0 24 24" width={15} />
            </Button>
          </Row>
        ),
        accessor: COLUMNS.TXID,
        width: '156',
        disableSortBy: true,
      },
      {
        Header: s('rows.pair'),
        Cell: ({ value }) => value || <>&mdash;</>,
        accessor: COLUMNS.PAIR,
        width: '142',
        disableSortBy: true,
      },
      {
        Header: s('rows.side'),
        Cell: ({ value }) => (
          <Text
            color={
              value.toLowerCase() === SIDE.Buy
                ? 'successPrimary'
                : 'dangerPrimary'
            }
          >
            {value}
          </Text>
        ),
        accessor: COLUMNS.SIDE,
        width: '142',
        disableSortBy: true,
      },
      {
        Header: s('rows.price'),
        Cell: ({ value }) => (
          <Text ellipsis maxWidth="138px">
            {value || <>&mdash;</>}
          </Text>
        ),
        accessor: COLUMNS.PRICE,
        width: '142',
        disableSortBy: true,
      },
      {
        Header: s('rows.executed'),
        Cell: ({ value }) => (
          <Text ellipsis maxWidth="138px">
            {value || <>&mdash;</>}
          </Text>
        ),
        accessor: COLUMNS.EXECUTED,
        width: '142',
        disableSortBy: true,
      },
      {
        Header: s('rows.fee'),
        Cell: ({ value }) => (
          <Text ellipsis maxWidth="138px">
            {value || <>&mdash;</>}
          </Text>
        ),
        accessor: COLUMNS.FEE,
        width: '142',
        disableSortBy: true,
      },
      {
        Header: s('rows.total'),
        Cell: ({ value }) => (
          <Text ellipsis maxWidth="138px">
            {value || <>&mdash;</>}
          </Text>
        ),
        accessor: COLUMNS.TOTAL,
        width: '142',
        disableSortBy: true,
      },
      {
        Header: s('rows.status'),
        Cell: ({ value }) => value,
        accessor: COLUMNS.STATUS,
        width: '120',
        disableSortBy: true,
      },
    ],
    [handleCopy, s],
  )
}

export function useHiddenColumns(kind) {
  return useMemo(() => {
    if (kind === KIND.DEPOSIT) {
      return [
        COLUMNS.WITHDRAW_WALLET,
        COLUMNS.PORTFOLIO_NAME,
        COLUMNS.PAIR,
        COLUMNS.SIDE,
        COLUMNS.PRICE,
        COLUMNS.EXECUTED,
        COLUMNS.FEE,
        COLUMNS.TOTAL,
      ]
    }

    if (kind === KIND.WITHDRAW) {
      return [
        COLUMNS.DEPOSIT_WALLET,
        COLUMNS.PORTFOLIO_NAME,
        COLUMNS.PAIR,
        COLUMNS.SIDE,
        COLUMNS.PRICE,
        COLUMNS.EXECUTED,
        COLUMNS.FEE,
        COLUMNS.TOTAL,
      ]
    }

    if (
      kind === KIND.SELL_ORDER_FILLED ||
      kind === KIND.BUY_ORDER_FILLED ||
      kind === KIND.REBALANCE_SELL_ORDER_FILLED ||
      kind === KIND.REBALANCE_BUY_ORDER_FILLED
    ) {
      return [
        COLUMNS.KIND,
        COLUMNS.DEPOSIT_WALLET,
        COLUMNS.WITHDRAW_WALLET,
        COLUMNS.PORTFOLIO_NAME,
        COLUMNS.ASSET,
        COLUMNS.AMOUNT,
        COLUMNS.DESTINATION,
        COLUMNS.TXID,
      ]
    }

    if (kind === KIND.FUND_PORTFOLIO) {
      return [
        COLUMNS.DEPOSIT_WALLET,
        COLUMNS.WITHDRAW_WALLET,
        COLUMNS.PAIR,
        COLUMNS.SIDE,
        COLUMNS.PRICE,
        COLUMNS.EXECUTED,
        COLUMNS.TOTAL,
        COLUMNS.ASSET,
        COLUMNS.DESTINATION,
        COLUMNS.TXID,
      ]
    }

    if (kind === KIND.CLOSE_PORTFOLIO) {
      return [
        COLUMNS.DEPOSIT_WALLET,
        COLUMNS.WITHDRAW_WALLET,
        COLUMNS.PAIR,
        COLUMNS.SIDE,
        COLUMNS.PRICE,
        COLUMNS.EXECUTED,
        COLUMNS.TOTAL,
        COLUMNS.TXID,
      ]
    }

    return []
  }, [kind])
}
