import React from 'react'
import PropTypes from 'prop-types'

import numeral from 'numeral'

import { Column, Text } from 'Components/UI'

import { theme } from 'Theme'

import { ArrowDownIcon, ArrowUpIcon, Value } from '../styles'

function arrow(value) {
  if (value > 0) {
    return (
      <ArrowUpIcon
        color={theme.colors.primary}
        height={12}
        mr={1}
        viewBox="0 0 24 24"
        width={12}
      />
    )
  }
  if (value < 0) {
    return (
      <ArrowDownIcon
        color={theme.colors.dangerLight}
        height={12}
        mr={1}
        viewBox="0 0 24 24"
        width={12}
      />
    )
  }

  return null
}

// function formatValue(value, currency = '$') {
//   return numeral(value).format(`${currency}0,0.[00]`)
// }

function Name({ row }) {
  const original = row?.original
  const percentage = original?.last24hPercentage

  // const gainValue = (original.cost * percentage) / 100

  if (!percentage) return null

  return (
    <Column justifyCenter>
      {/* <Value negative={percentage < 0} positive={percentage > 0}> */}
      {/*  <Text fontWeight={1} heading6> */}
      {/*    {formatValue(gainValue)} */}
      {/*  </Text> */}
      {/* </Value> */}

      <Value negative={percentage < 0} positive={percentage > 0}>
        {arrow(percentage)}
        <Text heading6>({numeral(percentage).format('0.00')}%)</Text>
      </Value>
    </Column>
  )
}

Name.defaultProps = {
  row: {},
}

Name.propTypes = {
  row: PropTypes.object,
}

export default Name
