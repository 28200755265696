import React from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Button, Loader, Row, Text } from 'Components/UI'

import { ArrowRightIcon, Container, PlusIcon, Title } from './styles'

function NetWorthButton({ title, amount, onClick, iconVariant, loading }) {
  return (
    <Container>
      <Title>{title}</Title>

      <Row center width={1}>
        <Text fontWeight={2} heading3>
          {loading ? <Loader /> : `$${amount}`}
        </Text>

        <Button icon ml="auto" xSmall onClick={onClick}>
          {iconVariant === 'plus' ? (
            <PlusIcon height={14} viewBox="0 0 24 24" width={14} />
          ) : (
            <ArrowRightIcon height={14} viewBox="0 0 24 24" width={14} />
          )}
        </Button>
      </Row>
    </Container>
  )
}

NetWorthButton.defaultProps = {
  amount: null,
  iconVariant: 'plus',
  loading: false,
  title: null,
  onClick: noop,
}

NetWorthButton.propTypes = {
  amount: PropTypes.string,
  iconVariant: PropTypes.oneOf(['plus', 'arrow']),
  loading: PropTypes.bool,
  title: PropTypes.node,
  onClick: PropTypes.func,
}

export default NetWorthButton
