import { ExpertPortfolioLayout } from 'Components/Layout'

import ExpertPortfolio from 'Pages/ExpertPortfolio'

import * as ROUTES from './routes'

export const EXPERT_PORTFOLIO_ROUTES = {
  component: ExpertPortfolioLayout,
  path: ROUTES.EXPERT_PORTFOLIO,
  routes: [
    {
      component: ExpertPortfolio.Root,
      path: ROUTES.EXPERT_PORTFOLIO_ROOT(),
      exact: true,
    },
    {
      component: ExpertPortfolio.Backtesting,
      path: ROUTES.EXPERT_PORTFOLIO_BACKTEST(),
      exact: true,
    },
  ],
}
