import { rgba } from 'polished'

export default {
  black: '#000000',
  white: '#ffffff',

  primary100: '#0E2C2D',
  primary90: '#064144',
  primary80: '#0F6266',
  primary70: '#1B8388',
  primary60: '#2AA4AA',
  primary50: '#3DC5CC',
  primary40: '#93DFE3',
  primary30: '#BEECEE',
  primary20: '#D4F2F4',
  primary10: '#E9F9F9',

  text100: '#25282D',
  text90: '#495059',
  text80: '#5E656F',
  text70: '#747B86',
  text60: '#8A929D',
  text50: '#A1A8B3',
  text40: '#B9C0CA',
  text30: '#D2D8E1',
  text20: '#EBF0F7',
  text10: '#FFF',

  gray100: '#3B3636',
  gray90: '#4F4A4A',
  gray80: '#645F5F',
  gray70: '#787474',
  gray60: '#8C8A8A',
  gray50: '#A1A1A1',
  gray40: '#B5B5B5',
  gray30: '#CACACA',
  gray20: '#DEDEDE',
  gray10: '#F2F2F2',

  successDark: '#4C8431',
  successPrimary: '#58B034',
  successLight: '#B8F1C9',
  successBackground: '#EBFFF1',

  dangerDark: '#C1271B',
  dangerPrimary: '#F44336',
  dangerLight: '#FF9992',
  dangerBackground: '#FFECEB',

  warningDark: '#BB6B00',
  warningPrimary: '#FF9407',
  warningLight: '#FFD49C',
  warningBackground: '#FFF4E6',

  warningSecondaryDark: '#997E1B',
  warningSecondaryPrimary: '#EFC719',
  warningSecondaryLight: '#FFEA9E',
  warningSecondaryBackground: '#FFFBED',

  infoDark: '#0A4CAA',
  infoPrimary: '#2D7DEE',
  infoLight: '#BBD7FF',
  infoBackground: '#EAF3FF',

  charlestonGreen: '#25282D',
  slateBlue: rgba(37, 40, 45, 0.8),
  brightGray: '#E7E9EC',
  cultured: '#F5F7F9',
  aquaMist: rgba(61, 197, 204, 0.3),
  softBlueHaze: rgba(37, 40, 45, 0.2),

  shark: '#1d1e1f',

  abbey100: '#4a4b4c',
  abbey80: '#a5a5a5',
  abbey60: '#d2d2d2',
  abbey40: '#dbdbdb',

  azureRadiance120: '#0e62be',
  azureRadiance100: '#0676ed',
  azureRadiance80: '#4599f2',
  azureRadiance60: '#82b6f8',
  azureRadiance40: '#c1ddfa',

  charcoal120: '#3b3c3c',
  charcoal100: '#4a4b4c',
  charcoal80: '#777879',
  charcoal60: '#a5a5a5',
  charcoal40: '#d2d2d2',

  jade120: '#009b48',
  jade100: '#06ba63',
  jade80: '#45cc8a',
  jade60: '#82dcb1',
  jade40: '#c1eed8',

  paperWhite100: '#f1f1f1',
  paperWhite80: '#f4f4f4',
  paperWhite60: '#f7f7f7',
  paperWhite40: '#f9f9f9',
  paperWhite20: '#fcfcfc',

  positive: '#35d17d',
  positiveLight: '#5ddf99',
  positiveDark: '#27ac64',
  negative: '#ff8468',
  negativeLight: '#ff7f5b',
  negativeDark: '#ff3800',

  salmon: '#ff8468',
}
