import forEach from 'lodash/forEach'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'

import { CRYPTO_TOKEN_KIND } from 'Constants/cryptoTokens'

export function populateAssets(assets, tickers) {
  const result = []

  forEach(assets, asset => {
    const tickerBase = asset.currency
    const ticker = get(tickers, tickerBase)

    result.push({
      base: ticker?.base,
      baseName: ticker?.baseName,
      currency: asset.currency,
      cost: asset.cost,
      amount: asset.amount,
      cryptoTokenKind: asset.cryptoTokenKind,
    })
  })

  return result
}

function calculatePercentage(amount, totalAmount) {
  return (amount / totalAmount) * 100
}

function groupAssets(assets) {
  const groupedByKind = groupBy(assets, asset => asset.cryptoTokenKind)

  return {
    [CRYPTO_TOKEN_KIND.COIN]: groupedByKind[CRYPTO_TOKEN_KIND.COIN],
    [CRYPTO_TOKEN_KIND.ALT_COIN]: [
      ...(groupedByKind[CRYPTO_TOKEN_KIND.ALT_COIN] || []),
      ...(groupedByKind.null || []),
    ],
    [CRYPTO_TOKEN_KIND.STABLE_COIN]:
      groupedByKind[CRYPTO_TOKEN_KIND.STABLE_COIN],
  }
}

export function assetsByGroup(assets) {
  const result = []
  const groupedAssets = groupAssets(assets)

  const totalCost = sumBy(assets, asset => asset.cost)

  forEach(groupedAssets, (items, key) => {
    const groupTotalCost = sumBy(items, item => item.cost)
    const groupWeightPercentage = calculatePercentage(groupTotalCost, totalCost)
    const subRows = orderBy(
      items?.map(item => ({
        ...item,
        weight: calculatePercentage(item.cost, totalCost),
      })),
      ['weight'],
      ['desc'],
    )

    switch (key) {
      case CRYPTO_TOKEN_KIND.COIN: {
        result.push(
          ...map(items, item => ({
            ...item,
            subRows: [],
            weight: calculatePercentage(item.cost, totalCost),
          })),
        )
        break
      }
      case CRYPTO_TOKEN_KIND.STABLE_COIN: {
        result.push({
          groupName: 'Stablecoins',
          subRows,
          cost: groupTotalCost,
          weight: groupWeightPercentage,
        })
        break
      }
      case CRYPTO_TOKEN_KIND.ALT_COIN: {
        result.push({
          groupName: 'Altcoins',
          subRows,
          cost: groupTotalCost,
          weight: groupWeightPercentage,
        })
        break
      }
      default:
        result.push(
          ...map(items, item => ({
            ...item,
            subRows: [],
            weight: calculatePercentage(item.cost, totalCost),
          })),
        )
        break
    }
  })

  return result
}
