import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { Container } from './styles'
import Table from './Table'

function ExternalAssetsList({ portfolio, ...rest }) {
  return (
    <Container {...pick(rest)}>
      <Table currency="$" portfolio={portfolio} />
    </Container>
  )
}

ExternalAssetsList.propTypes = {
  portfolio: PropTypes.object.isRequired,
}

export default ExternalAssetsList
