import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import noop from 'lodash/noop'

import Plaid from 'Components/Blocks/Integrations/Plaid'
import Vezgo from 'Components/Blocks/Integrations/Vezgo'
import { Column } from 'Components/UI'

import { ADD_TO_NET_WORTH_KIND } from 'Constants/netWorth'
import { VEZGO_PROVIDER_CATEGORY } from 'Constants/vezgo'

import { useScopedI18n } from 'Services/I18n'

import { FIELD } from './constants'
import FormFields from './FormFields'

const FORM_CONSTRAINTS = {
  [FIELD.KIND]: {
    presence: true,
  },
}

function ConnectAccount({
  initialKind,
  content,
  actionContent,
  backActionContent,
  // TODO: temporary option to disable the exchange
  disabledExchange,
  onSuccessFinish,
  onBack,
}) {
  const formRef = useRef()
  const s = useScopedI18n('blocks.connectAccount')

  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (initialKind && formRef?.current?.submit) formRef.current.submit()
  }, [initialKind])

  const handleMount = useCallback(form => {
    formRef.current = form
  }, [])

  const submit = useCallback(
    values => {
      switch (values[FIELD.KIND]) {
        case ADD_TO_NET_WORTH_KIND.BANK: {
          setLoading(true)
          Plaid.start(data => {
            if (data) {
              toast.success(s('success.bank'))

              onSuccessFinish()
            }
            setLoading(false)
          })

          break
        }

        case ADD_TO_NET_WORTH_KIND.EXCHANGE: {
          setLoading(true)
          Vezgo.start(
            data => {
              if (data) {
                toast.success(s('success.exchange'))
                onSuccessFinish()
              }
              setLoading(false)
            },
            {
              providerCategories: [VEZGO_PROVIDER_CATEGORY.EXCHANGES],
            },
          )

          break
        }

        case ADD_TO_NET_WORTH_KIND.WALLET: {
          setLoading(true)
          Vezgo.start(
            data => {
              if (data) {
                toast.success(s('success.wallet'))
                onSuccessFinish()
              }
              setLoading(false)
            },
            {
              providerCategories: [VEZGO_PROVIDER_CATEGORY.WALLETS],
            },
          )
          break
        }

        default:
          break
      }
    },
    [s, onSuccessFinish],
  )

  return (
    <Column>
      <Form
        initialValues={{
          [FIELD.KIND]: initialKind,
        }}
        render={props => (
          <FormFields
            {...props}
            actionContent={actionContent}
            backActionContent={backActionContent}
            content={content}
            disabledExchange={disabledExchange}
            loading={isLoading}
            onBack={onBack}
            onMount={handleMount}
          />
        )}
        validate={values => validate(values, FORM_CONSTRAINTS)}
        onSubmit={submit}
      />

      <Plaid />
      <Vezgo />
    </Column>
  )
}

ConnectAccount.defaultProps = {
  initialKind: undefined,
  content: null,
  disabledExchange: false,
  actionContent: '',
  backActionContent: '',
  onBack: noop,
  onSuccessFinish: noop,
}

ConnectAccount.propTypes = {
  actionContent: PropTypes.string,
  backActionContent: PropTypes.string,
  content: PropTypes.node,
  disabledExchange: PropTypes.bool,
  initialKind: PropTypes.string,
  onBack: PropTypes.func,
  onSuccessFinish: PropTypes.func,
}

export default ConnectAccount
