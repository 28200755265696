import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import forEach from 'lodash/forEach'

import userCryptoWalletsQuery from 'GraphQL/Queries/UserCryptoWallets/userCryptoWallets.graphql'

export default function useWallets() {
  const { data, loading } = useQuery(userCryptoWalletsQuery)

  return useMemo(() => {
    let total = 0
    const wallets = data?.userCryptoWallets?.rows

    forEach(wallets, wallet => {
      forEach(wallet?.userAssets, asset => {
        const { cost } = asset
        total += cost
      })
    })

    return [total, wallets, loading]
  }, [data, loading])
}
