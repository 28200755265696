import React from 'react'
import { useLocation } from 'react-router'

import { AddToNetWorth } from 'Components/Blocks/NetWorth'
import { Column } from 'Components/UI'

function AddNetWorth() {
  const location = useLocation()
  const netWorthKind = location?.state?.netWorthKind

  return (
    <Column center flexGrow={1} fullWidth justifyCenter>
      <AddToNetWorth initialKind={netWorthKind} />
    </Column>
  )
}

export default AddNetWorth
