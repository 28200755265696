import React, { useCallback, useRef } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'

import get from 'lodash/get'

import { Button, Loader, Modal, Row } from 'Components/UI'

import adminApproveUserKYCMutation from 'GraphQL/Mutations/Admin/adminApproveUserKYC.graphql'
import adminDeclineUserKYCMutation from 'GraphQL/Mutations/Admin/adminDeclineUserKYC.graphql'
import AdminInfoUpdaters from 'GraphQL/Updaters/Admin/Info'

import _ from 'Services/I18n'

import { Content, Subtitle, Text } from './styles'

function UserKYCModal({ kyc, ...rest }) {
  const close = useRef(null)

  const [approve, { loading: approveLoading }] = useMutation(
    adminApproveUserKYCMutation,
    {
      variables: { id: kyc?.id },
      update: AdminInfoUpdaters.decreasePendingKYC,
    },
  )

  const [decline, { loading: declineLoading }] = useMutation(
    adminDeclineUserKYCMutation,
    {
      variables: { id: kyc?.id },
      update: AdminInfoUpdaters.decreasePendingKYC,
    },
  )

  const handleApprove = useCallback(async () => {
    try {
      await approve()

      if (close.current) {
        close.current()
      }
    } catch (error) {
      toast.error(get(error, 'message') || _('error.generic'))
    }
  }, [approve])

  const handleDecline = useCallback(async () => {
    try {
      await decline()

      if (close.current) {
        close.current()
      }
    } catch (error) {
      toast.error(get(error, 'message') || _('error.generic'))
    }
  }, [decline])

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title="User KYC Request"
      onMount={handleMount}
    >
      <Content>
        <Subtitle mb={2} mt={1}>
          Please check user KYC request before approve or decline
        </Subtitle>
        <Text mb={2}>
          KYC link here:{' '}
          <a href={kyc?.url || ''} rel="noopener noreferrer" target="_blank">
            {kyc?.url}
          </a>
        </Text>
      </Content>

      <Row borderTop center height={56} justifyCenter>
        {declineLoading || approveLoading ? (
          <Loader />
        ) : (
          <>
            <Button secondary width={130} onClick={handleDecline}>
              Decline
            </Button>
            <Button ml={3} width={130} onClick={handleApprove}>
              Approve
            </Button>
          </>
        )}
      </Row>
    </Modal>
  )
}

UserKYCModal.defaultProps = {
  kyc: null,
}

UserKYCModal.propTypes = {
  kyc: PropTypes.object,
}

export default UserKYCModal
