import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, Loader, Modal, Row, Select } from 'Components/UI'
import { InputField, InputLabels, PhoneInputField } from 'Components/UI/Forms'

import { RISK_TOLERANCE } from 'Constants/ids'

import adminUpdateUserProfileMutation from 'GraphQL/Mutations/Admin/adminUpdateUserProfile.graphql'

import _, { useScopedI18n } from 'Services/I18n'

import Utils from 'Utils'

import { Content, Subtitle } from './styles'

const FIELDS = {
  PHONE: 'phone',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  RISK_TOLERANCE: 'riskTolerance',
}
const PHONE_VALIDATOR = Utils.Form.phoneValidator()

function EditProfileModal({ profile, ...rest }) {
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const s = useScopedI18n('admin.modal.editProfile')

  const [adminUpdateUserProfile] = useMutation(adminUpdateUserProfileMutation)

  const option = useCallback(
    priority => ({
      label: _(Utils.Data.riskToleranceText(priority)),
      value: priority,
    }),
    [],
  )

  const riskToleranceOptions = useMemo(
    () => [
      option(RISK_TOLERANCE.CONSERVATIVE),
      option(RISK_TOLERANCE.MODERATE),
      option(RISK_TOLERANCE.AGGRESSIVE),
    ],
    [option],
  )

  const initialValues = useMemo(
    () => ({
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      phone: profile?.phone,
      riskTolerance: profile?.riskAssessment?.riskTolerance
        ? option(profile.riskAssessment.riskTolerance)
        : null,
    }),
    [profile, option],
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.PHONE]: {
        [PHONE_VALIDATOR]: {
          [PHONE_VALIDATOR]: true,
          message: `^${s('phoneInvalid')}`,
        },
      },
    }),
    [s],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await adminUpdateUserProfile({
          variables: {
            id: profile.id,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            riskAssessment: {
              riskTolerance: values[FIELDS.RISK_TOLERANCE].value,
            },
          },
        })

        toast.success(s('success'))

        setLoading(false)

        close.current()
      } catch (error) {
        // TODO: API error formatter here
        toast.error(error?.message)

        setLoading(false)
      }
    },
    [adminUpdateUserProfile, profile, s],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Subtitle mt={1}>
                {s('subtitle', { userId: profile?.userId })}
              </Subtitle>

              <InputLabels mt={3} title={s('firstName')}>
                <InputField
                  input={{ placeholder: s('firstNamePlaceholder') }}
                  name={FIELDS.FIRST_NAME}
                />
              </InputLabels>

              <InputLabels mt={3} title={s('lastName')}>
                <InputField
                  input={{ placeholder: s('lastNamePlaceholder') }}
                  name={FIELDS.LAST_NAME}
                />
              </InputLabels>

              <InputLabels mt={4} title={s('phone')}>
                <PhoneInputField
                  input={{
                    placeholder: s('phonePlaceholder'),
                  }}
                  name={FIELDS.PHONE}
                />
              </InputLabels>

              <InputLabels mt={4} title={s('riskTolerance')}>
                <Field
                  component={({ input, ...restSelect }) => (
                    <Select {...input} {...restSelect} />
                  )}
                  name={FIELDS.RISK_TOLERANCE}
                  options={riskToleranceOptions}
                  required
                />
              </InputLabels>
            </Content>

            <Row borderTop center height={56} justifyCenter>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button
                    secondary
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {_('general.cancel')}
                  </Button>
                  <Button ml={3} width={130} onClick={handleSubmit}>
                    {s('action')}
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

EditProfileModal.defaultProps = {
  profile: null,
}

EditProfileModal.propTypes = {
  profile: PropTypes.object,
}

export default EditProfileModal
