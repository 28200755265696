import React from 'react'

import { PortfolioTemplateGroupsTable } from 'Components/Blocks/Admin'
import { Row } from 'Components/UI'

function PortfolioTemplates() {
  return (
    <Row p={2}>
      <PortfolioTemplateGroupsTable />
    </Row>
  )
}

export default PortfolioTemplates
