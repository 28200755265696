import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import cancelGlyph from 'iconoir/icons/cancel.svg'
import chatLinesGlyph from 'iconoir/icons/chat-lines.svg'

export const Wrapper = styled.div`
  display: flex;
  background-color: ${themeGet('colors.bg.main')};
  padding: 12px 20px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;

  ${margin};
`

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary50')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeGet('colors.white')};
  flex-shrink: 0;
`

export const ChatIcon = styled(chatLinesGlyph)``

export const CancelIcon = styled(cancelGlyph)``
