import palette from 'Theme/Main/palette'

export default {
  height: {
    default: 44,
  },

  borderRadius: { default: 8 },

  valueFontSize: {
    default: 16,
  },

  optionFontSize: {
    default: 14,
  },

  dropdown: {
    active: {
      color: {
        default: palette.primary50,
      },
      backgroundColor: {
        default: palette.primary10,
      },
    },
    selected: {
      color: {
        default: palette.white,
      },
      backgroundColor: {
        default: palette.primary50,
      },
    },
  },
}
