import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'
import checkGlyph from 'iconoir/icons/check.svg'

import { portfolioPieChartGlyph } from 'Assets/Svg'

import { gap } from 'Theme/system'

export const BuildContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.bg.main')};
  padding: 20px;
  border-radius: 12px;

  ${margin};
`

const secondaryStepIconCss = ({ secondary }) =>
  secondary &&
  css`
    background-color: ${themeGet('colors.gray10')};
    color: ${themeGet('colors.gray50')};
  `

const passedStepIconCss = ({ passed }) =>
  passed &&
  css`
    background-color: ${themeGet('colors.primary50')};
    color: ${themeGet('colors.white')};
  `

export const StepIcon = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #353c50;
  color: white;

  ${secondaryStepIconCss};
  ${passedStepIconCss};
`

export const Card = styled.div`
  display: flex;
  border-radius: ${themeGet('space.3')}px;
  padding: 20px;
  border: 1px solid ${themeGet('colors.divider.light')};
  background: ${themeGet('colors.white')};
  width: 100%;

  ${gap};
`

export const Line = styled.div`
  width: 48px;
  height: 1px;
  background-color: ${themeGet('colors.divider.light')};
`

export const CheckIcon = styled(checkGlyph)``

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${themeGet('colors.divider.light')};

  ${margin}
`

const lightRiskCircleCss = ({ light }) =>
  light &&
  css`
    background-color: ${themeGet('colors.primary20')};
  `
export const RiskCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary50')};

  ${lightRiskCircleCss};
`

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`

export const PieChartIcon = styled(portfolioPieChartGlyph)`
  width: 200px;
  height: 200px;
  flex-shrink: 0;
`
