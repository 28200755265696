import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'

import Status from 'Components/Blocks/KYC/Status'
import ProgressBar from 'Components/Blocks/Onboarding/ProgressBar'
import { Button, Column, Loader, Row, Text } from 'Components/UI'

import { KYC_STATUS } from 'Constants/ids'

import updateOnboardingStepsMutation from 'GraphQL/Mutations/User/updateOnboardingSteps.graphql'
import requestUserKYCMutation from 'GraphQL/Mutations/UserKYC/requestUserKYC.graphql'

import { useAppContext, useLocationQueryParams } from 'Hooks'

import { APP_ROOT, ONBOARDING_KYC } from 'Router/routes'

import _, { useScopedI18n } from 'Services/I18n'

import Utils from 'Utils'

import {
  ArrowRightIcon,
  Content,
  IconContainer,
  IdentificationIcon,
} from './styles'

function KYC() {
  const history = useHistory()
  const { me, refetchMe } = useAppContext()
  const s = useScopedI18n('onboarding.kyc')
  const queryParams = useLocationQueryParams()

  const [loading, setLoading] = useState(false)

  const [updateOnboardingSteps] = useMutation(updateOnboardingStepsMutation)
  const [requestUserKYC] = useMutation(requestUserKYCMutation)

  const updateOnboardingStep = useCallback(async () => {
    try {
      setLoading(true)
      await updateOnboardingSteps({ variables: { kyc: true } })
    } catch (error) {
      toast.error(error?.message || _('error.generic'))
      setLoading(false)
    }
  }, [updateOnboardingSteps])

  const handleRequest = useCallback(async () => {
    try {
      setLoading(true)
      const response = await requestUserKYC({
        variables: {
          redirectUrl: ONBOARDING_KYC,
        },
      })

      window.open(response.data.requestUserKYC.url, '_blank')
      await refetchMe()
    } catch (error) {
      toast.error(error?.message || _('error.generic'))
    } finally {
      setLoading(false)
    }
  }, [requestUserKYC, refetchMe])

  const handleContinue = async () => {
    await updateOnboardingStep()
    history.push(APP_ROOT)
  }

  const kyc = me?.kyc
  const redirectStatus = queryParams?.status

  return (
    <Column width={1}>
      <Row gap="20px" justifyCenter>
        <ProgressBar mt="72px" />

        <Column>
          <Row justifyCenter>
            <Text fontWeight={2} heading2>
              {s('title')}
            </Text>
          </Row>

          <Content mt={6}>
            {!kyc ? (
              <>
                <Row justifyCenter width={1}>
                  <IconContainer>
                    <IdentificationIcon />
                  </IconContainer>
                </Row>

                <Text
                  fontWeight={2}
                  heading4
                  maxWidth="380px"
                  mt={3}
                  textAlign="center"
                >
                  {s('subtitle')}
                </Text>

                <Text mt={2} small textAlign="center">
                  {s('description')}
                </Text>

                <Row justifyCenter onClick={handleRequest}>
                  <Button mt={5}>
                    {s('continue')} <ArrowRightIcon />
                    {loading && <Loader ml={1} />}
                  </Button>
                </Row>
              </>
            ) : (
              <Status
                status={
                  redirectStatus === 'aborted'
                    ? KYC_STATUS.FAILURE
                    : Utils.UserKYC.getStatus(kyc.state)
                }
                onContinue={handleContinue}
                onGoToKYC={handleRequest}
              />
            )}
          </Content>
        </Column>
      </Row>
    </Column>
  )
}

export default KYC
