import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import toNumber from 'lodash/toNumber'

import BacktestingSummary from 'Components/Blocks/Backtesting/Summary'
import BacktestingChart from 'Components/Blocks/Charts/Assets/Backtesting'
import { ExpertPortfolioHoldingsList } from 'Components/Blocks/ExpertPortfolio'
import { InfoPie } from 'Components/Blocks/Portfolio'
import PortfolioRebalanceForm from 'Components/Blocks/Portfolio/Rebalance/Form'
import {
  Button,
  Column,
  DateRangeInput,
  Loader,
  Row,
  Text,
} from 'Components/UI'
import { Input, InputLabels } from 'Components/UI/Forms'

import { PRIMARY_CURRENCY } from 'Constants/currencies'
import { REBALANCE_TYPE } from 'Constants/portfolios'

import backtestQuery from 'GraphQL/Queries/Backtesting/backtest.graphql'

import _, { useScopedI18n } from 'Services/I18n'
import shared from 'Services/Shared'

import Utils from 'Utils'

import { Content, Divider, Wrapper } from './styles'

function Backtest({
  initialPortfolio,
  disableRebalanceForm,
  showInvest,
  onInvestNowClick,
}) {
  const s = useScopedI18n('blocks.backtesting.backtest')
  const rebalanceFormInstance = useRef()

  const [isBacktestingLoading, setBacktestingLoading] = useState(false)
  const [backtestResult, setBacktestResult] = useState(null)
  const [portfolio, setPortfolio] = useState(null)
  const [amount, setAmount] = useState(null)
  const [dateRange, setDateRange] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!initialPortfolio) return

    async function loadData() {
      setLoading(true)

      const result = await shared
        .getPortfolioService()
        .populatePortfolios([initialPortfolio])

      const nextPortfolio = result[0]
      setPortfolio(nextPortfolio)
      setAmount(nextPortfolio.amount)
      setLoading(false)
    }

    loadData().then()
  }, [initialPortfolio])

  const handleRebalanceMount = useCallback(instance => {
    rebalanceFormInstance.current = instance
  }, [])

  const handleChangeAmount = useCallback(event => {
    setAmount(toNumber(event.target.value))
  }, [])

  const handleDateChange = useCallback(date => {
    setDateRange(date)
  }, [])

  const handleBacktest = useCallback(async () => {
    const rebalanceForm = rebalanceFormInstance.current
    const assets = portfolio.assets || []
    if (!rebalanceForm) {
      return
    }

    rebalanceForm.submit()

    const rebalanceFormState = rebalanceForm.getState()

    // -- Assets
    if (isEmpty(portfolio.assets)) {
      toast.error(s('errors.noAssets'))
      return
    }

    const queryAssets = []
    let totalPercentage = 0
    forEach(assets, asset => {
      const percentage = toNumber(asset.percentage) || 0

      queryAssets.push({
        asset: asset.base,
        percentage,
      })
      totalPercentage += percentage
    })

    if (!Utils.Numbers.isEqual(totalPercentage, 100)) {
      toast.error(s('errors.incorrectAllocation'))
      return
    }

    const variables = {
      value: amount,
      currency: PRIMARY_CURRENCY,
      assets: queryAssets,
    }

    if (dateRange) {
      variables.dateStart = dateRange.from
      variables.dateEnd = dateRange.to
    }

    const rebalanceValue = rebalanceFormState.values?.rebalanceValue
    if (rebalanceValue !== undefined) {
      const rebalanceType = rebalanceFormState.values?.rebalanceType?.value
      if (rebalanceType === REBALANCE_TYPE.THRESHOLD) {
        variables.rebalancePercentage = toNumber(rebalanceValue)
      }
      if (rebalanceType === REBALANCE_TYPE.PERIOD) {
        variables.rebalanceDays = toNumber(rebalanceValue)
      }
    }

    try {
      setBacktestingLoading(true)

      const result = await shared.getClient().query({
        query: backtestQuery,
        variables,
      })

      setBacktestResult(result?.data?.backtest)
    } catch (error) {
      toast.error(error?.message || _('error.generic'))
    } finally {
      setBacktestingLoading(false)
    }
  }, [amount, dateRange, portfolio, s])

  if (loading || !portfolio) return <Loader fullHeight fullWidth />

  return (
    <Content>
      <Wrapper mt="20px">
        <Row center fullWidth spaceBetween>
          <Text as="h3" fontWeight={2} heading3>
            {s('title')}
          </Text>

          {showInvest && (
            <Button xSmall onClick={onInvestNowClick}>
              {s('investNow')}
            </Button>
          )}
        </Row>

        <Row gap="40px" mt="20px" width={1}>
          <InfoPie initialValue={portfolio.amount} name={portfolio.name} />
          <Column flexGrow={1}>
            <InputLabels title={s('amount')}>
              <Input value={amount} onChange={handleChangeAmount} />
            </InputLabels>

            <PortfolioRebalanceForm
              disabled={disableRebalanceForm}
              portfolio={portfolio}
              width={1}
              onMount={handleRebalanceMount}
            />

            <Row fullWidth justifyEnd>
              <Button mt="20px" small xSmall onClick={handleBacktest}>
                {s('action')}
              </Button>
            </Row>

            {backtestResult && (
              <BacktestingSummary
                currency="$"
                data={backtestResult}
                mt="20px"
              />
            )}
          </Column>
        </Row>
        <Column center>
          <Divider mb="20px" mt="20px" />

          {isBacktestingLoading && <Loader />}

          {backtestResult && (
            <>
              <Row width={1}>
                <DateRangeInput
                  numberOfMonths={2}
                  placeholder={s('datePickerPlaceholder')}
                  timeZone="utc"
                  width="230px"
                  onChange={handleDateChange}
                />
              </Row>

              <Row mt="20px" width={1}>
                <BacktestingChart data={backtestResult?.rows} />
              </Row>
            </>
          )}

          <Row mt="20px" width={1}>
            <ExpertPortfolioHoldingsList assets={portfolio?.assets} />
          </Row>
        </Column>
      </Wrapper>
    </Content>
  )
}

Backtest.defaultProps = {
  disableRebalanceForm: false,
  initialPortfolio: null,
  showInvest: true,
  onInvestNowClick: noop,
}

Backtest.propTypes = {
  disableRebalanceForm: PropTypes.bool,
  initialPortfolio: PropTypes.object,
  showInvest: PropTypes.bool,
  onInvestNowClick: PropTypes.func,
}

export default Backtest
