import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { pick } from '@styled-system/props'

import ConnectedAccounts from 'Components/Blocks/ConnectedAccounts'

import { ADD_NET_WORTH } from 'Router/routes'

import { Container } from './styles'

function AccountsList({ ...rest }) {
  const history = useHistory()

  const handleConnect = useCallback(() => {
    history.push(ADD_NET_WORTH)
  }, [history])

  const handleBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Container gap={5} {...pick(rest)}>
      <ConnectedAccounts onBack={handleBack} onConnect={handleConnect} />
    </Container>
  )
}

export default AccountsList
