import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { omit, pick } from '@styled-system/props'

import clamp from 'lodash/clamp'
import noop from 'lodash/noop'

import { Loader, Row } from 'Components/UI'

import { AbsoluteContainer, Container, Input, PercentageLine } from './styles'

const SYMBOL = '%'

function PercentageInput({
  color,
  disabled,
  value,
  showLine,
  onChange,
  small,
  loading,
  ...rest
}) {
  const inputValue = useMemo(() => {
    if (!value || value === SYMBOL) return ''

    return `${value}`
  }, [value])

  const handleChange = useCallback(
    event => {
      onChange(parseFloat(event?.target?.value))
    },
    [onChange],
  )

  return showLine ? (
    <Row {...pick(rest)} center gap={3} relative>
      <PercentageLine
        color={color}
        percentage={clamp(value, 0, 100)}
        width="35%"
      />
      <Input
        {...omit(rest)}
        accessory={SYMBOL}
        disabled={disabled}
        small={small}
        type="number"
        value={inputValue}
        width="45%"
        onChange={handleChange}
      />
      {loading && (
        <AbsoluteContainer>
          <Loader size={15} />
        </AbsoluteContainer>
      )}
    </Row>
  ) : (
    <Container {...pick(rest)} color={color} percentage={clamp(value, 0, 100)}>
      <Input
        {...omit(rest)}
        accessory={SYMBOL}
        disabled={disabled}
        small={small}
        value={inputValue}
        onChange={handleChange}
      />
      {loading && (
        <AbsoluteContainer>
          <Loader size={15} />
        </AbsoluteContainer>
      )}
    </Container>
  )
}

PercentageInput.defaultProps = {
  color: null,
  disabled: false,
  loading: false,
  showLine: false,
  small: false,
  value: undefined,
  onChange: noop,
}

PercentageInput.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  showLine: PropTypes.bool,
  small: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
}

export default PercentageInput
