import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import { Column, Row, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { Circle, Container, InfoPieChartIcon, LogoIcon } from './styles'

const MAX_LEVEL = 4

function Pie({ name, initialValue, riskLevel, horizon }) {
  const theme = useTheme()
  const s = useScopedI18n('portfolio.info.pie')

  const riskCircles = useMemo(() => {
    return Array.from({ length: MAX_LEVEL }, (_, index) => (
      <Circle key={index + 1} light={riskLevel < index + 1} />
    ))
  }, [riskLevel])

  const horizonCircles = useMemo(() => {
    return Array.from({ length: MAX_LEVEL }, (_, index) => (
      <Circle key={index + 1} light={horizon < index + 1} />
    ))
  }, [horizon])

  return (
    <Container>
      <InfoPieChartIcon />

      <Column center>
        <LogoIcon />

        <Text
          ellipsis
          fontWeight={2}
          heading4
          mt={1}
          textAlign="center"
          width="185px"
        >
          {name}
        </Text>

        {riskLevel && horizon && (
          <>
            <Column mt={1}>
              <Row>
                <Text color={theme.colors.text70} small>
                  {s('riskLevel')}
                </Text>
                <Row center gap={1} ml={3}>
                  {riskCircles}
                </Row>
              </Row>

              <Row>
                <Text color={theme.colors.text70} small>
                  {s('horizon')}
                </Text>
                <Row center gap={1} ml={3}>
                  {horizonCircles}
                </Row>
              </Row>
            </Column>
          </>
        )}

        {initialValue && (
          <>
            {' '}
            <Text mt={1} small>
              {s('initialValue')}
            </Text>
            <Text fontWeight={2} heading4 mt={1}>
              ${initialValue}
            </Text>{' '}
          </>
        )}
      </Column>
    </Container>
  )
}

Pie.defaultProps = {
  horizon: null,
  initialValue: null,
  name: null,
  riskLevel: null,
}

Pie.propTypes = {
  horizon: PropTypes.number,
  initialValue: PropTypes.number,
  name: PropTypes.string,
  riskLevel: PropTypes.number,
}
export default Pie
