import React from 'react'
import PropTypes from 'prop-types'

import { Link, Row, Text } from 'Components/UI'

import { PORTFOLIO_ROOT } from 'Router/routes'

import _ from 'Services/I18n'

import { ArrowLeftIcon, Wrapper } from './styles'

function BackToPortfolio({ portfolioId }) {
  return (
    <Wrapper>
      <Link to={PORTFOLIO_ROOT(portfolioId)}>
        <Row center>
          <ArrowLeftIcon height={14} viewBox="0 0 24 24" width={14} />
          <Text fontSize={1} fontWeight={2} ml={1}>
            {_('portfolio.menu.backToPortfolio')}
          </Text>
        </Row>
      </Link>
    </Wrapper>
  )
}

BackToPortfolio.defaultProps = {
  portfolioId: null,
}

BackToPortfolio.propTypes = {
  portfolioId: PropTypes.string,
}

export default BackToPortfolio
