import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import forEach from 'lodash/forEach'

import exchangeIntegrationsQuery from 'GraphQL/Queries/ExchangeIntegrations/exchangeIntegrations.graphql'

export default function useExchanges() {
  const { data, loading } = useQuery(exchangeIntegrationsQuery)

  return useMemo(() => {
    let total = 0
    const integrations = data?.exchangeIntegrations?.rows

    forEach(integrations, integration => {
      forEach(integration?.userAssets, asset => {
        const { cost } = asset
        total += cost
      })
    })

    return [total, integrations, loading]
  }, [data, loading])
}
