import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import { useQuery } from '@apollo/client'

import groupBy from 'lodash/groupBy'
import map from 'lodash/map'

import { Column, Loader, Row, Text } from 'Components/UI'

import portfolioTemplatesQuery from 'GraphQL/Queries/PortfolioTemplates/portfolioTemplates.graphql'

import { EXPERT_PORTFOLIO, EXPERT_PORTFOLIO_ROOT } from 'Router/routes'

import _, { useScopedI18n } from 'Services/I18n'

import Item from './Item'
import { Container, Content, MenuSection, Tab, TabsContainer } from './styles'

const TAB = {
  ALL: 'all',
}

const TABS = [
  {
    title: 'all',
    value: TAB.ALL,
  },
]

function ExpertPortfolioMenu({ redirectOnLoad }) {
  const s = useScopedI18n('expertPortfolio.menu')
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()

  const [tab, setTab] = useState(TAB.ALL)

  const { data: expertPortfoliosData, loading } = useQuery(
    portfolioTemplatesQuery,
    {
      variables: {
        limit: 999,
        page: 0,
      },
    },
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const expertPortfolios = expertPortfoliosData?.portfolioTemplates?.rows || []

  useEffect(() => {
    if (!redirectOnLoad) return

    const latestPortfolioId = expertPortfolios.at(0)?.id

    if (latestPortfolioId && location.pathname === EXPERT_PORTFOLIO)
      history.push(EXPERT_PORTFOLIO_ROOT(latestPortfolioId))
  }, [expertPortfolios, history, location, redirectOnLoad])

  const tabs = useMemo(() => {
    return TABS.map(item => (
      <Tab
        active={item.value === tab}
        key={item.value}
        onClick={() => setTab(item.value)}
      >
        {s(item.title)}
      </Tab>
    ))
  }, [s, tab])

  const groupedPortfolioByRisk = useMemo(() => {
    return groupBy(expertPortfolios, portfolio => {
      // hardcode to join groups ( 1 and 2 risk level should be joined in 1)
      // Risk 1 and 2: Conservative
      // Risk 3: Moderate
      // Risk 4 : Aggressive
      if (portfolio.risk === 1) return 2
      return portfolio.risk
    })
  }, [expertPortfolios])

  const content = useMemo(() => {
    if (loading)
      return (
        <Column center justifyCenter>
          <Loader />
        </Column>
      )

    return map(groupedPortfolioByRisk, (group, key) => {
      const riskLabel =
        key === '0' ? null : _(`data.expertPortfolio.riskLevel.${key}`)

      return (
        <MenuSection key={key}>
          {riskLabel && (
            <Text color={theme.colors.text70} mb={3} px={3} small>
              {riskLabel}
            </Text>
          )}

          <Column>
            {group?.map(portfolio => (
              <Item
                category={portfolio.categories?.join(', ')}
                color={theme.colors.managedPortfolios?.[portfolio.name]}
                id={portfolio.id}
                key={portfolio.id}
                name={portfolio.name}
                state={portfolio.state}
              />
            ))}
          </Column>
        </MenuSection>
      )
    })
  }, [loading, groupedPortfolioByRisk, theme])

  return (
    <Container>
      <Row center px={3}>
        <Text fontWeight={2}>{s('title')}</Text>
        <Text color={theme.colors.text70} ml={1}>
          ({expertPortfolios?.length})
        </Text>
      </Row>

      <TabsContainer gap={2} mt={3}>
        {tabs}
      </TabsContainer>

      <Content mt={3}>{content}</Content>
    </Container>
  )
}

ExpertPortfolioMenu.defaultProps = {
  redirectOnLoad: false,
}

ExpertPortfolioMenu.propTypes = {
  redirectOnLoad: PropTypes.bool,
}
export default ExpertPortfolioMenu
