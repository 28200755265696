
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RiskAssessmentFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RiskAssessment"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalNetWorth"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"from"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"to"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"totalInvestments"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"from"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"to"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"investingExperience"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"riskTolerance"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"investmentHorizon"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":190}};
    doc.loc.source = {"body":"fragment RiskAssessmentFields on RiskAssessment {\n  totalNetWorth {\n    from\n    to\n  }\n  totalInvestments {\n    from\n    to\n  }\n\n  investingExperience\n  riskTolerance\n  investmentHorizon\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
