import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import arrowDownGlyph from 'iconoir/icons/arrow-down.svg'
import arrowUpGlyph from 'iconoir/icons/arrow-up.svg'

const negativeCounterCss = ({ negative }) =>
  negative &&
  css`
    color: ${themeGet('colors.dangerLight')};
  `
const positiveCounterCss = ({ positive }) =>
  positive &&
  css`
    color: ${themeGet('colors.primary')};
  `

export const Counter = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  ${negativeCounterCss};
  ${positiveCounterCss}
`

export const ArrowUpIcon = styled(arrowUpGlyph)`
  stroke-width: 3px;
`
export const ArrowDownIcon = styled(arrowDownGlyph)`
  stroke-width: 3px;
`
