import React, { useCallback, useRef } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation, useQuery } from '@apollo/client'

import get from 'lodash/get'

import { Button, Loader, Modal, Row } from 'Components/UI'

import { PRIMARY_CURRENCY } from 'Constants/currencies'
import { KIND } from 'Constants/userTransactions'

import adminApproveUserTransactionMutation from 'GraphQL/Mutations/Admin/adminApproveUserTransaction.graphql'
import adminDeclineUserTransactionMutation from 'GraphQL/Mutations/Admin/adminDeclineUserTransaction.graphql'
import userTransactionQuery from 'GraphQL/Queries/UserTransactions/userTransaction.graphql'
import AdminInfoUpdaters from 'GraphQL/Updaters/Admin/Info'

import _, { useScopedI18n } from 'Services/I18n'

import { Content, Subtitle, Text } from './styles'

function UserTransactionModal({ transactionId, ...rest }) {
  const close = useRef(null)

  const { data, loading: transactionLoading } = useQuery(userTransactionQuery, {
    variables: { id: transactionId },
    skip: !transactionId,
  })

  const [approve, { loading: approveLoading }] = useMutation(
    adminApproveUserTransactionMutation,
    {
      variables: { id: transactionId },
      update: AdminInfoUpdaters.decreasePendingTransactions,
    },
  )

  const [decline, { loading: declineLoading }] = useMutation(
    adminDeclineUserTransactionMutation,
    {
      variables: { id: transactionId },
      update: AdminInfoUpdaters.decreasePendingTransactions,
    },
  )

  const handleApprove = useCallback(async () => {
    try {
      await approve()

      if (close.current) {
        close.current()
      }
    } catch (error) {
      toast.error(get(error, 'message') || _('error.generic'))
    }
  }, [approve])

  const handleDecline = useCallback(async () => {
    try {
      await decline()

      if (close.current) {
        close.current()
      }
    } catch (error) {
      toast.error(get(error, 'message') || _('error.generic'))
    }
  }, [decline])

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const s = useScopedI18n('admin.modal.userTransactionModal')

  const transaction = data?.userTransaction

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onMount={handleMount}
    >
      {transactionLoading ? (
        <Loader />
      ) : (
        <>
          <Content>
            <Subtitle mb={2} mt={1}>
              {s('subtitle')}
            </Subtitle>
            <Text mb={2}>
              {s(
                transaction?.kind === KIND.DEPOSIT ? 'transferred' : 'withdraw',
                {
                  firstName: transaction?.user?.profile?.firstName || '',
                  lastName: transaction?.user?.profile?.lastName || '',
                  amount: transaction?.amount || 0,
                  currency: transaction?.currency || PRIMARY_CURRENCY,
                },
              )}
            </Text>

            {transaction?.cryptoNetwork && (
              <Row fullWidth mb={2}>
                <Text>
                  {s('transactionCryptoNetwork')}: #{transaction.cryptoNetwork}
                </Text>
              </Row>
            )}

            {transaction?.address && (
              <Row fullWidth mb={2}>
                <Text>
                  {s('transactionWallet')}: #{transaction.address}
                </Text>
              </Row>
            )}
          </Content>

          <Row borderTop center height={56} justifyCenter>
            {declineLoading || approveLoading ? (
              <Loader />
            ) : (
              <>
                <Button secondary width={130} onClick={handleDecline}>
                  {s('decline')}
                </Button>
                <Button ml={3} width={130} onClick={handleApprove}>
                  {s('approve')}
                </Button>
              </>
            )}
          </Row>
        </>
      )}
    </Modal>
  )
}

UserTransactionModal.defaultProps = {
  transactionId: null,
}

UserTransactionModal.propTypes = {
  transactionId: PropTypes.string,
}

export default UserTransactionModal
