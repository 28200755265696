const API = 'api'
const APOLLO_CLIENT = 'apolloClient'
const PORTFOLIO_SERVICE = 'portfolioService'
const STORE = 'store'
const SUBSCRIPTION_LINK = 'subscriptionLink'

class Shared {
  static instance = null

  constructor() {
    if (!Shared.instance) {
      this.services = {}
      Shared.instance = this
    }

    return Shared.instance
  }

  setApi(apiCall) {
    this.services[API] = apiCall
  }

  apiCall(options) {
    return this.services[API](options)
  }

  setClient(client) {
    this.services[APOLLO_CLIENT] = client
  }

  getClient() {
    return this.services[APOLLO_CLIENT]
  }

  setPortfolioService(instance) {
    this.services[PORTFOLIO_SERVICE] = instance
  }

  getPortfolioService() {
    return this.services[PORTFOLIO_SERVICE]
  }

  setStore(store) {
    this.services[STORE] = store
  }

  getStore() {
    return this.services[STORE]
  }

  setSubscriptionLink(subscriptionLink) {
    this.services[SUBSCRIPTION_LINK] = subscriptionLink
  }

  getSubscriptionLink() {
    return this.services[SUBSCRIPTION_LINK]
  }
}

const instance = new Shared()

Object.freeze(instance)

export default instance
