import ReactSlider from 'react-slider'

import styled from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${layout.width}
  ${margin}
`

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 8px;
`

export const Thumb = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${themeGet('colors.white')};
  border: solid 2px ${themeGet('colors.bg.secondary')};
  border-radius: 50%;
  outline: none;
`

export const Track = styled.div`
  height: 4px;
  top: 2px;
  bottom: 0;
  background-color: ${({ index }) => {
    return index === 0
      ? themeGet('colors.bg.secondary')
      : themeGet('colors.bg.lightColored')
  }};
  border-radius: 2px;
`

export const Label = styled.div`
  ${Styles.text(10)}
`
