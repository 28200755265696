export const KIND = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  FUND_PORTFOLIO: 'fundPortfolio',
  CLOSE_PORTFOLIO: 'closePortfolio',
  BUY_ORDER_FILLED: 'buyOrderFilled',
  SELL_ORDER_FILLED: 'sellOrderFilled',
  GOAL_DEPOSIT: 'goalDeposit',
  GOAL_WITHDRAW: 'goalWithdraw',
  REBALANCE_BUY_ORDER_FILLED: 'rebalanceBuyOrderFilled',
  REBALANCE_SELL_ORDER_FILLED: 'rebalanceSellOrderFilled',
  DAILY_GOAL_INTEREST: 'dailyGoalInterest',
}

export const DATE_RANGE = {
  PAST_7DAYS: 'past7Days',
  PAST_30DAYS: 'past30Days',
  PAST_90DAYS: 'past90Days',
  CUSTOMIZED: 'customized',
}
