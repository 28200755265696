import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

const labelErrorCss = ({ error }) =>
  error &&
  css`
    color: ${themeGet('colors.negative')};
  `

export const Label = styled.label`
  display: flex;
  align-items: center;
  min-height: 15px;
  font-size: 12px;
  line-height: normal;
  color: ${themeGet('colors.font.secondary')};

  ${margin}
  ${labelErrorCss}
`
