import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.white')};
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  height: 100%;
`
