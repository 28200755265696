import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import CheckboxInput from './CheckboxInput'
import { EnhancedCheckboxContainer } from './styles'

function EnhancedCheckboxField({ id, name, validate, value, ...rest }) {
  return (
    <Field name={name} type="checkbox" validate={validate} value={value}>
      {({ input }) => {
        return (
          <EnhancedCheckboxContainer>
            <CheckboxInput {...input} {...rest} id={id} />
          </EnhancedCheckboxContainer>
        )
      }}
    </Field>
  )
}

EnhancedCheckboxField.defaultProps = {
  caption: undefined,
  checkErrorIfDirty: undefined,
  children: null,
  defaultValue: undefined,
  displayError: undefined,
  id: undefined,
  validate: undefined,
  value: null,
}

EnhancedCheckboxField.propTypes = {
  caption: PropTypes.string,
  checkErrorIfDirty: PropTypes.bool,
  children: PropTypes.node,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  displayError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  validate: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default EnhancedCheckboxField
