import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'

const dotColorCss = ({ color }) =>
  color &&
  css`
    background-color: ${color};
  `

const Dot = styled.div`
  border-radius: 100%;
  width: 8px;
  height: 8px;

  ${layout.size}
  ${margin}
  ${dotColorCss}
`

Dot.defaultProps = {
  color: 'black',
  size: 8,
}

Dot.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Dot
