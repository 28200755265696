import { LANGUAGE } from './ids'

export const AUTH_TOKEN_DEFAULTS = {
  accessToken: null,
  refreshToken: null,
}

export const I18N_DEFAULTS = {
  locale: LANGUAGE.EN,
}

export const EXPERT_PORTFOLIO_DEFAULTS = {
  showFAQ: true,
}

export const PORTFOLIO_TIPS_DEFAULTS = {
  closedPortfolioIds: {},
}

export const DASHBOARD_DEFAULTS = {
  showActions: true,
}

export const SIDEBAR_DEFAULTS = {
  sidebarOpen: true,
}
