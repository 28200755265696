import { useMemo } from 'react'

import { DateTime } from 'luxon'

import { ActionsCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 2,
      },
      {
        Header: 'Created',
        Cell: ({ value }) =>
          value
            ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
            : '—',
        accessor: 'createdAt',
        width: 2,
      },
      {
        Header: 'Action',
        Cell: ActionsCell,
        width: 1,
      },
    ],
    [],
  )
}
