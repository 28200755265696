import React, { useCallback, useEffect, useState } from 'react'

import { Add2FAModal } from 'Components/Blocks/Modals/2FA'

import { useSingleInstance } from 'Hooks'

import EventBus from 'Services/EventBus'

const ACTION = {
  START: 'Add2FACoordinator/Start',
}

function Add2FACoordinator() {
  useSingleInstance('Add2FACoordinator')

  const [modalOpen, setModalOpen] = useState(false)
  const [onFinish, setOnFinish] = useState(null)

  const handleFinish = useCallback(
    success => {
      setModalOpen(false)
      onFinish(success)
    },
    [onFinish],
  )

  const handleStart = useCallback(callback => {
    setModalOpen(true)
    setOnFinish(() => callback)
  }, [])

  useEffect(() => {
    EventBus.on(ACTION.START, handleStart)
    return () => {
      EventBus.off(ACTION.START)
    }
  }, [handleStart])

  return modalOpen ? (
    <Add2FAModal
      isOpen
      onClose={() => setModalOpen(false)}
      onFinish={handleFinish}
    />
  ) : null
}

Add2FACoordinator.start = onFinish => EventBus.trigger(ACTION.START, onFinish)

export default Add2FACoordinator
