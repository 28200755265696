import styled, { css } from 'styled-components'
import { width } from 'styled-system'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import {
  Background as CheckboxBackground,
  Icon as CheckboxIcon,
} from '../styles'

export const Icon = styled(CheckboxIcon)`
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  right: 27px;
  left: initial;
`

export const Background = styled(CheckboxBackground)`
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
`

const enhancedCheckboxContainerActiveCss = ({ active }) =>
  active &&
  css`
    border-color: ${themeGet('colors.primary50')};
  `

export const EnhancedCheckboxContainer = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${themeGet('colors.text20')};
  background: ${themeGet('colors.white')};
  z-index: 100;

  :hover {
    border-color: ${themeGet('colors.primary50')};
  }

  > label {
    padding: 17px 25px;
    cursor: pointer;
  }

  ${enhancedCheckboxContainerActiveCss};

  ${width};
  ${margin};
`
