import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'
import { pick } from '@styled-system/props'

import { Column, Row, Text } from 'Components/UI'

import { PORTFOLIO_LOCAL_DATA } from 'Constants/portfolios'

import { useScopedI18n } from 'Services/I18n'

import {
  Circle,
  Container,
  InfoPieChartIcon,
  LogoIcon,
  LogoImage,
} from './styles'

const MAX_LEVEL = 4

function ExpertPortfolioPie({ expertPortfolio, ...rest }) {
  const theme = useTheme()
  const s = useScopedI18n('blocks.expertPortfolio.info.pie')

  const { risk, expectedReturn, timeHorizon, assets } = expertPortfolio

  const riskCircles = useMemo(() => {
    return Array.from({ length: MAX_LEVEL }, (_, index) => (
      <Circle key={index + 1} light={risk < index + 1} />
    ))
  }, [risk])

  const timeHorizonCircles = useMemo(() => {
    return Array.from({ length: MAX_LEVEL }, (_, index) => (
      <Circle key={index + 1} light={timeHorizon < index + 1} />
    ))
  }, [timeHorizon])

  const expectedReturnCircles = useMemo(() => {
    return Array.from({ length: MAX_LEVEL }, (_, index) => (
      <Circle key={index + 1} light={expectedReturn < index + 1} />
    ))
  }, [expectedReturn])

  return (
    <Container {...pick(rest)}>
      <InfoPieChartIcon />

      <Column center>
        {PORTFOLIO_LOCAL_DATA[expertPortfolio.id] ? (
          <LogoImage src={PORTFOLIO_LOCAL_DATA[expertPortfolio.id].logo} />
        ) : (
          <LogoIcon height={40} viewBox="0 0 50 50" width={40} />
        )}

        <Column mt="20px">
          <Row>
            <Row flex={1}>
              <Text
                color={theme.colors.text70}
                extraSmall
                textAlign="end"
                width={1}
              >
                {s('riskLevel')}
              </Text>
            </Row>

            <Row center gap={1} ml={3} width="44px">
              {riskCircles}
            </Row>
          </Row>

          <Row>
            <Row flex={1}>
              <Text
                color={theme.colors.text70}
                extraSmall
                textAlign="end"
                width={1}
              >
                {s('timeHorizon')}
              </Text>
            </Row>

            <Row center gap={1} ml={3} width="44px">
              {timeHorizonCircles}
            </Row>
          </Row>

          <Row>
            <Row flex={1}>
              <Text
                color={theme.colors.text70}
                extraSmall
                textAlign="end"
                width={1}
              >
                {s('expectedReturn')}
              </Text>
            </Row>

            <Row center gap={1} ml={3} width="44px">
              {expectedReturnCircles}
            </Row>
          </Row>

          <Row>
            <Row flex={1}>
              <Text
                color={theme.colors.text70}
                extraSmall
                textAlign="end"
                width={1}
              >
                {s('assets')}
              </Text>
            </Row>

            <Row center gap={1} ml={3} width="44px">
              <Text color={theme.colors.primary50} extraSmall>
                {assets?.length}
              </Text>
            </Row>
          </Row>
        </Column>
      </Column>
    </Container>
  )
}

ExpertPortfolioPie.defaultProps = {
  expertPortfolio: {},
}

ExpertPortfolioPie.propTypes = {
  expertPortfolio: PropTypes.object,
}
export default ExpertPortfolioPie
