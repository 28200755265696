import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { logoExtendedGlyph } from 'Assets/Svg'

import { Link } from 'Components/UI'

export const Container = styled.div.attrs({
  as: 'section',
})`
  display: flex;
  background-color: ${themeGet('colors.bg.primary')};
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
`

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 215px;
  height: 100%;
  border-right: solid 1px ${themeGet('colors.border.primary')};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-color: ${themeGet('colors.bg.light')};
`

export const PageContent = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  height: calc(100vh - 48px);
  overflow: auto;
`

export const PageContentLimit = styled.div`
  max-width: 1800px;
  width: 100%;
`

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid ${themeGet('colors.border.primary')};
  color: ${themeGet('colors.font.primary')};

  &.active {
    color: ${themeGet('colors.font.colored')};
  }

  > svg {
    margin-right: 4px;
  }
`

export const LogoExtendedIcon = styled(logoExtendedGlyph)``
