import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import logoutGlyph from 'iconoir/icons/log-out.svg'

import { logoExtendedGlyph } from 'Assets/Svg'

import Styles from 'Components/Styles'

export const Container = styled.section`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: transparent;
  flex-direction: column;
`

export const Info = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 42px;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
`

export const Name = styled.div`
  ${Styles.text(14)};
  color: ${themeGet('colors.primary')};

  ${margin}
`

export const Slogan = styled.div`
  width: 304px;
  margin-top: 70px;
  text-align: center;
  ${Styles.text(24)}
`

export const Copyright = styled.div`
  ${Styles.text(12)}
`

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 456px;
`

export const LogoutIcon = styled(logoutGlyph)`
  color: ${themeGet('colors.primary50')};
  margin-right: ${themeGet('space.2')}px;
`

export const LogoExtendedIcon = styled(logoExtendedGlyph)``
