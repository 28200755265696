import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { FiCheckCircle, FiEdit3, FiGrid, FiInfo, FiTrash } from 'react-icons/fi'
import { MdOutlineWorkHistory } from 'react-icons/md'

import isNil from 'lodash/isNil'

import { Button, Popover, Row } from 'Components/UI'

import { USER_ROLE } from 'Constants/ids'

import { useAppContext } from 'Hooks'

import { ADMIN_USER_PORTFOLIO_TEMPLATE_LOGS } from 'Router/routes'

import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const history = useHistory()
  const { me } = useAppContext()
  const {
    onInfoRow,
    onEditRow,
    onDeleteRow,
    onUserPortfolioGroupEdit,
    onCreateApprovedUserKYC,
  } = useTableContext()

  const user = row?.original

  const handleInfo = useCallback(() => {
    onInfoRow(user)
  }, [onInfoRow, user])

  const handleEdit = useCallback(() => {
    onEditRow(user)
  }, [onEditRow, user])

  const handleDelete = useCallback(
    () => {
      onDeleteRow(user)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [row],
  )

  const handleEditPortfolioGroup = useCallback(() => {
    onUserPortfolioGroupEdit(user)
  }, [onUserPortfolioGroupEdit, user])

  const handleViewLogs = useCallback(() => {
    history.push(ADMIN_USER_PORTFOLIO_TEMPLATE_LOGS(user.id))
  }, [history, user])

  const handleCreateApprovedKYC = useCallback(() => {
    onCreateApprovedUserKYC(user)
  }, [onCreateApprovedUserKYC, user])

  const canInteract =
    me.role === USER_ROLE.SUPER_ADMIN || user.role === USER_ROLE.USER

  return (
    <Row>
      {canInteract && (
        <>
          {isNil(user?.kyc) && user.role === USER_ROLE.USER && (
            <Popover
              content="Create Approved KYC"
              delay={Popover.delay}
              showArrow
            >
              <Button
                icon
                outline
                secondary
                small
                squared
                onClick={handleCreateApprovedKYC}
              >
                <FiCheckCircle />
              </Button>
            </Popover>
          )}

          <Popover content="User info" delay={Popover.delay} showArrow>
            <Button icon outline secondary small squared onClick={handleInfo}>
              <FiInfo />
            </Button>
          </Popover>
          <Popover
            content="User portfolio template logs"
            delay={Popover.delay}
            showArrow
          >
            <Button
              icon
              outline
              secondary
              small
              squared
              onClick={handleViewLogs}
            >
              <MdOutlineWorkHistory />
            </Button>
          </Popover>
          <Popover
            content="Edit user portfolio groups"
            delay={Popover.delay}
            showArrow
          >
            <Button
              icon
              outline
              secondary
              small
              squared
              onClick={handleEditPortfolioGroup}
            >
              <FiGrid />
            </Button>
          </Popover>
          <Popover content="Edit user" delay={Popover.delay} showArrow>
            <Button
              icon
              ml={1}
              outline
              secondary
              small
              squared
              onClick={handleEdit}
            >
              <FiEdit3 />
            </Button>
          </Popover>
          <Popover content="Delete user" delay={Popover.delay} showArrow>
            <Button
              icon
              ml={1}
              outline
              secondary
              small
              squared
              onClick={handleDelete}
            >
              <FiTrash />
            </Button>
          </Popover>
        </>
      )}
    </Row>
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
