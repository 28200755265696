import styled from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;

  ${margin}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > div {
    padding: 0;
  }

  ${margin}
  ${layout.maxHeight}
`
