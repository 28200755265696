import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { renderRoutes } from 'react-router-config'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation, useSubscription } from '@apollo/client'

import { Button, Link, Loader, Row } from 'Components/UI'

import { KYC_STATUS } from 'Constants/ids'

import updateOnboardingStepsMutation from 'GraphQL/Mutations/User/updateOnboardingSteps.graphql'
import userKYCStateSubscription from 'GraphQL/Subscriptions/userKYCState.graphql'

import { useAppContext, useLocationQueryParams } from 'Hooks'

import { APP_ROOT, ONBOARDING_KYC, ROOT, SIGN_OUT } from 'Router/routes'

import _, { useScopedI18n } from 'Services/I18n'

import Utils from 'Utils'

import {
  Container,
  Content,
  Info,
  LogoExtendedIcon,
  LogoutIcon,
} from './styles'

function Onboarding({ route }) {
  const s = useScopedI18n('general')
  const location = useLocation()
  const history = useHistory()
  const { me, refetchMe } = useAppContext()
  const queryParams = useLocationQueryParams()

  const redirectStatus = queryParams?.status

  const [loading, setLoading] = useState(false)

  const [updateOnboardingSteps] = useMutation(updateOnboardingStepsMutation)

  useSubscription(userKYCStateSubscription, {
    onData({ data }) {
      const state = data?.data?.userKYCState?.state

      if (state) refetchMe()
    },
  })

  const showSkipKyc = useMemo(() => {
    const kycStatus =
      redirectStatus === 'aborted'
        ? KYC_STATUS.FAILURE
        : Utils.UserKYC.getStatus(me?.kyc?.state)

    if (
      location.pathname !== ONBOARDING_KYC ||
      kycStatus !== KYC_STATUS.FAILURE
    )
      return false

    return true
  }, [me, location, redirectStatus])

  const updateOnboardingStep = useCallback(async () => {
    try {
      setLoading(true)
      await updateOnboardingSteps({ variables: { kyc: true } })
    } catch (error) {
      toast.error(error?.message || _('error.generic'))
      setLoading(false)
    }
  }, [updateOnboardingSteps])

  const handleSkipKyc = async () => {
    await updateOnboardingStep()
    history.push(APP_ROOT)
  }

  return (
    <Container>
      <Info>
        <Row center spaceBetween width={1}>
          <Link to={ROOT}>
            <LogoExtendedIcon />
          </Link>

          {showSkipKyc && (
            <Row justifyCenter mr={4}>
              <Button disabled={loading} text onClick={handleSkipKyc}>
                {s('skipKyc')}
                {loading && <Loader ml={1} />}
              </Button>
            </Row>
          )}

          <Row center>
            <LogoutIcon height={14} viewBox="0 0 24 24" width={14} />
            <Link fontWeight={600} to={SIGN_OUT}>
              {s('logout')}
            </Link>
          </Row>
        </Row>
      </Info>

      <Content width={1}>{renderRoutes(route.routes)}</Content>

      {/* <SupportChat position="right" /> */}
    </Container>
  )
}

Onboarding.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Onboarding
