
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SettingsFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Settings"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"systemFee"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"interestRate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"advisorName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"advisorPhone"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"advisorEmail"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"advisorCalendly"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"advisorAvatar"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"binanceWallet"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":163}};
    doc.loc.source = {"body":"fragment SettingsFields on Settings {\n  systemFee\n  interestRate\n\n  advisorName\n  advisorPhone\n  advisorEmail\n  advisorCalendly\n  advisorAvatar\n\n  binanceWallet\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
