import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'

import ProgressBar from 'Components/Blocks/Onboarding/ProgressBar'
import { Button, Column, Loader, Row, Text } from 'Components/UI'

import updateOnboardingStepsMutation from 'GraphQL/Mutations/User/updateOnboardingSteps.graphql'
import updateRiskAssessmentMutation from 'GraphQL/Mutations/User/updateRiskAssessment.graphql'
import updateKYCDataMutation from 'GraphQL/Mutations/UserKYC/updateKYCData.graphql'

import { useStepper } from 'Hooks'

import { FIELDS } from 'Pages/Onboarding/RiskAssessment/constants'

import { ONBOARDING_PORTFOLIO, ONBOARDING_ROOT } from 'Router/routes'

import _ from 'Services/I18n'

import { theme } from 'Theme'

import { QUESTIONS } from './Questions/constants'
import Questions from './Questions'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DescriptionIndustryContainer,
  FormContent,
} from './styles'

const INDUSTRY_ENTITIES = 27

const CONSTRAINTS = {
  [FIELDS.RISK_TOLERANCE]: {
    presence: true,
  },
  [FIELDS.INVESTMENT_HORIZON]: {
    presence: true,
  },
  [FIELDS.INVESTING_EXPERIENCE]: {
    presence: true,
  },
  [FIELDS.TOTAL_NET_WORTH]: {
    presence: true,
  },
  [FIELDS.TAX_RESIDENCE]: {
    presence: true,
  },
  [FIELDS.US_CITIZEN]: {
    presence: true,
  },
  [FIELDS.INDUSTRY]: {
    presence: true,
  },
  [FIELDS.PROFESSION]: {
    presence: true,
  },
  [FIELDS.NET_MONTHLY_INCOME]: {
    presence: true,
  },
  [FIELDS.FUND_SOURCE]: {
    presence: true,
  },
}
function RiskAssessment() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const [updateOnboardingSteps] = useMutation(updateOnboardingStepsMutation)
  const [updateRiskAssessment] = useMutation(updateRiskAssessmentMutation)
  const [updateKYCData] = useMutation(updateKYCDataMutation)

  const { next, prev, step } = useStepper({
    ...Questions.STEPS,
  })

  const submit = useCallback(
    async values => {
      try {
        setLoading(true)

        await updateRiskAssessment({
          variables: {
            riskTolerance: values[FIELDS.RISK_TOLERANCE],
            totalNetWorth: values[FIELDS.TOTAL_NET_WORTH],
            investmentHorizon: values[FIELDS.INVESTMENT_HORIZON],
            investingExperience: values[FIELDS.INVESTING_EXPERIENCE],
            totalInvestments: values[FIELDS.TOTAL_INVESTMENTS],
          },
        })
        await updateKYCData({
          variables: {
            taxResidence: values[FIELDS.TAX_RESIDENCE]?.value,
            isUSCitizen: values[FIELDS.US_CITIZEN],
            isIndustryPresent: values[FIELDS.INDUSTRY],
            profession: values[FIELDS.PROFESSION],
            netMonthlyIncome: values[FIELDS.NET_MONTHLY_INCOME],
            fundSource: values[FIELDS.FUND_SOURCE],
          },
        })
        await updateOnboardingSteps({
          variables: {
            riskAssessment: true,
          },
        })
        history.push(ONBOARDING_PORTFOLIO)
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
        setLoading(false)
      }
    },
    [history, updateKYCData, updateOnboardingSteps, updateRiskAssessment],
  )

  const handleProceed = useCallback(
    event => {
      event.preventDefault()
      next()
    },
    [next],
  )

  const handlePrev = useCallback(
    event => {
      event.preventDefault()

      if (step === Questions.STEPS.EXPERIENCE) {
        history.push(ONBOARDING_ROOT)
      } else {
        prev()
      }
    },
    [history, prev, step],
  )

  const content = useMemo(() => {
    return <Questions step={step} />
  }, [step])

  const renderForm = useCallback(
    ({ handleSubmit, errors }) => {
      const isLastStep = step === Questions.STEPS.FUND_SOURCE
      const hasErrors = !!errors[QUESTIONS[step].name]

      return (
        <Column width={1}>
          <Row justifyCenter>
            <Text fontWeight={2} heading2>
              {_('onboarding.progressBar.investorProfile')}
            </Text>
          </Row>

          <FormContent mt={6}>
            <Text fontWeight={2} heading4>
              {_(QUESTIONS[step]?.title)}
            </Text>

            {QUESTIONS[step]?.subtitle && (
              <Text color={theme.colors.text70}>
                {_(QUESTIONS[step].subtitle)}
              </Text>
            )}

            <Column maxHeight="550px" overflow="auto">
              {step === Questions.STEPS.INDUSTRY && (
                <DescriptionIndustryContainer mt={5}>
                  {Array.from({ length: INDUSTRY_ENTITIES }, (item, index) => (
                    <Row gap={2}>
                      &#8226;{' '}
                      <Text preWrap small>
                        {
                          _(
                            'onboarding.riskAssessment.questions.industry.description',
                          )[index]
                        }
                      </Text>
                    </Row>
                  ))}
                </DescriptionIndustryContainer>
              )}
              {content}
            </Column>

            <Row mt={5} spaceBetween>
              <Button disabled={loading} outline onClick={handlePrev}>
                <ArrowLeftIcon height={16} viewBox="0 0 24 24" width={16} />
                Back
              </Button>
              <Button
                disabled={hasErrors || loading}
                onClick={isLastStep ? handleSubmit : handleProceed}
              >
                Continue
                <ArrowRightIcon height={16} viewBox="0 0 24 24" width={16} />
                {loading && <Loader ml={1} />}
              </Button>
            </Row>
          </FormContent>
        </Column>
      )
    },
    [content, handlePrev, handleProceed, loading, step],
  )

  return (
    <Row gap="20px">
      <ProgressBar mt="72px" />
      <Form
        initialValues={{
          [FIELDS.RISK_TOLERANCE]: null,
          [FIELDS.TOTAL_NET_WORTH]: null,
          [FIELDS.INVESTMENT_HORIZON]: null,
          [FIELDS.INVESTING_EXPERIENCE]: null,
          [FIELDS.TAX_RESIDENCE]: null,
          [FIELDS.US_CITIZEN]: null,
          [FIELDS.INDUSTRY]: null,
          [FIELDS.PROFESSION]: null,
          [FIELDS.NET_MONTHLY_INCOME]: null,
          [FIELDS.FUND_SOURCE]: null,
        }}
        render={renderForm}
        validate={values => validate(values, CONSTRAINTS)}
        onSubmit={submit}
      />
    </Row>
  )
}

export default RiskAssessment
