import { captureException } from '@sentry/react'
import qs from 'qs'
import Request from 'superagent'

import get from 'lodash/get'
import merge from 'lodash/merge'

import { API, IS_PRODUCTION } from 'Config'

import shared from 'Services/Shared'

const sendMethod = HTTPMethod =>
  HTTPMethod === 'post' ||
  HTTPMethod === 'put' ||
  HTTPMethod === 'patch' ||
  HTTPMethod === 'delete'
    ? 'send'
    : 'query'

const sendArguments = (HTTPMethod, query) =>
  HTTPMethod === 'post' ||
  HTTPMethod === 'put' ||
  HTTPMethod === 'patch' ||
  HTTPMethod === 'delete'
    ? query
    : qs.stringify(query, { arrayFormat: 'brackets' })

const defaultOptions = {
  url: `${API.URL}/v1`,
  endpoint: '',
  method: 'GET',
  query: {},
  headers: {},
  withoutAuthorization: false,
}

const absoluteUrl = new RegExp('^(?:[a-z]+:)?//', 'i')

export default () => options => {
  const agent = Request.agent()

  const { url, endpoint, method, query, headers, payload } = merge(
    {},
    defaultOptions,
    options,
  )

  const accessToken = get(
    shared.getClient(),
    'cache.data.data.AuthToken.accessToken',
  )

  const HTTPMethod = method.toLowerCase()

  const fullUrl = absoluteUrl.test(endpoint) ? endpoint : url + endpoint

  const request = agent[HTTPMethod](fullUrl)

  request[sendMethod(HTTPMethod)](sendArguments(HTTPMethod, query))

  return new Promise((resolve, reject) => {
    request
      .set({
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        ...headers,
      })
      .end((error, req) => {
        if (IS_PRODUCTION) {
          captureException(error) // Sentry exception capturer
        }

        const body = get(req, 'body')

        if (payload) {
          merge(body, { payload })
        }

        if (error) {
          reject(error)
        } else {
          const successData = {
            ...body,
          }

          resolve(successData)
        }
      })
  })
}
