import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import numeral from 'numeral'

import noop from 'lodash/noop'
import sumBy from 'lodash/sumBy'
import toNumber from 'lodash/toNumber'

import { Row } from 'Components/UI'

import _ from 'Services/I18n'

import { theme } from 'Theme'

import { Container, FormContent, Total } from './styles'
import Table from './Table'

function AllocationList({
  amount,
  assets,
  colors,
  contentMaxHeight,
  onMount,
  onDeleteAsset,
  showDeleteButton,
  ...rest
}) {
  const handleAssetDelete = useCallback(
    async asset => {
      onDeleteAsset(asset)
    },
    [onDeleteAsset],
  )

  const totalAllocation = sumBy(
    assets,
    asset => toNumber(asset.percentage) || 0,
  )

  return (
    <Container {...pick(rest)}>
      <FormContent maxHeight={contentMaxHeight} mt="20px">
        <Table
          amount={amount}
          assets={assets}
          colors={colors}
          showDeleteButton={showDeleteButton}
          onDelete={handleAssetDelete}
        />

        <Row justifyEnd width={1}>
          <Total>{_('portfolio.assets.totalAllocated')}:</Total>
          <Total
            ml={1}
            negative={totalAllocation > 100}
            positive={totalAllocation === 100}
          >
            {numeral(totalAllocation).format('0')}%
          </Total>
        </Row>
      </FormContent>
    </Container>
  )
}

AllocationList.defaultProps = {
  amount: 0,
  assets: [],
  colors: theme.colors.assets,
  contentMaxHeight: undefined,
  showDeleteButton: true,
  onDeleteAsset: noop,
  onMount: noop,
}

AllocationList.propTypes = {
  amount: PropTypes.number,
  assets: PropTypes.array,
  colors: PropTypes.arrayOf(PropTypes.string),
  contentMaxHeight: PropTypes.number,
  showDeleteButton: PropTypes.bool,
  onDeleteAsset: PropTypes.func,
  onMount: PropTypes.func,
}

export default AllocationList
