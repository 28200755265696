import React, { useMemo } from 'react'

import { pick } from '@styled-system/props'

import { useQuery } from '@apollo/client'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import AssetsChart from 'Components/Blocks/Charts/Assets/Line'
import ChartPlaceholder from 'Components/Blocks/Charts/Placeholder'

import netWorthDataQuery from 'GraphQL/Queries/NetWorthData/netWorthData.graphql'

import _ from 'Services/I18n'

import { Content } from './styles'

import { Container } from '../styles'

function NetWorthChart({ ...rest }) {
  const { data } = useQuery(netWorthDataQuery)

  const processedData = useMemo(() => {
    const rows = data?.netWorthData?.rows
    if (!rows) return []

    return map(rows, ({ time, amount }) => ({
      date: time,
      value: amount,
    }))
  }, [data])

  return (
    <Container {...pick(rest)}>
      <Content mt={2}>
        <AssetsChart
          data={processedData}
          displayExampleData={isEmpty(processedData)}
        />

        {isEmpty(processedData) && (
          <ChartPlaceholder
            title={_('portfolio.performance.chartPlaceholderTitle')}
          />
        )}
      </Content>
    </Container>
  )
}

export default NetWorthChart
