import { ApolloLink } from '@apollo/client'

import { getAccessToken } from 'Services/Store/auth'

export default () =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const accessToken = getAccessToken()

      return {
        headers: {
          ...headers,
          Authorization: accessToken ? `Bearer ${accessToken}` : null,
        },
      }
    })

    return forward(operation)
  })
