import React, { useCallback, useEffect, useMemo } from 'react'
import { useFlexLayout, usePagination, useTable } from 'react-table'

import { useLazyQuery } from '@apollo/client'

import { Row } from 'Components/UI'
import { Table } from 'Components/UI/Admin'

import adminAssetTotalQuery from 'GraphQL/Queries/Admin/adminAssetTotal.graphql'

import { useColumns } from './columns'
import { Container, Content } from './styles'

function AssetStatsTable() {
  const [loadAssets, { data, loading }] = useLazyQuery(adminAssetTotalQuery)

  const tableData = useMemo(() => data?.adminAssetTotal || [], [data])

  const totalCost = useMemo(
    () => tableData.reduce((acc, item) => acc + item.cost, 0),
    [tableData],
  )

  const columns = useColumns()

  const tableProps = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: 9999 },
    },
    usePagination,
    useFlexLayout,
  )

  const fetchData = useCallback(() => {
    loadAssets().then()
  }, [loadAssets])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Container>
      <Content>
        <Row borderBottom center mb={2} pb={3} spaceBetween>
          <div>
            Total cost of assets in filled portfolios:{' '}
            <b>{totalCost?.toFixed(2)} USDT</b>
          </div>
        </Row>

        <Table {...tableProps} loading={loading} />
      </Content>
    </Container>
  )
}

export default AssetStatsTable
