// NOTE: current order from provided colors doc is
//  2 3 4 7 5 6(?) 9 10 8 1

export default [
  // -- First ten
  '#FF8468',
  '#FFBC00',
  '#F3D661',
  '#3FCA87',
  '#87BCDE',
  '#4665EC',
  '#608EA9',
  '#426D8A',
  '#5D6F83',
  '#977289',
  // -- Second ten
  '#FF5E39',
  '#DFA400',
  '#F0CC3B',
  '#32B676',
  '#64A9D5',
  '#2448E8',
  '#517D97',
  '#3A5F78',
  '#516173',
  '#856378',
  // -- Third
  '#FF9279',
  '#FFC320',
  '#F5DB76',
  '#57D196',
  '#96C5E2',
  '#5D78EE',
  '#749CB4',
  '#4F82A4',
  '#6D8197',
  '#A48498',
  // -- Fourth
  '#FF7252',
  '#E4B100',
  '#EFD24C',
  '#4DC89F',
  '#79A6D8',
  '#3971F1',
  '#6E9FC0',
  '#5788AB',
  '#7B8AA1',
  '#B794A7',
  // -- Fifth
  '#FFA289',
  '#FFCE57',
  '#F9E587',
  '#66D9B5',
  '#8DBCE4',
  '#6781F3',
  '#88AFD4',
  '#679DC3',
  '#869FAF',
  '#B5A8B4',
  // -- Sixth
  '#FFBDA0',
  '#FFD982',
  '#FAE9A0',
  '#7FEDC3',
  '#A5D0F2',
  '#7F8CF9',
  '#9CB8DC',
  '#7FACCA',
  '#96AAC2',
  '#BCAFB6',
  // -- Seventh
  '#FFCCB1',
  '#FFE1A8',
  '#FBEDBF',
  '#98F3D6',
  '#BDD8F4',
  '#97A1FB',
  '#AAC7E8',
  '#97B8D8',
  '#AAC3D1',
  '#C4C5C5',
  // -- Eighth
  '#FFD9C2',
  '#FFE8C7',
  '#FCF2CC',
  '#AFE8DB',
  '#CCEAF9',
  '#ACABFF',
  '#B8CCEC',
  '#ACBEDD',
  '#B8C7D5',
  '#C8C9CC',
  // -- Ninth
  '#FFE6D4',
  '#FFF2D8',
  '#FDF5DC',
  '#C5EBE7',
  '#D8EEFA',
  '#C7C7FF',
  '#CFD7EE',
  '#C7D0E2',
  '#CFD4DC',
  '#D9D9D9',
]
