import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Row, Text } from 'Components/UI'
import { Input } from 'Components/UI/Forms'

import { ButtonNoBorder, CancelIcon, CheckIcon, EditPencilIcon } from './styles'

function EditName({ name, onEdit }) {
  const [isEdit, setEdit] = useState(false)
  const [value, setValue] = useState(name)

  const handleSubmit = useCallback(() => {
    setEdit(false)
    onEdit(value)
  }, [value, onEdit])

  return (
    <Row center width={1}>
      {!isEdit && (
        <>
          <Text fontWeight={2} heading4>
            {name}
          </Text>
          <ButtonNoBorder
            icon
            ml={3}
            outline
            xSmall
            onClick={() => setEdit(true)}
          >
            <EditPencilIcon height={14} viewBox="0 0 24 24" width={14} />
          </ButtonNoBorder>
        </>
      )}

      {isEdit && (
        <Row gap={4} width={1}>
          <Input
            value={value}
            onChange={event => setValue(event.target.value)}
          />

          <button type="button" onClick={handleSubmit}>
            <CheckIcon height={14} viewBox="0 0 24 24" width={14} />
          </button>

          <button type="button" onClick={() => setEdit(false)}>
            <CancelIcon height={14} viewBox="0 0 24 24" width={14} />
          </button>
        </Row>
      )}
    </Row>
  )
}

EditName.defaultProps = {
  name: null,
  onEdit: noop,
}

EditName.propTypes = {
  name: PropTypes.string,
  onEdit: PropTypes.func,
}
export default EditName
