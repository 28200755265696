import { PortfolioLayout } from 'Components/Layout'

import Portfolio from 'Pages/Portfolio'

import * as ROUTES from './routes'

export const PORTFOLIO_ROUTES = {
  component: PortfolioLayout,
  path: ROUTES.PORTFOLIO,
  routes: [
    {
      component: Portfolio.Root,
      path: ROUTES.PORTFOLIO_ROOT(),
      exact: true,
    },
    {
      component: Portfolio.Rebalance,
      path: ROUTES.PORTFOLIO_REBALANACE(),
      exact: true,
    },
    {
      component: Portfolio.Backtesting,
      path: ROUTES.PORTFOLIO_BACKTESTING(),
      exact: true,
    },
    {
      component: Portfolio.External.Exchange,
      path: ROUTES.PORTFOLIO_EXCHANGE_ROOT(),
      exact: true,
    },
    {
      component: Portfolio.External.Wallet,
      path: ROUTES.PORTFOLIO_WALLET_ROOT(),
      exact: true,
    },
  ],
}
