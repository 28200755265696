import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { toast } from 'react-toastify'

import { useTheme } from 'styled-components'

import { useMutation, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import numeral from 'numeral'
import { useStoreon } from 'storeon/react'

import first from 'lodash/first'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'

import { Dialog } from 'Components/Blocks/Modals'
import {
  AssetsList,
  InfoPie,
  PortfolioBalance,
  PortfolioPerformance,
  PortfolioTip,
} from 'Components/Blocks/Portfolio'
import { Button, Column, Loader, Row, Text } from 'Components/UI'

import { REBALANCE_TYPE, STATE } from 'Constants/portfolios'
import { PORTFOLIO_TIPS_ACTIONS, PORTFOLIO_TIPS_STATE } from 'Constants/store'

import closePortfolioMutation from 'GraphQL/Mutations/Portfolios/closePortfolio.graphql'
import fundPortfolioMutation from 'GraphQL/Mutations/Portfolios/fundPortfolio.graphql'
import removePortfolioMutation from 'GraphQL/Mutations/Portfolios/removePortfolio.graphql'
import updatePortfolioMutation from 'GraphQL/Mutations/Portfolios/updatePortfolio.graphql'
import portfolioQuery from 'GraphQL/Queries/Portfolios/portfolio.graphql'
import PortfolioUpdaters from 'GraphQL/Updaters/Portfolios'

import EditName from 'Pages/Portfolio/Root/EditName'

import {
  PORTFOLIO,
  PORTFOLIO_BACKTESTING,
  PORTFOLIO_REBALANACE,
} from 'Router/routes'

import _, { useScopedI18n } from 'Services/I18n'
import shared from 'Services/Shared'

import {
  Content,
  Controls,
  Divider,
  Info,
  RebalanceStatus,
  Wrapper,
} from './styles'

const DATE_FORMAT = 'd MMMM yyyy'

function Root() {
  const { params: routeParams } = useRouteMatch()
  const history = useHistory()
  const s = useScopedI18n('portfolio')
  const theme = useTheme()
  const { dispatch, portfolioTips } = useStoreon(PORTFOLIO_TIPS_STATE)

  const [portfolio, setPortfolio] = useState(null)
  const [isClosePortfolioDialogShow, setClosePortfolioDialogShow] = useState(
    false,
  )
  const [isDeletePortfolioDialogShow, setDeltePortfolioDialogShow] = useState(
    false,
  )

  const { data: portfolioData } = useQuery(portfolioQuery, {
    variables: { id: routeParams?.portfolioId },
  })
  const [closePortfolio] = useMutation(closePortfolioMutation)
  const [fundPortfolio] = useMutation(fundPortfolioMutation)
  const [updatePortfolio] = useMutation(updatePortfolioMutation)
  const [removePortfolio] = useMutation(removePortfolioMutation)

  const handleCloseTip = useCallback(() => {
    dispatch(PORTFOLIO_TIPS_ACTIONS.SET, {
      closedPortfolioIds: {
        ...(portfolioTips.closedPortfolioIds || {}),
        [portfolio.id]: true,
      },
    })
  }, [portfolioTips, portfolio, dispatch])

  const handleEditName = useCallback(
    async value => {
      try {
        await updatePortfolio({
          variables: {
            id: portfolio.id,
            name: value,
          },
        })
        toast.success(s('edit.updateSuccess'))
      } catch (error) {
        toast.error(error?.message || _('error.generic'))
      }
    },
    [s, portfolio, updatePortfolio],
  )

  const handleClose = useCallback(
    async success => {
      const { id } = portfolio

      if (success) {
        try {
          await closePortfolio({ variables: { id } })
          history.push(PORTFOLIO)
        } catch (error) {
          toast.error(get(error, 'message') || _('error.generic'))
        }
      }
    },
    [closePortfolio, portfolio, history],
  )

  const handleDelete = useCallback(
    async success => {
      const { id } = portfolio

      if (success) {
        try {
          await removePortfolio({
            variables: { id },
            update: PortfolioUpdaters.remove(id),
          })
          history.push(PORTFOLIO)
        } catch (error) {
          toast.error(get(error, 'message') || _('error.generic'))
        }
      }
    },
    [removePortfolio, portfolio, history],
  )

  const handleFund = useCallback(async () => {
    const { id } = portfolio

    try {
      await fundPortfolio({ variables: { id } })
    } catch (error) {
      toast.error(get(error, 'message') || _('error.generic'))
    }
  }, [fundPortfolio, portfolio])

  const handleGoRebalance = useCallback(() => {
    history.push(PORTFOLIO_REBALANACE(portfolio.id))
  }, [history, portfolio])

  const handleGoBacktesting = useCallback(() => {
    history.push(PORTFOLIO_BACKTESTING(portfolio.id), {
      portfolio,
    })
  }, [history, portfolio])

  useEffect(() => {
    const loadedPortfolio = get(portfolioData, 'portfolio')
    if (!loadedPortfolio) return

    shared
      .getPortfolioService()
      .populatePortfolios([loadedPortfolio])
      .then(result => {
        const portfolioResult = first(result)

        const totalAssetsCost = sumBy(
          portfolioResult.assets,
          asset => asset.cost,
        )

        const assets = portfolioResult.assets.map(asset => ({
          ...asset,
          asset: asset.currency,
          percentage: (asset.cost / totalAssetsCost) * 100,
        }))

        setPortfolio({
          ...portfolioResult,
          assets: orderBy(assets, ['percentage'], ['desc']),
        })
      })
  }, [portfolioData])

  const portfolioGain = portfolio?.cost - portfolio?.amount
  const portfolioGainPercentage = (portfolioGain / portfolio?.amount) * 100

  const portfolioTemplate = portfolio?.portfolioTemplate
  const portfolioTip = portfolioTemplate?.tip

  const isFilled = portfolio?.state === STATE.FILLED

  if (!portfolio) {
    return <Loader fullHeight fullWidth />
  }

  return (
    <>
      {portfolioTip && !portfolioTips?.closedPortfolioIds?.[portfolio.id] && (
        <PortfolioTip
          content={portfolioTip}
          mb="20px"
          onClose={handleCloseTip}
        />
      )}

      <Content>
        <Wrapper>
          <PortfolioBalance
            amount={numeral(portfolio.cost).format(`0,0.[00]`)}
            gainAmount={isFilled ? portfolioGain : 0}
            gainPercent={isFilled ? portfolioGainPercentage : 0}
          />

          <Divider my="20px" />

          <Row>
            <InfoPie
              initialValue={numeral(portfolio.amount).format(`0,0.[00]`)}
              name={portfolio.name}
            />

            <Column flexGrow={1} ml="40px">
              <EditName name={portfolio.name} onEdit={handleEditName} />
              <Divider my={3} />

              <Row>
                <Column flexBasis="33%">
                  <Text color={theme.colors.text70} extraSmall>
                    {s('info.initialValue')}
                  </Text>
                  <Text fontWeight={2} heading6 mt={1}>
                    ${numeral(portfolio.amount).format('0,0.[00]')}
                  </Text>
                </Column>
                <Column center flexBasis="33%">
                  <Text color={theme.colors.text70} extraSmall>
                    {s('info.rebalancing')}:
                  </Text>
                  <RebalanceStatus mt={1}>
                    <Text actionSmall color={theme.colors.white} fontWeight={1}>
                      {portfolio.rebalanceType ? s('info.yes') : s('info.no')}
                    </Text>
                  </RebalanceStatus>
                </Column>
                {portfolio.rebalanceType && (
                  <Column center flexBasis="33%">
                    <Text color={theme.colors.text70} extraSmall>
                      {portfolio.rebalanceType === REBALANCE_TYPE.THRESHOLD
                        ? s('info.threshold')
                        : s('info.period')}
                      :
                    </Text>
                    <Text fontWeight={2} heading6 mt={1}>
                      {portfolio.rebalanceValue}{' '}
                      {portfolio.rebalanceType === REBALANCE_TYPE.THRESHOLD
                        ? '%'
                        : s('info.days', {
                            count: portfolio.rebalanceValue || 0,
                          })}
                    </Text>
                  </Column>
                )}
              </Row>

              <Divider my={3} />

              <Info>
                <Row>
                  <Text color={theme.colors.text70} extraSmall>
                    {s('info.creationDate')}:
                  </Text>
                  <Text extraSmall fontWeight={1} ml={1}>
                    {DateTime.fromISO(portfolio.createdAt).toFormat(
                      DATE_FORMAT,
                    )}
                  </Text>
                </Row>
                <Row mt={1}>
                  <Text color={theme.colors.text70} extraSmall>
                    {s('info.lastModified')}:
                  </Text>
                  <Text extraSmall fontWeight={1} ml={1}>
                    {DateTime.fromISO(portfolio.updatedAt).toFormat(
                      DATE_FORMAT,
                    )}
                  </Text>
                </Row>
              </Info>

              <Controls gap={3} mt={4}>
                {portfolio?.state === STATE.INITIAL && (
                  <Button flex={1} small onClick={handleFund}>
                    {s('action.fund')}
                  </Button>
                )}
                <Button flex={1} outline small onClick={handleGoRebalance}>
                  {s('action.rebalance')}
                </Button>
                <Button flex={1} outline small onClick={handleGoBacktesting}>
                  {s('action.backtest')}
                </Button>

                {portfolio?.state === STATE.FILLED && (
                  <Button
                    flex={1}
                    outline
                    small
                    onClick={() => setClosePortfolioDialogShow(true)}
                  >
                    {s('action.close')}
                  </Button>
                )}

                {portfolio?.state === STATE.INITIAL && (
                  <Button
                    flex={1}
                    outline
                    small
                    onClick={() => setDeltePortfolioDialogShow(true)}
                  >
                    {s('action.delete')}
                  </Button>
                )}
              </Controls>
            </Column>
          </Row>

          <Divider my="20px" />

          <PortfolioPerformance
            id={portfolio.id}
            kind={PortfolioPerformance.KIND.PORTFOLIO}
          />

          <AssetsList mt="20px" portfolio={portfolio} />
        </Wrapper>

        <Dialog
          confirmText={_('portfolio.detail.close.action')}
          content={
            <Text fontWeight={1} heading6>
              {_('portfolio.detail.close.text')}
            </Text>
          }
          isOpen={isClosePortfolioDialogShow}
          title={
            <>
              {_('portfolio.detail.close.title')[0]}
              <br />
              {_('portfolio.detail.close.title')[1]}
            </>
          }
          onClose={() => setClosePortfolioDialogShow(false)}
          onFinish={handleClose}
        />

        <Dialog
          confirmText={_('portfolio.detail.delete.action')}
          isOpen={isDeletePortfolioDialogShow}
          title={_('portfolio.detail.delete.title')}
          onClose={() => setDeltePortfolioDialogShow(false)}
          onFinish={handleDelete}
        />
      </Content>
    </>
  )
}

export default Root
