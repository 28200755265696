import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-final-form'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import numeral from 'numeral'

import toNumber from 'lodash/toNumber'

import {
  FIELD,
  FUND_METHOD_KIND,
} from 'Components/Blocks/PortfolioFunding/constants'
import { Button, Column, Row, Text } from 'Components/UI'
import { EnhancedRadioButtonField, InputField } from 'Components/UI/Forms'

import { PRIMARY_CURRENCY } from 'Constants/currencies'

import { useAppContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  FormContainer,
  IconContainer,
  Inform,
  SwapIcon,
  WalletIcon,
  WarningIcon,
} from './styles'

function FormFields({ minimumInvestmentPlaceholder }) {
  const history = useHistory()
  const s = useScopedI18n('blocks.funding.form')
  const form = useForm()
  const theme = useTheme()
  const { me } = useAppContext()

  const platformBalance = useMemo(() => me.balances?.[0]?.amount || 0, [me])

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      form.submit()
    },
    [form],
  )

  const handleBack = useCallback(() => {
    history.goBack()
  }, [history])

  const formState = form.getState()

  const isBalanceMethodSelected =
    formState.values[FIELD.FUND_METHOD] === FUND_METHOD_KIND.BALANCE
  const isBalanceLowerMinimumAmount =
    platformBalance < formState.values[FIELD.AMOUNT]

  const isFundByBalanceError =
    isBalanceMethodSelected && isBalanceLowerMinimumAmount

  return (
    <FormContainer>
      <Column fullWidth>
        <InputField
          input={{
            big: true,
            placeholder: minimumInvestmentPlaceholder,
            renderBefore: (
              <Text actionSmall fontWeight={1} ml={3}>
                {s('amountCurrency')}
              </Text>
            ),
          }}
          name={FIELD.AMOUNT}
          parse={toNumber}
        />
        <Text color={theme.colors.text70} mt={1} small>
          {s('minimumInvestment')}: {minimumInvestmentPlaceholder}
          {PRIMARY_CURRENCY}
        </Text>
      </Column>

      <Inform>
        <WarningIcon />
        <Text extraSmall>{s('warning')} </Text>
      </Inform>

      <Column fullWidth gap={4}>
        <Column>
          <EnhancedRadioButtonField
            description={<Text small>{s('balanceDescription')}</Text>}
            id="balance"
            name={FIELD.FUND_METHOD}
            value={FUND_METHOD_KIND.BALANCE}
            width={1}
          >
            <Row fullWidth gap={3}>
              <IconContainer>
                <WalletIcon height={21} viewBox="0 0 24 24" width={21} />
              </IconContainer>
              <Column>
                <Text fontWeight={2} heading4>
                  {s('balance')}
                </Text>
                <Text as="div" mt="5px" small>
                  {s('availableBalance')}:{' '}
                  <Text color={theme.colors.primary50} fontWeight={2} small>
                    {numeral(platformBalance).format('0.00')} {PRIMARY_CURRENCY}{' '}
                  </Text>
                </Text>
              </Column>
            </Row>
          </EnhancedRadioButtonField>

          {isFundByBalanceError && (
            <Text color={theme.colors.dangerPrimary} extraSmall mt="10px">
              {s('errors.insufficientBalance')}
            </Text>
          )}
        </Column>

        <EnhancedRadioButtonField
          description={<Text small>{s('swapDescription')}</Text>}
          disabled
          id="swap"
          name={FIELD.FUND_METHOD}
          value={FUND_METHOD_KIND.SWAP}
          width={1}
        >
          <Row fullWidth gap={3}>
            <IconContainer>
              <SwapIcon height={21} viewBox="0 0 24 24" width={21} />
            </IconContainer>
            <Column>
              <Text fontWeight={2} heading4>
                {s('swap')}
              </Text>
              <Text as="div" mt="5px" small>
                {s('comingSoon')}
              </Text>
            </Column>
          </Row>
        </EnhancedRadioButtonField>

        <EnhancedRadioButtonField
          description={<Text small>{s('depositDescription')}</Text>}
          id="deposit"
          name={FIELD.FUND_METHOD}
          value={FUND_METHOD_KIND.DEPOSIT}
          width={1}
        >
          <Row center fullWidth gap={3}>
            <IconContainer>
              <WalletIcon height={21} viewBox="0 0 24 24" width={21} />
            </IconContainer>
            <Column>
              <Text fontWeight={2} heading4>
                {s('deposit')}
              </Text>
            </Column>
          </Row>
        </EnhancedRadioButtonField>
      </Column>

      <Row fullWidth spaceBetween>
        <Button outline onClick={handleBack}>
          <ArrowLeftIcon height={14} viewBox="0 0 24 24" width={14} />
          Back
        </Button>

        <Button disabled={isFundByBalanceError} onClick={handleSubmit}>
          Continue
          <ArrowRightIcon height={14} viewBox="0 0 24 24" width={14} />
        </Button>
      </Row>
    </FormContainer>
  )
}

FormFields.defaultProps = {
  minimumInvestmentPlaceholder: null,
}

FormFields.propTypes = {
  minimumInvestmentPlaceholder: PropTypes.string,
}

export default FormFields
