import palette from './palette'

export default {
  height: {
    big: 56,
    small: 44,
    xSmall: 32,
    default: 48,
  },
  fontSize: {
    big: 16,
    small: 14,
    xSmall: 12,
    default: 16,
  },
  fontWeight: {
    small: 500,
    xSmall: 500,
    default: 500,
    big: 500,
  },
  padding: {
    big: `0 20px`,
    small: `0 20px`,
    xSmall: `0 20px`,
    default: `0 20px`,
  },
  icon: {
    padding: {
      big: `20px`,
      small: `20px`,
      xSmall: `20px`,
      default: `20px`,
    },
  },
  border: {
    radius: {
      squared: 4,
      default: 8,
    },
    width: {
      outline: 1,
      default: 0,
      secondary: 0,
    },
    color: {
      default: 'transparent',
      outline: palette.primary50,
    },
  },
  color: {
    outline: palette.text100,
    text: palette.primary50,
    secondary: palette.text100,
    default: palette.text10,
  },
  backgroundColor: {
    secondary: palette.white,
    default: palette.primary50,
  },
  active: {
    color: {
      outline: palette.text100,
      text: palette.primary60,
      secondary: palette.text100,
      default: palette.text10,
    },
    backgroundColor: {
      secondary: palette.primary20,
      default: palette.primary70,
      text: 'transparent',
    },
  },
  hover: {
    color: {
      outline: palette.text100,
      text: palette.primary60,
      secondary: palette.text100,
      default: palette.text10,
    },
    backgroundColor: {
      secondary: palette.primary10,
      default: palette.primary60,
      text: 'transparent',
    },
  },
  focus: {
    backgroundColor: {
      default: palette.primary50,
      secondary: palette.white,
    },
    boxShadow: {
      default: palette.aquaMist,
    },
  },
  disabled: {
    color: {
      outline: palette.text100,
      text: palette.text50,
      secondary: palette.text100,
      default: palette.text10,
    },
    border: {
      color: {
        default: palette.abbey60,
        text: palette.text50,
      },
    },
    backgroundColor: {
      default: palette.aquaMist,
      outline: palette.text50,
      text: 'transparent',
    },
  },

  restricted: {
    active: {
      default: palette.primary80,
    },
    color: {
      default: palette.white,
      outline: palette.text100,
    },
    border: {
      color: {
        default: palette.abbey60,
      },
    },
    backgroundColor: {
      default: palette.aquaMist,
    },
    hover: {
      default: palette.primary60,
    },
    opacity: {
      default: 1,
      outline: 0.3,
    },
  },

  outline: {
    color: {
      secondary: palette.abbey100,
      default: palette.text100,
    },
    active: {
      secondary: palette.charcoal80,
      default: palette.primary80,
    },
    backgroundColor: {
      default: palette.white,
    },
    hover: {
      secondary: palette.charcoal120,
      default: palette.primary60,
    },
    disabled: {
      default: palette.gray50,
    },
  },

  ghost: {
    color: {
      default: palette.primary50,
      secondary: palette.text100,
    },
    active: {
      backgroundColor: {
        default: palette.primary20,
        secondary: palette.brightGray,
      },
    },
    hover: {
      backgroundColor: {
        default: palette.primary10,
        secondary: palette.cultured,
      },
    },
    focus: {
      backgroundColor: {
        default: palette.white,
        secondary: palette.white,
      },

      boxShadow: {
        default: palette.aquaMist,
        secondary: palette.softBlueHaze,
      },
    },
    disabled: {
      color: {
        default: palette.gray50,
        secondary: palette.gray50,
      },
    },
  },
}
