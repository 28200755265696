import React from 'react'
import { components } from 'react-select'

import get from 'lodash/get'

import { ControlWrapper } from './styles'

const Control = props => (
  <ControlWrapper
    as={components.Control}
    {...props}
    small={get(props, ['selectProps', 'small'])}
  />
)

Control.propTypes = {
  ...components.Control.propTypes,
}

export default Control
