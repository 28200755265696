import { NUMERIC_RISK_TOLERANCE, RISK_TOLERANCE } from 'Constants/ids'

function defineRiskTolerance(risk) {
  if (risk <= NUMERIC_RISK_TOLERANCE[RISK_TOLERANCE.CONSERVATIVE]) {
    return RISK_TOLERANCE.CONSERVATIVE
  }
  if (risk === NUMERIC_RISK_TOLERANCE[RISK_TOLERANCE.MODERATE]) {
    return RISK_TOLERANCE.MODERATE
  }
  if (risk >= NUMERIC_RISK_TOLERANCE[RISK_TOLERANCE.AGGRESSIVE]) {
    return RISK_TOLERANCE.AGGRESSIVE
  }

  return null
}

function determineRiskToleranceText(risk) {
  if (risk >= 3) {
    return 'data.riskAssessment.riskTolerance.high'
  }
  if (risk === 2) {
    return 'data.riskAssessment.riskTolerance.medium'
  }
  if (risk <= 1) {
    return 'data.riskAssessment.riskTolerance.low'
  }

  return 'data.riskAssessment.riskTolerance.unknown'
}

export default {
  defineRiskTolerance,
  determineRiskToleranceText,
}
