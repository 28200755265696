import React from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'
import toNumber from 'lodash/toNumber'

import BacktestingSummary from 'Components/Blocks/Backtesting/Summary'
import BacktestingChart from 'Components/Blocks/Charts/Assets/Backtesting'
import { AssetsSelectList } from 'Components/Blocks/Portfolio/Assets'
import PortfolioInfoForm from 'Components/Blocks/Portfolio/Info/Form'
import PortfolioRebalanceForm from 'Components/Blocks/Portfolio/Rebalance/Form'
import { Button, Column, Loader, Row, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { theme } from 'Theme'

import { STEPS } from './constants'
import { BuildContainer, CheckIcon, Line, Separator, StepIcon } from './styles'

function Build({
  amount,
  backtestResult,
  step,
  isBacktestingLoading,
  isCreateLoading,
  onHandleChangeInfo,
  onMountHandleInfo,
  onAssetsChange,
  onAssetsMount,
  onRebalanceMount,
  onSubmitBacktest,
  onCreatePortfolio,
  onContinue,
  onBack,
}) {
  const s = useScopedI18n('portfolio.create')

  return (
    <BuildContainer>
      <Row>
        <Text fontWeight={2} heading2>
          {s('buildTitle')}
        </Text>

        <Row center gap={4} ml="40px">
          <StepIcon passed={step === STEPS.BUILD}>
            {step === STEPS.BUILD ? (
              <CheckIcon height={24} viewBox="0 0 24 24" width={24} />
            ) : (
              1
            )}
          </StepIcon>
          <Line />
          <StepIcon secondary={step === STEPS.INFO}>2</StepIcon>
        </Row>
      </Row>

      <Row hidden={step !== STEPS.INFO} width={1}>
        <PortfolioInfoForm
          width={1}
          onChange={onHandleChangeInfo}
          onMount={onMountHandleInfo}
        />
      </Row>

      <Column hidden={step !== STEPS.BUILD} width={1}>
        <AssetsSelectList
          amount={toNumber(amount) || 0}
          colors={theme.colors.assets}
          onChange={onAssetsChange}
          onMount={onAssetsMount}
        />

        <Separator mt={2} />

        <Row center mt="40px">
          <PortfolioRebalanceForm width="80%" onMount={onRebalanceMount} />
          <Button
            alignSelf="flex-end"
            disabled={isBacktestingLoading}
            ml="auto"
            outline
            small
            onClick={onSubmitBacktest}
          >
            {s('backtest')} {isBacktestingLoading && <Loader ml={1} />}
          </Button>
        </Row>

        {backtestResult && (
          <>
            <BacktestingSummary
              currency="$"
              data={backtestResult}
              mb="40px"
              mt="40px"
            />
            <BacktestingChart data={backtestResult?.rows} mt={2} />
          </>
        )}
      </Column>

      <Row spaceBetween width={1}>
        {step === STEPS.INFO && (
          <Button width="180px" onClick={onContinue}>
            {s('continue')}
          </Button>
        )}

        {step === STEPS.BUILD && (
          <Row fullWidth gap="20px" spaceBetween>
            <Button outline width="180px" onClick={onBack}>
              {s('back')}
            </Button>

            <Button
              disabled={isCreateLoading}
              width="180px"
              onClick={onCreatePortfolio}
            >
              {s('create')} {isCreateLoading && <Loader ml={1} />}
            </Button>
          </Row>
        )}
      </Row>
    </BuildContainer>
  )
}
Build.defaultProps = {
  amount: null,
  backtestResult: null,
  isBacktestingLoading: false,
  isCreateLoading: false,
  step: null,
  onAssetsChange: noop,
  onAssetsMount: noop,
  onContinue: noop,
  onCreatePortfolio: noop,
  onHandleChangeInfo: noop,
  onMountHandleInfo: noop,
  onRebalanceMount: noop,
  onSubmitBacktest: noop,
  onBack: noop,
}

Build.propTypes = {
  amount: PropTypes.string,
  backtestResult: PropTypes.object,
  isBacktestingLoading: PropTypes.bool,
  isCreateLoading: PropTypes.bool,
  step: PropTypes.string,
  onAssetsChange: PropTypes.func,
  onAssetsMount: PropTypes.func,
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
  onCreatePortfolio: PropTypes.func,
  onHandleChangeInfo: PropTypes.func,
  onMountHandleInfo: PropTypes.func,
  onRebalanceMount: PropTypes.func,
  onSubmitBacktest: PropTypes.func,
}
export default Build
