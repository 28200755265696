import React, { useCallback } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Modal } from 'Components/UI'

import disableTOTPVerificationMutation from 'GraphQL/Mutations/2FA/disableTOTPVerification.graphql'

import _, { useScopedI18n } from 'Services/I18n'

import { FIELD } from './constants'
import FormFields from './FormFields'
import { Content } from './styles'

const FORM_CONSTRAINTS = {
  [FIELD.CODE]: {
    presence: true,
  },
}

function Verify({ isOpen, onFinish, ...rest }) {
  const s = useScopedI18n('modal.2fa.disable')

  const [disableTOTPVerification, { loading }] = useMutation(
    disableTOTPVerificationMutation,
  )

  const handleSubmit = useCallback(
    async values => {
      try {
        await disableTOTPVerification({
          variables: {
            code: values[FIELD.CODE],
          },
        })
        onFinish()
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
      }
    },
    [disableTOTPVerification, onFinish],
  )

  return (
    <Modal {...Modal.pickProps(rest)} isOpen={isOpen} title={s('title')}>
      <Content>
        <Form
          render={props => <FormFields {...props} loading={loading} />}
          validate={values => validate(values, FORM_CONSTRAINTS)}
          onSubmit={handleSubmit}
        />
      </Content>
    </Modal>
  )
}

Verify.defaultProps = {
  ...Modal.defaultProps,
  onFinish: noop,
}

Verify.propTypes = {
  ...Modal.propTypes,
  onFinish: PropTypes.func,
}

export default Verify
