import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { Loader } from 'Components/UI'

import Pagination from './Pagination'
import {
  Body,
  Header,
  HeaderCell,
  RowCell,
  StyledTable,
  TableRow,
} from './styles'

function Table({
  loading,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  contentOffset,
  rowGap,
}) {
  return (
    <StyledTable {...getTableProps()}>
      <Header contentOffset={contentOffset}>
        {map(headerGroups, headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {map(headerGroup.headers, column => (
              <HeaderCell
                {...column.getHeaderProps()}
                right={get(column, 'headerRight')}
              >
                {column.render('Header')}
              </HeaderCell>
            ))}
          </TableRow>
        ))}
      </Header>
      <Body {...getTableBodyProps()} rowGap={rowGap}>
        {loading ? (
          <TableRow>
            <RowCell center colspan="10000" info>
              <Loader />
              Loading...
            </RowCell>
          </TableRow>
        ) : (
          <>
            {isEmpty(page) ? (
              <TableRow>
                <RowCell center colspan="10000" info>
                  No entities found
                </RowCell>
              </TableRow>
            ) : (
              map(page, row => {
                prepareRow(row)

                return (
                  <TableRow {...row.getRowProps()}>
                    {map(row.cells, cell => (
                      <RowCell
                        {...cell.getCellProps()}
                        right={get(cell, ['column', 'cellRight'])}
                      >
                        {cell.render('Cell')}
                      </RowCell>
                    ))}
                  </TableRow>
                )
              })
            )}
          </>
        )}
      </Body>
    </StyledTable>
  )
}

Table.defaultProps = {
  contentOffset: undefined,
  getTableBodyProps: noop,
  getTableProps: noop,
  headerGroups: [],
  loading: false,
  page: [],
  prepareRow: noop,
  rowGap: undefined,
  rowHeight: undefined,
}

Table.propTypes = {
  contentOffset: PropTypes.number,
  getTableBodyProps: PropTypes.func,
  getTableProps: PropTypes.func,
  headerGroups: PropTypes.array,
  loading: PropTypes.bool,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  rowGap: PropTypes.number,
  rowHeight: PropTypes.number,
}

export default Table

export { Pagination }
