import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Select } from 'Components/UI'
import { InputLabels } from 'Components/UI/Forms'

import { STATUS as ORDER_STATUS } from 'Constants/orders'
import { STATE as PORTFOLIO_STATE } from 'Constants/portfolios'
import { KIND } from 'Constants/transactions'
import { STATE as USER_TRANSACTIONS_STATE } from 'Constants/userTransactions'

import { useScopedI18n } from 'Services/I18n'

const ALL = 'all'

function StatusSelect({ kind, option, onChange }) {
  const s = useScopedI18n('transactions.table.filters.status')

  const valueToOption = useCallback(
    value => ({
      label: s(`values.${value}`),
      value,
    }),
    [s],
  )
  const options = useMemo(() => {
    switch (kind.value) {
      case KIND.FUND_PORTFOLIO:
      case KIND.CLOSE_PORTFOLIO: {
        return [
          valueToOption(ALL),
          valueToOption(PORTFOLIO_STATE.INITIAL),
          valueToOption(PORTFOLIO_STATE.FUNDED),
          valueToOption(PORTFOLIO_STATE.FILLED),
          valueToOption(PORTFOLIO_STATE.CLOSING),
          valueToOption(PORTFOLIO_STATE.CLOSED),
        ]
      }

      case KIND.DEPOSIT:
      case KIND.WITHDRAW: {
        return [
          valueToOption(ALL),
          valueToOption(USER_TRANSACTIONS_STATE.PENDING),
          valueToOption(USER_TRANSACTIONS_STATE.APPROVED),
          valueToOption(USER_TRANSACTIONS_STATE.DECLINED),
          valueToOption(USER_TRANSACTIONS_STATE.CANCELED),
        ]
      }

      default:
        return [
          valueToOption(ALL),
          valueToOption(ORDER_STATUS.Open),
          valueToOption(ORDER_STATUS.Closed),
          valueToOption(ORDER_STATUS.Rejected),
          valueToOption(ORDER_STATUS.Canceled),
        ]
    }
  }, [kind, valueToOption])

  return (
    <InputLabels title={s('label')} width="160px">
      <Select options={options} value={option} onChange={onChange} />
    </InputLabels>
  )
}

StatusSelect.defaultProps = {
  kind: null,
  option: null,
  onChange: noop,
}

StatusSelect.propTypes = {
  kind: PropTypes.object,
  option: PropTypes.object,
  onChange: PropTypes.func,
}

export default StatusSelect
