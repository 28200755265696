import { useMemo } from 'react'

import { DateTime } from 'luxon'

import { useScopedI18n } from 'Services/I18n'

export function useColumns() {
  const s = useScopedI18n('blocks.adminTransactions.table')

  return useMemo(
    () => [
      {
        Header: s('rows.amount'),
        Cell: ({ row }) =>
          `${row?.original?.amount} ${row?.original?.currency}`,
        width: 2,
      },
      {
        Header: s('rows.fee'),
        Cell: ({ row }) => `${row?.original?.fee} ${row?.original?.currency}`,
        width: 2,
      },
      {
        Header: s('rows.kind'),
        accessor: 'kind',
        width: 2,
      },
      {
        Header: s('rows.paymentMethod'),
        accessor: 'userTransaction',
        Cell: ({ value }) => (value ? `${value?.paymentMethod}` : s('unknown')),
        width: 2,
      },
      {
        Header: s('rows.userName'),
        accessor: 'user',
        Cell: ({ value }) =>
          `${value.profile?.firstName} ${value.profile?.lastName}`,
        width: 3,
      },
      {
        Header: s('rows.createdAt'),
        Cell: ({ value }) =>
          value
            ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
            : '—',
        accessor: 'createdAt',
        width: 2,
      },
    ],
    [s],
  )
}
