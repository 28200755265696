import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import arrowDownGlyph from 'iconoir/icons/nav-arrow-down.svg'
import arrowUpGlyph from 'iconoir/icons/nav-arrow-up.svg'

export const Content = styled.div`
  width: 100%;
  .table {
    padding-bottom: 10px;
    width: 100%;
    .header {
      padding: 10px 0;
      border-radius: 6px 6px 0 0;
      margin-bottom: 12px;
      .row {
        padding-bottom: 10px;
      }
    }
    .row {
      border-bottom: 1px solid ${themeGet('colors.divider.light')};
    }
  }
`

export const ArrowUpIcon = styled(arrowUpGlyph)``

export const ArrowDownIcon = styled(arrowDownGlyph)``
