import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { FiArrowDown, FiArrowUp } from 'react-icons/fi'

import numeral from 'numeral'

import get from 'lodash/get'

import { TooltipContent } from 'Components/Blocks/Charts/styles'
import { Row } from 'Components/UI'

import { PRIMARY_CURRENCY } from 'Constants/currencies'

import { theme } from 'Theme'

import Utils from 'Utils'

import { Text } from './styles'

const ARROW_SIZE = 12

function Tooltip({ active, currency, payload, percentageKey, valueKey }) {
  const payloadData = get(payload, [0, 'payload'])
  const percentage = get(payloadData, percentageKey)

  const arrow = useMemo(() => {
    if (percentage > 0) {
      return <FiArrowUp color={theme.colors.positive} size={ARROW_SIZE} />
    }
    if (percentage < 0) {
      return <FiArrowDown color={theme.colors.negative} size={ARROW_SIZE} />
    }

    return null
  }, [percentage])

  if (!active) return null

  return (
    <TooltipContent>
      <Text>
        {Utils.Numbers.formatWithSpaces(get(payloadData, valueKey))} {currency}
      </Text>
      <Row center>
        {arrow}
        <Text negative={percentage < 0} positive={percentage > 0}>
          {numeral(percentage).format('0.0[0]%')}
        </Text>
      </Row>
    </TooltipContent>
  )
}

Tooltip.defaultProps = {
  active: false,
  currency: PRIMARY_CURRENCY,
  payload: undefined,
  percentageKey: 'change',
  valueKey: 'value',
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  currency: PropTypes.string,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  percentageKey: PropTypes.string,
  valueKey: PropTypes.string,
}

export default Tooltip
