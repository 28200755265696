import { useApolloClient, useSubscription } from '@apollo/client'

import map from 'lodash/map'

import meQuery from 'GraphQL/Queries/User/me.graphql'
import platformBalanceSubscription from 'GraphQL/Subscriptions/platformBalance.graphql'

import { setIn } from 'Utils/Object'

function Balances() {
  const client = useApolloClient()

  // TODO: move this shit upper, should act like observer
  useSubscription(platformBalanceSubscription, {
    onData({ data }) {
      const balance = data?.data?.platformBalance
      if (!balance) return

      const meStoreQuery = {
        query: meQuery,
      }

      const meData = client.readQuery(meStoreQuery)
      if (!meData) return

      const nextBalances = meData?.me?.balances || []

      client.writeQuery({
        ...meStoreQuery,
        data: setIn(
          meData,
          ['me', 'balances'],
          map(nextBalances, item =>
            item.currency !== balance.currency ? item : balance,
          ),
        ),
      })
    },
  })

  return null
}

export default Balances
