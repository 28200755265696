import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { FiEdit3, FiTrash } from 'react-icons/fi'

import { Button, Flex, Popover } from 'Components/UI'

import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const { onDelete, onEdit } = useTableContext()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const group = row?.original || {}

  const handleDelete = useCallback(() => {
    onDelete(group)
  }, [onDelete, group])

  const handleEdit = useCallback(() => {
    onEdit(group)
  }, [onEdit, group])

  return (
    <Flex>
      <Popover content="Edit template" delay={Popover.delay} showArrow>
        <Button icon outline secondary small squared onClick={handleEdit}>
          <FiEdit3 />
        </Button>
      </Popover>
      <Popover content="Delete template" delay={Popover.delay} showArrow>
        <Button
          icon
          ml={1}
          outline
          secondary
          small
          squared
          onClick={handleDelete}
        >
          <FiTrash />
        </Button>
      </Popover>
    </Flex>
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
