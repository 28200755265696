import keyBy from 'lodash/keyBy'

export const EXCHANGE = {
  BINANCE: {
    id: 'binance',
    name: 'Binance',
    apiHowTo: 'https://www.binance.com/en/support/articles/360002502072',
  },
  BITFINEX: {
    id: 'bitfinex',
    name: 'Bitfinex',
    apiHowTo:
      'https://support.bitfinex.com/hc/en-us/articles/115002349625-API-Key-Setup-Login',
  },
  BITMEX: {
    id: 'bitmex',
    name: 'BitMEX',
    apiHowTo:
      'https://help.3commas.io/en/articles/3108975-bitmex-how-to-create-api-keys',
  },
  BITSTAMP: {
    id: 'bitstamp',
    name: 'Bitstamp',
    apiHowTo:
      'https://helpdesk.bitsgap.com/en/articles/3608286-how-to-create-and-setup-bitstamp-api-key',
    additionalCredential: 'uid',
  },
  BITTREX: {
    id: 'bittrex',
    name: 'Bittrex',
    apiHowTo:
      'https://bittrex.zendesk.com/hc/en-us/articles/360031921872-How-to-create-an-API-key-',
  },
  BYBIT: {
    id: 'bybit',
    name: 'Bybit',
    apiHowTo:
      'https://help.bybit.com/hc/en-us/articles/360039749613-How-to-create-a-new-API-key-',
  },
  COINBASE: {
    id: 'coinbase',
    name: 'Coinbase Pro',
    apiHowTo:
      'https://help.coinbase.com/en/pro/other-topics/api/how-do-i-create-an-api-key-for-coinbase-pro',
    additionalCredential: 'password',
  },
  OKEX: {
    id: 'okex',
    name: 'OKEx',
    apiHowTo:
      'https://help.3commas.io/en/articles/3109057-okex-how-to-create-api-keys',
    additionalCredential: 'password',
  },
  KRAKEN: {
    id: 'kraken',
    name: 'Kraken',
    apiHowTo:
      'https://support.kraken.com/hc/en-us/articles/360000919966-How-to-generate-an-API-key-pair-',
  },
}

export const EXCHANGE_BY_ID = keyBy(EXCHANGE, 'id')
