import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import arrowLeftGlyph from 'iconoir/icons/arrow-left.svg'
import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'
import plusGlyph from 'iconoir/icons/plus.svg'
import starGlyph from 'iconoir/icons/star.svg'

export const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  border-radius: 12px;
  background: ${themeGet('colors.white')};
  width: 670px;

  ${margin}
`

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary50')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StarIcon = styled(starGlyph)`
  color: white;
`

export const PlusIcon = styled(plusGlyph)`
  color: white;
`

export const FieldDescription = styled.div`
  display: flex;
  padding: 16px 24px 24px 24px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 0 0 12px 12px;
  background: ${themeGet('colors.cultured')};
`

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`

export const ArrowLeftIcon = styled(arrowLeftGlyph)`
  margin-right: 8px;
`
