import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { useTheme } from 'styled-components'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import toNumber from 'lodash/toNumber'

import { Column, Text } from 'Components/UI'

import { KIND } from 'Constants/userTransactions'

import addUserTransactionMutation from 'GraphQL/Mutations/UserTransactions/addUserTransaction.graphql'
import UserTransactions from 'GraphQL/Updaters/UserTransactions'

import { useAppContext } from 'Hooks'

import { FIELD } from 'Pages/App/Withdrawal/Crypto/constants'

import { APP_ROOT } from 'Router/routes'

import _, { useScopedI18n } from 'Services/I18n'

import FormFields from './FormFields'
import { Card } from './styles'

function CryptoWithdraw() {
  const theme = useTheme()
  const s = useScopedI18n('withdrawal.crypto')
  const { me } = useAppContext()
  const history = useHistory()

  const [isLoading, setLoading] = useState(false)

  const [addUserTransaction] = useMutation(addUserTransactionMutation)

  const platformBalance = useMemo(() => me?.balances?.[0]?.amount || 0, [me])

  const handleValidate = useCallback(
    values => {
      const constraints = {
        [FIELD.NETWORK]: {
          presence: true,
        },
        [FIELD.ADDRESS]: {
          presence: true,
        },
        [FIELD.AMOUNT]: {
          presence: true,
          numericality: {
            greaterThan: 0,
            lessThanOrEqualTo: platformBalance,
          },
        },
      }

      if (me?.verificationTOTPEnabled)
        constraints[FIELD.CODE] = {
          presence: true,
        }

      return validate(values, constraints)
    },
    [platformBalance, me],
  )

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        const variables = {
          amount: toNumber(values[FIELD.AMOUNT]),
          address: values[FIELD.ADDRESS],
          kind: KIND.WITHDRAWAL,
          currency: values[FIELD.CURRENCY].value,
          cryptoNetwork: values[FIELD.NETWORK].value,
        }

        if (me?.verificationTOTPEnabled)
          variables.verificationCode = values[FIELD.CODE]

        await addUserTransaction({
          variables,
          update: UserTransactions.add,
        })
        toast.success(s('successMessage'))
        history.push(APP_ROOT)
      } catch (error) {
        toast.error(error?.message || _('error.generic'))
      } finally {
        setLoading(false)
      }
    },
    [addUserTransaction, s, history, me],
  )

  return (
    <Column center flexGrow={1} fullWidth pt="60px">
      <Text as="h2" fontWeight={2} heading2>
        {s('title')}
      </Text>

      <Text color={theme.colors.text70} fontWeight={2} heading5 mt={4}>
        {s('subTitle')}
      </Text>

      <Card mt={6}>
        <Form
          render={props => (
            <FormFields
              {...props}
              balance={platformBalance}
              loading={isLoading}
            />
          )}
          validate={handleValidate}
          onSubmit={submit}
        />
      </Card>
    </Column>
  )
}

export default CryptoWithdraw
