import { OnboardingLayout } from 'Components/Layout'

import Onboarding from 'Pages/Onboarding'

import * as ROUTES from './routes'

export const ONBOARDING_ROUTES = {
  component: OnboardingLayout,
  path: ROUTES.ONBOARDING_ROOT,
  routes: [
    {
      component: Onboarding.Plan,
      path: ROUTES.ONBOARDING_ROOT,
      exact: true,
    },
    {
      component: Onboarding.RiskAssessment,
      path: ROUTES.ONBOARDING_RISK_ASSESSMENT,
      exact: true,
    },
    {
      component: Onboarding.Portfolio,
      path: ROUTES.ONBOARDING_PORTFOLIO,
      exact: true,
    },
    {
      component: Onboarding.KYC,
      path: ROUTES.ONBOARDING_KYC,
      exact: true,
    },
  ],
}
