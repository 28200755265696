import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import capitalize from 'lodash/capitalize'
import includes from 'lodash/includes'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

import { Row, Text } from 'Components/UI'

import { RISK_ASSESSMENT, RISK_TOLERANCE } from 'Constants/ids'

import _, { useScopedI18n } from 'Services/I18n'

import Utils from 'Utils'

import { Container } from './styles'

function RiskAssessment({ user, ...rest }) {
  const s = useScopedI18n('admin.modal.profileInfo')

  const renderTotalValues = useCallback(value => {
    if (value.from && value.to) {
      return `$${value.from} — $${value.to}`
    }

    if (value.from) {
      return `${_('general.over')} ${value.from}`
    }

    return `${_('general.under')} $${value.to}`
  }, [])

  const renderItemValue = useCallback(
    key => {
      const riskAssessment = user?.profile?.riskAssessment
      const kycData = user?.profile?.kycData

      const riskAssessmentValue = (riskAssessment || {})[key]
      const kycValue = (kycData || {})[key]

      if (
        isNil(riskAssessmentValue) &&
        key !== RISK_ASSESSMENT.RISK_TOLERANCE &&
        isNil(kycValue)
      )
        return '—'

      if (key === RISK_ASSESSMENT.INVESTING_EXPERIENCE) {
        return _(Utils.Data.investingExperienceText(riskAssessmentValue))
      }

      if (key === RISK_ASSESSMENT.RISK_TOLERANCE) {
        return _(
          Utils.Data.riskToleranceText(
            riskAssessmentValue || RISK_TOLERANCE.CONSERVATIVE,
          ),
        )
      }

      if (key === RISK_ASSESSMENT.INVESTMENT_HORIZON) {
        return _(Utils.Data.investmentHorizonText(riskAssessmentValue))
      }

      if (
        includes(
          [RISK_ASSESSMENT.TOTAL_INVESTMENTS, RISK_ASSESSMENT.TOTAL_NET_WORTH],
          key,
        )
      ) {
        return renderTotalValues(riskAssessmentValue)
      }

      if (RISK_ASSESSMENT.NET_MONTHLY_INCOME === key) {
        return renderTotalValues(kycValue)
      }

      if (key === RISK_ASSESSMENT.TAX_RESIDENCE) {
        return kycValue
      }

      if (
        key === RISK_ASSESSMENT.US_CITIZEN ||
        key === RISK_ASSESSMENT.INDUSTRIES
      ) {
        return _(kycValue ? 'data.kyc.yes' : 'data.kyc.no')
      }

      if (key === RISK_ASSESSMENT.PROFESSION) {
        return capitalize(kycValue)
      }

      if (key === RISK_ASSESSMENT.FUND_SOURCE) {
        if (!kycValue?.length) {
          return _('data.kyc.fundSource.unknown')
        }

        const fundSourcesString = sortBy(kycValue, fundSource =>
          fundSource === 'other' ? 1 : 0,
        )
          .map(fundSource => _(Utils.Data.fundSourceText(fundSource)))
          .join(', ')
        return fundSourcesString
      }

      return '-'
    },
    [user, renderTotalValues],
  )

  return (
    <Container {...pick(rest)}>
      <Text fontWeight={600}>{s('riskAssessment')}:</Text>
      {map(RISK_ASSESSMENT, key => (
        <Row fullWidth key={key} spaceBetween>
          <Text>{_(Utils.UserProfiles.riskAssessmentTitle(key))}</Text>
          <Text maxWidth="280px" preWrap textAlign="end">
            {renderItemValue(key)}
          </Text>
        </Row>
      ))}
    </Container>
  )
}

RiskAssessment.defaultProps = {
  user: {},
}

RiskAssessment.propTypes = {
  user: PropTypes.object, // TODO: shape
}

export default RiskAssessment
