import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import { Button, Popover, Row, Text } from 'Components/UI'

import { STATE as KYC_STATE } from 'Constants/kyc'

import { useAppContext } from 'Hooks'

import { ADD_NET_WORTH, CREATE_PORTFOLIO_SELECTION, KYC } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import {
  ArrowRightIcon,
  BarChartIcon,
  Container,
  IconContainer,
  MoneyIcon,
  SettingsUserIcon,
} from './styles'

const VARIANT = {
  INVEST: 'invest',
  CONNECT: 'connect',
  MARKET: 'market',
}

function Action({ variant }) {
  const s = useScopedI18n('dashboard.action')
  const theme = useTheme()
  const history = useHistory()
  const { me } = useAppContext()

  const isKYCApproved = me?.kyc?.state === KYC_STATE.APPROVED

  const data = useMemo(() => {
    switch (variant) {
      case VARIANT.INVEST:
        return {
          icon: <MoneyIcon />,
          title: s('invest'),
          description: s('startBuilding'),
          button: s('investNow'),
          restricted: !isKYCApproved,
          restrictedMessage: s('investRestricted'),
          onClick: () => {
            if (!isKYCApproved) {
              history.push(KYC)
              return
            }

            history.push(CREATE_PORTFOLIO_SELECTION)
          },
        }
      case VARIANT.CONNECT:
        return {
          icon: <SettingsUserIcon />,
          title: s('connectYourAccounts'),
          description: s('connectingAll'),
          button: s('connect'),
          onClick: () => {
            history.push(ADD_NET_WORTH)
          },
        }
      case VARIANT.MARKET:
        return {
          icon: <BarChartIcon />,
          title: s('markets'),
          description: s('exploreInvestment'),
          button: s('explore'),
        }
      default:
        return null
    }
  }, [variant, s, history, isKYCApproved])

  return (
    <Container variant={variant}>
      <IconContainer>{data.icon}</IconContainer>
      <Text color={theme.colors.white} fontWeight={2} heading4 mt="6px">
        {data.title}
      </Text>
      <Text color={theme.colors.white} extraSmall mt={2}>
        {data.description}
      </Text>

      <Row>
        <Popover
          content={data?.restricted ? data?.restrictedMessage : null}
          offset={[10, 10]}
          showArrow
        >
          <Button extraSmall mt="20px" secondary onClick={data?.onClick}>
            {data.button}{' '}
            <ArrowRightIcon height={14} viewBox="0 0 24 24" width={14} />
          </Button>
        </Popover>
      </Row>
    </Container>
  )
}

Action.propTypes = {
  variant: PropTypes.oneOf(['invest', 'connect', 'market']).isRequired,
}
export default Action
