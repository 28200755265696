export const AUTH_STATE = 'auth'
export const AUTH_ACTIONS = {
  SET: `${AUTH_STATE}/set`,
  CLEAR: `${AUTH_STATE}/clear`,
}

export const I18N_STATE = 'i18n'
export const I18N_ACTIONS = {
  SET: `${I18N_STATE}/set`,
}

export const DASHBOARD_STATE = 'dashboard'
export const DASHBOARD_ACTIONS = {
  SET: `${DASHBOARD_STATE}/set`,
}

export const EXPERT_PORTFOLIO_STATE = 'expertPortfolio'
export const EXPERT_PORTFOLIO_ACTIONS = {
  SET: `${EXPERT_PORTFOLIO_STATE}/set`,
}

export const PORTFOLIO_TIPS_STATE = 'portfolioTips'
export const PORTFOLIO_TIPS_ACTIONS = {
  SET: `${PORTFOLIO_TIPS_STATE}/set`,
}

export const SIDEBAR_STATE = 'sidebar'
export const SIDEBAR_ACTIONS = {
  SET: `${SIDEBAR_STATE}/set`,
}
