
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserPortfolioFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserPortfolio"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"currency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"state"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rebalanceType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rebalanceValue"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"assets"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserPortfolioAssetFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"portfolioTemplate"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tip"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":302}};
    doc.loc.source = {"body":"#import \"GraphQL/Fragments/UserPortfolioAsset.graphql\"\n\nfragment UserPortfolioFields on UserPortfolio {\n  id\n  name\n  amount\n  currency\n  state\n  rebalanceType\n  rebalanceValue\n  createdAt\n  updatedAt\n\n  assets {\n    ...UserPortfolioAssetFields\n  }\n\n  portfolioTemplate {\n    id\n    name\n    tip\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("GraphQL/Fragments/UserPortfolioAsset.graphql").definitions));


      module.exports = doc;
    
