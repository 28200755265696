import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import checkGlyph from 'iconoir/icons/check.svg'
import mailGlyph from 'iconoir/icons/mail.svg'
import sendMailGlyph from 'iconoir/icons/send-mail.svg'

export const Row = styled.div`
  display: flex;

  ${margin}
`

export const ControlsRow = styled(Row)`
  justify-content: flex-end;
  width: 100%;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${margin};
`

export const Text = styled.div`
  max-width: 420px;
  font-size: 14px;
  color: ${themeGet('colors.font.primary')};
  flex-shrink: 0;

  ${margin};
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

export const Card = styled.div`
  width: 600px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  background: white;
`

export const Title = styled.h3`
  font-size: 24px;
  font-style: normal;
  font-weight: ${themeGet('fontWeights.2')};
  line-height: 32px;
  color: ${themeGet('colors.font.primary')};
  margin: 0;
`

export const SubText = styled.div`
  font-size: ${themeGet('fontSizes.1')}px;
  color: ${themeGet('colors.font.secondary')};
  line-height: 18px;

  span {
    color: ${themeGet('colors.font.primary')};
    font-weight: ${themeGet('fontWeights.1')};
  }

  ${margin};
`

export const MailIcon = styled(mailGlyph)`
  margin-left: 12px;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  width: 60px;
  height: 60px;
  background-color: ${themeGet('colors.primary10')};
`

export const SendMailIcon = styled(sendMailGlyph)`
  color: ${themeGet('colors.bg.secondary')};
`

export const CheckIcon = styled(checkGlyph)`
  color: ${themeGet('colors.bg.secondary')};
`

export const OtpInputWrapper = styled.div`
  > div {
    gap: 8px;
  }

  ${margin};
`

export const OtpCodeInput = styled.input`
  width: 48px !important;
  height: 48px !important;
  border-radius: 8px;
  border: 1px solid #d7d9e4;
  background: ${themeGet('colors.white')};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2) inset;
  font-size: 24px;

  :focus-visible {
    outline: ${themeGet('inputs.focus.borderColor.default')} auto 1px;
  }
`
