import React from 'react'

import styled from 'styled-components'

import { Column, Loader } from 'Components/UI'

import _ from 'Services/I18n'

const Container = styled(Column)`
  width: 100vw;
  height: 100vh;
`

function FullPageLoader() {
  return (
    <Container center justifyCenter>
      <Loader />
      <span>{_('general.loading')}...</span>
    </Container>
  )
}

export default FullPageLoader
