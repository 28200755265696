import React, { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

import { Button, Text } from 'Components/UI'

import { PORTFOLIO } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import { FUNDING_SUCCESS_KIND } from './contants'
import {
  ArrowRightIcon,
  CheckIcon,
  Container,
  Content,
  IconWrapper,
} from './styles'

const Success = () => {
  const location = useLocation()
  const history = useHistory()
  const s = useScopedI18n('funding.success')

  const fundingSuccessKind = location?.state?.fundingSuccessKind

  const handleAction = useCallback(() => {
    switch (fundingSuccessKind) {
      case FUNDING_SUCCESS_KIND.EXPERT_PORTFOLIO: {
        history.push(PORTFOLIO)
        break
      }
      default:
        history.push(PORTFOLIO)
        break
    }
  }, [fundingSuccessKind, history])

  const subTitle = useMemo(() => {
    const subTitles = {
      [FUNDING_SUCCESS_KIND.EXPERT_PORTFOLIO]: s('expertPortfolioTitle'),
      [FUNDING_SUCCESS_KIND.PORTFOLIO]: s('expertPortfolioTitle'),
    }

    return subTitles[fundingSuccessKind]
  }, [s, fundingSuccessKind])

  return (
    <Container>
      <Content>
        <IconWrapper>
          <CheckIcon height={40} viewBox="0 0 24 24" width={40} />
        </IconWrapper>

        <Text as="h4" fontWeight={2} heading4>
          {s('title')}!
        </Text>

        <Text as="h6" fontWeight={1} heading6 textAlign="center">
          {subTitle}
        </Text>

        <Button onClick={handleAction}>
          {s('action')}
          <ArrowRightIcon height={16} viewBox="0 0 24 24" width={16} />
        </Button>
      </Content>
    </Container>
  )
}

export default Success
