import styled from 'styled-components'
import { margin } from 'styled-system'

import copyGlyph from 'iconoir/icons/copy.svg'
import linkGlyph from 'iconoir/icons/link.svg'

export const TableContainer = styled.div`
  overflow: auto;

  ${margin}

  & > .table {
    min-width: 100% !important;
  }
`

export const CopyIcon = styled(copyGlyph)``
export const LinkIcon = styled(linkGlyph)``
