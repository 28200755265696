import styled from 'styled-components'
import { position } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import copyGlyph from 'iconoir/icons/copy.svg'
import warningTriangleGlyph from 'iconoir/icons/warning-triangle.svg'

import { tetherIconGlyph } from 'Assets/Svg'

export const WarningTriangleIcon = styled(warningTriangleGlyph)`
  color: ${themeGet('colors.warningSecondaryPrimary')};
  margin-right: 12px;
`

export const CopyIcon = styled(copyGlyph)`
  color: ${themeGet('colors.black')};
`

export const TetherIcon = styled(tetherIconGlyph)`
  margin-right: ${themeGet('space.2')}px;
`

export const GroupHeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
  text-transform: none;
  padding: ${themeGet('space.3')}px;
  border-radius: 8px;
  background-color: ${themeGet('colors.warningSecondaryBackground')};
`

export const CopyAddressContainer = styled.div`
  position: absolute;
  display: flex;
  cursor: pointer;

  ${position}
`
