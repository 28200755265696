import { FiArrowDown, FiArrowUp } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

const ARROW_SIZE = 12

export const StyledTable = styled.div`
  width: 100%;

  ${margin}
`

const cellCenterCss = ({ center }) =>
  center &&
  css`
    justify-content: center;
  `

const cellRightCss = ({ right }) =>
  right &&
  css`
    justify-content: flex-end;
  `

const headerContentOffsetCss = ({ contentOffset }) =>
  contentOffset &&
  css`
    margin-bottom: ${contentOffset}px;
  `

export const Header = styled.div`
  display: flex;
  align-items: center;

  ${headerContentOffsetCss}
`

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  ${Styles.text()};
  font-weight: ${themeGet('fontSizes.0')}px;
  color: ${themeGet('colors.text70')};
  user-select: none;

  ${cellRightCss}
  ${cellCenterCss}
`

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${themeGet('colors.text20')};

  ${layout.height}
`

export const RowCell = styled.div`
  display: flex;
  align-items: center;
  font-size: ${themeGet('fontSizes.0')}px;

  ${cellRightCss}
  ${cellCenterCss}
`

const bodyRowGapCss = ({ rowGap }) =>
  rowGap &&
  css`
    > ${TableRow}:not(:first-child) {
      margin-top: ${rowGap}px;
    }
  `

export const Body = styled.div`
  ${bodyRowGapCss}
`

export const ArrowAsc = styled(FiArrowUp).attrs({
  size: ARROW_SIZE,
})`
  color: ${themeGet('colors.positive')};

  ${margin}
`

export const ArrowDesc = styled(FiArrowDown).attrs({
  size: ARROW_SIZE,
})`
  color: ${themeGet('colors.negative')};

  ${margin}
`
