import forEach from 'lodash/forEach'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'

import { CRYPTO_TOKEN_KIND } from 'Constants/cryptoTokens'

import { ASSETS_GROUP } from 'Pages/App/Assets/constants'

function calculatePercentage(amount, totalAmount) {
  return (amount / totalAmount) * 100
}

export function populateAssets(assets, tickers) {
  const result = []

  forEach(assets, asset => {
    const tickerBase = asset.currency
    const ticker = get(tickers, tickerBase)

    result.push({
      base: ticker?.base,
      baseName: ticker?.baseName,
      last24hPercentage: ticker?.perecentage,
      currency: asset.currency,
      cost: asset.cost,
      amount: asset.amount,
      cryptoTokenKind: asset.cryptoTokenKind,
    })
  })

  return result
}

function groupAssets(assets) {
  const groupedByKind = groupBy(assets, asset => asset.cryptoTokenKind)

  return {
    [ASSETS_GROUP.STABLE_COINS]:
      groupedByKind[CRYPTO_TOKEN_KIND.STABLE_COIN] || [],
    [ASSETS_GROUP.CRYPTO_CURRENCIES]:
      groupedByKind[CRYPTO_TOKEN_KIND.COIN] || [],
    [ASSETS_GROUP.ALT_COINS]: [
      ...(groupedByKind[CRYPTO_TOKEN_KIND.ALT_COIN] || []),
      ...(groupedByKind?.null || []),
    ],
  }
}

export function assetsByGroup(assets) {
  const result = {
    [ASSETS_GROUP.STABLE_COINS]: [],
    [ASSETS_GROUP.CRYPTO_CURRENCIES]: [],
    [ASSETS_GROUP.ALT_COINS]: [],
  }
  const groupedAssets = groupAssets(assets)

  forEach(groupedAssets, (items, key) => {
    const groupTotalCost = sumBy(items, item => item.cost)

    forEach(items, item => {
      result[key].push({
        ...item,
        weight: calculatePercentage(item.cost, groupTotalCost),
      })
    })

    result[key] = orderBy(result[key], ['weight'], ['desc'])
  })

  return result
}
