import styled from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'

import Styles from 'Components/Styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;

  ${margin}
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  > div {
    padding: 0;
  }

  ${margin}
  ${layout.maxHeight}
`

export const Total = styled.div`
  font-weight: 600;

  ${margin};
  ${Styles.text()};
  ${Styles.conditional.text.negative};
  ${Styles.conditional.text.positive};
`
