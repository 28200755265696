import styled, { css } from 'styled-components'
import background from '@styled-system/background'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { portfolioLogoGlyph } from 'Assets/Svg'

import { gap } from 'Theme/system'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${themeGet('colors.bg.main')};
  box-shadow: 0 6px 18px 0 rgba(112, 220, 220, 0.07);
  padding: 20px 8px;
  width: 300px;
  flex-shrink: 0;
  overflow: auto;

  ${margin};
`

export const TabsContainer = styled.div`
  display: flex;
  width: 100%;

  ${margin}
  ${gap}
`

const tabActiveCss = ({ active }) =>
  active &&
  css`
    color: ${themeGet('colors.text100')};
    border-bottom-color: ${themeGet('colors.primary50')};
  `

export const Tab = styled.button`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: ${themeGet('space.3')}px 0;
  border-bottom: 2px solid transparent;
  flex-basis: 33%;
  color: ${themeGet('colors.text70')};
  display: flex;
  justify-content: center;
  cursor: pointer;

  :hover {
    color: ${themeGet('colors.text100')};
    border-bottom-color: ${themeGet('colors.primary50')};
  }

  ${tabActiveCss}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${margin};
`

const menuItemActiveCss = ({ active }) =>
  active &&
  css`
    background: ${themeGet('colors.primary10')};
  `

export const MenuItem = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  width: 100%;
  border-radius: 12px;
  cursor: pointer;

  :hover {
    background: ${themeGet('colors.primary10')};
  }

  ${menuItemActiveCss};
`

export const MenuIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  background-color: #fafafa;
  padding-left: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 6px;
  flex-shrink: 0;
  overflow: hidden;
`

export const ColorBlock = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  width: 3px;

  ${background};
`

export const LogoIcon = styled(portfolioLogoGlyph)``

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${themeGet('colors.divider.light')};

  ${margin}
`

export const MenuSection = styled.div`
  display: flex;
  flex-direction: column;

  :after {
    content: '';
    width: calc(100% - 26px);
    height: 1px;
    margin: ${themeGet('space.4')}px auto;
    background-color: ${themeGet('colors.divider.light')};
  }

  :last-of-type {
    :after {
      background-color: transparent;
    }
  }
`

export const LogoImage = styled.img`
  max-width: 40px;
  height: 40px;
  object-fit: contain;
`
