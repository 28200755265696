import styled from 'styled-components'

import Styles from 'Components/Styles'

export const Container = styled.div`
  ${Styles.flexCenter};
  flex-direction: column;
  height: 100%;
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 384px;
`
