import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Link, Popover, Row } from 'Components/UI'

import { SIGN_OUT } from 'Router/routes'

import { MenuIcon } from './styles'

function Header({ height }) {
  return (
    <Row borderBottom center height={height} justifyEnd noShrink px={3}>
      <Popover
        content={<Link to={SIGN_OUT}>Logout</Link>}
        interactive
        trigger="click"
      >
        <Flex>
          <MenuIcon height={24} viewBox="0 0 24 24" width={24} />
        </Flex>
      </Popover>
    </Row>
  )
}

Header.defaultProps = {
  height: 48,
}

Header.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Header
