import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { FiEdit3, FiTrash } from 'react-icons/fi'

import { Button, Popover, Row } from 'Components/UI'

import { useTableContext } from '../context'

function ActionsCell({ row }) {
  const { onEditRow, onDeleteRow } = useTableContext()

  const token = row?.original

  const handleEdit = useCallback(() => {
    onEditRow(token)
  }, [onEditRow, token])

  const handleDelete = useCallback(
    () => {
      onDeleteRow(token)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [row],
  )

  return (
    <Row>
      <>
        <Popover content="Edit token" delay={Popover.delay} showArrow>
          <Button
            icon
            ml={1}
            outline
            secondary
            small
            squared
            onClick={handleEdit}
          >
            <FiEdit3 />
          </Button>
        </Popover>
        <Popover content="Delete token" delay={Popover.delay} showArrow>
          <Button
            icon
            ml={1}
            outline
            secondary
            small
            squared
            onClick={handleDelete}
          >
            <FiTrash />
          </Button>
        </Popover>
      </>
    </Row>
  )
}

ActionsCell.defaultProps = {
  row: {},
}

ActionsCell.propTypes = {
  row: PropTypes.object,
}

export default ActionsCell
