import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import { Accessory, Container, StyledInput, StyledTextarea } from './styles'

function Input({
  accessory,
  accessoryWidth,
  autoComplete,
  autoFocus,
  big,
  className,
  disabled,
  error,
  placeholder,
  renderAfter,
  renderBefore,
  type,
  textarea,
  value,
  small,
  onChange,
  onBlur,
  ...rest
}) {
  return (
    <Container
      {...pick(rest)}
      big={big}
      className={className}
      disabled={disabled}
      error={error}
      small={small}
      textarea={textarea}
      onBlur={onBlur}
    >
      {renderBefore}

      {textarea ? (
        <StyledTextarea
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
        />
      ) : (
        <StyledInput
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
        />
      )}

      {renderAfter}

      {accessory && (
        <Accessory error={error} width={accessoryWidth}>
          {accessory}
        </Accessory>
      )}
    </Container>
  )
}

Input.defaultProps = {
  accessory: null,
  accessoryWidth: undefined,
  autoComplete: 'on',
  autoFocus: false,
  big: false,
  className: undefined,
  disabled: false,
  error: false,
  placeholder: '',
  renderAfter: null,
  renderBefore: null,
  textarea: false,
  type: 'text',
  small: false,
  value: undefined,
  onChange: noop,
  onBlur: noop,
}

Input.propTypes = {
  accessory: PropTypes.any,
  accessoryWidth: PropTypes.number,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  big: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  placeholder: PropTypes.string,
  renderAfter: PropTypes.node,
  renderBefore: PropTypes.node,
  small: PropTypes.bool,
  textarea: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

export default Input
