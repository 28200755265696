import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

export const Container = styled.div`
  position: absolute;
  ${Styles.flexCenter};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${themeGet('colors.black')}7f;
`

export const Controls = styled.div`
  ${Styles.flexCenter};
  width: 127px;
  height: 40px;
  background-color: ${themeGet('colors.white')};
  border-radius: 20px;
`

export const Button = styled.div`
  ${Styles.flexCenter};
  width: 30px;
  height: 30px;
  cursor: pointer;

  > svg {
    color: ${themeGet('colors.font.primary')};
  }

  ${margin}
`

export const Line = styled.div`
  height: 32px;
  width: 1px;
  background-color: ${themeGet('colors.border.primary')};

  ${margin}
`
