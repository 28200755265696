export const GENERIC = 'generic'

export const GRAPHQL = {
  AUTH: {
    VERIFICATION_TOKEN_INVALID: 'verificationTokenInvalid',
    VERIFICATION_TOKEN_EXPIRED: 'verificationTokenExpired',
    PASSWORD_REGEX_NO_MATCH: 'passwordRegexNoMatch',
    PASSWORD_TOKEN_INVALID: 'passwordTokenInvalid',
    PASSWORD_TOKEN_EXPIRED: 'passwordTokenExpired',
    EMAIL_ALREADY_VERIFIED: 'emailAlreadyVerified',
    EMAIL_NOT_FOUND: 'emailNotFound',
    USER_ALREADY_AUTHORIZED: 'userAuthorized',
  },
}

export const EXCHANGE = {
  NOT_SUPPORTED: 'notSupported',
  INCORRECT_KEYS: 'incorrectKeys',
}
