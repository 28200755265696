import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFlexLayout, usePagination, useTable } from 'react-table'

import { useLazyQuery } from '@apollo/client'

import get from 'lodash/get'
import map from 'lodash/map'

import { DateRangeInput, Row, Select } from 'Components/UI'
import { Pagination, Table } from 'Components/UI/Admin'
import { InputLabels } from 'Components/UI/Forms'

import { KIND, SIDE, STATUS } from 'Constants/orders'

import adminOrdersQuery from 'GraphQL/Queries/Admin/adminOrders.graphql'

import { useColumns } from './columns'
import { Container, Content } from './styles'

const ALL = 'all'

function valueToOption(value) {
  return {
    label: value,
    value,
  }
}

function buildOptions(values) {
  return map([ALL, ...Object.values(values)], valueToOption)
}

const KIND_OPTIONS = buildOptions(KIND)
const STATUS_OPTIONS = buildOptions(STATUS)
const SIDE_OPTIONS = buildOptions(SIDE)

function OrdersTable() {
  const [kind, setKind] = useState(KIND_OPTIONS[0])
  const [status, setStatus] = useState(STATUS_OPTIONS[0])
  const [side, setSide] = useState(SIDE_OPTIONS[0])
  const [range, setRange] = useState(null)

  const [loadOrders, { data, loading }] = useLazyQuery(adminOrdersQuery)

  const orders = get(data, 'adminOrders') || {}

  const tableData = useMemo(() => get(orders, 'rows') || [], [orders])

  const columns = useColumns()

  const tableProps = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: get(orders, 'pages') || 0,
    },
    usePagination,
    useFlexLayout,
  )

  const {
    state: { pageIndex, pageSize },
  } = tableProps

  const fetchData = useCallback(() => {
    const variables = {
      page: pageIndex,
      limit: pageSize,
      sort: [{ column: 'createdAt', order: 'desc' }],
    }

    if (kind.value !== ALL) {
      variables.kind = [kind.value]
    }

    if (status.value !== ALL) {
      variables.status = [status.value]
    }

    if (side.value !== ALL) {
      variables.side = [side.value]
    }

    if (range) {
      variables.from = range.from.toISO()
      variables.to = range.to.toISO()
    }

    loadOrders({ variables })
  }, [loadOrders, kind, status, side, range, pageIndex, pageSize])

  useEffect(
    () => {
      tableProps.gotoPage(0)
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kind, side, status, range],
  )

  useEffect(
    () => {
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, pageSize],
  )

  return (
    <Container>
      <Content>
        <Row borderBottom mb={2} pb={3}>
          <InputLabels title="Kind" width={160}>
            <Select
              options={KIND_OPTIONS}
              small
              value={kind}
              onChange={setKind}
            />
          </InputLabels>

          <InputLabels ml={3} title="Status" width={160}>
            <Select
              options={STATUS_OPTIONS}
              small
              value={status}
              onChange={setStatus}
            />
          </InputLabels>

          <InputLabels ml={3} title="Side" width={160}>
            <Select
              options={SIDE_OPTIONS}
              small
              value={side}
              onChange={setSide}
            />
          </InputLabels>

          <InputLabels ml={3} title="Dates" width={160}>
            <DateRangeInput
              placeholder="Select date range"
              small
              value={range}
              onChange={setRange}
            />
          </InputLabels>
        </Row>

        <Table {...tableProps} loading={loading} />

        <Row borderTop mt={2} pt={3}>
          <Pagination {...tableProps} total={get(orders, ['count']) || 0} />
        </Row>
      </Content>
    </Container>
  )
}

export default OrdersTable
