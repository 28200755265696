import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import * as CryptoIcons from '@styled-icons/crypto'

import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import upperFirst from 'lodash/upperFirst'

import colors from 'Assets/currency_colors.json'

function CurrencyIcon({ currency, size }) {
  const { Icon, color } = useMemo(
    () => ({
      Icon: CryptoIcons[upperFirst(toLower(currency))] || CryptoIcons.Generic,
      color: colors[toUpper(currency)],
    }),
    [currency],
  )

  return <Icon color={color} size={size} />
}

CurrencyIcon.defaultProps = {
  size: 28,
}

CurrencyIcon.propTypes = {
  currency: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default CurrencyIcon
