import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import EyeGlyph from 'iconoir/icons/eye-empty.svg'
import EyeOffGlyph from 'iconoir/icons/eye-off.svg'

import { Text } from 'Components/UI'

export const CheckboxText = styled(Text)`
  min-height: 16px;
  color: ${themeGet('colors.text70')};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
`

export const TextDescription = styled(Text)`
  color: ${themeGet('colors.text70')};
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  align-items: flex-start;
  border-radius: 12px;
  background: ${themeGet('colors.white')};

  ${margin}
`

export const OtpInputWrapper = styled.div`
  > div {
    gap: 8px;
  }

  ${margin};
`

export const SubTitle = styled(Text)`
  color: #667085;
  font-feature-settings: 'clig' off, 'liga' off;
`

export const IconButton = styled.button`
  cursor: pointer;
  background: transparent;
  margin-right: 12px;
  border: 0;
  display: flex;
  align-items: center;
`

export const EyeIcon = styled(EyeGlyph)``

export const EyeOffIcon = styled(EyeOffGlyph)``

export const TermsLink = styled.a`
  text-decoration: underline;
  font-size: ${themeGet('fontSizes.0')}px;
  font-weight: 400;
  color: ${themeGet('colors.font.secondary')};
`

export const OtpCodeInput = styled.input`
  width: 48px !important;
  height: 48px !important;
  border-radius: 8px;
  border: 1px solid #d7d9e4;
  background: ${themeGet('colors.white')};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2) inset;
  font-size: 24px;

  :focus-visible {
    outline: ${themeGet('inputs.focus.borderColor.default')} auto 1px;
  }
`
