import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFlexLayout, usePagination, useTable } from 'react-table'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useLazyQuery, useMutation } from '@apollo/client'

import debounce from 'lodash/debounce'
import get from 'lodash/get'

import {
  EditEmailModal,
  EditProfileModal,
  EditUserPortfolioGroupModal,
  InviteUserModal,
  ProfileInfoModal,
} from 'Components/Blocks/Admin/Modals'
import { Dialog } from 'Components/Blocks/Modals'
import { Button, Flex, Row } from 'Components/UI'
import { Pagination, Table } from 'Components/UI/Admin'
import { Input, InputLabels } from 'Components/UI/Forms'

import { USER_ROLE } from 'Constants/ids'

import adminCreateApprovedUserKYCMutation from 'GraphQL/Mutations/Admin/adminCreateApprovedUserKYC.graphql'
import adminDropUserMutation from 'GraphQL/Mutations/Admin/adminDropUser.graphql'
import adminUsersQuery from 'GraphQL/Queries/Admin/adminUsers.graphql'

import { useAppContext } from 'Hooks'

import { useColumns } from './columns'
import TableContext from './context'
import { Container, Content } from './styles'

const sort = { column: 'lastLoginAt', order: 'asc' }

function UsersTable() {
  const { me } = useAppContext()

  const [inviteModal, setInviteModal] = useState({ isOpen: false })
  const [editModal, setEditModal] = useState({ isOpen: false, entity: null })
  const [editEmailModal, setEditEmailModal] = useState({
    isOpen: false,
    entity: null,
  })
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    entity: null,
  })
  const [createKYCModal, setCreateKYCModal] = useState({
    isOpen: false,
    entity: null,
  })
  const [userPortfolioGroupModal, setUserPortfolioGroupModal] = useState({
    isOpen: false,
    entity: null,
  })
  const [profileInfoUser, setProfileInfoUser] = useState(null)
  const [search, setSearch] = useState('')

  const [loadUsers, { data, loading, refetch }] = useLazyQuery(adminUsersQuery)
  const [adminDropUser] = useMutation(adminDropUserMutation)
  const [adminCreateApprovedUserKYC] = useMutation(
    adminCreateApprovedUserKYCMutation,
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const users = get(data, 'adminUsers') || {}

  const tableData = useMemo(() => get(users, 'rows') || [], [users])

  const columns = useColumns()

  const tableProps = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: get(users, 'pages') || 0,
    },
    usePagination,
    useFlexLayout,
  )

  const {
    state: { pageIndex, pageSize },
  } = tableProps

  const fetchData = useCallback(() => {
    loadUsers({ variables: { page: pageIndex, limit: pageSize, sort, search } })
  }, [search, pageSize, pageIndex, loadUsers])

  useEffect(
    () => {
      tableProps.gotoPage(0)
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search],
  )

  useEffect(
    () => {
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, pageSize],
  )

  const debouncedSearch = useCallback(
    debounce(value => {
      setSearch(value)
    }, 700),
    [],
  )

  const handleChangeSearch = useCallback(
    ({ target: { value } }) => {
      debouncedSearch(value)
    },
    [debouncedSearch],
  )

  const handleEditRow = useCallback(entity => {
    setEditModal({ isOpen: true, entity: entity?.profile })
  }, [])
  const handleCloseEditModal = useCallback(() => {
    setEditModal({ isOpen: false, entity: null })
  }, [])

  const handleEditEmail = useCallback(
    entity => setEditEmailModal({ isOpen: true, entity }),
    [],
  )
  const handleCloseEditEmailModal = useCallback(
    () => setEditEmailModal({ isOpen: false, entity: null }),
    [],
  )

  const handleDeleteRow = useCallback(entity => {
    setDeleteModal({ isOpen: true, entity })
  }, [])
  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModal({ isOpen: false, entity: null })
  }, [])

  const handleDeleteUser = useCallback(
    async success => {
      if (success) {
        try {
          await adminDropUser({ variables: { id: deleteModal.entity?.id } })
          await refetch()
        } catch (error) {
          toast.error(error.message)
        }
      }
      handleCloseDeleteModal()
    },
    [adminDropUser, deleteModal, handleCloseDeleteModal, refetch],
  )

  const handleInfoRow = useCallback(user => {
    setProfileInfoUser(user)
  }, [])

  const handleEditUserPortfolioGroup = useCallback(user => {
    setUserPortfolioGroupModal({
      isOpen: true,
      entity: user,
    })
  }, [])
  const handleCloseUserPortfolioGroupModal = useCallback(() => {
    setUserPortfolioGroupModal({
      isOpen: false,
      entity: null,
    })
  }, [])

  const handleShowCreateKycModal = useCallback(user => {
    setCreateKYCModal({
      isOpen: true,
      entity: user,
    })
  }, [])

  const handleCloseCreateKycModal = useCallback(() => {
    setCreateKYCModal({ isOpen: false, entity: null })
  }, [])

  const handleCreateApprovedUserKYC = useCallback(
    async success => {
      if (!success) return
      try {
        await adminCreateApprovedUserKYC({
          variables: {
            userId: createKYCModal.entity?.id,
          },
        })
        await refetch()
      } catch (error) {
        toast.error(error.message)
      }
    },
    [adminCreateApprovedUserKYC, refetch, createKYCModal],
  )

  return (
    <TableContext.Provider
      value={{
        onEditRow: handleEditRow,
        onEditEmail: handleEditEmail,
        onDeleteRow: handleDeleteRow,
        onInfoRow: handleInfoRow,
        onUserPortfolioGroupEdit: handleEditUserPortfolioGroup,
        onCreateApprovedUserKYC: handleShowCreateKycModal,
      }}
    >
      <Container>
        <Content>
          <Row borderBottom center mb={2} pb={3} spaceBetween>
            <Flex>
              <InputLabels title="Search" width={200}>
                <Input
                  name="search"
                  placeholder="Search by name..."
                  small
                  width={300}
                  onChange={handleChangeSearch}
                />
              </InputLabels>
            </Flex>

            <Flex>
              {me.role === USER_ROLE.SUPER_ADMIN && (
                <Button
                  outline
                  small
                  onClick={() => setInviteModal({ isOpen: true })}
                >
                  Invite user
                </Button>
              )}
            </Flex>
          </Row>

          <Table {...tableProps} loading={loading} />

          <Row borderTop mt={2} pt={3}>
            <Pagination {...tableProps} total={get(users, ['count']) || 0} />
          </Row>
        </Content>
      </Container>

      <InviteUserModal
        isOpen={inviteModal.isOpen}
        onClose={() => setInviteModal({ isOpen: false })}
      />

      <EditProfileModal
        isOpen={editModal.isOpen}
        profile={editModal.entity}
        onClose={handleCloseEditModal}
      />

      <EditEmailModal
        email={editEmailModal.entity?.email}
        isOpen={editEmailModal.isOpen}
        userId={editEmailModal.entity?.userId}
        onClose={handleCloseEditEmailModal}
        onRefetch={refetch}
      />

      <ProfileInfoModal
        isOpen={!!profileInfoUser}
        user={profileInfoUser}
        onClose={() => setProfileInfoUser(null)}
      />

      <EditUserPortfolioGroupModal
        isOpen={userPortfolioGroupModal.isOpen}
        user={userPortfolioGroupModal.entity}
        onClose={handleCloseUserPortfolioGroupModal}
      />

      <Dialog
        content={`Are you sure you want to delete ${deleteModal.entity?.profile?.firstName} ${deleteModal.entity?.profile?.lastName}`}
        isOpen={deleteModal.isOpen}
        title="Delete user"
        onClose={handleCloseDeleteModal}
        onFinish={handleDeleteUser}
      />

      <Dialog
        content={`Are you sure you want to create approved KYC record for ${createKYCModal.entity?.profile?.firstName} ${createKYCModal.entity?.profile?.lastName}`}
        isOpen={createKYCModal.isOpen}
        title="Create Approved KYC record"
        onClose={handleCloseCreateKycModal}
        onFinish={handleCreateApprovedUserKYC}
      />
    </TableContext.Provider>
  )
}

UsersTable.defaultProps = {
  search: '',
}

UsersTable.propTypes = {
  search: PropTypes.string,
}

export default UsersTable
