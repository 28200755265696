import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import numeral from 'numeral'

import { Column, Row, Text } from 'Components/UI'

import { theme } from 'Theme'

import { ArrowDownIcon, ArrowUpIcon, BalanceValue } from './styles'

function arrow(value) {
  if (value > 0) {
    return (
      <ArrowUpIcon
        color={theme.colors.primary}
        height={14}
        viewBox="0 0 24 24"
        width={14}
      />
    )
  }
  if (value < 0) {
    return (
      <ArrowDownIcon
        color={theme.colors.dangerLight}
        height={14}
        viewBox="0 0 24 24"
        width={14}
      />
    )
  }

  return null
}

function Balance({ amount, gainAmount, gainPercent, currency, ...rest }) {
  return (
    <Row alignEnd gap={5} width={1} {...pick(rest)}>
      <Column>
        <Text fontWeight={2} heading3>
          {numeral(amount).format(`${currency}0,0.00`)}
        </Text>
        <Text fontWeight={1} heading6 mt="5px" textAlign="end">
          Balance
        </Text>
      </Column>

      <Column>
        <BalanceValue negative={gainPercent < 0} positive={gainPercent > 0}>
          {gainAmount > 0 && '+'}
          {numeral(gainAmount).format(`${currency}0,0.00`)}
        </BalanceValue>
        <Text fontWeight={1} heading6 mt="5px" textAlign="end">
          Gain
        </Text>
      </Column>
      <Column>
        <BalanceValue negative={gainPercent < 0} positive={gainPercent > 0}>
          {arrow(gainAmount)} {numeral(gainPercent).format('0.00')}%
        </BalanceValue>
        <Text fontWeight={1} heading6 mt="5px" textAlign="end">
          Return
        </Text>
      </Column>
    </Row>
  )
}

Balance.defaultProps = {
  currency: '$',
}

Balance.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string,
  gainAmount: PropTypes.number.isRequired,
  gainPercent: PropTypes.number.isRequired,
}

export default Balance
