import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, Loader, Modal, Row, Select } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms'

import { USER_ROLE } from 'Constants/ids'

import adminInviteUserMutation from 'GraphQL/Mutations/Admin/adminInviteUser.graphql'

import _, { useScopedI18n } from 'Services/I18n'

import { Content, Subtitle } from './styles'

const FIELDS = {
  EMAIL: 'email',
  ROLE: 'role',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
}

function InviteUserModal({ ...rest }) {
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const s = useScopedI18n('admin.modal.inviteUserModal')

  const [adminInviteUser] = useMutation(adminInviteUserMutation)

  const userRolesOptions = useMemo(
    () =>
      Object.values(USER_ROLE).map(role => ({
        value: role,
        label: _(`userRoles.${role}`),
      })),
    [],
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.EMAIL]: {
        presence: {
          presence: true,
          message: `^${s('emailRequired')}`,
        },
        email: {
          email: true,
          message: `^${s('emailInvalid')}`,
        },
      },
      [FIELDS.ROLE]: {
        presence: {
          presence: true,
          message: `^${s('roleRequired')}`,
        },
      },
    }),
    [s],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await adminInviteUser({
          variables: {
            email: values.email,
            role: values.role?.value,
            firstName: values.firstName,
            lastName: values.lastName,
          },
        })

        toast.success(s('inviteSuccessful'))

        close.current()
      } catch (error) {
        // TODO: API error formatter here
        toast.error(error?.message)
      } finally {
        setLoading(false)
      }
    },
    [adminInviteUser, s],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Subtitle mt={1}>{s('subtitle')}</Subtitle>

              <InputLabels mt={3} title={s('email')}>
                <InputField
                  input={{ placeholder: s('emailPlaceholder') }}
                  name={FIELDS.EMAIL}
                  required
                  type="email"
                />
              </InputLabels>

              <InputLabels mt={3} title={s('role')}>
                {/* TODO: Replace with connected Select */}
                <Field
                  component={({ input, ...restSelect }) => (
                    <Select {...input} {...restSelect} />
                  )}
                  name={FIELDS.ROLE}
                  options={userRolesOptions}
                  required
                />
              </InputLabels>

              <InputLabels mt={3} title={s('firstName')}>
                <InputField
                  input={{ placeholder: s('firstNamePlaceholder') }}
                  name={FIELDS.FIRST_NAME}
                />
              </InputLabels>

              <InputLabels mt={3} title={s('lastName')}>
                <InputField
                  input={{ placeholder: s('lastNamePlaceholder') }}
                  name={FIELDS.LAST_NAME}
                />
              </InputLabels>
            </Content>

            <Row borderTop center height={56} justifyCenter>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button
                    secondary
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {_('general.cancel')}
                  </Button>
                  <Button ml={3} width={130} onClick={handleSubmit}>
                    {s('invite')}
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

export default InviteUserModal
