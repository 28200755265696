import { split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'

import httpLink from './httpLink'
import SubscriptionLink from './subscriptionLink'

export default cache => {
  return split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    new SubscriptionLink(cache),
    httpLink,
  )
}
