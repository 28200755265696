import React, { useCallback, useMemo, useState } from 'react'
import { useField } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useLazyQuery } from '@apollo/client'
import numeral from 'numeral'

import debounce from 'lodash/debounce'
import get from 'lodash/get'
import noop from 'lodash/noop'
import toNumber from 'lodash/toNumber'

import PercentageInput from 'Components/Blocks/PercentageInput'
import { Popover, Row, Text } from 'Components/UI'

import adminCheckPortfolioTemplateAssetPercentageQuery from 'GraphQL/Queries/Admin/adminCheckPortfolioTemplateAssetPercentage.graphql'

import _ from 'Services/I18n'

function PercentageAllocation({
  templateId,
  colors,
  colorsSize,
  row,
  editingDisabled,
  onUpdateAssetsAllocationErrors,
}) {
  const { input } = useField(`${toNumber(get(row, 'index'))}.percentage`)

  const [isError, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const [loadData, { data }] = useLazyQuery(
    adminCheckPortfolioTemplateAssetPercentageQuery,
  )

  const adminCheckPortfolioTemplateAssetPercentageQueryData =
    data?.adminCheckPortfolioTemplateAssetPercentage
  const totalPortfolioAffectedCount =
    adminCheckPortfolioTemplateAssetPercentageQueryData?.totalPortfolioAffectedCount ||
    0
  const smallestPortfolioOrder =
    adminCheckPortfolioTemplateAssetPercentageQueryData?.smallestOrderSize || 0
  const belowMinimumPortfolioCount =
    adminCheckPortfolioTemplateAssetPercentageQueryData?.belowMinimumPortfolioCount ||
    0
  const belowMinimum =
    adminCheckPortfolioTemplateAssetPercentageQueryData?.belowMinimum || 0

  const debouncedValidation = useCallback(
    debounce(async (value, rowData) => {
      try {
        setLoading(true)
        const result = await loadData({
          variables: {
            asset: rowData?.original?.asset,
            portfolioTemplateId: templateId,
            percentage: value,
          },
          fetchPolicy: 'network-only',
        })

        if (
          result.data.adminCheckPortfolioTemplateAssetPercentage
            ?.belowMinimumPortfolioCount > 0
        ) {
          onUpdateAssetsAllocationErrors(row.original.asset, 'add')
          setError(true)
        } else {
          onUpdateAssetsAllocationErrors(row.original.asset, 'remove')
          setError(false)
        }
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
      } finally {
        setLoading(false)
      }
    }, 3000),
    [],
  )

  const handleChange = useCallback(
    value => {
      input.onChange(value)
      debouncedValidation(value, row)
    },
    [input, debouncedValidation, row],
  )

  const popoverContent = useMemo(() => {
    if (belowMinimumPortfolioCount <= 1) return null

    return (
      <Text
        color={belowMinimumPortfolioCount > 0 ? 'negative' : 'font.primary'}
      >
        {totalPortfolioAffectedCount} portfolios affected.
        <br />
        {belowMinimumPortfolioCount > 0 && (
          <>
            {belowMinimumPortfolioCount} portfolios below the minimum. <br />
            The smallest portfolio order size: $
            {numeral(smallestPortfolioOrder).format(`0,0.[00]`)} ($
            {numeral(belowMinimum).format(`0,0.[00]`)} below minimum)
          </>
        )}
      </Text>
    )
  }, [
    belowMinimum,
    belowMinimumPortfolioCount,
    smallestPortfolioOrder,
    totalPortfolioAffectedCount,
  ])

  return (
    <Row justifyCenter width={1}>
      <Popover
        appendTo={document.body}
        content={popoverContent}
        offset={[0, 10]}
        placement="bottom"
        zIndex={99999}
      >
        <Row justifyCenter width={1}>
          <PercentageInput
            color={get(colors, get(row, 'index') % colorsSize)}
            disabled={editingDisabled}
            error={isError}
            loading={isLoading}
            offset={[10, 0]}
            showLine
            small
            value={input.value}
            onChange={handleChange}
          />
        </Row>
      </Popover>
    </Row>
  )
}

PercentageAllocation.defaultProps = {
  onUpdateAssetsAllocationErrors: noop,
}

PercentageAllocation.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  colorsSize: PropTypes.number.isRequired,
  editingDisabled: PropTypes.bool.isRequired,
  row: PropTypes.object.isRequired,
  templateId: PropTypes.string.isRequired,
  onUpdateAssetsAllocationErrors: PropTypes.func,
}

export default PercentageAllocation
