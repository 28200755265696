import styled from 'styled-components'
import { margin, padding, width } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import arrowLeftGlyph from 'iconoir/icons/arrow-left.svg'
import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'
import warningTriangleGlyph from 'iconoir/icons/warning-triangle.svg'

import { gap } from 'Theme/system'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${themeGet('colors.bg.main')};
  flex-shrink: 0;

  ${margin};
  ${gap};
  ${padding}
  ${width}
`

export const OutlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${themeGet('colors.text20')};
`

export const InstructionsContainer = styled(OutlineContainer)`
  border-color: ${themeGet('colors.dangerPrimary')};
  background-color: ${themeGet('colors.dangerBackground')};
  padding: ${themeGet('space.3')}px;
`

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`
export const ArrowLeftIcon = styled(arrowLeftGlyph)`
  margin-right: 8px;
`

export const ErrorTriangleIcon = styled(warningTriangleGlyph)`
  color: ${themeGet('colors.dangerPrimary')};
  margin-right: 12px;
  flex-shrink: 0;
`
