import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'

import validate from 'validate.js'

import { Column } from 'Components/UI'

import { useWallets } from 'Hooks'

import AccountsList from './AccountsList'
import BankDeposit from './BankDeposit'
import ConnectDepositAccount from './ConnectDepositAccount'
import { FIELDS, STEPS, VALUES } from './constants'
import CryptoDeposit from './CryptoDeposit'
import DepositFields from './DepositFields'

const formConstraints = {
  [FIELDS.DEPOSIT]: {
    presence: true,
  },
}

function Deposit() {
  const history = useHistory()
  const [, wallets, walletsLoading] = useWallets()

  const [step, setStep] = useState(STEPS.DEPOSIT_SELECTION)

  const submit = useCallback(
    values => {
      const selected = values[FIELDS.DEPOSIT]
      switch (selected) {
        case VALUES.CRYPTO_DEPOSIT: {
          if (wallets?.length > 0) {
            setStep(STEPS.ACCOUNTS)
            break
          }

          setStep(STEPS.CONNECT_ACCOUNT)
          break
        }

        case VALUES.BANK_DEPOSIT: {
          setStep(STEPS.BANK_DEPOSIT)

          break
        }

        default:
          break
      }
    },
    [wallets],
  )

  const handleBack = useCallback(
    event => {
      event.preventDefault()
      history.goBack()
    },
    [history],
  )

  const renderForm = useCallback(
    ({ handleSubmit, values }) => {
      return (
        <DepositFields
          loading={walletsLoading}
          values={values}
          onBack={handleBack}
          onSubmit={handleSubmit}
        />
      )
    },
    [walletsLoading, handleBack],
  )

  const content = useMemo(() => {
    switch (step) {
      case STEPS.CONNECT_ACCOUNT: {
        return (
          <ConnectDepositAccount
            onBack={() => setStep(STEPS.DEPOSIT_SELECTION)}
            onSuccessFinish={() => setStep(STEPS.CRYPTO_DEPOSIT)}
          />
        )
      }

      case STEPS.ACCOUNTS: {
        return (
          <AccountsList
            onBack={() => setStep(STEPS.DEPOSIT_SELECTION)}
            onConnect={() => setStep(STEPS.CONNECT_ACCOUNT)}
            onContinue={() => setStep(STEPS.CRYPTO_DEPOSIT)}
          />
        )
      }

      case STEPS.BANK_DEPOSIT: {
        return <BankDeposit onBack={() => setStep(STEPS.DEPOSIT_SELECTION)} />
      }

      case STEPS.CRYPTO_DEPOSIT: {
        return <CryptoDeposit onBack={() => setStep(STEPS.ACCOUNTS)} />
      }

      default: {
        return (
          <Form
            initialValues={{
              [FIELDS.DEPOSIT]: null,
            }}
            render={renderForm}
            validate={values => validate(values, formConstraints)}
            onSubmit={submit}
          />
        )
      }
    }
  }, [step, renderForm, submit])

  return <Column>{content}</Column>
}

export default Deposit
