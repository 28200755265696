import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import chatBubbleQuestionGlyph from 'iconoir/icons/chat-bubble-question.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${themeGet('colors.bg.main')};
  padding: 20px;
  max-width: 500px;
  width: 100%;
  flex-shrink: 0;

  ${margin};
`

export const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary10')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeGet('colors.primary70')};
`

export const ChatBubbleQuestionIcon = styled(chatBubbleQuestionGlyph)``

export const Step = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${themeGet('space.2')}px;
`

export const StepNumber = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${themeGet('colors.primary50')};
  color: white;
`
