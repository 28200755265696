import filter from 'lodash/filter'
import forEach from 'lodash/forEach'

const EventBus = (() => {
  let subscriptions = []

  return {
    on(event, callback, context = null) {
      subscriptions.push({
        event,
        callback,
        context,
      })
    },

    off(event) {
      subscriptions = filter(
        subscriptions,
        subscription => subscription.event !== event,
      )
    },

    trigger(event, ...args) {
      const eventSubscriptions = filter(
        subscriptions,
        subscription => subscription.event === event,
      )

      forEach(eventSubscriptions, ({ callback, context }) => {
        callback.call(context, ...args)
      })
    },
  }
})()

export default EventBus
