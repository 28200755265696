import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFlexLayout, usePagination, useTable } from 'react-table'

import { pick } from '@styled-system/props'

import { useLazyQuery } from '@apollo/client'

import { Column, Loader, Row, Table, Text } from 'Components/UI'
import { Pagination } from 'Components/UI/Admin'

import { DATE_RANGE, KIND } from 'Constants/transactions'

import transactionsQuery from 'GraphQL/Queries/Transactions/transactions.graphql'

import { useScopedI18n } from 'Services/I18n'

import { theme } from 'Theme'

import { useColumns, useHiddenColumns } from './columns'
import KindSelect from './KindSelect'
import StatusSelect from './StatusSelect'
import { TableContainer } from './styles'
import {
  getDateRangeData,
  getStatusData,
  processTransactionsData,
} from './utils'

import DateRangeSelect from '../DateRangeSelect'

const ALL = 'all'

function Transactions({ ...rest }) {
  const s = useScopedI18n('transactions.table')

  const [loadData, { data, loading }] = useLazyQuery(transactionsQuery)

  const [kind, setKind] = useState({
    label: s(`filters.kind.values.${KIND.DEPOSIT}`),
    value: KIND.DEPOSIT,
  })
  const [status, setStatus] = useState({
    label: s(`filters.status.values.${ALL}`),
    value: ALL,
  })
  const [dateRange, setDateRange] = useState({
    label: s(`filters.dateRange.values.${DATE_RANGE.PAST_7DAYS}`),
    value: DATE_RANGE.PAST_7DAYS,
  })

  const { transactions, totalCount, pages } = useMemo(
    () => ({
      transactions: processTransactionsData({
        data,
      }),
      totalCount: data?.transaction?.count || 0,
      pages: data?.transaction?.pages || 0,
    }),
    [data],
  )

  const columns = useColumns()
  const hiddenColumns = useHiddenColumns(kind.value)

  const tableProps = useTable(
    {
      columns,
      data: transactions,
      initialState: {
        pageIndex: 0,
        hiddenColumns,
      },
      manualPagination: true,
      pageCount: pages,
      headerRowHeight: 36,
      rowHeight: 50,
    },
    usePagination,
    useFlexLayout,
  )

  const {
    state: { pageIndex, pageSize },
  } = tableProps

  const fetchData = useCallback(() => {
    const dateRangeData = getDateRangeData(dateRange)
    const statusData = getStatusData({ kind: kind.value, status: status.value })

    const variables = {
      page: pageIndex,
      limit: pageSize,
      sort: [{ column: 'createdAt', order: 'desc' }],
      kind: kind.value,
      ...(dateRangeData && dateRangeData),
      ...(statusData && statusData),
    }

    loadData({ variables, fetchPolicy: 'cache-and-network' })
  }, [loadData, pageIndex, pageSize, dateRange, kind, status])

  useEffect(
    () => {
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, pageSize, status, kind, dateRange],
  )

  const handleChangeKind = useCallback(
    value => {
      setKind(value)
      setStatus({
        label: s(`filters.status.values.${ALL}`),
        value: ALL,
      })
    },
    [s],
  )

  return (
    <Column {...pick(rest)} overflow="auto">
      <Row alignEnd spaceBetween>
        <Row center gap={3}>
          <KindSelect option={kind} onChange={handleChangeKind} />

          <DateRangeSelect option={dateRange} onChange={setDateRange} />

          <StatusSelect kind={kind} option={status} onChange={setStatus} />
        </Row>
      </Row>

      {loading ? (
        <Row center justifyCenter mt={5}>
          <Loader />
        </Row>
      ) : (
        <>
          <TableContainer mt={5}>
            <Table
              {...tableProps}
              noData={
                <Text color={theme.colors.text70} fontWeight={1} heading6 p={4}>
                  {s('noData')}
                </Text>
              }
            />
          </TableContainer>

          {transactions.length > 0 && (
            <Row mt={2} pt={3}>
              <Pagination {...tableProps} total={totalCount} />
            </Row>
          )}
        </>
      )}
    </Column>
  )
}

export default Transactions
