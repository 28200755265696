import React from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import ConnectAccount from 'Components/Blocks/ConnectAccount'
import { Row, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { Card, ErrorTriangleIcon, InstructionsContainer } from '../styles'

function ConnectDepositAccount({ onBack, onSuccessFinish }) {
  const s = useScopedI18n('blocks.deposit.connectDepositAccount')

  return (
    <Card p={6} width="570px">
      <Text as="h3" fontWeight={2} heading3>
        {s('title')}
      </Text>
      <Text as="h6" fontWeight={1} heading6 mt={3}>
        {s('subTitle')}
      </Text>

      <ConnectAccount
        content={
          <InstructionsContainer>
            <Row>
              <ErrorTriangleIcon height={21} viewBox="0 0 24 24" width={21} />
              <Text extraSmall>{s('instructions')}</Text>
            </Row>
          </InstructionsContainer>
        }
        disabledExchange
        onBack={onBack}
        onSuccessFinish={onSuccessFinish}
      />
    </Card>
  )
}

ConnectDepositAccount.defaultProps = {
  onBack: noop,
  onSuccessFinish: noop,
}

ConnectDepositAccount.propTypes = {
  onBack: PropTypes.func,
  onSuccessFinish: PropTypes.func,
}

export default ConnectDepositAccount
