import styled from 'styled-components'
import { margin } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'

import { identificationCardGlyph } from 'Assets/Svg'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 60px;
  border-radius: 12px;
  background: ${themeGet('colors.white')};
  align-items: center;
  max-width: 500px;
  ${margin}
`

export const IconContainer = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary10')};
`

export const IdentificationIcon = styled(identificationCardGlyph)``

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`
