import userTransactionsQuery from 'GraphQL/Queries/UserTransactions/userTransactions.graphql'

import Utils from 'Utils'

function add(store, data) {
  try {
    Utils.GraphQL.updateList(
      store,
      userTransactionsQuery,
      data?.data?.addUserTransaction,
      'userTransactions',
    )
  } catch (error) {
    //
  }
}

export default { add }
