import React from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Button, Column, Row, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { CheckIcon, IconWrapper } from './styles'

function Success({ onClick }) {
  const s = useScopedI18n('modal.2fa.enable.success')

  return (
    <Column center fullWidth>
      <IconWrapper mt={5}>
        <CheckIcon height={40} viewBox="0 0 24 24" width={40} />
      </IconWrapper>

      <Text fontWeight={2} heading3 mt={5}>
        {s('verified')}
      </Text>

      <Text fontWeight={1} heading6 mt={3}>
        {s('message')}
      </Text>

      <Row fullWidth mt={5}>
        <Button gap={3} width="100%" onClick={onClick}>
          {s('action')}
        </Button>
      </Row>
    </Column>
  )
}

Success.defaultProps = {
  onClick: noop,
}

Success.propTypes = {
  onClick: PropTypes.func,
}
export default Success
