import React, { useCallback } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'

import { useTheme } from 'styled-components'

import validate from 'validate.js'

import { Column, Text } from 'Components/UI'

import { WITHDRAWAL_CRYPTO } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import { FIELD, WITHDRAWAL_KIND } from './constants'
import FormFields from './FormFields'
import { Card } from './styles'

const FORM_CONSTRAINTS = {
  [FIELD.KIND]: {
    presence: true,
  },
}

function Root() {
  const theme = useTheme()
  const s = useScopedI18n('withdrawal')
  const history = useHistory()

  const submit = useCallback(
    values => {
      switch (values[FIELD.KIND]) {
        case WITHDRAWAL_KIND.CRYPTO:
          history.push(WITHDRAWAL_CRYPTO)
          break
        default:
          break
      }
    },
    [history],
  )

  return (
    <Column center flexGrow={1} fullWidth pt="60px">
      <Text as="h2" fontWeight={2} heading2>
        {s('title')}
      </Text>

      <Text color={theme.colors.text70} fontWeight={2} heading5 mt={4}>
        {s('subTitle')}
      </Text>

      <Card mt={6}>
        <Form
          render={props => <FormFields {...props} />}
          validate={values => validate(values, FORM_CONSTRAINTS)}
          onSubmit={submit}
        />
      </Card>
    </Column>
  )
}

export default Root
