import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import concat from 'lodash/concat'
import filter from 'lodash/filter'
import noop from 'lodash/noop'
import sumBy from 'lodash/sumBy'

import AssetsSelect from 'Components/Blocks/Portfolio/Assets/Select'
import { Row } from 'Components/UI'

import _ from 'Services/I18n'

import { theme } from 'Theme'

import { Container, FormContent, Total } from './styles'
import Table from './Table'

function AllocationSelectList({
  amount,
  colors,
  contentMaxHeight,
  onChange,
  onMount,
  onUpdateAssetsAllocationErrors,
  initialAssets,
  disabled,
  template,
  ...rest
}) {
  const [assets, setAssets] = useState(initialAssets)
  const [totalAllocation, setTotalAllocation] = useState(0)

  const internalAssets = useRef([])
  const excludedAssetsBase = useRef(new Set())

  useEffect(() => {
    setAssets(initialAssets)
  }, [initialAssets])

  const handleFormMount = useCallback(
    instance => {
      onMount(instance)
    },
    [onMount],
  )

  const handleAssetSelect = useCallback(asset => {
    excludedAssetsBase.current.add(asset.base)
    setAssets(concat(internalAssets.current, asset))
  }, [])

  const handleAssetDelete = useCallback(
    asset => {
      excludedAssetsBase.current.delete(asset.base)
      setAssets(
        filter(
          internalAssets.current,
          internalAsset => internalAsset.base !== asset.base,
        ),
      )
      onUpdateAssetsAllocationErrors(asset.base, 'delete')
    },
    [onUpdateAssetsAllocationErrors],
  )

  const handleAssetsChange = useCallback(
    formAssets => {
      internalAssets.current = formAssets
      setTotalAllocation(
        sumBy(formAssets, asset => parseFloat(asset.percentage) || 0),
      )
      onChange(formAssets)
    },
    [internalAssets, onChange],
  )

  return (
    <Container {...pick(rest)}>
      <Row>
        <AssetsSelect
          disabled={disabled}
          excludeAssets={excludedAssetsBase.current}
          onSelect={handleAssetSelect}
        />
      </Row>

      <FormContent maxHeight={contentMaxHeight} mt="20px">
        <Table
          amount={amount}
          assets={assets}
          colors={colors}
          editingDisabled={disabled}
          templateId={template?.id}
          onChange={handleAssetsChange}
          onDelete={handleAssetDelete}
          onMount={handleFormMount}
          onUpdateAssetsAllocationErrors={onUpdateAssetsAllocationErrors}
        />

        <Row justifyEnd width={1}>
          <Total>{_('portfolio.assets.totalAllocated')}:</Total>
          <Total
            ml={1}
            negative={totalAllocation > 100}
            positive={totalAllocation === 100}
          >
            {totalAllocation}%
          </Total>
        </Row>
      </FormContent>
    </Container>
  )
}

AllocationSelectList.defaultProps = {
  amount: 0,
  colors: theme.colors.assets,
  contentMaxHeight: undefined,
  disabled: false,
  initialAssets: [],
  template: {},
  onChange: noop,
  onFinish: noop,
  onUpdateAssetsAllocationErrors: noop,
  onMount: noop,
}

AllocationSelectList.propTypes = {
  amount: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.string),
  contentMaxHeight: PropTypes.number,
  disabled: PropTypes.bool,
  initialAssets: PropTypes.array,
  template: PropTypes.object,
  onChange: PropTypes.func,
  onFinish: PropTypes.func,
  onMount: PropTypes.func,
  onUpdateAssetsAllocationErrors: PropTypes.func,
}

export default AllocationSelectList
