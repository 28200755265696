import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { EXPERT_PORTFOLIO_DEFAULTS } from 'Constants/defaults'
import {
  EXPERT_PORTFOLIO_ACTIONS,
  EXPERT_PORTFOLIO_STATE,
} from 'Constants/store'

export default function expertPortfolioState(store) {
  store.on('@init', () => ({
    [EXPERT_PORTFOLIO_STATE]: EXPERT_PORTFOLIO_DEFAULTS,
  }))

  store.on(EXPERT_PORTFOLIO_ACTIONS.SET, (state, variables) => {
    const values = pick(variables, Object.keys(EXPERT_PORTFOLIO_DEFAULTS))

    const nextState = { ...state[EXPERT_PORTFOLIO_STATE], ...values }

    if (isEqual(nextState, state[EXPERT_PORTFOLIO_STATE])) return null

    return { [EXPERT_PORTFOLIO_STATE]: nextState }
  })
}
