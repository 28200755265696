import { useMemo } from 'react'

import { DateTime } from 'luxon'

import map from 'lodash/map'

import { ActionsCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 2,
      },
      {
        Header: 'Portfolio Groups',
        accessor: 'portfolioGroups',
        width: 1,
        Cell: ({ value }) => map(value, group => group.name).join(', '),
      },
      {
        Header: 'Assets',
        accessor: 'assets',
        Cell: ({ value }) =>
          map(value, asset => `${asset.asset} (${asset.percentage}%)`).join(
            ', ',
          ),
        width: 2,
      },
      {
        Header: 'Minimum Investment',
        accessor: 'minimumInvestment',
        width: 1,
      },
      {
        Header: 'Rebalance Type',
        accessor: 'rebalanceType',
        width: 1,
      },
      {
        Header: 'Rebalance Value',
        accessor: 'rebalanceValue',
        width: 1,
      },
      {
        Header: 'Created',
        Cell: ({ value }) =>
          value
            ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
            : '—',
        accessor: 'createdAt',
        width: 2,
      },
      {
        Header: 'Action',
        Cell: ActionsCell,
        width: 1,
      },
    ],
    [],
  )
}
