import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { gap } from 'Theme/system'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${themeGet('colors.bg.main')};
  padding: ${themeGet('space.6')}px;
  max-width: 570px;
  width: 100%;
  flex-shrink: 0;

  ${margin};
  ${gap};
`
