import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { pick } from '@styled-system/props'

import { useMutation } from '@apollo/client'

import { Button, Loader, Row, Text } from 'Components/UI'

import { KYC_STATUS } from 'Constants/ids'

import requestUserKYCMutation from 'GraphQL/Mutations/UserKYC/requestUserKYC.graphql'

import { useAppContext, useLocationQueryParams } from 'Hooks'

import {
  ArrowRightIcon,
  IconContainer,
  IdentificationIcon,
} from 'Pages/Onboarding/KYC/styles'

import { APP_ROOT, KYC as KYC_ROUTE } from 'Router/routes'

import _, { useScopedI18n } from 'Services/I18n'

import Utils from 'Utils'

import Status from './Status'
import { Container, Content } from './styles'

function KYC({ ...rest }) {
  const history = useHistory()
  const { me } = useAppContext()
  const s = useScopedI18n('kyc')
  const queryParams = useLocationQueryParams()

  const [isLoading, setLoading] = useState(false)

  const [requestUserKYC] = useMutation(requestUserKYCMutation)

  const handleRequest = useCallback(async () => {
    setLoading(true)

    try {
      const response = await requestUserKYC({
        variables: {
          redirectUrl: KYC_ROUTE,
        },
      })
      setLoading(false)
      window.open(response.data.requestUserKYC.url, '_blank')
    } catch (error) {
      toast.error(error?.message || _('error.generic'))
    } finally {
      setLoading(false)
    }
  }, [requestUserKYC])

  const handleContinue = () => {
    history.push(APP_ROOT)
  }

  const kyc = me?.kyc
  const redirectStatus = queryParams?.status

  return (
    <Container {...pick(rest)}>
      <Content>
        {!kyc ? (
          <>
            <Row justifyCenter width={1}>
              <IconContainer>
                <IdentificationIcon />
              </IconContainer>
            </Row>

            <Text fontWeight={2} heading4 mt={3} textAlign="center">
              {s('subtitle')}
            </Text>

            <Text mt={2} small textAlign="center">
              {s('description')}
            </Text>

            <Row justifyCenter onClick={handleRequest}>
              <Button disabled={isLoading} mt={5}>
                {s('continue')} <ArrowRightIcon />
                {isLoading && <Loader ml={1} />}
              </Button>
            </Row>
          </>
        ) : (
          <Status
            status={
              redirectStatus === 'aborted'
                ? KYC_STATUS.FAILURE
                : Utils.UserKYC.getStatus(kyc.state)
            }
            onContinue={handleContinue}
            onGoToKYC={handleRequest}
          />
        )}
      </Content>
    </Container>
  )
}

export default KYC
