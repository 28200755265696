import { useEffect } from 'react'
import PropTypes from 'prop-types'

function VezgoHolder({ vezgoUser, providerCategories, onSuccess, onExit }) {
  useEffect(() => {
    if (vezgoUser && providerCategories?.length > 0) {
      vezgoUser
        .connect({
          providerCategories,
        })
        .onConnection(async accountId => {
          onSuccess(accountId)
        })
        .onError(error => {
          // eslint-disable-next-line no-console
          console.error('account connection error:', error)
        })
        .onEvent(name => {
          if (name === 'CLOSE' || name === 'FORCE_CLOSE') onExit()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerCategories, vezgoUser])

  return null
}

VezgoHolder.propTypes = {
  providerCategories: PropTypes.array.isRequired,
  vezgoUser: PropTypes.object.isRequired,
  onExit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default VezgoHolder
