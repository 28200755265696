import { OAuthLayout } from 'Components/Layout'

import OAuth from 'Pages/OAuth'

import * as ROUTES from './routes'

export const OAUTH_ROUTES = {
  component: OAuthLayout,
  path: ROUTES.OAUTH_ROOT,
  routes: [
    {
      component: OAuth.Root,
      path: ROUTES.OAUTH_ROOT,
      exact: true,
    },
  ],
}
