import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

export const Container = styled.div`
  position: absolute;
  ${Styles.flexCenter};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(3px);
`

export const Title = styled.div`
  ${Styles.text(16)};
  color: ${themeGet('colors.font.secondary')};
`
