export const APP = {
  NAME: 'Wealth Square',
}

export const USER_ROLE = {
  SUPER_ADMIN: 'superAdmin',
  ADMIN: 'admin',
  USER: 'user',
}

export const BREAKPOINTS = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
  LARGE: 'large',
}

export const COOKIE_KEYS = {
  AUTH_TOKEN: 'auth-token',
  REMEMBER_ME: 'remember-me',
  I18N: 'i18n',
}

export const LANGUAGE = {
  EN: 'en',
  FR: 'fr',
  KO: 'ko',
}

export const RISK_ASSESSMENT = {
  INVESTING_EXPERIENCE: 'investingExperience',
  RISK_TOLERANCE: 'riskTolerance',
  INVESTMENT_HORIZON: 'investmentHorizon',
  TOTAL_NET_WORTH: 'totalNetWorth',
  TOTAL_INVESTMENTS: 'totalInvestments',
  TAX_RESIDENCE: 'taxResidence',
  US_CITIZEN: 'isUSCitizen',
  INDUSTRIES: 'isIndustryPresent',
  PROFESSION: 'profession',
  NET_MONTHLY_INCOME: 'netMonthlyIncome',
  FUND_SOURCE: 'fundSource',
}

export const RISK_TOLERANCE = {
  CONSERVATIVE: 'conservative',
  MODERATE: 'moderate',
  AGGRESSIVE: 'aggressive',
}

export const INVESTING_EXPERIENCE = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
}

export const INVESTMENT_HORIZON = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
}

export const KYC_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  REVIEW: 'review',
}

export const TAX_RESIDENCE = {
  FRANCE: 'france',
  EUROPEAN_UNION: 'europeanUnion',
  NON_EUROPEAN_UNION: 'nonEuropeanUnion',
}

export const FUND_SOURCE = {
  SALARY: 'salary',
  PROPERTY_SALES: 'propertySales',
  SAVINGS: 'savings',
  INVESTMENT_GAINS: 'investmentGains',
  DONATION: 'donation',
  LOAN: 'loan',
  LEGACY: 'legacy',
  GAMBLING: 'gambling',
  DIVIDENDS: 'dividends',
  OTHER: 'other',
}

export const PROFESSION = {
  UNEMPLOYED: 'Unemployed',
  NOT_IN_EMPLOYMENT: 'notInEmployment',
  RETIRED: 'retired',
  FARMER: 'farmer',
  ARTISAN: 'artisan',
  SELF_EMPLOYED: 'selfEmployed',
  PRIVATE_SECTOR_PROFESSION: 'intellectualProfessionPrivateSector',
  PUBLIC_SECTOR_PROFESSION: 'intellectualProfessionPublicSector',
  PRIVATE_SECTOR_SUPERVISOR: 'privateSectorSupervisor',
  MERCHANT_AND_RELATED: 'merchantRelated',
  BUSINESS_OWNER: 'businessOwner',
  PRIVATE_SECTOR_EMPLOYEE: 'privateSectorEmployee',
  PUBLIC_SECTOR_EMPLOYEE: 'publicSectorEmployee',
  PRIVATE_SECTOR_MANAGER: 'privateSectorManager',
  PUBLIC_SECTOR_MANAGER: 'publicSectorManager',
  STUDENT: 'student',
  LEGAL_PROFESSIONAL: 'legalProfessional',
  MEDICAL_PROFESSIONAL: 'medicalProfessional',
  OTHER: 'otherProfession',
}

export const NUMERIC_RISK_TOLERANCE = {
  [RISK_TOLERANCE.CONSERVATIVE]: 1,
  [RISK_TOLERANCE.MODERATE]: 2,
  [RISK_TOLERANCE.AGGRESSIVE]: 3,
}
