import React, { useCallback } from 'react'
import { renderRoutes } from 'react-router-config'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useSubscription } from '@apollo/client'
import { Add2FACoordinator } from 'Coordinators'
import { useStoreon } from 'storeon/react'

import Menu from 'Components/Blocks/Menu'
import BalancesSubscription from 'Components/Blocks/Subscriptions/Balances'
import { Button, Link, Row } from 'Components/UI'

import { SIDEBAR_ACTIONS, SIDEBAR_STATE } from 'Constants/store'

import notificationsSubscription from 'GraphQL/Subscriptions/notifications.graphql'
import userKYCStateSubscription from 'GraphQL/Subscriptions/userKYCState.graphql'

import { useAppContext } from 'Hooks'

import { APP_ROOT } from 'Router/routes'

import {
  Container,
  Content,
  LogoExtended,
  MenuContainer,
  PageContent,
  PageContentLimit,
  PageLeftIcon,
  PageRightIcon,
} from './styles'

function App({ route }) {
  const { refetchMe } = useAppContext()
  const { dispatch, sidebar } = useStoreon(SIDEBAR_STATE)

  useSubscription(notificationsSubscription, {
    onData({ data }) {
      const notification = data?.data?.notifications
      if (notification) {
        toast(
          <div
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: notification.text,
            }}
          />,
          { type: notification.type },
        )
      }
    },
  })

  useSubscription(userKYCStateSubscription, {
    onData({ data }) {
      const state = data?.data?.userKYCState?.state

      if (state) refetchMe()
    },
  })

  const handleToggleSidebar = useCallback(() => {
    dispatch(SIDEBAR_ACTIONS.SET, { sidebarOpen: !sidebar.sidebarOpen })
  }, [dispatch, sidebar])

  return (
    <Container>
      <MenuContainer collapsed={!sidebar.sidebarOpen}>
        <Row center fullWidth height="50px" justifyCenter>
          {sidebar.sidebarOpen && (
            <Link ml="6px" to={APP_ROOT}>
              <LogoExtended />
            </Link>
          )}

          <Button
            ml={sidebar.sidebarOpen ? 'auto' : 0}
            text
            onClick={handleToggleSidebar}
          >
            {sidebar.sidebarOpen ? (
              <PageLeftIcon height={18} viewBox="0 0 24 24" width={18} />
            ) : (
              <PageRightIcon height={18} viewBox="0 0 24 24" width={18} />
            )}
          </Button>
        </Row>

        <Menu mt={5} sidebarOpen={sidebar.sidebarOpen} />
      </MenuContainer>

      <Content>
        <PageContent>
          <PageContentLimit>{renderRoutes(route.routes)}</PageContentLimit>
        </PageContent>
      </Content>

      <Add2FACoordinator />

      <BalancesSubscription />
    </Container>
  )
}

App.propTypes = {
  route: PropTypes.object.isRequired,
}

export default App
