import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, Loader, Modal, Row } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms'

import adminUpdateUserTransactionMutation from 'GraphQL/Mutations/Admin/adminUpdateUserTransaction.graphql'

import _, { useScopedI18n } from 'Services/I18n'

import { Content, Subtitle } from './styles'

const FIELDS = {
  AMOUNT: 'amount',
}

function EditUserTransactionModal({ transaction, ...rest }) {
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const s = useScopedI18n('admin.modal.editUserTransaction')

  const [adminUpdateUserTransaction] = useMutation(
    adminUpdateUserTransactionMutation,
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.AMOUNT]: {
        presence: {
          presence: true,
          message: `^${s('amountRequired')}`,
        },
        numericality: {
          greaterThan: 0,
          message: `^${s('amountGreaterThanZero')}`,
        },
      },
    }),
    [s],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await adminUpdateUserTransaction({
          variables: {
            id: transaction.id,
            amount: parseFloat(values.amount),
          },
        })

        toast.success(s('success'))

        setLoading(false)

        close.current()
      } catch (error) {
        // TODO: API error formatter here
        toast.error(error?.message)

        setLoading(false)
      }
    },
    [adminUpdateUserTransaction, s, transaction],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        initialValues={transaction}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Subtitle mt={1}>
                {s('subtitle', { id: transaction?.id })}
              </Subtitle>

              <InputLabels mt={3} title={s('amount')}>
                <InputField
                  input={{ placeholder: s('amountPlaceholder') }}
                  name={FIELDS.AMOUNT}
                />
              </InputLabels>
            </Content>

            <Row borderTop center height={56} justifyCenter>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button
                    secondary
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {_('general.cancel')}
                  </Button>
                  <Button ml={3} width={130} onClick={handleSubmit}>
                    {s('action')}
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

EditUserTransactionModal.defaultProps = {
  transaction: null,
}

EditUserTransactionModal.propTypes = {
  transaction: PropTypes.object,
}

export default EditUserTransactionModal
