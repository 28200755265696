import React, { useCallback, useEffect, useMemo, useState } from 'react'
import QRCode from 'react-qr-code'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation, useQuery } from '@apollo/client'

import noop from 'lodash/noop'

import { Button, Column, Loader, Row, Select, Text } from 'Components/UI'
import { InputLabels } from 'Components/UI/Forms'

import { PRIMARY_CURRENCY } from 'Constants/currencies'
import { KIND, PAYMENT_METHOD } from 'Constants/userTransactions'

import addUserTransactionMutation from 'GraphQL/Mutations/UserTransactions/addUserTransaction.graphql'
import systemWalletsQuery from 'GraphQL/Queries/SystemWallets/systemWallets.graphql'
import UserTransactions from 'GraphQL/Updaters/UserTransactions'

import { useAppContext } from 'Hooks'

import { PORTFOLIO } from 'Router/routes'

import _, { useScopedI18n } from 'Services/I18n'

import { SYSTEM_WALLET_TOKENS } from './constants'
import {
  CopyAddressContainer,
  CopyIcon,
  GroupHeaderContainer,
  TetherIcon,
  WarningTriangleIcon,
} from './styles'

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Card,
  ErrorTriangleIcon,
  InstructionsContainer,
  OutlineContainer,
} from '../styles'

const WALLET_NAME = `${PRIMARY_CURRENCY} (Tether)`
const MINIMUM_AMOUNT = '10.00'

function systemWalletToOption(systemWallet) {
  return {
    label: systemWallet.network,
    value: systemWallet.network,
    address: systemWallet.address,
  }
}

function CryptoDeposit({ onBack }) {
  const history = useHistory()
  const s = useScopedI18n('blocks.deposit.cryptoDeposit')
  const { me } = useAppContext()

  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const [selectedWallet, setSelectedWallet] = useState(null)

  const { data, loading: systemWalletsLoading } = useQuery(systemWalletsQuery)
  const [addUserTransaction, { loading }] = useMutation(
    addUserTransactionMutation,
  )

  const systemWallets = useMemo(() => data?.systemWallets || [], [data])

  useEffect(() => {
    if (systemWallets.length === 0 || selectedNetwork) return

    setSelectedNetwork(systemWalletToOption(systemWallets[0]))
  }, [systemWallets, selectedNetwork])

  const handleCopyToClipboard = useCallback(async () => {
    if (!navigator.clipboard) return
    try {
      const text = `${selectedNetwork.address}`

      await navigator.clipboard.writeText(text)
      toast.success(s('toasts.success.copy'))
    } catch (err) {
      toast.error(s('toasts.errors.copy'))
    }
  }, [selectedNetwork, s])

  const handleCreateDeposit = useCallback(async () => {
    if (!selectedWallet?.value) {
      toast.error(s('errors.walletAddress'))
      return
    }

    try {
      await addUserTransaction({
        variables: {
          amount: 0,
          address: selectedWallet.value,
          currency: PRIMARY_CURRENCY,
          kind: KIND.DEPOSIT,
          paymentMethod: PAYMENT_METHOD.BANK,
        },
        update: UserTransactions.add,
      })

      history.push(PORTFOLIO)
    } catch (error) {
      toast.error(error?.message || _('error.generic'))
    }
  }, [selectedWallet, history, s, addUserTransaction])

  const myWalletOptions = useMemo(() => {
    return me?.userCryptoWallets?.map(item => ({
      label: item.name,
      value: item.address,
    }))
  }, [me])

  const networkOptions = useMemo(
    () => [
      {
        label: s('network.warning'),
        options: systemWallets.map(systemWalletToOption),
      },
    ],
    [systemWallets, s],
  )

  const formatGroupLabel = useCallback(
    ({ label }) => (
      <Row p={1}>
        <GroupHeaderContainer>
          <WarningTriangleIcon height={36} viewBox="0 0 24 24" width={36} />
          <Text color="text100" extraSmall>
            {label}
          </Text>
        </GroupHeaderContainer>
      </Row>
    ),
    [],
  )

  const formatMyWalletOption = useCallback(
    option => (
      <Column>
        <Text firstCapital fontWeight={2}>
          {option.label}
        </Text>
        <Text>{option.value}</Text>
      </Column>
    ),
    [],
  )

  const formatNetworkOption = useCallback(
    option => (
      <Column>
        <Text fontWeight={2}>{option.label}</Text>

        {SYSTEM_WALLET_TOKENS[option.value] && (
          <Text>{SYSTEM_WALLET_TOKENS[option.value]}</Text>
        )}
      </Column>
    ),
    [],
  )

  return (
    <>
      <Column center mb={6}>
        <Text fontWeight={2} heading2>
          {s('title')}
        </Text>

        <Text color="text70" fontWeight={2} heading5 mt={4}>
          {s('subtitle')}
        </Text>
      </Column>

      <Card p="20px" width="630px">
        <Text actionSmall mb={1}>
          {s('name')}
        </Text>

        <OutlineContainer>
          <Row center p={3}>
            <TetherIcon />
            <Text small>{WALLET_NAME}</Text>
          </Row>
        </OutlineContainer>

        <InputLabels mt={5} title={s('myWallet.title')}>
          <Select
            formatOptionLabel={formatMyWalletOption}
            loading
            options={myWalletOptions}
            value={selectedWallet}
            onChange={setSelectedWallet}
          />
        </InputLabels>

        <InputLabels mb={5} mt={5} title={s('network.title')}>
          {systemWalletsLoading || !selectedNetwork ? (
            <Column height="44px" justifyCenter>
              <Loader />
            </Column>
          ) : (
            <Select
              formatGroupLabel={formatGroupLabel}
              formatOptionLabel={formatNetworkOption}
              loading
              options={networkOptions}
              value={selectedNetwork}
              onChange={setSelectedNetwork}
            />
          )}
        </InputLabels>

        <OutlineContainer>
          <Column center height="234px" justifyCenter p={5} relative>
            {systemWalletsLoading || !selectedNetwork ? (
              <Loader />
            ) : (
              <>
                <QRCode level="M" size={105} value={selectedNetwork?.address} />

                <Text fontWeight={2} heading4 mt={5}>
                  {s('depositAddress')}
                </Text>
                <Text mt={2} small>
                  {selectedNetwork?.address}
                </Text>

                <CopyAddressContainer
                  right="16px"
                  top="16px"
                  onClick={handleCopyToClipboard}
                >
                  <CopyIcon />
                </CopyAddressContainer>
              </>
            )}
          </Column>
        </OutlineContainer>

        <Row fullWidth justifyEnd mt={2}>
          <Text color="text80" extraSmall>
            {s('minimumDeposit')} {MINIMUM_AMOUNT} {PRIMARY_CURRENCY}
          </Text>
        </Row>

        <Text extraSmall mb={5}>
          {s('depositCredited')}
        </Text>

        <InstructionsContainer>
          <Row center>
            <ErrorTriangleIcon height={21} viewBox="0 0 24 24" width={21} />
            <Text fontWeight={3} heading6>
              {s('instructions.title')}
            </Text>
          </Row>

          <Text ml={6} small>
            {s('instructions.initiateTransfer')}
          </Text>
        </InstructionsContainer>

        <Row mt={5} spaceBetween>
          <Button outline onClick={onBack}>
            <ArrowLeftIcon height={14} viewBox="0 0 24 24" width={14} />
            {s('actions.back')}
          </Button>

          <Row center gap={3}>
            <Text extraSmall maxWidth="187px" textAlign="right">
              {s('afterMadeDeposit')}
            </Text>

            <Button
              disabled={systemWalletsLoading || loading}
              onClick={handleCreateDeposit}
            >
              {s('actions.confirm')}
              <ArrowRightIcon height={16} viewBox="0 0 24 24" width={14} />
              {(systemWalletsLoading || loading) && <Loader ml={1} />}
            </Button>
          </Row>
        </Row>
      </Card>
    </>
  )
}

CryptoDeposit.defaultProps = {
  onBack: noop,
}

CryptoDeposit.propTypes = {
  onBack: PropTypes.func,
}

export default CryptoDeposit
