import React from 'react'

import TermsOfServiceComponent from 'Components/Blocks/TermsOfService'

import { Container } from './styles'

function TermsOfService() {
  return (
    <Container>
      <TermsOfServiceComponent />
    </Container>
  )
}

export default TermsOfService
