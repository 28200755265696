import { useCallback, useMemo, useState } from 'react'

import get from 'lodash/get'
import values from 'lodash/values'

export default function useStepper(steps) {
  const [index, setIndex] = useState(0)
  const stepValues = useMemo(() => values(steps), [steps])

  const next = useCallback(() => {
    setIndex(Math.min(index + 1, (stepValues.length || 1) - 1))
  }, [index, stepValues])

  const prev = useCallback(() => {
    setIndex(Math.max(index - 1, 0))
  }, [index])

  const reset = useCallback(() => {
    setIndex(0)
  }, [])

  const step = useMemo(() => get(stepValues, index), [index, stepValues])

  return { step, next, prev, reset }
}
