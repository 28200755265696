import React from 'react'
import { useHistory, useParams } from 'react-router'

import { useQuery } from '@apollo/client'

import Backtest from 'Components/Blocks/Backtesting/Backtest'
import { BackToExpertPortfolio } from 'Components/Blocks/ExpertPortfolio'
import { Loader } from 'Components/UI'

import portfolioTemplateQuery from 'GraphQL/Queries/PortfolioTemplates/portfolioTemplate.graphql'

import { Content } from './styles'

function Backtesting() {
  const params = useParams()
  const history = useHistory()

  const { expertPortfolioId } = params

  const { data, loading } = useQuery(portfolioTemplateQuery, {
    variables: {
      id: expertPortfolioId,
    },
  })
  const expertPortfolio = data?.portfolioTemplate

  const handleInvestNowClick = () => {
    history.push('')
  }

  return (
    <Content>
      {loading ? (
        <Loader />
      ) : (
        <>
          <BackToExpertPortfolio expertPortfolioId={expertPortfolioId} />
          <Backtest
            disableRebalanceForm
            initialPortfolio={expertPortfolio}
            onInvestNowClick={handleInvestNowClick}
          />
        </>
      )}
    </Content>
  )
}

export default Backtesting
