import React from 'react'

import { TransactionsTable } from 'Components/Blocks/Admin'
import { Row } from 'Components/UI'

function Transactions() {
  return (
    <Row p={2}>
      <TransactionsTable />
    </Row>
  )
}

export default Transactions
