import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { Button } from 'Components/UI'

export const Answers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > ${Button}:not(:first-child) {
    margin-top: ${themeGet('space.2')}px;
  }

  ${margin}
`

export const DescriptionContainer = styled.div`
  padding: ${themeGet('space.4')}px ${themeGet('space.5')}px;
  background-color: ${themeGet('colors.cultured')};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: auto;
  max-height: 350px;
  margin-top: -10px;
`

export const SelectWrapper = styled.div`
  .react-select-container,
  .react-select__control {
    height: 62px !important;
  }
`
