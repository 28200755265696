import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import Tippy from '@tippyjs/react/headless'

import noop from 'lodash/noop'

import DateRangePicker from 'Components/UI/DatePicker/RangePicker'
import Text from 'Components/UI/Text'

import { CalendarIcon, Container } from './styles'

// TODO: default value or controlled here!
function DateRangeInput({
  numberOfMonths,
  placeholder,
  small,
  timeZone,
  onChange,
  ...rest
}) {
  const tippyInstance = useRef(null)
  const [dates, setDates] = useState(null)

  const handleFinish = useCallback(
    pickedDates => {
      setDates(pickedDates)
      onChange(pickedDates)

      if (tippyInstance.current) {
        tippyInstance.current.hide()
      }
    },
    [onChange],
  )

  const value = useMemo(() => {
    if (!dates) {
      return placeholder
    }

    const { from, to } = dates
    return `${from.toLocaleString()} — ${to.toLocaleString()}`
  }, [dates, placeholder])

  return (
    <Tippy
      interactive
      render={() => (
        <DateRangePicker
          numberOfMonths={numberOfMonths}
          timeZone={timeZone}
          onFinish={handleFinish}
        />
      )}
      trigger="click"
      onMount={instance => {
        tippyInstance.current = instance
      }}
    >
      <Container {...pick(rest)} small={small}>
        <CalendarIcon height={16} viewBox="0 0 24 24" width={16} />
        <Text ml={2}>{value}</Text>
      </Container>
    </Tippy>
  )
}

DateRangeInput.defaultProps = {
  numberOfMonths: 1,
  placeholder: null,
  small: false,
  timeZone: null,
  onChange: noop,
}

DateRangeInput.propTypes = {
  numberOfMonths: PropTypes.number,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  timeZone: PropTypes.string,
  onChange: PropTypes.func,
}

export default DateRangeInput
