import styled from 'styled-components'
import { margin } from '@styled-system/space'

import Styles from 'Components/Styles'

export const ExpanderContainer = styled.div`
  ${Styles.flexCenter};
  height: 100%;
  cursor: pointer;

  ${margin}
`
