import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import ImageOverlay from 'Components/Blocks/ImageOverlay'
import Styles from 'Components/Styles'

const containerImageCss = ({ image }) =>
  image &&
  css`
    background-image: url(${image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-style: none;
  `

export const Container = styled.div`
  position: relative;
  ${Styles.flexCenter};
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  ${Styles.border()};
  border-style: dashed;
  outline: none;
  cursor: pointer;

  > svg {
    color: ${themeGet('colors.font.secondary')};
  }

  ${layout.height}
  ${layout.width}
  ${margin}
  ${containerImageCss}
`

export const Text = styled.div`
  ${Styles.text()};
  color: ${themeGet('colors.font.secondary')};

  ${margin}
`

export const SelectFile = styled.div`
  ${Styles.text(14)};
  color: ${themeGet('colors.primary')};
  font-weight: ${themeGet('weight.2')};
  cursor: pointer;

  ${margin}
`

export const Overlay = styled(ImageOverlay)`
  display: none;

  ${Container}:hover & {
    display: flex;
  }
`
