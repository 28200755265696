import React from 'react'

import { UserTransactionsTable } from 'Components/Blocks/Admin'
import { Row } from 'Components/UI'

function UserTransactions() {
  return (
    <Row p={2}>
      <UserTransactionsTable />
    </Row>
  )
}

export default UserTransactions
