import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { useMutation, useQuery } from '@apollo/client'

import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'

import BacktestingSummary from 'Components/Blocks/Backtesting/Summary'
import BacktestingChart from 'Components/Blocks/Charts/Assets/Backtesting'
import { ExpertPortfolioPie } from 'Components/Blocks/ExpertPortfolio'
import { Dialog } from 'Components/Blocks/Modals'
import ProgressBar from 'Components/Blocks/Onboarding/ProgressBar'
import { AssetsSelectList } from 'Components/Blocks/Portfolio/Assets'
import PortfolioInfoForm from 'Components/Blocks/Portfolio/Info/Form'
import PortfolioRebalanceForm from 'Components/Blocks/Portfolio/Rebalance/Form'
import { Button, Column, Loader, Row, Text } from 'Components/UI'
import { Input, InputLabels } from 'Components/UI/Forms'

import { PRIMARY_CURRENCY } from 'Constants/currencies'
import { REBALANCE_TYPE } from 'Constants/portfolios'
import { MIN_ASSET_VALUE } from 'Constants/values'

import addPortfolioMutation from 'GraphQL/Mutations/Portfolios/addPortfolio.graphql'
import updateOnboardingStepsMutation from 'GraphQL/Mutations/User/updateOnboardingSteps.graphql'
import backtestQuery from 'GraphQL/Queries/Backtesting/backtest.graphql'
import portfolioTemplatesQuery from 'GraphQL/Queries/PortfolioTemplates/portfolioTemplates.graphql'
import Portfolios from 'GraphQL/Updaters/Portfolios'

import { useAppContext } from 'Hooks'

import { APP_ROOT, ONBOARDING_KYC } from 'Router/routes'

import _ from 'Services/I18n'
import shared from 'Services/Shared'

import { theme } from 'Theme'

import Utils from 'Utils'

import {
  ArrowRightIcon,
  Card,
  CheckIcon,
  Container,
  Line,
  Separator,
  StepIcon,
} from './styles'

const STEPS = {
  INFO: 'info',
  BUILD: 'build',
}

function Portfolio() {
  const history = useHistory()
  const { me } = useAppContext()

  const infoFormInstance = useRef(null)
  const assetsFormInstance = useRef(null)
  const rebalanceFormInstance = useRef(null)

  const [loading, setLoading] = useState(false)
  const [isBuildPortfolio, setIsBuildPortfolio] = useState(false)
  const [isBacktestingLoading, setBacktestingLoading] = useState(false)
  const [step, setStep] = useState(STEPS.INFO)
  const [amount, setAmount] = useState(0)
  const [portfolioAssets, setPortfolioAssets] = useState(null)
  const [backtestResult, setBacktestResult] = useState(null)
  const [portfolioAmountDialogShow, setPortfolioAmountDialogShow] = useState(
    false,
  )
  // TODO: temporary solution for adding amount
  const [expertPortfolioAmount, setExpertPortfolioAmount] = useState(null)

  const [updateOnboardingSteps] = useMutation(updateOnboardingStepsMutation)

  const {
    data: expertPortfoliosData,
    loading: expertPortfoliosLoading,
  } = useQuery(portfolioTemplatesQuery, {
    variables: {
      limit: 999,
      page: 0,
      sort: [{ column: 'createdAt', order: 'desc' }],
    },
  })

  const expertPortfolios = useMemo(
    () => expertPortfoliosData?.portfolioTemplates?.rows || [],
    [expertPortfoliosData],
  )
  const foundMatchingPortfolio = useMemo(
    () =>
      expertPortfolios.find(expertPortfolio => {
        const portfolioRisk = Utils.RiskAssessment.defineRiskTolerance(
          expertPortfolio?.risk,
        )

        return portfolioRisk === me?.profile?.riskAssessment?.riskTolerance
      }),
    [expertPortfolios, me],
  )
  const minimumInvestmentMatching = useMemo(
    () =>
      foundMatchingPortfolio?.minimumInvestment &&
      expertPortfolioAmount < foundMatchingPortfolio.minimumInvestment,
    [foundMatchingPortfolio, expertPortfolioAmount],
  )

  const handleProceed = useCallback(async () => {
    try {
      setLoading(true)

      await updateOnboardingSteps({
        variables: {
          portfolio: true,
        },
      })

      history.push(ONBOARDING_KYC)
    } catch (error) {
      toast.error(get(error, 'message') || _('error.generic'))
      setLoading(false)
    }
  }, [history, updateOnboardingSteps])

  const [addPortfolio] = useMutation(addPortfolioMutation)

  const handleInfoMount = useCallback(instance => {
    infoFormInstance.current = instance
  }, [])

  const handleAssetsMount = useCallback(instance => {
    assetsFormInstance.current = instance
  }, [])

  const handleRebalanceMount = useCallback(instance => {
    rebalanceFormInstance.current = instance
  }, [])

  const handleInfoChange = useCallback(values => {
    setAmount(get(values, ['values', 'amount']) || 0)
  }, [])

  const handleSubmitBacktest = useCallback(async () => {
    const infoForm = infoFormInstance.current
    const assetsForm = assetsFormInstance.current
    const rebalanceForm = rebalanceFormInstance.current

    if (!assetsForm || !rebalanceForm) {
      return
    }

    const infoFormState = infoForm.getState()
    const rebalanceFormState = rebalanceForm.getState()

    // -- Assets
    if (isEmpty(portfolioAssets)) {
      toast.error(_('backtesting.errors.noAssets'))
      return
    }

    const queryAssets = []
    let totalPercentage = 0
    forEach(portfolioAssets, asset => {
      const percentage = toNumber(asset.percentage) || 0

      queryAssets.push({
        asset: asset.base,
        percentage,
      })
      totalPercentage += percentage
    })

    if (!Utils.Numbers.isEqual(totalPercentage, 100)) {
      toast.error(_('backtesting.errors.incorrectAllocation'))
      return
    }

    // -- Query
    const variables = {
      value: toNumber(infoFormState.values?.amount),
      currency: PRIMARY_CURRENCY,
      assets: queryAssets,
    }

    const rebalanceValue = rebalanceFormState.values?.rebalanceValue
    if (rebalanceValue !== undefined) {
      const rebalanceType = rebalanceFormState.values?.rebalanceType?.value
      if (rebalanceType === REBALANCE_TYPE.THRESHOLD) {
        variables.rebalancePercentage = toNumber(rebalanceValue)
      }
      if (rebalanceType === REBALANCE_TYPE.PERIOD) {
        variables.rebalanceDays = toNumber(rebalanceValue)
      }
    }

    try {
      setBacktestingLoading(true)
      const result = await shared.getClient().query({
        query: backtestQuery,
        variables,
      })

      setBacktestResult(result?.data?.backtest)
    } catch (error) {
      toast.error(error?.message || _('error.generic'))
    } finally {
      setBacktestingLoading(false)
    }
  }, [portfolioAssets])

  const handleContinue = useCallback(() => {
    const infoForm = get(infoFormInstance, ['current'])
    infoForm.submit()
    const infoFormState = infoForm.getState()

    if (!infoFormState.valid) {
      return
    }

    setStep(STEPS.BUILD)
  }, [])

  const handleCreatePortfolio = useCallback(async () => {
    const infoForm = infoFormInstance.current
    const assetsForm = assetsFormInstance.current
    const rebalanceForm = rebalanceFormInstance.current

    const rebalanceFormState = rebalanceForm.getState()

    const infoFormState = infoForm.getState()
    if (!infoFormState.valid) {
      return
    }

    const assetsFormState = assetsForm.getState()
    if (isEmpty(assetsFormState.values)) {
      toast.error(_('modal.createPortfolio.noAssets'))
      return
    }

    const portfolioAmount = toNumber(get(infoFormState.values, 'amount')) || 0
    let percentageSum = 0
    let minAssetValueError = false
    forEach(assetsFormState.values, asset => {
      percentageSum += asset.percentage

      if (!minAssetValueError) {
        minAssetValueError =
          asset.percentage * portfolioAmount * 0.01 < MIN_ASSET_VALUE
      }
    })

    if (!Utils.Numbers.isEqual(percentageSum, 100)) {
      toast.error(_('modal.createPortfolio.incorrectAllocation'))
      return
    }

    if (minAssetValueError) {
      toast.error(_('error.minAssetValue', { value: MIN_ASSET_VALUE }))
      return
    }

    try {
      const variables = {
        name: get(infoFormState.values, 'name'),
        amount: portfolioAmount,
        currency: PRIMARY_CURRENCY,
        assets: map(assetsFormState.values, asset => {
          return {
            asset: get(asset, 'base'),
            percentage: toNumber(get(asset, 'percentage')) || 0,
          }
        }),
      }

      const rebalanceValue = get(rebalanceFormState.values, 'rebalanceValue')
      if (!isNil(rebalanceValue)) {
        variables.rebalanceType = get(rebalanceFormState.values, [
          'rebalanceType',
          'value',
        ])
        variables.rebalanceValue = toNumber(rebalanceValue)
      }

      await addPortfolio({
        variables,
        update: Portfolios.add,
      })

      await handleProceed()
    } catch (error) {
      toast.error(get(error, 'message') || _('error.generic'))
    }
  }, [addPortfolio, handleProceed])

  const handleClose = useCallback(
    async success => {
      if (!foundMatchingPortfolio || !success) return

      setLoading(true)

      try {
        await addPortfolio({
          variables: {
            name: foundMatchingPortfolio.name,
            amount: expertPortfolioAmount,
            currency: PRIMARY_CURRENCY,
            portfolioTemplateId: foundMatchingPortfolio.id,
            assets: foundMatchingPortfolio?.assets.map(portfolioAsset => ({
              asset: portfolioAsset.asset,
              percentage: portfolioAsset.percentage,
            })),
            ...(foundMatchingPortfolio?.rebalanceValue && {
              rebalanceValue: foundMatchingPortfolio.rebalanceValue,
            }),
            ...(foundMatchingPortfolio?.rebalanceType && {
              rebalanceType: foundMatchingPortfolio.rebalanceType,
            }),
          },
          update: Portfolios.add,
        })

        toast.success(_('onboarding.portfolio.toasts.success'))

        await handleProceed()
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
      }
    },
    [
      foundMatchingPortfolio,
      expertPortfolioAmount,
      addPortfolio,
      handleProceed,
    ],
  )

  const handleSkipOnboardingPortfolio = useCallback(async () => {
    setLoading(true)

    await updateOnboardingSteps({
      variables: {
        portfolio: true,
        kyc: true,
      },
    })

    history.push(APP_ROOT)
  }, [history, updateOnboardingSteps])

  const portfolioContent = useMemo(() => {
    if (expertPortfoliosLoading) {
      return <Loader />
    }

    return (
      <Row fullWidth spaceBetween>
        {foundMatchingPortfolio ? (
          <>
            <Column>
              <Text actionExtraSmall color="primary">
                {`${
                  me?.profile?.firstName ? `${me.profile.firstName}, ` : ''
                } ${_('onboarding.portfolio.teamHasPrepared')}`}
              </Text>

              <Text fontWeight={2} heading3 mt={3}>
                {_(
                  Utils.RiskAssessment.determineRiskToleranceText(
                    foundMatchingPortfolio.risk,
                  ),
                )}
              </Text>
              <Row maxHeight="80px" overflow="auto">
                <Text
                  dangerouslySetInnerHTML={{
                    __html: foundMatchingPortfolio?.description,
                  }}
                  extraSmall
                  mt={3}
                />
              </Row>

              <Row mt="auto">
                <Button
                  width="141px"
                  onClick={() => setPortfolioAmountDialogShow(true)}
                >
                  {_('onboarding.portfolio.continue')}
                  <ArrowRightIcon height={16} viewBox="0 0 24 24" width={16} />
                </Button>
              </Row>
            </Column>
            <ExpertPortfolioPie expertPortfolio={foundMatchingPortfolio} />
          </>
        ) : (
          <Text fontWeight={2} heading5>
            {_('onboarding.portfolio.noMatchingExpertPortfolio')}
          </Text>
        )}

        {/*  TODO: temporary solution for adding amount */}
        <Dialog
          confirmText={_('onboarding.portfolio.dialog.confirmText')}
          content={
            <InputLabels title={_('onboarding.portfolio.dialog.inputLabel')}>
              <Input
                name="amount"
                placeholder={_('onboarding.portfolio.dialog.inputPlaceholder')}
                small
                type="number"
                value={expertPortfolioAmount}
                onChange={event =>
                  setExpertPortfolioAmount(
                    !event.target.value ? null : Number(event.target.value),
                  )
                }
              />
              {minimumInvestmentMatching && (
                <Text color="dangerPrimary" extraSmall mt={1}>
                  {_('onboarding.portfolio.dialog.errorText', {
                    value: foundMatchingPortfolio.minimumInvestment,
                  })}
                </Text>
              )}
            </InputLabels>
          }
          disabledConfirm={minimumInvestmentMatching}
          isOpen={portfolioAmountDialogShow}
          title={_('onboarding.portfolio.dialog.title')}
          onClose={() => setPortfolioAmountDialogShow(false)}
          onFinish={handleClose}
        />
      </Row>
    )
  }, [
    me,
    expertPortfolioAmount,
    expertPortfoliosLoading,
    foundMatchingPortfolio,
    portfolioAmountDialogShow,
    minimumInvestmentMatching,
    handleClose,
  ])

  return isBuildPortfolio ? (
    <Container gap="32px">
      <Row>
        <Text fontWeight={2} heading2>
          {_('onboarding.portfolio.buildTitle')}
        </Text>

        <Row center gap={4} ml="40px">
          <StepIcon passed={step === STEPS.BUILD}>
            {step === STEPS.BUILD ? (
              <CheckIcon height={24} viewBox="0 0 24 24" width={24} />
            ) : (
              1
            )}
          </StepIcon>
          <Line />
          <StepIcon secondary={step === STEPS.INFO}>2</StepIcon>
        </Row>
      </Row>

      {step === STEPS.INFO && (
        <Row width={1}>
          <PortfolioInfoForm
            width={1}
            onChange={handleInfoChange}
            onMount={handleInfoMount}
          />
        </Row>
      )}

      {step === STEPS.BUILD && (
        <Column width={1}>
          <AssetsSelectList
            amount={toNumber(amount) || 0}
            colors={theme.colors.assets}
            onChange={setPortfolioAssets}
            onMount={handleAssetsMount}
          />

          <Separator mt={2} />

          <Row center mt="40px">
            <PortfolioRebalanceForm
              width="80%"
              onMount={handleRebalanceMount}
            />
            <Button
              alignSelf="flex-end"
              disabled={isBacktestingLoading}
              ml="auto"
              outline
              small
              onClick={handleSubmitBacktest}
            >
              Backtest {isBacktestingLoading && <Loader ml={1} />}
            </Button>
          </Row>

          {backtestResult && (
            <>
              <BacktestingSummary
                currency="$"
                data={backtestResult}
                mb="40px"
                mt="40px"
              />
              <BacktestingChart data={backtestResult?.rows} mt={2} />
            </>
          )}
        </Column>
      )}

      <Row spaceBetween width={1}>
        <Row gap="20px">
          <Button outline small width="180px" onClick={handleProceed}>
            {_('onboarding.portfolio.skip')}
          </Button>

          {step === STEPS.BUILD && (
            <Button
              outline
              small
              width="180px"
              onClick={() => setStep(STEPS.INFO)}
            >
              {_('onboarding.portfolio.back')}
            </Button>
          )}
        </Row>

        {step === STEPS.INFO && (
          <Button width="180px" onClick={handleContinue}>
            {_('onboarding.portfolio.continue')}
          </Button>
        )}

        {step === STEPS.BUILD && (
          <Row gap="20px">
            <Button
              disabled={loading}
              width="180px"
              onClick={handleCreatePortfolio}
            >
              {_('onboarding.portfolio.saveForLater')}{' '}
              {loading && <Loader ml={1} />}
            </Button>
          </Row>
        )}
      </Row>
    </Container>
  ) : (
    <Column width={1}>
      <Row gap="20px">
        <ProgressBar mt="72px" />

        <Column>
          <Row justifyCenter>
            <Text fontWeight={2} heading2>
              {_('onboarding.portfolio.title')}
            </Text>
          </Row>

          <Container mt={6} width="640px">
            <Card gap={5}>{portfolioContent}</Card>

            <Card gap={5} mt="40px">
              <Column>
                <Text fontWeight={2} heading3>
                  {_('onboarding.portfolio.ownPortfolio')[0]}
                  <br />
                  {_('onboarding.portfolio.ownPortfolio')[1]}
                </Text>
                <Text extraSmall mt={3}>
                  {_('onboarding.portfolio.ownPortfolioText')}
                </Text>

                <Row mt={5}>
                  <Button onClick={() => setIsBuildPortfolio(true)}>
                    {_('onboarding.portfolio.buildPortfolio')}

                    <ArrowRightIcon
                      height={16}
                      viewBox="0 0 24 24"
                      width={16}
                    />
                  </Button>
                </Row>
              </Column>
            </Card>

            <Button
              ml="auto"
              mt="20px"
              text
              onClick={handleSkipOnboardingPortfolio}
            >
              {_('onboarding.portfolio.actions.skip')}
            </Button>
          </Container>
        </Column>
      </Row>
    </Column>
  )
}

export default Portfolio
