import styled from 'styled-components'
import { flexbox } from '@styled-system/flexbox'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;

  ${layout.size}
  ${margin}
  ${flexbox}
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Acronym = styled.div`
  width: 100%;
  height: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`
