import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import PortfolioGroupSelect from 'Components/Blocks/Admin/PortfolioGroupSelect'
import { Button, Loader, Modal, Row } from 'Components/UI'
import { InputLabels } from 'Components/UI/Forms'

import adminSetPortfolioGroupsToUserMutation from 'GraphQL/Mutations/Admin/adminSetPortfolioGroupsToUser.graphql'

import _, { useScopedI18n } from 'Services/I18n'

import { Content } from './styles'

const FIELDS = {
  PORTFOLIO_GROUPS: 'portfolioGroups',
}

function EditUserPortfolioGroup({ user, ...rest }) {
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const s = useScopedI18n('admin.modal.editUserPortfolioGroup')

  const [adminSetPortfolioGroupsToUser] = useMutation(
    adminSetPortfolioGroupsToUserMutation,
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.PORTFOLIO_GROUPS]: {
        presence: {
          presence: true,
        },
      },
    }),
    [],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await adminSetPortfolioGroupsToUser({
          variables: {
            userId: user.id,
            portfolioGroupIds: values[FIELDS.PORTFOLIO_GROUPS].map(
              group => group.value,
            ),
          },
        })

        toast.success('Success')

        setLoading(false)

        close.current()
      } catch (error) {
        toast.error(error?.message)

        setLoading(false)
      }
    },
    [adminSetPortfolioGroupsToUser, user],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        initialValues={{
          [FIELDS.PORTFOLIO_GROUPS]: user?.portfolioGroups.map(item => ({
            label: item.name,
            value: item.id,
          })),
        }}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <InputLabels mt="20px" title="Portfolio Group">
                <Field name={FIELDS.PORTFOLIO_GROUPS}>
                  {({ input: { value, onChange: handleChange } }) => (
                    <PortfolioGroupSelect
                      small
                      value={value}
                      withPortal
                      onChange={handleChange}
                    />
                  )}
                </Field>
              </InputLabels>
            </Content>

            <Row borderTop center height={56} justifyCenter mt="20px">
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button
                    secondary
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {_('general.cancel')}
                  </Button>
                  <Button ml={3} width={130} onClick={handleSubmit}>
                    {s('action')}
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

EditUserPortfolioGroup.defaultProps = {
  user: null,
  onRefetch: noop,
}

EditUserPortfolioGroup.propTypes = {
  user: PropTypes.object,
  onRefetch: PropTypes.func,
}

export default EditUserPortfolioGroup
