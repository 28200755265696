import React from 'react'

import { Loader } from 'Components/UI'

import _ from 'Services/I18n'

import { Text } from './styles'

function Root() {
  return (
    <>
      <Loader />
      <Text mt={2}>{_('general.pleaseWait')}</Text>
    </>
  )
}

export default Root
