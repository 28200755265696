/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import numeral from 'numeral'

import { Column, Popover, Row, Table, Text } from 'Components/UI'

import _ from 'Services/I18n'

import { theme } from 'Theme'

import { GainCell, NameCell } from './Cells'
import { ArrowInfoIcon, Content } from './styles'

function AssetsTable({ assets, currency, title, showHoldingsAmount }) {
  const columns = useMemo(() => {
    return [
      {
        Header: () => (
          <Text
            color={theme.colors.font.secondary}
            extraSmall
            fontWeight={0}
            px={3}
          >
            {_('assets.table.name')}
          </Text>
        ),

        Cell: ({ row }) => <NameCell row={row} />,
        id: 'name',
        width: 100,
        disableSortBy: true,
      },
      {
        Header: (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {_('assets.table.holdings')}
          </Text>
        ),
        Cell: ({ value, row }) => (
          <Column>
            {showHoldingsAmount && (
              <Text fontWeight={1} heading6>
                {row.original.amount.toFixed(8)} {row.original.base}
              </Text>
            )}

            <Text heading6>
              {currency}
              {numeral(value?.toFixed(5)).format('0,0.00')}
            </Text>
          </Column>
        ),
        accessor: 'cost',
        width: 70,
        disableSortBy: true,
      },
      {
        Header: (
          <Row center>
            <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
              {_('assets.table.24hChange')}{' '}
            </Text>
            <Popover
              content={_('assets.table.performance24')}
              offset={[0, 10]}
              placement="right"
              showArrow
            >
              <Row>
                <ArrowInfoIcon
                  height={14}
                  ml={1}
                  viewBox="0 0 24 24"
                  width={14}
                />
              </Row>
            </Popover>
          </Row>
        ),
        Cell: ({ row }) => <GainCell row={row} />,
        accessor: 'last24hPercentage',
        width: 70,
        disableSortBy: true,
      },
      {
        Header: (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {_('assets.table.weight')}
          </Text>
        ),
        Cell: ({ value }) => (
          <Text heading6>{numeral(value?.toFixed(3)).format('0.00')}%</Text>
        ),
        accessor: 'weight',
        width: 70,
        disableSortBy: true,
      },
    ]
  }, [currency, showHoldingsAmount])

  return (
    <Content>
      <Text fontWeight={2} heading5>
        {title}
      </Text>
      <Table columns={columns} data={assets} rowHeight={50} sortable />
    </Content>
  )
}

AssetsTable.defaultProps = {
  title: null,
  currency: '$',
  showHoldingsAmount: true,
}

AssetsTable.propTypes = {
  assets: PropTypes.array.isRequired,
  currency: PropTypes.string,
  showHoldingsAmount: PropTypes.bool,
  title: PropTypes.string,
}

export default AssetsTable
