import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.bg.main')};
  padding: 20px;
  border-radius: 12px;

  ${margin};
`
