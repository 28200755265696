import React, { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import PropTypes from 'prop-types'

import { Column, Text } from 'Components/UI'

import { PORTFOLIO_LOCAL_DATA } from 'Constants/portfolios'

import { EXPERT_PORTFOLIO_ROOT } from 'Router/routes'

import {
  ColorBlock,
  LogoIcon,
  LogoImage,
  MenuIconWrapper,
  MenuItem,
} from './styles'

function Item({ id, name, category, color }) {
  const history = useHistory()
  const match = useRouteMatch(EXPERT_PORTFOLIO_ROOT(id))

  const handleClick = useCallback(() => {
    history.push(EXPERT_PORTFOLIO_ROOT(id))
  }, [history, id])

  return (
    <MenuItem active={match} onClick={handleClick}>
      <MenuIconWrapper>
        {PORTFOLIO_LOCAL_DATA[id] ? (
          <LogoImage src={PORTFOLIO_LOCAL_DATA[id].logo} />
        ) : (
          <>
            <ColorBlock background={color} />

            <LogoIcon />
          </>
        )}
      </MenuIconWrapper>

      <Column>
        <Text fontWeight={2} heading6>
          {name}
        </Text>
        <Text fontWeight={1} heading6 small>
          {category}
        </Text>
      </Column>
    </MenuItem>
  )
}

Item.propTypes = {
  category: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
export default Item
