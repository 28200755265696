import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import Utils from 'Utils'

import Switch from './Switch'

function SwitchField({
  checkErrorIfDirty,
  displayError,
  input,
  label,
  name,
  disabled,
  ...rest
}) {
  return (
    <Field name={name} type="checkbox">
      {({ input: { checked, onChange: handleChange }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <>
            <Switch
              {...pick(rest)}
              {...input}
              checked={checked}
              disabled={disabled}
              error={error}
              hasError={hasError}
              label={label}
              onChange={handleChange}
            />
            {displayError && hasError && <div>{error}</div>}
          </>
        )
      }}
    </Field>
  )
}

SwitchField.defaultProps = {
  checkErrorIfDirty: undefined,
  disabled: undefined,
  displayError: true,
  input: null,
  label: undefined,
}

SwitchField.propTypes = {
  checkErrorIfDirty: PropTypes.bool,
  disabled: PropTypes.bool,
  displayError: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
}

export default SwitchField
