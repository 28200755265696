import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFlexLayout, usePagination, useTable } from 'react-table'
import { toast } from 'react-toastify'

import { useLazyQuery, useMutation } from '@apollo/client'

import get from 'lodash/get'

import { AddEditPortfolioTemplateModal } from 'Components/Blocks/Admin/Modals'
import { Dialog } from 'Components/Blocks/Modals'
import { Button, Row } from 'Components/UI'
import { Pagination, Table } from 'Components/UI/Admin'

import adminDeletePortfolioTemplateMutation from 'GraphQL/Mutations/Admin/adminDeletePortfolioTemplate.graphql'
import adminPortfolioTemplatesQuery from 'GraphQL/Queries/Admin/adminPortfolioTemplates.graphql'

import { useColumns } from './columns'
import TableContext from './context'
import { Container, Content } from './styles'

function PortfolioTemplatesTable() {
  const [addEditModal, setAddEditModal] = useState({
    isOpen: false,
    entity: null,
  })
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    entity: null,
  })

  const [loadData, { data, loading, refetch }] = useLazyQuery(
    adminPortfolioTemplatesQuery,
  )
  const [adminDeletePortfolioTemplate] = useMutation(
    adminDeletePortfolioTemplateMutation,
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = get(data, 'adminPortfolioTemplates') || {}

  const tableData = useMemo(() => get(items, 'rows') || [], [items])

  const columns = useColumns()

  const tableProps = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: get(items, 'pages') || 0,
    },
    usePagination,
    useFlexLayout,
  )

  const {
    state: { pageIndex, pageSize },
  } = tableProps

  const fetchData = useCallback(() => {
    const variables = {
      page: pageIndex,
      limit: pageSize,
      sort: [{ column: 'createdAt', order: 'desc' }],
    }

    loadData({ variables, fetchPolicy: 'cache-and-network' })
  }, [loadData, pageIndex, pageSize])

  useEffect(
    () => {
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, pageSize],
  )

  const handleOpenAddModal = useCallback(() => {
    setAddEditModal({ isOpen: true, entity: null })
  }, [])

  const handleOpenEditModal = useCallback(entity => {
    setAddEditModal({ isOpen: true, entity })
  }, [])

  const handleCloseAddEditModal = useCallback(() => {
    setAddEditModal({ isOpen: false, entity: null })
  }, [])

  const handleOpenDeleteModal = useCallback(entity => {
    setDeleteModal({ isOpen: true, entity })
  }, [])

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModal({ isOpen: false, entity: null })
  }, [])

  const handleDeleteTemplate = useCallback(
    async success => {
      if (success) {
        try {
          await adminDeletePortfolioTemplate({
            variables: { id: deleteModal.entity?.id },
          })

          await refetch()
        } catch (error) {
          toast.error(error.message)
        }
      }
      handleCloseDeleteModal()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteModal],
  )

  return (
    <TableContext.Provider
      value={{ onEdit: handleOpenEditModal, onDelete: handleOpenDeleteModal }}
    >
      <Container>
        <Content>
          <Row borderBottom justifyEnd mb={2} pb={3}>
            <Button outline small onClick={handleOpenAddModal}>
              Add template
            </Button>
          </Row>
          <Table {...tableProps} loading={loading} />

          <Row borderTop mt={2} pt={3}>
            <Pagination {...tableProps} total={get(items, ['count']) || 0} />
          </Row>

          {addEditModal.isOpen && (
            <AddEditPortfolioTemplateModal
              isOpen
              template={addEditModal.entity}
              onClose={handleCloseAddEditModal}
              onRefetch={refetch}
            />
          )}

          <Dialog
            content={`Are you sure you want to delete ${deleteModal.entity?.name} template?`}
            isOpen={deleteModal.isOpen}
            title="Delete user"
            onClose={handleCloseDeleteModal}
            onFinish={handleDeleteTemplate}
          />
        </Content>
      </Container>
    </TableContext.Provider>
  )
}

export default PortfolioTemplatesTable
