import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'

import forEach from 'lodash/forEach'
import noop from 'lodash/noop'
import upperFirst from 'lodash/upperFirst'

import { Dialog } from 'Components/Blocks/Modals'
import { Button, Column, Loader, Row, Text } from 'Components/UI'

import deleteExchangeIntegrationMutation from 'GraphQL/Mutations/ExchangeIntegrations/deleteExchangeIntegration.graphql'
import removeUserCryptoWalletMutation from 'GraphQL/Mutations/UserCryptoWallets/removeUserCryptoWallet.graphql'
import ExchangeIntegrations from 'GraphQL/Updaters/ExchangeIntegrations'
import UserCryptoWallets from 'GraphQL/Updaters/UserCryptoWallets'

import { useExchanges, useWallets } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import Item from './Item'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ConnectContainer,
  Inform,
  ItemsList,
  PlusIcon,
  WarningIcon,
} from './styles'

const LIST_ENTITY = {
  WALLET: 'wallet',
  EXCHANGE: 'exchange',
}

function ConnectedAccounts({
  content,
  showContinue,
  onConnect,
  onContinue,
  onBack,
}) {
  const s = useScopedI18n('blocks.connectedAccounts')

  const [entityToDelete, setEntityToDelete] = useState(null)

  const [, exchanges, exchangesLoading] = useExchanges()
  const [, wallets, walletsLoading] = useWallets()

  const [deleteExchangeIntegration] = useMutation(
    deleteExchangeIntegrationMutation,
  )
  const [removeUserCryptoWallet] = useMutation(removeUserCryptoWalletMutation)

  const handleDisconnect = useCallback(
    confirmation => {
      if (!confirmation) return

      if (entityToDelete.type === LIST_ENTITY.EXCHANGE) {
        deleteExchangeIntegration({
          variables: { id: entityToDelete?.id },
          update: ExchangeIntegrations.remove(entityToDelete?.id),
        })
      }

      if (entityToDelete.type === LIST_ENTITY.WALLET) {
        removeUserCryptoWallet({
          variables: { id: entityToDelete?.id },
          update: UserCryptoWallets.remove(entityToDelete?.id),
        })
      }
    },
    [deleteExchangeIntegration, entityToDelete, removeUserCryptoWallet],
  )

  const items = useMemo(() => {
    const result = []

    forEach(exchanges, exchange => {
      result.push(
        <Item
          action="Disconnect"
          key={exchange.id}
          logo={<img alt={exchange.exchange} src={exchange.logo} />}
          outlineButton
          title={exchange.exchange}
          onClick={() =>
            setEntityToDelete({ ...exchange, type: LIST_ENTITY.EXCHANGE })
          }
        />,
      )
    })

    forEach(wallets, wallet => {
      result.push(
        <Item
          action="Disconnect"
          key={wallet.id}
          logo={<img alt={wallet.name} src={wallet.logo} />}
          outlineButton
          title={wallet.name}
          onClick={() =>
            setEntityToDelete({ ...wallet, type: LIST_ENTITY.WALLET })
          }
        />,
      )
    })

    result.push(
      <Item
        action={s('connectAction')}
        description={s('connectDescription')}
        key="connect"
        logo={
          <ConnectContainer>
            <PlusIcon height={21} viewBox="0 0 24 24" width={21} />
          </ConnectContainer>
        }
        title={s('connect')}
        onClick={onConnect}
      />,
    )

    return result
  }, [exchanges, wallets, onConnect, s])

  const loading = exchangesLoading || walletsLoading

  return (
    <>
      <Column fullWidth>
        <Text as="h3" fontWeight={2} heading3>
          {s('title')}
        </Text>
        <Text as="h6" fontWeight={1} heading6 mt={3}>
          {s('subTitle')}
        </Text>
      </Column>

      <ItemsList gap={4}>{loading ? <Loader /> : items}</ItemsList>

      <Inform>
        <WarningIcon height={21} viewBox="0 0 24 24" width={21} />
        <Text extraSmall>{s('information')}</Text>
      </Inform>

      {!!content && content}

      <Dialog
        content={s('disconnect', {
          name: upperFirst(entityToDelete?.name || entityToDelete?.exchange),
        })}
        isOpen={!!entityToDelete}
        title={s('disconnectTitle')}
        onClose={() => setEntityToDelete(null)}
        onFinish={handleDisconnect}
      />

      <Row spaceBetween>
        <Button outline onClick={onBack}>
          <ArrowLeftIcon height={14} viewBox="0 0 24 24" width={14} />
          {s('back')}
        </Button>

        {showContinue && (
          <Button
            disabled={!wallets?.length && !exchanges?.length}
            onClick={onContinue}
          >
            {s('continue')}
            <ArrowRightIcon height={14} viewBox="0 0 24 24" width={14} />
          </Button>
        )}
      </Row>
    </>
  )
}

ConnectedAccounts.defaultProps = {
  content: null,
  showContinue: false,
  onBack: noop,
  onConnect: noop,
  onContinue: noop,
}

ConnectedAccounts.propTypes = {
  content: PropTypes.node,
  showContinue: PropTypes.bool,
  onBack: PropTypes.func,
  onConnect: PropTypes.func,
  onContinue: PropTypes.func,
}

export default ConnectedAccounts
