import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Select } from 'Components/UI'
import { InputLabels } from 'Components/UI/Forms'

import { KIND } from 'Constants/transactions'

import { useScopedI18n } from 'Services/I18n'

function KindSelect({ option, onChange }) {
  const s = useScopedI18n('transactions.table.filters.kind')

  const valueToOption = useCallback(
    value => ({
      label: s(`values.${value}`),
      value,
    }),
    [s],
  )
  const options = useMemo(
    () => [
      valueToOption(KIND.DEPOSIT),
      valueToOption(KIND.WITHDRAW),
      valueToOption(KIND.BUY_ORDER_FILLED),
      valueToOption(KIND.SELL_ORDER_FILLED),
      valueToOption(KIND.REBALANCE_BUY_ORDER_FILLED),
      valueToOption(KIND.REBALANCE_SELL_ORDER_FILLED),
      valueToOption(KIND.FUND_PORTFOLIO),
      valueToOption(KIND.CLOSE_PORTFOLIO),
    ],
    [valueToOption],
  )

  return (
    <InputLabels title={s('label')} width="160px">
      <Select options={options} value={option} onChange={onChange} />
    </InputLabels>
  )
}

KindSelect.defaultProps = {
  option: null,
  onChange: noop,
}

KindSelect.propTypes = {
  option: PropTypes.object,
  onChange: PropTypes.func,
}

export default KindSelect
