import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'
import checkGlyph from 'iconoir/icons/check.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  width: 500px;
  padding: 60px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 12px;
  background: ${themeGet('colors.bg.main')};
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: ${themeGet('colors.bg.success')};
  border-radius: 50%;
`

export const CheckIcon = styled(checkGlyph)`
  color: ${themeGet('colors.successPrimary')};
`

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`
