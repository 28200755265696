import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { color } from 'styled-system'
import { flexbox } from '@styled-system/flexbox'
import { layout } from '@styled-system/layout'
import { space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'
import { typography } from '@styled-system/typography'

import Styles from 'Components/Styles'

import { gap } from 'Theme/system'

const outlineCss = ({ outline }) =>
  outline &&
  css`
    color: ${mapToTheme('buttons.outline.color')};
    background-color: transparent !important;

    &:focus,
    &:hover {
      border-color: ${mapToTheme('buttons.outline.hover')};
      color: ${mapToTheme('buttons.outline.color')};
    }

    &:active {
      border-color: ${mapToTheme('buttons.outline.active')};
      color: ${mapToTheme('buttons.outline.color')};
    }

    &:disabled {
      border-color: ${mapToTheme('buttons.outline.disabled')};
      color: ${mapToTheme('buttons.outline.color')};
    }
  `

const restrictedCss = ({ restricted }) =>
  restricted &&
  css`
    color: ${mapToTheme('buttons.restricted.color')};
    background-color: ${mapToTheme('buttons.restricted.backgroundColor')};
    opacity: ${mapToTheme('buttons.restricted.opacity')};

    &:focus,
    &:hover {
      border-color: ${mapToTheme('buttons.restricted.hover')};
      color: ${mapToTheme('buttons.restricted.color')};
    }

    &:active {
      border-color: ${mapToTheme('buttons.restricted.active')};
      color: ${mapToTheme('buttons.restricted.color')};
    }
  `

const ghostCss = ({ ghost }) =>
  ghost &&
  css`
    color: ${mapToTheme('buttons.ghost.color')};
    background-color: transparent;

    &:hover {
      background-color: ${mapToTheme('buttons.ghost.hover.backgroundColor')};
    }

    &:focus {
      background-color: ${mapToTheme('buttons.ghost.focus.backgroundColor')};
      box-shadow: 0 0 0 3px ${mapToTheme('buttons.ghost.focus.boxShadow')};
    }

    &:active {
      background-color: ${mapToTheme('buttons.ghost.active.backgroundColor')};
    }

    &:disabled {
      background-color: transparent;
      border-color: transparent;
      color: ${mapToTheme('buttons.ghost.disabled.color')};
    }
  `

const iconCss = ({ icon }) =>
  icon &&
  css`
    flex-shrink: 0;
    width: ${mapToTheme('buttons.height')}px;
    padding: 0;
  `

const smallCss = ({ small }) =>
  small &&
  css`
    line-height: 0;
  `

export const textCss = ({ text }) =>
  text &&
  css`
    font-weight: ${themeGet('fontWeights.2')};
    background-color: transparent;
    border-color: transparent;
    height: auto;
    padding: 4px;
  `

const Button = styled.button`
  position: relative;
  ${Styles.flexCenter};
  flex-shrink: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;

  border-style: solid;
  border-radius: ${mapToTheme('buttons.border.radius')}px;
  border-width: ${mapToTheme('buttons.border.width')}px;
  border-color: ${mapToTheme('buttons.border.color')};

  height: ${mapToTheme('buttons.height')}px;
  padding: ${mapToTheme('buttons.padding')};

  font-family: inherit;
  font-size: ${mapToTheme('buttons.fontSize')}px;
  font-weight: ${mapToTheme('buttons.fontWeight')};

  color: ${mapToTheme('buttons.color')};
  background-color: ${mapToTheme('buttons.backgroundColor')};

  transition: all 0.17s ease-in-out;

  &:focus,
  &:hover {
    background-color: ${mapToTheme('buttons.hover.backgroundColor')};
  }

  :hover {
    color: ${mapToTheme('buttons.hover.color')};
  }

  :focus {
    box-shadow: 0 0 0 3px ${mapToTheme('buttons.focus.boxShadow')};
  }

  &:active {
    background-color: ${mapToTheme('buttons.active.backgroundColor')};
    color: ${mapToTheme('buttons.active.color')};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${mapToTheme('buttons.disabled.backgroundColor')};
    color: ${mapToTheme('buttons.disabled.color')};

    &:hover {
      color: ${mapToTheme('buttons.color')};
    }
  }

  ${outlineCss}
  ${restrictedCss}
  ${iconCss}
  ${smallCss}
  ${ghostCss}
  ${textCss}


  ${layout}
  ${space}
  ${typography}
  ${flexbox}
  ${color}
  ${gap}
`

Button.propTypes = {
  big: PropTypes.bool,
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  icon: PropTypes.bool,
  outline: PropTypes.bool,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  squared: PropTypes.bool,
  xSmall: PropTypes.bool,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  big: false,
  disabled: false,
  ghost: false,
  icon: false,
  small: false,
  outline: false,
  secondary: false,
  squared: false,
  xSmall: false,
  onClick: undefined,
}

export default Button
