import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { SIDEBAR_DEFAULTS } from 'Constants/defaults'
import { SIDEBAR_ACTIONS, SIDEBAR_STATE } from 'Constants/store'

export default function sidebarState(store) {
  store.on('@init', () => ({
    [SIDEBAR_STATE]: SIDEBAR_DEFAULTS,
  }))

  store.on(SIDEBAR_ACTIONS.SET, (state, variables) => {
    const values = pick(variables, Object.keys(SIDEBAR_DEFAULTS))

    const nextState = { ...state[SIDEBAR_STATE], ...values }

    if (isEqual(nextState, state[SIDEBAR_STATE])) return null

    return { [SIDEBAR_STATE]: nextState }
  })
}
