import { KIND, STATE } from 'Constants/userTransactions'

const KIND_TEXT = {
  [KIND.DEPOSIT]: 'data.userTransaction.kind.deposit',
  [KIND.WITHDRAWAL]: 'data.userTransaction.kind.withdrawal',
}

function kindText(userTransaction) {
  return KIND_TEXT[userTransaction?.kind] || 'data.userTransaction.kind.unknown'
}

const STATE_TEXT = {
  [STATE.PENDING]: 'data.userTransaction.state.pending',
  [STATE.APPROVED]: 'data.userTransaction.state.approved',
  [STATE.DECLINED]: 'data.userTransaction.state.declined',
  [STATE.CANCELED]: 'data.userTransaction.state.canceled',
}

function stateText(userTransaction) {
  return (
    STATE_TEXT[userTransaction?.state] || 'data.userTransaction.state.unknown'
  )
}

export default {
  kindText,
  stateText,
}
