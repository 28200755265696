import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { useQuery } from '@apollo/client'

import first from 'lodash/first'
import orderBy from 'lodash/orderBy'

import {
  ExpertPortfolioDetails,
  ExpertPortfolioHoldingsList,
  ExpertPortfolioPie,
} from 'Components/Blocks/ExpertPortfolio'
import { PORTFOLIO_FUNDING } from 'Components/Blocks/PortfolioFunding/constants'
import { Button, Column, Loader, Popover, Row, Text } from 'Components/UI'

import { STATE as KYC_STATE } from 'Constants/kyc'
import { PORTFOLIO_LOCAL_DATA } from 'Constants/portfolios'

import portfolioTemplateQuery from 'GraphQL/Queries/PortfolioTemplates/portfolioTemplate.graphql'

import { useAppContext } from 'Hooks'

import { EXPERT_PORTFOLIO_BACKTEST, FUNDING, KYC } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'
import shared from 'Services/Shared'

import { Content, Description, Wrapper } from './styles'

function ExpertPortfolioRoot() {
  const { me } = useAppContext()
  const history = useHistory()
  const { expertPortfolioId } = useParams()
  const s = useScopedI18n('expertPortfolio.page')

  const [portfolio, setPortfolio] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const { data } = useQuery(portfolioTemplateQuery, {
    variables: {
      id: expertPortfolioId,
    },
  })

  const isKYCApproved = me?.kyc?.state === KYC_STATE.APPROVED
  const expertPortfolio = data?.portfolioTemplate

  useEffect(() => {
    if (!expertPortfolio) return

    const { assets } = expertPortfolio

    setLoading(true)
    shared
      .getPortfolioService()
      .populatePortfolios([
        {
          ...expertPortfolio,
          assets: orderBy(assets, ['percentage'], ['desc']),
        },
      ])
      .then(result => {
        setPortfolio(first(result))
        setLoading(false)
      })
  }, [expertPortfolio])

  const handleInvest = () => {
    if (isKYCApproved) {
      history.push({
        pathname: FUNDING,
        state: {
          portfolio,
          type: PORTFOLIO_FUNDING.EXPERT_PORTFOLIO,
        },
      })
    } else {
      history.push(KYC)
    }
  }

  const handleBacktest = () => {
    history.push(EXPERT_PORTFOLIO_BACKTEST(portfolio.id))
  }

  const handleFactsheetClick = () => {
    if (!PORTFOLIO_LOCAL_DATA[portfolio.id]) {
      window.open(
        'https://www.upwealth.io/managed-portfolio/#:~:text=Aggressive%20Strategy-,Flagship,-Large%2C%20best%2Din',
        '_blank',
      )
    }

    window.open(
      PORTFOLIO_LOCAL_DATA[portfolio.id].factSheetPdfUrl,
      '_blank',
      'noopener,noreferrer',
    )
  }

  return (
    <Content>
      <Wrapper>
        {isLoading ? (
          <Row fullWidth justifyCenter>
            <Loader />
          </Row>
        ) : (
          <>
            <Row gap="60px">
              <Column width="206px">
                <Text
                  as="h3"
                  fontWeight={2}
                  heading3
                  textAlign="center"
                  width={1}
                >
                  {expertPortfolio?.name}
                </Text>

                <ExpertPortfolioPie expertPortfolio={portfolio} mt="20px" />

                <Column mt="20px">
                  <Popover
                    content={isKYCApproved ? null : s('investRestricted')}
                    offset={[10, 10]}
                    showArrow
                  >
                    <Button
                      restricted={!isKYCApproved}
                      small
                      width={1}
                      onClick={handleInvest}
                    >
                      {s('invest')}
                    </Button>
                  </Popover>

                  <Button
                    mt={3}
                    outline
                    small
                    width={1}
                    onClick={handleFactsheetClick}
                  >
                    {s('factsheet')}
                  </Button>
                </Column>
              </Column>

              <Description
                dangerouslySetInnerHTML={{ __html: portfolio?.description }}
              />
            </Row>

            <Column mt="60px">
              <ExpertPortfolioHoldingsList assets={portfolio?.assets} />
              <Row fullWidth justifyEnd mt={3}>
                <Button xSmall onClick={handleBacktest}>
                  {s('backtest')}
                </Button>
              </Row>
            </Column>

            <ExpertPortfolioDetails expertPortfolio={portfolio} mt="60px" />
          </>
        )}
      </Wrapper>
    </Content>
  )
}

export default ExpertPortfolioRoot
