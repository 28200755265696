export const KIND = {
  VEHICLE: 'vehicle',
  VACATION: 'vacation',
  CASH: 'cash',
  HOUSE: 'house',
  RENOVATION: 'renovation',
  EDUCATION: 'education',
  HEALTHCARE: 'healthcare',
  WEDDING: 'wedding',
  RETIREMENT: 'retirement',
  OTHER: 'other',
}
