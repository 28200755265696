import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

export const Container = styled.div`
  ${Styles.flexCenter};
  flex-direction: column;
`

export const Header = styled.div`
  ${Styles.flexCenter};
  flex-direction: column;
  max-width: 700px;
  text-align: justify-all;
`

export const Paragraph = styled.div`
  ${margin}
`

export const Title = styled.div`
  ${Styles.text(32)};
  font-weight: bold;
`

export const Text = styled.div`
  ${Styles.text(16)};

  ${margin}
`

export const MailLink = styled.a`
  text-transform: none;
  color: ${themeGet('colors.font.colored')};
`
