import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { useQuery } from '@apollo/client'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'

import AssetsLineChart from 'Components/Blocks/Charts/Assets/Line'
import ChartPlaceholder from 'Components/Blocks/Charts/Placeholder'

import exchangeIntegrationDataQuery from 'GraphQL/Queries/ExchangeIntegrations/exchangeIntegrationData.graphql'
import portfolioDataQuery from 'GraphQL/Queries/Portfolios/portfolioData.graphql'
import userCryptoWalletDataQuery from 'GraphQL/Queries/UserCryptoWallets/userCryptoWalletData.graphql'

import { useScopedI18n } from 'Services/I18n'

import { Container } from './styles'

const KIND = {
  PORTFOLIO: 'portfolio',
  EXCHANGE: 'exchange',
  WALLET: 'wallet',
}

function PortfolioPerformance({
  actionTitle,
  id,
  title,
  kind,
  onDateRangeChange,
  ...rest
}) {
  const s = useScopedI18n('portfolio.performance')

  const { data: portfolioData } = useQuery(portfolioDataQuery, {
    variables: { id },
    skip: kind !== KIND.PORTFOLIO || !id,
  })

  const { data: exchangeIntegrationData } = useQuery(
    exchangeIntegrationDataQuery,
    {
      variables: { id },
      skip: kind !== KIND.EXCHANGE || !id,
    },
  )

  const { data: userCryptoWalletData } = useQuery(userCryptoWalletDataQuery, {
    variables: { id },
    skip: kind !== KIND.WALLET || !id,
  })

  const chartData = useMemo(() => {
    switch (kind) {
      case KIND.PORTFOLIO:
        return map(portfolioData?.portfolioData?.rows, ({ time, amount }) => ({
          date: time,
          value: amount,
        }))
      case KIND.EXCHANGE:
        return map(
          exchangeIntegrationData?.exchangeIntegrationData?.rows,
          ({ time, amount }) => ({
            date: time,
            value: amount,
          }),
        )
      case KIND.WALLET:
        return map(
          userCryptoWalletData?.userCryptoWalletData?.rows,
          ({ time, amount }) => ({
            date: time,
            value: amount,
          }),
        )
      default:
        return []
    }
  }, [kind, exchangeIntegrationData, portfolioData, userCryptoWalletData])

  return (
    <Container {...pick(rest)}>
      <AssetsLineChart
        data={chartData}
        displayExampleData={isEmpty(chartData)}
        height={192}
      />

      {isEmpty(chartData) && (
        <ChartPlaceholder title={s('chartPlaceholderTitle')} />
      )}
    </Container>
  )
}

PortfolioPerformance.defaultProps = {
  actionTitle: null,
  id: null,
  title: null,
  kind: null,
  onDateRangeChange: noop,
}

PortfolioPerformance.propTypes = {
  actionTitle: PropTypes.string,
  id: PropTypes.string,
  kind: PropTypes.oneOf(Object.values(KIND)),
  title: PropTypes.string,
  onDateRangeChange: PropTypes.func,
}

PortfolioPerformance.KIND = KIND

export default PortfolioPerformance
