import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  padding: ${themeGet('space.4')}px ${themeGet('space.4')}px
    ${themeGet('space.5')}px;

  overflow: auto;
`

export const IconWrapper = styled.div`
  ${Styles.flexCenter};
  width: 24px;
  height: 24px;
  ${Styles.border(4)};
  border-color: ${themeGet('colors.border.colored')};

  > svg {
    color: ${themeGet('colors.font.primary')};
  }
`

export const ErrorText = styled.div`
  ${Styles.text()};
  color: ${themeGet('colors.negative')};

  ${margin}
`
