export const SYSTEM_WALLET_NETWORKS = {
  BSC: 'BSC',
  ETH: 'ETH',
  TRX: 'TRX',
  MATIC: 'MATIC',
  AVALANCHE: 'AVALANCHE',
}

export const SYSTEM_WALLET_TOKENS = {
  [SYSTEM_WALLET_NETWORKS.BSC]: 'BNB Smart Chain (BEP20)',
  [SYSTEM_WALLET_NETWORKS.ETH]: 'Ethereum (ERC20)',
  [SYSTEM_WALLET_NETWORKS.TRX]: 'Tron (TRC 20)',
  [SYSTEM_WALLET_NETWORKS.MATIC]: 'Polygon',
  [SYSTEM_WALLET_NETWORKS.AVALANCHE]: 'AVAX C-Chain',
}
