import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { useLazyQuery, useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'

import { Button, Column, Link, Loader } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms'

import { GRAPHQL } from 'Constants/errors'
import { PASSWORD_REGEX } from 'Constants/strings'

import changePasswordMutation from 'GraphQL/Mutations/Auth/changePassword.graphql'
import checkPasswordTokenQuery from 'GraphQL/Queries/Auth/checkPasswordToken.graphql'

import { useLocationQueryParams } from 'Hooks'

import { ROOT } from 'Router/routes'

import _ from 'Services/I18n'

import {
  Card,
  CheckIcon,
  ControlsRow,
  FormContent,
  IconWrapper,
  Info,
  SubText,
  Text,
  Title,
} from '../styles'

const FIELDS = {
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
}

function ChangePassword() {
  const history = useHistory()
  const queryParams = useLocationQueryParams()

  const [loading, setLoading] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)

  const token = get(queryParams, 'token')

  const [
    checkVerificationToken,
    { data: tokenData, error: tokenError },
  ] = useLazyQuery(checkPasswordTokenQuery)
  const [changePassword] = useMutation(changePasswordMutation)

  useEffect(() => {
    if (token) {
      checkVerificationToken({ variables: { token } })
    }
  }, [checkVerificationToken, token])

  const renderForm = useCallback(
    ({ handleSubmit }) => {
      return (
        <FormContent>
          <Title>{_('auth.changePassword.title')}</Title>
          <SubText mt={2}>{_('auth.changePassword.subtitle')}</SubText>
          <InputLabels
            mt={4}
            title={`${_('auth.changePassword.newPassword')}*`}
          >
            <InputField
              input={{
                placeholder: _('auth.shared.passwordPlaceholder'),
                type: 'password',
              }}
              name={FIELDS.PASSWORD}
            />
          </InputLabels>
          <InputLabels
            mt={4}
            title={`${_('auth.changePassword.confirmPassword')}*`}
          >
            <InputField
              input={{
                placeholder: _('auth.shared.confirmPasswordPlaceholder'),
                type: 'password',
              }}
              name={FIELDS.CONFIRM_PASSWORD}
            />
          </InputLabels>

          <Button disabled={loading} mt={5} onClick={handleSubmit}>
            {_('auth.changePassword.action')}
            {loading && <Loader ml={1} />}
          </Button>
        </FormContent>
      )
    },
    [loading],
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.PASSWORD]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.passwordRequired')}`,
        },
        format: {
          pattern: PASSWORD_REGEX,
          flags: 'i',
          message: `^${_('auth.shared.passwordInvalid')}`,
        },
      },
      [FIELDS.CONFIRM_PASSWORD]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.confirmPasswordRequired')}`,
        },
        equality: {
          attribute: FIELDS.PASSWORD,
          message: `^${_('auth.shared.confirmPasswordNotEqual')}`,
        },
      },
    }),
    [],
  )

  const handleSubmit = useCallback(
    async values => {
      try {
        setLoading(true)

        await changePassword({
          variables: {
            token,
            password: get(values, FIELDS.PASSWORD),
          },
        })

        setPasswordChanged(true)
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
      } finally {
        setLoading(false)
      }
    },
    [changePassword, token],
  )

  const handleSignIn = useCallback(() => {
    history.push(ROOT)
  }, [history])

  if (!tokenData && !tokenError) {
    return null
  }

  const errorMessage = get(tokenError, 'message')

  if (errorMessage === GRAPHQL.AUTH.PASSWORD_TOKEN_INVALID) {
    return (
      <Column center height="100%" justifyCenter width={1}>
        <Card>
          <Info>
            <Text large>{_('general.oops')}!</Text>
            <Text mt={2}>{_('auth.changePassword.tokenInvalid')}</Text>
          </Info>
        </Card>
      </Column>
    )
  }

  if (errorMessage === GRAPHQL.AUTH.PASSWORD_TOKEN_EXPIRED) {
    return (
      <Column center height="100%" justifyCenter width={1}>
        <Card>
          <Info>
            <Text large>{_('general.oops')}!</Text>
            <Text mt={2}>{_('auth.changePassword.tokenExpired')}</Text>
          </Info>
        </Card>
      </Column>
    )
  }

  return (
    <>
      <ControlsRow>
        <Text>
          {_('auth.changePassword.backTo')}{' '}
          <Link fontWeight={600} to={ROOT}>
            {_('auth.changePassword.signIn')}
          </Link>
        </Text>
      </ControlsRow>

      {passwordChanged ? (
        <Column center height="100%" justifyCenter width={1}>
          <Card>
            <IconWrapper>
              <CheckIcon height={24} viewBox="0 0 24 24" width={24} />
            </IconWrapper>

            <Info mt={5}>
              <Title>{_('auth.changePassword.title')}</Title>
              <SubText mt={2}>{_('auth.changePassword.success')}</SubText>
            </Info>
            <Button mt={5} onClick={handleSignIn}>
              {_('auth.changePassword.signIn')}
            </Button>
          </Card>
        </Column>
      ) : (
        <Column center height="100%" justifyCenter width={1}>
          <Card>
            <Form
              render={renderForm}
              validate={values => validate(values, formConstraints)}
              onSubmit={handleSubmit}
            />
          </Card>
        </Column>
      )}
    </>
  )
}

export default ChangePassword
