import React from 'react'
import PropTypes from 'prop-types'

import { Container, Title } from './styles'

function ChartPlaceholder({ title }) {
  return (
    <Container>
      <Title>{title}</Title>
    </Container>
  )
}

ChartPlaceholder.defaultProps = {
  title: undefined,
}

ChartPlaceholder.propTypes = {
  title: PropTypes.string,
}

export default ChartPlaceholder
