import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { useTheme } from 'styled-components'
import { pick } from '@styled-system/props'

import { Button, Column, Text } from 'Components/UI'

import { DEPOSIT } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import { Block, NoAssetsIcon } from './styles'

function NoAssets({ ...rest }) {
  const theme = useTheme()
  const s = useScopedI18n('assets.noAssets')
  const history = useHistory()

  const handleDeposit = useCallback(() => {
    history.push(DEPOSIT)
  }, [history])

  return (
    <Block {...pick(rest)}>
      <Column center flexGrow={1} gap="20px" justifyCenter>
        <NoAssetsIcon />
        <Text color={theme.colors.text70} heading5>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: s('description') }} />
        </Text>

        <Button xSmall onClick={handleDeposit}>
          {s('action')}
        </Button>
      </Column>
    </Block>
  )
}

export default NoAssets
