import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.bg.main')};
  padding: 20px;
  border-radius: 12px;
`

export const Description = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: ${themeGet('fontSizes.2')}px;
    font-style: normal;
    font-weight: ${themeGet('fontWeights.2')};
    line-height: 20px;
    font-feature-settings: 'clig' off, 'liga' off;
  }

  p {
    margin: ${themeGet('space.3')}px 0;
    font-size: ${themeGet('fontSizes.1')}px;
    font-style: normal;
    line-height: 18px;
    font-feature-settings: 'clig' off, 'liga' off;
  }
`
