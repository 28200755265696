import get from 'lodash/get'

import { USER_ROLE } from 'Constants/ids'

import { ADMIN_ROOT, APP_ROOT, ROOT } from 'Router/routes'

function isOnboardingPassed(user) {
  const { plan, portfolio, riskAssessment, kyc } =
    get(user, ['profile', 'onboardingSteps']) || {}

  return plan && portfolio && riskAssessment && kyc
}

function getRootRoute(user) {
  switch (user?.role) {
    case USER_ROLE.ADMIN:
    case USER_ROLE.SUPER_ADMIN:
      return ADMIN_ROOT
    case USER_ROLE.USER:
      return APP_ROOT
    default:
      return ROOT
  }
}

export default {
  isOnboardingPassed,
  getRootRoute,
}
