import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import numeral from 'numeral'

import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import noop from 'lodash/noop'

import { Column, Row, Text } from 'Components/UI'

import { PRIMARY_CURRENCY } from 'Constants/currencies'

import { useScopedI18n } from 'Services/I18n'

import { theme } from 'Theme'

import { ArrowDownIcon, ArrowUpIcon, Card, Container, Value } from './styles'

function arrow(value) {
  if (value > 0) {
    return (
      <ArrowUpIcon
        color={theme.colors.primary}
        height={14}
        viewBox="0 0 24 24"
        width={14}
      />
    )
  }
  if (value < 0) {
    return (
      <ArrowDownIcon
        color={theme.colors.dangerLight}
        height={14}
        viewBox="0 0 24 24"
        width={14}
      />
    )
  }

  return null
}

function formatValue(value, currency = '$') {
  return numeral(value).format(`${currency}0,0.[00]`)
}

function formatReturn(value) {
  return numeral(value).format('0.00%')
}

function BacktestingSummary({ data, currency, onAction, ...rest }) {
  const s = useScopedI18n('blocks.backtesting.summary')

  const processedData = useMemo(() => {
    const result = {}
    if (isEmpty(data?.rows)) return result

    const initialValue = data?.initialValue
    const platformGain = last(data?.rows)?.value || 0
    const hold = last(data?.rows)?.hold || 0

    result.initialValue = initialValue
    result.platformGain = platformGain
    result.platformReturn = (platformGain - initialValue) / initialValue
    result.hold = hold
    result.return = (hold - initialValue) / initialValue

    return result
  }, [data])

  return (
    <Container {...pick(rest)}>
      <Card>
        <Text fontWeight={1} heading6 textAlign="center" width={1}>
          {s('rebalance')}
        </Text>
        <Row gap="40px" mt="15px" spaceBetween>
          <Column alignEnd>
            <Value
              negative={processedData.platformReturn < 0}
              positive={processedData.platformReturn > 0}
            >
              {processedData.platformReturn < 0 && '-'}
              {formatValue(processedData.platformGain, currency)}
            </Value>
            <Text mt={1} small>
              {s('gainValue')}
            </Text>
          </Column>
          <Column alignEnd>
            <Value
              negative={processedData.platformReturn < 0}
              positive={processedData.platformReturn > 0}
            >
              {arrow(processedData.platformReturn)}
              {formatReturn(processedData.platformReturn)}
            </Value>
            <Text mt={1} small>
              {s('return')}
            </Text>
          </Column>
        </Row>
      </Card>

      <Text actionSmall fontWeight={1}>
        vs
      </Text>

      <Card>
        <Text fontWeight={1} heading6 textAlign="center" width={1}>
          {s('hold')}
        </Text>
        <Row gap="40px" mt="15px" spaceBetween>
          <Column alignEnd>
            <Value
              negative={processedData.return < 0}
              positive={processedData.return > 0}
            >
              {processedData.return < 0 && '-'}
              {formatValue(processedData.hold, currency)}
            </Value>
            <Text mt={1} small>
              {s('gainValue')}
            </Text>
          </Column>
          <Column alignEnd>
            <Value
              negative={processedData.return < 0}
              positive={processedData.return > 0}
            >
              {arrow(processedData.return)}
              {formatReturn(processedData.return)}
            </Value>
            <Text mt={1} small>
              {s('return')}
            </Text>
          </Column>
        </Row>
      </Card>
    </Container>
  )
}

BacktestingSummary.defaultProps = {
  currency: PRIMARY_CURRENCY,
  data: null,
  onAction: noop,
}

BacktestingSummary.propTypes = {
  currency: PropTypes.string,
  data: PropTypes.shape({
    initialValue: PropTypes.number,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        hold: PropTypes.number,
      }),
    ),
  }),
  onAction: PropTypes.func,
}

export default BacktestingSummary
