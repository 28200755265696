import styled from 'styled-components'
import { layout } from 'styled-system'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import cancelGlyph from 'iconoir/icons/cancel.svg'
import checkGlyph from 'iconoir/icons/check.svg'

export const BlockContainer = styled.div`
  background-color: ${themeGet('colors.white')};
  padding: 20px;
  border-radius: 12px;

  ${layout}
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${themeGet('colors.divider.light')};
  ${margin}
`

export const CancelIcon = styled(cancelGlyph)`
  stroke-width: 1px;
  color: ${themeGet('colors.dangerPrimary')};
`
export const CheckIcon = styled(checkGlyph)`
  stroke-width: 1px;
  color: ${themeGet('colors.primary50')};
`
