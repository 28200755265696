import styled from 'styled-components'
import { space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Arrow = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 6px;
    content: '';
    transform: rotate(45deg);
    background: ${themeGet('colors.bg.main')};
  }
`

export const PopoverBlock = styled.div`
  padding: ${themeGet('space.1')}px ${themeGet('space.3')}px;
  background: ${themeGet('colors.bg.main')};
  border-radius: 4px;
  color: ${themeGet('colors.text70')};
  box-shadow: 0px 8px 10px 0px rgba(116, 123, 134, 0.1),
    0px -2px 10px 0px rgba(116, 123, 134, 0.1);

  &[data-placement^='top'] ${Arrow} {
    bottom: -2px;
  }

  &[data-placement^='bottom'] ${Arrow} {
    top: -2px;
  }

  &[data-placement^='left'] ${Arrow} {
    right: -2px;
  }

  &[data-placement^='right'] ${Arrow} {
    left: -2px;
  }

  ${space};
`
