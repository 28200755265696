import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import CurrencyIcon from 'Components/Blocks/CurrencyIcon'
import { IconWrapper } from 'Components/Blocks/Widgets/NetWorth/styles'
import { Row, Text } from 'Components/UI'

function Name({ row }) {
  const theme = useTheme()

  const original = row?.original
  const base = original?.base || original?.currency
  const baseName = original?.baseName
  const groupName = original?.groupName
  const isSubrow = row.depth > 0

  return (
    <Row center flexShrink={1} pl={isSubrow ? 5 : 3} pr={3}>
      {groupName ? (
        <>
          <IconWrapper
            dark={groupName === 'Altcoins'}
            light={groupName === 'Stablecoins'}
          >
            <Text color={theme.colors.white} fontWeight={1} heading6>
              {groupName.charAt(0)}
            </Text>
          </IconWrapper>
          <Text fontWeight={1} ml={2}>
            {groupName}
          </Text>
        </>
      ) : (
        <>
          <CurrencyIcon currency={base} />
          <Text fontWeight={1} ml={2}>
            {baseName}
          </Text>
          <Text color={theme.colors.text70} fontWeight={1} ml={1}>
            {base}
          </Text>
        </>
      )}
    </Row>
  )
}

Name.defaultProps = {
  row: {},
}

Name.propTypes = {
  row: PropTypes.object,
}

export default Name
