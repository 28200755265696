import React from 'react'
import { useParams } from 'react-router'

import { PortfolioTemplateLogsTable } from 'Components/Blocks/Admin'
import { Row } from 'Components/UI'

function UserPortfolioTemplateLogs() {
  const params = useParams()

  return (
    <Row p={2}>
      <PortfolioTemplateLogsTable userId={params.userId} />
    </Row>
  )
}

export default UserPortfolioTemplateLogs
