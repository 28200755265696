import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Detail = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${themeGet('colors.divider.light')};
  padding: ${themeGet('space.4')}px;
`

export const CapitalizedFirstLetter = styled.div`
  ::first-letter {
    text-transform: uppercase;
  }
`
