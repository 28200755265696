import adminInfoQuery from 'GraphQL/Queries/Admin/adminInfo.graphql'

import { setIn } from 'Utils/Object'

function decreasePendingTransactions(store) {
  try {
    const info = {
      query: adminInfoQuery,
    }

    const infoData = store.readQuery(info)

    if (infoData) {
      store.writeQuery({
        ...info,
        data: setIn(
          infoData,
          ['adminInfo', 'userTransactionsCount'],
          Math.max(infoData?.adminInfo?.userTransactionsCount - 1, 0),
        ),
      })
    }
  } catch (e) {
    //
  }
}

function decreasePendingKYC(store) {
  try {
    const info = {
      query: adminInfoQuery,
    }

    const infoData = store.readQuery(info)

    if (infoData) {
      store.writeQuery({
        ...info,
        data: setIn(
          infoData,
          ['adminInfo', 'userKYCCount'],
          Math.max(infoData?.adminInfo?.userKYCCount - 1, 0),
        ),
      })
    }
  } catch (e) {
    //
  }
}

export default { decreasePendingTransactions, decreasePendingKYC }
