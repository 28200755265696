import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'
import { pick } from '@styled-system/props'

import { useQuery } from '@apollo/client'

import currencyColors from 'Assets/currency_colors.json'

import { Pie } from 'Components/Blocks/Charts/Assets'
import {
  assetsByGroup,
  populateAssets,
} from 'Components/Blocks/Widgets/NetWorth/utils'
import { Button, Column, Loader, Row, Text } from 'Components/UI'

import { STATE as PORTFOLIO_STATE } from 'Constants/portfolios'

import netWorthDataQuery from 'GraphQL/Queries/NetWorthData/netWorthData.graphql'
import portfoliosQuery from 'GraphQL/Queries/Portfolios/portfolios.graphql'
import userAssetsQuery from 'GraphQL/Queries/UserAssets/userAssets.graphql'

import { useExchanges, useWallets } from 'Hooks'

import { PORTFOLIO } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'
import shared from 'Services/Shared'

import AssetInfo from './AssetInfo'
import {
  ArrowRightIcon,
  ChartWrapper,
  Container,
  Content,
  Divider,
  TableWrapper,
} from './styles'
import NetworthAssetsTable from './Table'

function NetWorth({ colors, ...rest }) {
  const theme = useTheme()
  const history = useHistory()
  const s = useScopedI18n('widgets.netWorth')

  const [tickers, setTickers] = useState()
  const [isLoadingTickers, setLoadingTickers] = useState(true)

  const [, wallets] = useWallets()
  const [, exchanges] = useExchanges()

  useEffect(() => {
    shared
      .getPortfolioService()
      .getTickers()
      .then(data => {
        setTickers(data)
        setLoadingTickers(false)
      })
  }, [])

  const { data: portfoliosData, loading: portfoliosLoading } = useQuery(
    portfoliosQuery,
  )
  const { data: userAssetsData, loading: userAssetsLoading } = useQuery(
    userAssetsQuery,
  )
  const { data: netWorthData, loading: netWorthLoading } = useQuery(
    netWorthDataQuery,
  )

  const loading =
    portfoliosLoading ||
    userAssetsLoading ||
    netWorthLoading ||
    isLoadingTickers

  const pieData = useMemo(() => {
    const netWorthRows = netWorthData?.netWorthData?.rows

    const initialAmount = netWorthRows?.at(0)?.amount || 0
    const amount = netWorthRows?.at(-1)?.amount || 0

    const percentageChange =
      ((amount - initialAmount) / Math.max(initialAmount, 1)) * 100

    return {
      initialAmount,
      amount,
      percentageChange,
    }
  }, [netWorthData])

  const filteredAssetsByCost = useMemo(() => {
    if (userAssetsData?.userAssets?.length === 0) return []

    return userAssetsData?.userAssets?.filter(asset => asset.cost > 0.01)
  }, [userAssetsData])

  const assetsData = useMemo(() => {
    const populatedAssets = populateAssets(filteredAssetsByCost, tickers)

    return assetsByGroup(populatedAssets)
  }, [filteredAssetsByCost, tickers])

  const filledPortfolios = useMemo(() => {
    const portfolios = portfoliosData?.portfolios?.rows
    return (
      portfolios?.filter(
        portfolio => portfolio.state === PORTFOLIO_STATE.FILLED,
      ) || []
    )
  }, [portfoliosData])

  const handleManagePortolios = useCallback(() => {
    history.push(PORTFOLIO)
  }, [history])

  const renderAssetInfo = useCallback(asset => {
    return <AssetInfo asset={asset} />
  }, [])

  const portfoliosCount =
    filledPortfolios.length + (wallets?.length || 0) + (exchanges?.length || 0)

  if (loading) {
    return (
      <Container {...pick(rest)}>
        <Column height="100%" justifyCenter>
          <Loader fullWidth />
        </Column>
      </Container>
    )
  }

  return (
    <Container {...pick(rest)}>
      <Content>
        <Row fullHeight>
          <ChartWrapper>
            <Pie
              amount={pieData.amount}
              assetInfo={renderAssetInfo}
              assets={assetsData}
              colors={colors}
              initialAmount={pieData.initialAmount}
              percentageChange={pieData.percentageChange}
            />

            <Row center gap={3} mt={4}>
              <Column center flex={1}>
                <Text heading5>{portfoliosCount}</Text>
                <Text color={theme.colors.text70} mt={1} small>
                  {s('portfolios')}
                </Text>
              </Column>

              <Divider />

              <Column center flex={1}>
                <Text heading5>{filteredAssetsByCost.length}</Text>
                <Text color={theme.colors.text70} mt={1} small>
                  {s('cryptoAssets')}
                </Text>
              </Column>
            </Row>
          </ChartWrapper>

          <TableWrapper ml={5}>
            <NetworthAssetsTable assets={assetsData} currency="$" />
            <Row justifyEnd mt="20px" width={1}>
              <Button small onClick={handleManagePortolios}>
                {s('managePortfolios')} <ArrowRightIcon />
              </Button>
            </Row>
          </TableWrapper>
        </Row>
      </Content>
    </Container>
  )
}

NetWorth.defaultProps = {
  colors: currencyColors,
}

NetWorth.propTypes = {
  colors: PropTypes.object,
}

export default NetWorth
