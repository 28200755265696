import React, { useCallback } from 'react'

import { pick } from '@styled-system/props'

import { useStoreon } from 'storeon/react'

import { Button, Column, Row } from 'Components/UI'

import { DASHBOARD_ACTIONS, DASHBOARD_STATE } from 'Constants/store'

import { CloseIcon } from 'Pages/App/Root/Actions/styles'

import Action from './Action'

function Actions({ ...rest }) {
  const { dispatch } = useStoreon(DASHBOARD_STATE)

  const handleClose = useCallback(() => {
    dispatch(DASHBOARD_ACTIONS.SET, {
      showActions: false,
    })
  }, [dispatch])

  return (
    <Column {...pick(rest)}>
      <Row fullWidth justifyEnd>
        <Button icon outline secondary xSmall onClick={handleClose}>
          <CloseIcon height={14} viewBox="0 0 24 24" width={14} />
        </Button>
      </Row>
      <Row gap="20px">
        <Action variant="invest" />
        <Action variant="connect" />
        <Action variant="market" />
      </Row>
    </Column>
  )
}

export default Actions
