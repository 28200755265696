import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { margin, padding } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import addCircleGlyph from 'iconoir/icons/add-circle.svg'
import creditCardGlyph from 'iconoir/icons/credit-card.svg'
import dataTransferCheckGlyph from 'iconoir/icons/data-transfer-check.svg'
import graphUpGlyph from 'iconoir/icons/graph-up.svg'
import logoutGlyph from 'iconoir/icons/log-out.svg'
import starGlyph from 'iconoir/icons/star.svg'
import statsUpGlyph from 'iconoir/icons/stats-up-square.svg'
import viewGridGlyph from 'iconoir/icons/view-grid.svg'
import walletGlyph from 'iconoir/icons/wallet.svg'
import warningGlyph from 'iconoir/icons/warning-circle.svg'

import { chartPieGlyph, settingsDollarGlyph } from 'Assets/Svg'

const navigationLinkEllipsisCss = ({ ellipsis }) =>
  ellipsis &&
  css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${margin}
`

export const ViewGridIcon = styled(viewGridGlyph)``

export const ChartPieIcon = styled(chartPieGlyph)`
  stroke: #747b86;
  width: 20px;
  height: 20px;
`

export const CreditCardIcon = styled(creditCardGlyph)``

export const StarIcon = styled(starGlyph)``

export const AddCircleIcon = styled(addCircleGlyph)``

export const StatsUpIcon = styled(statsUpGlyph)``

export const GraphUpIcon = styled(graphUpGlyph)``

export const WalletIcon = styled(walletGlyph)``

export const DataTransferCheckIcon = styled(dataTransferCheckGlyph)``

export const SettingsIcon = styled(settingsDollarGlyph)`
  stroke: #747b86;
  width: 20px;
  height: 20px;
`

export const LogoutIcon = styled(logoutGlyph)`
  flex-shrink: 0;
`

export const WarningIcon = styled(warningGlyph)`
  color: ${themeGet('colors.dangerPrimary')};
`

export const ComingSoon = styled.div`
  display: flex;
  border-radius: 100px;
  background: ${themeGet('colors.bg.success')};
  font-size: ${themeGet('fontSizes.0')}px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: ${themeGet('colors.successDark')};
  margin-left: ${themeGet('space.2')}px;
`

export const NavigationLink = styled(NavLink)`
  display: flex;
  font-size: ${themeGet('fontSizes.1')}px;
  font-style: normal;
  font-weight: ${themeGet('fontWeights.1')};
  line-height: 20px;
  padding: 14px 0;
  align-items: center;
  align-self: stretch;
  text-decoration: none;
  position: relative;
  color: ${themeGet('colors.text70')};

  svg {
    flex-shrink: 0;
    margin-right: 16px;
  }

  &.active,
  :hover {
    color: ${themeGet('colors.primary50')};

    svg {
      color: ${themeGet('colors.primary50')};
      stroke: ${themeGet('colors.primary50')};
    }

    :before {
      content: '';
      position: absolute;
      width: 4px;
      height: 24px;
      border-radius: 0 ${themeGet('space.3')}px ${themeGet('space.3')}px 0;
      background: ${themeGet('colors.primary50')};
      left: -28px;
    }
  }

  ${navigationLinkEllipsisCss}
  ${padding}
`

export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
