import React from 'react'
import PropTypes from 'prop-types'

import styled, { ThemeProvider } from 'styled-components'

import theme from './defaultTheme'

const Base = styled.div``

const Provider = props => (
  <ThemeProvider theme={{ ...theme, ...props.theme }}>
    <Base {...props} />
  </ThemeProvider>
)

Provider.defaultProps = {
  theme,
}

Provider.propTypes = {
  theme: PropTypes.shape({
    breakpoints: PropTypes.arrayOf(PropTypes.number),
    space: PropTypes.arrayOf(PropTypes.number),
    fontSizes: PropTypes.arrayOf(PropTypes.number),
    weights: PropTypes.arrayOf(PropTypes.number),
    colors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    font: PropTypes.string,
    monospace: PropTypes.string,
    radius: PropTypes.number,
  }),
}

export default Provider
