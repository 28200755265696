import React, { useCallback, useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import superagent from 'superagent'

import isFunction from 'lodash/isFunction'

import { API } from 'Config'

import addUserPlaidMutation from 'GraphQL/Mutations/UserPlaid/addUserPlaid.graphql'
import UserPlaid from 'GraphQL/Updaters/UserPlaid'

import EventBus from 'Services/EventBus'
import { getAccessToken } from 'Services/Store/auth'

import PlaidHolder from './PlaidHolder'

const ACTIONS = {
  START: 'plaid/start',
}

function Plaid() {
  const [started, setStarted] = useState(false)
  const [onFinish, setOnFinish] = useState(null)
  const [linkToken, setLinkToken] = useState(null)

  const [addUserPlaid] = useMutation(addUserPlaidMutation)

  const handleStart = useCallback(
    async callback => {
      if (started) {
        return
      }
      setStarted(true)

      setOnFinish(() => callback)

      const { body } = await superagent
        .post(`${API.URL}/v1/private/plaidLinkToken`)
        .set('Authorization', `Bearer ${getAccessToken()}`)
        .send()

      setLinkToken(body?.linkToken)
    },
    [started],
  )

  const handleStop = useCallback(() => {
    setOnFinish(null)
    setLinkToken(null)
    setStarted(false)
  }, [])

  const handleSuccess = useCallback(
    async (token, meta) => {
      const result = await addUserPlaid({
        variables: {
          name: meta?.institution?.name,
          token,
        },
        update: UserPlaid.add,
      })

      if (isFunction(onFinish)) {
        onFinish(result?.data?.addUserPlaid)
      }

      handleStop()
    },
    [addUserPlaid, handleStop, onFinish],
  )

  const handleExit = useCallback(() => {
    if (isFunction(onFinish)) {
      onFinish(null)
    }
  }, [onFinish])

  useEffect(
    () => {
      EventBus.on(ACTIONS.START, handleStart)

      return () => {
        EventBus.off(ACTIONS.START)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return linkToken ? (
    <PlaidHolder
      token={linkToken}
      onExit={handleExit}
      onSuccess={handleSuccess}
    />
  ) : null
}

Plaid.start = onFinish => EventBus.trigger(ACTIONS.START, onFinish)

export default Plaid
