import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { FiEdit3 } from 'react-icons/fi'

import find from 'lodash/find'

import { Popover } from 'Components/UI'

import { USER_ROLE } from 'Constants/ids'

import { useAppContext } from 'Hooks'

import { useTableContext } from '../context'
import { Email } from '../styles'

function EmailCell({ value, row }) {
  const { me } = useAppContext()
  const { onEditEmail } = useTableContext()

  const user = row?.original
  const email = find(value, 'isPrimary')?.email

  const handleEdit = useCallback(
    () => {
      onEditEmail({ userId: user.id, email })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [row],
  )

  const canInteract =
    me.role === USER_ROLE.SUPER_ADMIN || user.role === USER_ROLE.USER

  return (
    canInteract && (
      <Popover content="Edit email" delay={Popover.delay} showArrow>
        <Email onClick={handleEdit}>
          {email} <FiEdit3 />
        </Email>
      </Popover>
    )
  )
}

EmailCell.defaultProps = {
  row: {},
  value: [],
}

EmailCell.propTypes = {
  row: PropTypes.object,
  value: PropTypes.array,
}

export default EmailCell
