import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import pageLeftGlyph from 'iconoir/icons/page-left.svg'
import pageRightGlyph from 'iconoir/icons/page-right.svg'

import { logoExtendedGlyph } from 'Assets/Svg'

const menuContainerCollapsedCss = ({ collapsed }) =>
  collapsed &&
  css`
    width: 76px;
  `

export const Container = styled.div.attrs({
  as: 'section',
})`
  display: flex;
  background-color: ${themeGet('colors.bg.primary')};
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
`

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 20px;
  width: 240px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 0 ${themeGet('space.3')}px ${themeGet('space.3')}px 0px;
  background: ${themeGet('colors.bg.main')};
  overflow: auto;
  transition: all ${themeGet('transitionTime.default')};

  ${menuContainerCollapsedCss}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow: auto;
`

export const PageContent = styled.div`
  padding: 20px;
  flex-direction: column;
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
`

export const PageContentLimit = styled.div`
  max-width: 1800px;
  width: 100%;
`

export const LogoExtended = styled(logoExtendedGlyph)``

export const PageLeftIcon = styled(pageLeftGlyph)`
  color: ${themeGet('colors.text70')};
`

export const PageRightIcon = styled(pageRightGlyph)`
  color: ${themeGet('colors.text70')};
`
