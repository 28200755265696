import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import PortfolioMenu from 'Components/Blocks/Portfolio/Menu'

import { Container, Content } from './styles'

function Portfolio({ route }) {
  return (
    <Container>
      <PortfolioMenu />
      <Content>{renderRoutes(route.routes)}</Content>
    </Container>
  )
}

Portfolio.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Portfolio
