import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div:not(:first-child) {
    margin-top: ${themeGet('space.1')}px;
  }

  ${margin}
`
