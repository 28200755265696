import styled from 'styled-components'
import { margin } from '@styled-system/space'

import Styles from 'Components/Styles'

export const Text = styled.div`
  ${Styles.text(14)};
  font-weight: 600;

  ${margin}
`
