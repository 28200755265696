import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import arrowDownGlyph from 'iconoir/icons/arrow-down.svg'
import arrowUpGlyph from 'iconoir/icons/arrow-up.svg'
import infoEmptyGlyph from 'iconoir/icons/info-empty.svg'

export const Content = styled.div`
  width: 100%;
  .table {
    padding-bottom: 10px;
    width: 100%;
    .header {
      padding: 10px 0;
      border-radius: 6px 6px 0 0;
      margin-bottom: 12px;
      .row {
        padding-bottom: 10px;
      }
    }
    .row {
      border-bottom: 1px solid ${themeGet('colors.divider.light')};
    }
  }
`

const negativeValueCss = ({ negative }) =>
  negative &&
  css`
    color: ${themeGet('colors.dangerLight')};
  `
const positiveValueCss = ({ positive }) =>
  positive &&
  css`
    color: ${themeGet('colors.primary')};
  `

export const Value = styled.div`
  display: flex;
  align-items: center;

  ${negativeValueCss};
  ${positiveValueCss};
`

export const ArrowUpIcon = styled(arrowUpGlyph)`
  ${margin}
`

export const ArrowDownIcon = styled(arrowDownGlyph)`
  ${margin}
`

export const ArrowInfoIcon = styled(infoEmptyGlyph)`
  color: ${themeGet('colors.text70')};

  ${margin};
`
