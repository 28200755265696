import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import arrowLeftGlyph from 'iconoir/icons/arrow-left.svg'
import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'
import sendEurosGlyph from 'iconoir/icons/send-euros.svg'
import walletGlyph from 'iconoir/icons/wallet.svg'
import warningGlyph from 'iconoir/icons/warning-triangle.svg'

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 670px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  ${margin};
`

export const FormContainer = styled.form`
  border-radius: 12px;
  background: ${themeGet('colors.bg.main')};
  display: flex;
  width: 670px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`

export const Inform = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${themeGet('colors.warningSecondaryPrimary')};
  background: ${themeGet('colors.warningSecondaryBackground')};
  padding: ${themeGet('space.3')}px;
  gap: ${themeGet('space.3')}px;
  align-self: stretch;
`

export const WarningIcon = styled(warningGlyph)`
  flex-shrink: 0;
  color: ${themeGet('colors.warningSecondaryPrimary')};
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: ${themeGet('colors.primary50')};
  border-radius: 50%;
`

export const WalletIcon = styled(walletGlyph)`
  color: ${themeGet('colors.white')};
`

export const SwapIcon = styled(sendEurosGlyph)`
  color: ${themeGet('colors.white')};
`

export const ArrowLeftIcon = styled(arrowLeftGlyph)`
  margin-right: 8px;
`

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`

export const ErrorContainer = styled.div`
  color: ${themeGet('colors.dangerPrimary')};
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`
