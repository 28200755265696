import styled from 'styled-components'
import { width } from 'styled-system'
import { margin } from '@styled-system/space'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;

  ${width}
  ${margin};
`
