import React from 'react'

import { UserPortfoliosTable } from 'Components/Blocks/Admin'
import { Row } from 'Components/UI'

function UserPortfolios() {
  return (
    <Row p={2}>
      <UserPortfoliosTable />
    </Row>
  )
}

export default UserPortfolios
