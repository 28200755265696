import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import map from 'lodash/map'

import _ from 'Services/I18n'

import Step from './Step'
import { Connector, Container } from './styles'

function StepProgress({ step, steps, lastStep, ...rest }) {
  return (
    <Container {...pick(rest)}>
      {map(steps, (item, key) => {
        const active = step === item.step
        const passed = item.step < step

        return (
          <Fragment key={key}>
            <Step active={active} passed={passed}>
              {_(item.label)}
            </Step>
            {key !== lastStep && <Connector />}
          </Fragment>
        )
      })}
    </Container>
  )
}

StepProgress.defaultProps = {
  lastStep: '',
}

StepProgress.propTypes = {
  lastStep: PropTypes.string,
  step: PropTypes.number.isRequired,
  steps: PropTypes.object.isRequired,
}

export default StepProgress
