import React from 'react'
import { useLocation } from 'react-router'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { useStoreon } from 'storeon/react'

import { ExpertPortfolioMenu } from 'Components/Blocks/ExpertPortfolio'
import ExpertPortfolioFAQ from 'Components/Blocks/ExpertPortfolio/FAQ'
import { Column } from 'Components/UI'

import { EXPERT_PORTFOLIO_STATE } from 'Constants/store'

import { EXPERT_PORTFOLIO } from 'Router/routes'

import { Container, Content } from './styles'

function Portfolio({ route }) {
  const location = useLocation()
  const { expertPortfolio } = useStoreon(EXPERT_PORTFOLIO_STATE)

  const isLayoutRootPage = location.pathname === EXPERT_PORTFOLIO

  return (
    <Container>
      <ExpertPortfolioMenu redirectOnLoad={!expertPortfolio.showFAQ} />
      <Content>
        {isLayoutRootPage && expertPortfolio.showFAQ && (
          <Column center flexGrow={1} justifyCenter>
            <ExpertPortfolioFAQ />
          </Column>
        )}

        {renderRoutes(route.routes)}
      </Content>
    </Container>
  )
}

Portfolio.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Portfolio
