import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import { Redirect, useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'

import { formatAmount } from 'Components/Blocks/PortfolioFunding/utils'

import { PRIMARY_CURRENCY } from 'Constants/currencies'

import addPortfolioMutation from 'GraphQL/Mutations/Portfolios/addPortfolio.graphql'
import fundPortfolioMutation from 'GraphQL/Mutations/Portfolios/fundPortfolio.graphql'

import { FUNDING_SUCCESS_KIND } from 'Pages/App/Funding/Success/contants'

import { APP_ROOT, DEPOSIT, FUNDING_SUCCESS } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import { FIELD, FUND_METHOD_KIND, PORTFOLIO_FUNDING } from './constants'
import FormFields from './FormFields'
import { Container } from './styles'

const PortfolioFunding = ({ type, portfolio, ...rest }) => {
  const history = useHistory()
  const s = useScopedI18n('blocks.funding')

  const [addPortfolio] = useMutation(addPortfolioMutation)
  const [fundPortfolio] = useMutation(fundPortfolioMutation)

  const submit = useCallback(
    async values => {
      if (values[FIELD.FUND_METHOD] === FUND_METHOD_KIND.BALANCE) {
        const variables = {
          name: portfolio?.name,
          amount: values[FIELD.AMOUNT],
          currency: PRIMARY_CURRENCY,
          assets: map(portfolio.assets, asset => ({
            asset: get(asset, 'base'),
            percentage: toNumber(get(asset, 'percentage')) || 0,
          })),
        }

        const rebalanceValue = portfolio?.rebalanceValue
        if (!isNil(rebalanceValue)) {
          variables.rebalanceType = portfolio?.rebalanceType
          variables.rebalanceValue = toNumber(rebalanceValue)
        }

        if (type === PORTFOLIO_FUNDING.EXPERT_PORTFOLIO) {
          variables.portfolioTemplateId = portfolio.id
        }

        const createdPortfolio = await addPortfolio({
          variables: {
            ...variables,
            portfolioTemplateId: portfolio.id,
          },
        })

        await fundPortfolio({
          variables: {
            id: createdPortfolio?.data?.addPortfolio.id,
          },
        })

        history.push({
          pathname: FUNDING_SUCCESS,
          state: {
            fundingSuccessKind:
              type === PORTFOLIO_FUNDING.EXPERT_PORTFOLIO
                ? FUNDING_SUCCESS_KIND.EXPERT_PORTFOLIO
                : FUNDING_SUCCESS_KIND.PORTFOLIO,
          },
        })
      }

      if (values[FIELD.FUND_METHOD] === FUND_METHOD_KIND.DEPOSIT) {
        history.push(DEPOSIT)
      }
    },
    [addPortfolio, fundPortfolio, portfolio, type, history],
  )

  const formConstraints = useMemo(
    () => ({
      [FIELD.FUND_METHOD]: {
        presence: {
          presence: true,
        },
      },
      [FIELD.AMOUNT]: (_, attributes) => {
        if (attributes[FIELD.FUND_METHOD] === FUND_METHOD_KIND.DEPOSIT) {
          return undefined
        }

        return {
          presence: {
            presence: true,
            message: `^${s('form.errors.amountEmpty')}`,
          },
          numericality: {
            greaterThanOrEqualTo: portfolio?.minimumInvestment,
            message: `^${s('form.errors.amount', {
              amount: portfolio?.minimumInvestment,
            })}`,
          },
        }
      },
    }),
    [portfolio, s],
  )

  if (!portfolio) return <Redirect to={APP_ROOT} />

  return (
    <Container {...pick(rest)}>
      <Form
        initialValues={{
          [FIELD.FUND_METHOD]: undefined,
          [FIELD.AMOUNT]: undefined,
        }}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      >
        {() => (
          <FormFields
            minimumInvestmentPlaceholder={formatAmount(
              portfolio?.minimumInvestment,
            )}
          />
        )}
      </Form>
    </Container>
  )
}

PortfolioFunding.propTypes = {
  portfolio: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
}

export default PortfolioFunding
