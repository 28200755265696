import React from 'react'
import { useLocation } from 'react-router'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

// import SupportChat from 'Components/Blocks/SupportChat'
import { Link, Row } from 'Components/UI'

import { AUTH_SIGN_UP, ROOT } from 'Router/routes'

import _ from 'Services/I18n'

import {
  Container,
  Content,
  Info,
  Lines,
  LogoExtendedIcon,
  Slogan,
} from './styles'

function Auth({ route }) {
  const location = useLocation()
  const isSignUpRoute = location.pathname === AUTH_SIGN_UP

  return (
    <Container
      flexDirection={isSignUpRoute ? 'column' : 'row'}
      justifyConten={isSignUpRoute ? 'flex-start' : 'flex-end'}
      transparent={isSignUpRoute}
    >
      <Info>
        <Row center>
          <Link to={ROOT}>
            <LogoExtendedIcon />
          </Link>
        </Row>

        {!isSignUpRoute && (
          <>
            <Row mt="auto">
              <Slogan>{_('general.slogan')}</Slogan>
            </Row>
            <Lines />
          </>
        )}
      </Info>

      <Content width={isSignUpRoute ? '100%' : '59%'}>
        {renderRoutes(route.routes)}
      </Content>

      {/* <SupportChat position="right" /> */}
    </Container>
  )
}

Auth.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Auth
