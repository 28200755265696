import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'
import VezgoService from 'vezgo-sdk-js/dist/vezgo.es5'

import get from 'lodash/get'
import isFunction from 'lodash/isFunction'

import { API, VEZGO } from 'Config'

import { VEZGO_PROVIDER_CATEGORY } from 'Constants/vezgo'

import addUserExchangeMutation from 'GraphQL/Mutations/ExchangeIntegrations/addExchangeIntegration.graphql'
import addUserWalletMutation from 'GraphQL/Mutations/UserCryptoWallets/addUserCryptoWallet.graphql'
import ExchangeIntegrations from 'GraphQL/Updaters/ExchangeIntegrations'
import UserCryptoWallets from 'GraphQL/Updaters/UserCryptoWallets'

import { useAppContext } from 'Hooks'

import EventBus from 'Services/EventBus'
import _ from 'Services/I18n'
import { getAccessToken } from 'Services/Store/auth'

import VezgoHolder from './VezgoHolder'

const ACTIONS = {
  START: 'vezgo/start',
}

function Vezgo() {
  const { refetchMe } = useAppContext()

  const [started, setStarted] = useState(false)
  const [onFinish, setOnFinish] = useState(null)
  const [vezgoUser, setVezgoUser] = useState(null)
  const [providerCategories, setProviderCategories] = useState([
    VEZGO_PROVIDER_CATEGORY.EXCHANGES,
    VEZGO_PROVIDER_CATEGORY.WALLETS,
  ])

  const [addUserExchange] = useMutation(addUserExchangeMutation)
  const [addUserWallet] = useMutation(addUserWalletMutation)

  const handleStart = useCallback(
    async (callback, context) => {
      if (started) {
        return
      }

      setStarted(true)
      setOnFinish(() => callback)
      setProviderCategories(context.providerCategories)

      const vezgo = VezgoService.init({
        clientId: VEZGO.CLIENT_ID,
        authEndpoint: `${API.URL}/v1/private/vezgo/token`, // default value, your server should implement this endpoint
        auth: {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      })

      setVezgoUser(vezgo.login())
    },
    [started],
  )

  const handleStop = useCallback(() => {
    setOnFinish(null)
    setVezgoUser(null)
    setStarted(false)
  }, [])

  const handleSuccess = useCallback(
    async accountId => {
      const account = await vezgoUser.accounts.getOne(accountId)

      try {
        if (account.provider?.auth_type === 'wallet') {
          await addUserWallet({
            variables: {
              name: account.provider.name,
              accountId,
              address: account.wallets[0]?.address,
              logo: account.provider.logo,
            },
            update: UserCryptoWallets.add,
          })
        } else {
          await addUserExchange({
            variables: {
              exchange: account.provider.name,
              accountId,
              logo: account.provider.logo,
            },
            update: ExchangeIntegrations.add,
          })
        }

        refetchMe()

        if (isFunction(onFinish)) {
          onFinish(account)
        }
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
      }

      handleStop()
    },
    [
      refetchMe,
      vezgoUser,
      onFinish,
      handleStop,
      addUserWallet,
      addUserExchange,
    ],
  )

  const handleExit = useCallback(() => {
    if (isFunction(onFinish)) {
      onFinish(null)
    }
  }, [onFinish])

  useEffect(
    () => {
      EventBus.on(ACTIONS.START, handleStart)

      return () => {
        EventBus.off(ACTIONS.START)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return vezgoUser ? (
    <VezgoHolder
      providerCategories={providerCategories}
      vezgoUser={vezgoUser}
      onExit={handleExit}
      onSuccess={handleSuccess}
    />
  ) : null
}

Vezgo.start = (onFinish, context) =>
  EventBus.trigger(ACTIONS.START, onFinish, context)

export default Vezgo
