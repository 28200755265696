import palette from 'Theme/Main/palette'

export default {
  color: {
    default: palette.white,
  },

  borderRadius: {
    default: 4,
  },
  borderWidth: {
    default: 1,
  },
  borderColor: {
    default: palette.text20,
  },
  backgroundColor: {
    default: palette.white,
  },
  hover: {
    borderColor: {
      default: palette.primary50,
    },
    backgroundColor: {
      default: palette.primary60,
    },
  },
  focus: {
    borderColor: {
      default: palette.primary50,
    },
    backgroundColor: {
      default: palette.primary50,
    },
    boxShadow: {
      default: palette.aquaMist,
    },
  },

  checked: {
    backgroundColor: {
      default: palette.primary50,
    },
    borderColor: {
      default: palette.primary50,
    },
    hover: {
      default: palette.primary60,
    },
    focus: {
      borderWidth: {
        default: 2,
      },
      backgroundColor: {
        default: palette.primary50,
      },
      boxShadow: {
        default: palette.aquaMist,
      },
    },
    disabled: {
      borderColor: {
        default: 'transparent',
      },
      backgroundColor: {
        default: palette.primary30,
      },
    },
  },

  disabled: {
    borderWidth: {
      default: 2,
    },
    borderColor: {
      default: palette.gray20,
    },
    backgroundColor: {
      default: palette.gray10,
    },
  },
}
