import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'
import { pick } from '@styled-system/props'

import Avatar from 'Components/Blocks/Avatar'
import { Column, Link, Popover, Row, Text } from 'Components/UI'

import { KYC_STATUS } from 'Constants/ids'

import { useAppContext } from 'Hooks'

import {
  APP_ROOT,
  ASSETS,
  EXPERT_PORTFOLIO,
  PORTFOLIO,
  PORTFOLIO_CREATE,
  PROFILE,
  SIGN_OUT,
  TRANSACTIONS,
} from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import Utils from 'Utils'

import {
  AddCircleIcon,
  ChartPieIcon,
  ComingSoon,
  Container,
  CreditCardIcon,
  DataTransferCheckIcon,
  // GraphUpIcon,
  LogoutIcon,
  NavigationLink,
  ProfileInfoContainer,
  SettingsIcon,
  StarIcon,
  // StatsUpIcon,
  ViewGridIcon,
  WalletIcon,
  WarningIcon,
} from './styles'

function Menu({ sidebarOpen, ...rest }) {
  const s = useScopedI18n('menu')
  const { me } = useAppContext()
  const theme = useTheme()
  const history = useHistory()

  const kyc = me?.kyc

  const handleClickProfile = useCallback(() => {
    history.push(PROFILE)
  }, [history])

  return (
    <Container {...pick(rest)}>
      <Column pl="8px">
        <NavigationLink ellipsis exact to={APP_ROOT}>
          <ViewGridIcon height={20} viewBox="0 0 24 24" width={20} />
          {sidebarOpen && s('dashboard')}
        </NavigationLink>

        <Column mt={sidebarOpen ? 5 : 0}>
          {sidebarOpen && (
            <Text fontWeight={1} heading6 mb={2}>
              {s('manage')}
            </Text>
          )}

          <NavigationLink ellipsis to={PORTFOLIO}>
            <ChartPieIcon />
            {sidebarOpen && s('myPortfolios')}
          </NavigationLink>

          <NavigationLink ellipsis exact to={ASSETS}>
            <CreditCardIcon height={20} viewBox="0 0 24 24" width={20} />
            {sidebarOpen && s('myAssets')}
          </NavigationLink>
        </Column>

        <Column mt={sidebarOpen ? 5 : 0}>
          {sidebarOpen && (
            <Text fontWeight={1} heading6 mb={2}>
              {s('invest')}
            </Text>
          )}

          <NavigationLink ellipsis to={EXPERT_PORTFOLIO}>
            <StarIcon height={20} viewBox="0 0 24 24" width={20} />
            {sidebarOpen && s('expertPortfolios')}
          </NavigationLink>

          <NavigationLink ellipsis exact to={PORTFOLIO_CREATE}>
            <AddCircleIcon height={20} viewBox="0 0 24 24" width={20} />
            {sidebarOpen && s('buildPortfolios')}
          </NavigationLink>

          {/* <NavigationLink ellipsis exact to="/"> */}
          {/*  <StatsUpIcon height={20} viewBox="0 0 24 24" width={20} /> */}
          {/*  {sidebarOpen && s('buySellSwap')} */}
          {/* </NavigationLink> */}

          {/* <NavigationLink ellipsis exact to="/"> */}
          {/*  <GraphUpIcon height={20} viewBox="0 0 24 24" width={20} /> */}
          {/*  {sidebarOpen && s('market')} */}
          {/* </NavigationLink> */}

          <NavigationLink ellipsis exact to={TRANSACTIONS}>
            <DataTransferCheckIcon height={20} viewBox="0 0 24 24" width={20} />
            {sidebarOpen && s('transactions')}
          </NavigationLink>
        </Column>

        <Column mt={sidebarOpen ? 5 : 0}>
          <NavigationLink ellipsis exact to="/">
            <WalletIcon height={20} viewBox="0 0 24 24" width={20} />
            {sidebarOpen && (
              <>
                {s('earn')}
                <ComingSoon>{s('comingSoon')}</ComingSoon>
              </>
            )}
          </NavigationLink>

          <NavigationLink ellipsis exact to="/">
            <SettingsIcon height={20} viewBox="0 0 24 24" width={20} />
            {sidebarOpen && (
              <>
                {s('optimize')}
                <ComingSoon>{s('comingSoon')}</ComingSoon>
              </>
            )}
          </NavigationLink>
        </Column>
      </Column>

      <Row center gap={3} mt="auto">
        <Row onClick={handleClickProfile}>
          <Avatar userProfile={me?.profile} />
        </Row>

        {sidebarOpen && (
          <>
            <ProfileInfoContainer>
              <Text actionExtraSmall ellipsis fontWeight={1}>
                {Utils.UserProfiles.fullName(me?.profile)}
              </Text>
              <Text
                color={theme.colors.text70}
                ellipsis
                extraSmall
                title={me?.emailCredentials?.[0]?.email}
              >
                {me?.emailCredentials?.[0]?.email}
              </Text>
            </ProfileInfoContainer>

            <Link color={theme.colors.text100} to={SIGN_OUT}>
              <Row center>
                <LogoutIcon height={14} viewBox="0 0 24 24" width={14} />
              </Row>
            </Link>

            {Utils.UserKYC.getStatus(kyc?.state) !== KYC_STATUS.SUCCESS && (
              <Popover
                content={<Text small>{s('notVerified')}</Text>}
                placement="right"
                showArrow
              >
                <Row center noShrink>
                  <WarningIcon height={16} viewBox="0 0 24 24" width={16} />
                </Row>
              </Popover>
            )}
          </>
        )}
      </Row>
    </Container>
  )
}

Menu.defaultProps = {
  sidebarOpen: false,
}

Menu.propTypes = {
  sidebarOpen: PropTypes.bool,
}
export default Menu
