
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PortfolioTemplateUserLogFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PortfolioTemplateUserLog"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"userId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"userPortfolioId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"portfolioTemplateId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"portfolioTemplate"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"profile"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserProfileFields"},"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":291}};
    doc.loc.source = {"body":"#import \"GraphQL/Fragments/UserProfile.graphql\"\n\nfragment PortfolioTemplateUserLogFields on PortfolioTemplateUserLog {\n  id\n  createdAt\n  userId\n  userPortfolioId\n  portfolioTemplateId\n\n  portfolioTemplate {\n    id\n    name\n  }\n\n  user {\n    profile {\n      ...UserProfileFields\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("GraphQL/Fragments/UserProfile.graphql").definitions));


      module.exports = doc;
    
