import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Button, Column, Loader, Row, Text } from 'Components/UI'
import { EnhancedRadioButtonField } from 'Components/UI/Forms'

import { ADD_TO_NET_WORTH_KIND } from 'Constants/netWorth'

import { useScopedI18n } from 'Services/I18n'

import { FIELD } from './constants'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowsIcon,
  DescriptionLabel,
  // BankIcon,
  // BrokerIcon,
  FormContainer,
  IconContainer,
  Inform,
  WalletIcon,
  WarningIcon,
} from './styles'

function FormFields({
  valid,
  loading,
  form,
  content,
  backActionContent,
  actionContent,
  // TODO: temporary option to disable the exchange
  disabledExchange,
  handleSubmit,
  onMount,
  onBack,
}) {
  const s = useScopedI18n('blocks.connectAccount')

  useEffect(() => {
    onMount(form)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMount])

  const handleBack = useCallback(
    event => {
      event.preventDefault()
      onBack()
    },
    [onBack],
  )

  return (
    <FormContainer gap={4} mt={5}>
      <EnhancedRadioButtonField
        disabled={disabledExchange}
        id="exchange"
        name={FIELD.KIND}
        value={ADD_TO_NET_WORTH_KIND.EXCHANGE}
        width={1}
      >
        <IconContainer>
          <ArrowsIcon height={21} width={21} />
        </IconContainer>
        <Column gap={1} ml={3}>
          <Text large>{s('exchangeTitle')}</Text>
          <Text small>{s('exchangeSubTitle')}</Text>
        </Column>

        {disabledExchange && (
          <DescriptionLabel>{s('comingSoon')}</DescriptionLabel>
        )}
      </EnhancedRadioButtonField>

      <EnhancedRadioButtonField
        id="wallet"
        name={FIELD.KIND}
        value={ADD_TO_NET_WORTH_KIND.WALLET}
        width={1}
      >
        <IconContainer>
          <WalletIcon height={21} viewBox="0 0 24 24" width={21} />
        </IconContainer>
        <Column gap={1} ml={3}>
          <Text large>{s('walletTitle')}</Text>
          <Text small>{s('walletSubTitle')}</Text>
        </Column>
      </EnhancedRadioButtonField>

      {/* <EnhancedRadioButtonField */}
      {/*  id="bank" */}
      {/*  name={FIELD.KIND} */}
      {/*  value={ADD_TO_NET_WORTH_KIND.BANK} */}
      {/*  width={1} */}
      {/* > */}
      {/*  <IconContainer> */}
      {/*    <BankIcon height={21} viewBox="0 0 24 24" width={21} /> */}
      {/*  </IconContainer> */}
      {/*  <Column gap={1} ml={3}> */}
      {/*    <Text large>{s('bankTitle')}</Text> */}
      {/*    <Text small>{s('bankSubTitle')}</Text> */}
      {/*  </Column> */}
      {/* </EnhancedRadioButtonField> */}

      {/* <EnhancedRadioButtonField */}
      {/*  id="broker" */}
      {/*  name={FIELD.KIND} */}
      {/*  value={ADD_TO_NET_WORTH_KIND.BROKER} */}
      {/*  width={1} */}
      {/* > */}
      {/*  <IconContainer> */}
      {/*    <BrokerIcon height={21} viewBox="0 0 24 24" width={21} /> */}
      {/*  </IconContainer> */}
      {/*  <Column gap={1} ml={3}> */}
      {/*    <Text large>{s('brokerTitle')}</Text> */}
      {/*    <Text small>{s('brokerSubTitle')}</Text> */}
      {/*  </Column> */}
      {/* </EnhancedRadioButtonField> */}

      <Inform>
        <WarningIcon height={21} viewBox="0 0 24 24" width={21} />
        <Text extraSmall>{s('information')}</Text>
      </Inform>

      {!!content && content}

      <Row fullWidth spaceBetween>
        <Button gap={3} outline onClick={handleBack}>
          {backActionContent || (
            <>
              <ArrowLeftIcon height={14} viewBox="0 0 24 24" width={14} />
              {s('back')}
            </>
          )}
        </Button>
        <Button disabled={!valid || loading} gap={3} onClick={handleSubmit}>
          {loading ? (
            <Loader />
          ) : (
            actionContent || (
              <>
                {s('action')}
                <ArrowRightIcon height={14} viewBox="0 0 24 24" width={14} />
              </>
            )
          )}
        </Button>
      </Row>
    </FormContainer>
  )
}

FormFields.defaultProps = {
  loading: false,
  content: null,
  disabledExchange: false,
  backActionContent: '',
  actionContent: '',
  onBack: noop,
  onMount: noop,
}

FormFields.propTypes = {
  actionContent: PropTypes.string,
  backActionContent: PropTypes.string,
  content: PropTypes.node,
  disabledExchange: PropTypes.bool,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  valid: PropTypes.bool.isRequired,
  onBack: PropTypes.func,
  onMount: PropTypes.func,
}

export default FormFields
