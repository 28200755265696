import React from 'react'
import PropTypes from 'prop-types'

import { Link, Row, Text } from 'Components/UI'

import { EXPERT_PORTFOLIO_ROOT } from 'Router/routes'

import _ from 'Services/I18n'

import { ArrowLeftIcon, Wrapper } from './styles'

function BackToExpertPortfolio({ expertPortfolioId }) {
  return (
    <Wrapper>
      <Link to={EXPERT_PORTFOLIO_ROOT(expertPortfolioId)}>
        <Row center>
          <ArrowLeftIcon height={14} viewBox="0 0 24 24" width={14} />
          <Text fontSize={1} fontWeight={2} ml={1}>
            {_('expertPortfolio.menu.backToPortfolio')}
          </Text>
        </Row>
      </Link>
    </Wrapper>
  )
}

BackToExpertPortfolio.defaultProps = {
  expertPortfolioId: null,
}

BackToExpertPortfolio.propTypes = {
  expertPortfolioId: PropTypes.string,
}

export default BackToExpertPortfolio
