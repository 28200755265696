import { ApolloLink } from '@apollo/client'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { API } from 'Config'

import shared from 'Services/Shared'
import { getAccessToken } from 'Services/Store/auth'

class SubscriptionLink extends ApolloLink {
  subscriptionClient

  constructor(cache) {
    super()

    this.cache = cache

    this.token = getAccessToken()

    if (this.token) {
      this.subscriptionClient = SubscriptionLink.createSubscriptionClient(
        this.token,
      )
    }

    shared.setSubscriptionLink(this)
  }

  request(operation, forward) {
    return this.subscriptionClient.request(operation, forward)
  }

  onTokenChanged() {
    const newToken = getAccessToken()

    if (newToken !== this.token) {
      this.token = newToken

      if (this.subscriptionClient) this.subscriptionClient.close()

      if (this.token) {
        this.subscriptionClient = SubscriptionLink.createSubscriptionClient(
          this.token,
        )
      }
    }
  }

  static createSubscriptionClient(token) {
    return new SubscriptionClient(`${API.WS}/graphql`, {
      reconnect: true,
      connectionParams: {
        Authorization: token ? `Bearer ${token}` : null,
      },
    })
  }
}

export default SubscriptionLink
