import { EXCHANGE } from 'Constants/exchanges'

const ADDITIONAL_FIELD_TEXTS = {
  [EXCHANGE.BITSTAMP.id]: 'data.exchange.additionalCredential.bitstamp',
  [EXCHANGE.COINBASE.id]: 'data.exchange.additionalCredential.coinbase',
  [EXCHANGE.OKEX.id]: 'data.exchange.additionalCredential.okex',
}

function additionalCredentialLabel(exchange) {
  return (
    ADDITIONAL_FIELD_TEXTS[exchange?.id] ||
    'data.exchange.additionalCredential.unknown'
  )
}

export default { additionalCredentialLabel }
