import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { DASHBOARD_DEFAULTS } from 'Constants/defaults'
import { DASHBOARD_ACTIONS, DASHBOARD_STATE } from 'Constants/store'

export default function dashboardState(store) {
  store.on('@init', () => ({
    [DASHBOARD_STATE]: DASHBOARD_DEFAULTS,
  }))

  store.on(DASHBOARD_ACTIONS.SET, (state, variables) => {
    const values = pick(variables, Object.keys(DASHBOARD_DEFAULTS))

    const nextDashboard = { ...state[DASHBOARD_STATE], ...values }

    if (isEqual(nextDashboard, state[DASHBOARD_STATE])) return null

    return { [DASHBOARD_STATE]: nextDashboard }
  })
}
