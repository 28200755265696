/* eslint-disable no-unused-expressions */
import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import noop from 'lodash/noop'

import ConnectAccount from 'Components/Blocks/ConnectAccount'
import { Button, Column, Modal, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

function OnboardingConnectAccountModal({ onAction, onSkip, ...rest }) {
  const s = useScopedI18n('onboarding.plan.modal')
  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const handleClose = useCallback(() => {
    if (!close.current) return
    close.current()
  }, [])

  return (
    <Modal {...Modal.pickProps(rest)} title={s('title')} onMount={handleMount}>
      <Column width="500px">
        <Text mt={3}>{s('subtitle')}</Text>

        <ConnectAccount
          backActionContent={s('actions.cancel')}
          content={
            <Column center>
              <Button text width="min-content" onClick={onSkip}>
                {s('actions.skip')}
              </Button>
            </Column>
          }
          onBack={handleClose}
          onSuccessFinish={onAction}
        />
      </Column>
    </Modal>
  )
}

OnboardingConnectAccountModal.defaultProps = {
  onAction: noop,
  onSkip: noop,
}

OnboardingConnectAccountModal.propTypes = {
  onAction: PropTypes.func,
  onSkip: PropTypes.func,
}

export default OnboardingConnectAccountModal
