import React, { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import { Column, Text } from 'Components/UI'

import {
  PORTFOLIO_EXCHANGE_ROOT,
  PORTFOLIO_ROOT,
  PORTFOLIO_WALLET_ROOT,
} from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import {
  LogoIcon,
  LogoImage,
  MenuIconStatus,
  MenuIconWrapper,
  MenuItem,
} from './styles'

const KIND = {
  PORTFOLIO: 'portfolio',
  EXCHANGE: 'exchange',
  WALLET: 'wallet',
}

function Item({ id, state, name, logoUrl, kind }) {
  const theme = useTheme()
  const history = useHistory()
  const s = useScopedI18n('data.portfolio')

  const portfolioMatch = useRouteMatch(PORTFOLIO_ROOT(id))
  const portfolioExchangeMatch = useRouteMatch(PORTFOLIO_EXCHANGE_ROOT(id))
  const portfolioWalletMatch = useRouteMatch(PORTFOLIO_WALLET_ROOT(id))

  const match = portfolioMatch || portfolioExchangeMatch || portfolioWalletMatch

  const isActive = state === 'filled'

  const handleClick = useCallback(() => {
    if (kind === KIND.PORTFOLIO) {
      history.push(PORTFOLIO_ROOT(id))
      return
    }

    if (kind === KIND.EXCHANGE) {
      history.push(PORTFOLIO_EXCHANGE_ROOT(id))
      return
    }

    history.push(PORTFOLIO_WALLET_ROOT(id))
  }, [history, id, kind])

  return (
    <MenuItem active={match} onClick={handleClick}>
      <MenuIconWrapper>
        {logoUrl ? (
          <LogoImage src={logoUrl} />
        ) : (
          <LogoIcon height={40} viewBox="0 0 50 50" width={40} />
        )}
        {state && <MenuIconStatus active={isActive} />}
      </MenuIconWrapper>

      <Column>
        <Text fontWeight={1} heading6>
          {name}
        </Text>

        {state && (
          <Text color={theme.colors.text70} small>
            {s(`state.${state}`)}
          </Text>
        )}
      </Column>
    </MenuItem>
  )
}

Item.defaultProps = {
  state: null,
  logoUrl: null,
}

Item.propTypes = {
  id: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(Object.values(KIND)).isRequired,
  logoUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  state: PropTypes.oneOf(['initial', 'funded', 'filled', 'closed']),
}

Item.KIND = KIND
export default Item
