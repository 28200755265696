import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import first from 'lodash/first'
import get from 'lodash/get'
import map from 'lodash/map'
import noop from 'lodash/noop'

import PortfolioGroupSelect from 'Components/Blocks/Admin/PortfolioGroupSelect'
import { Button, Column, Loader, Modal, Row, Select } from 'Components/UI'
import { EditorField, InputField, InputLabels } from 'Components/UI/Forms'

import { PRIMARY_CURRENCY } from 'Constants/currencies'
import { REBALANCE_TYPE, WITHDRAW_FREQUENCY } from 'Constants/portfolios'

import adminAddPortfolioTemplateMutation from 'GraphQL/Mutations/Admin/adminAddPortfolioTemplate.graphql'
import adminUpdatePortfolioTemplateMutation from 'GraphQL/Mutations/Admin/adminUpdatePortfolioTemplate.graphql'

import _ from 'Services/I18n'
import shared from 'Services/Shared'

import Utils from 'Utils'

import AssetsSelectList from './AllocationSelectList'
import { Content, ErrorText } from './styles'

const FIELDS = {
  NAME: 'name',
  RISK: 'risk',
  PROFIT_PERCENTAGE: 'profitPercentage',
  PROFIT_PERIOD: 'profitPeriod',
  REBALANCE_TYPE: 'rebalanceType',
  REBALANCE_VALUE: 'rebalanceValue',
  EXPECTED_RETURN: 'expectedReturn',
  TIMEHORIZON: 'timeHorizon',
  DESCRIPTION: 'description',
  WITHDRAW_FREQUENCY: 'withdrawFrequency',
  MINIMUM_INVESTMENT: 'minimumInvestment',
  PERFORMANCE_FEE: 'performanceFee',
  MANAGEMENT_FEE: 'managementFee',
  CATEGORIES: 'categories',
  MARKET_CAPITALIZATION: 'marketCapitalization',
  AVERAGE_MARKET_CAPITALIZATION: 'averageMarketCapitalization',
  RETURN_YTD: 'returnYtd',
  PORTFOLIO_GROUPS: 'portfolioGroups',
  TIP: 'tip',
  CONFIRM_EDIT: 'confirmEdit',
}

const WITHDRAW_FREQUENCY_OPTIONS = [
  {
    label: 'Daily',
    value: WITHDRAW_FREQUENCY.DAILY,
  },
  {
    label: 'Weekly',
    value: WITHDRAW_FREQUENCY.WEEKLY,
  },
  {
    label: 'Monthly',
    value: WITHDRAW_FREQUENCY.MONTHLY,
  },
  {
    label: 'Quarterly',
    value: WITHDRAW_FREQUENCY.QUARTERLY,
  },
]

function getRebalanceOption(type) {
  return {
    label: _(
      Utils.UserPortfolios.rebalanceTypeText({
        rebalanceType: type,
      }),
    ),
    value: type,
  }
}

function AddEditPortfolioTemplateModal({ template, onRefetch, ...rest }) {
  const [loading, setLoading] = useState(false)
  const [portfolio, setPortfolio] = useState(null)
  const [assets, setAssets] = useState([])
  const [assetsAllocationErrors, setAssetsAllocationErrors] = useState({})

  const close = useRef(null)

  const [add] = useMutation(adminAddPortfolioTemplateMutation)
  const [update] = useMutation(adminUpdatePortfolioTemplateMutation)

  const formConstraints = useMemo(() => {
    const constraints = {
      [FIELDS.NAME]: {
        presence: true,
      },
      [FIELDS.MINIMUM_INVESTMENT]: {
        presence: true,
        numericality: {
          greaterThan: 0,
        },
      },
    }
    if (template) {
      constraints[FIELDS.CONFIRM_EDIT] = {
        equality: FIELDS.NAME,
        presence: true,
      }
    }

    return constraints
  }, [template])

  const rebalanceOptions = useMemo(
    () => [
      getRebalanceOption(REBALANCE_TYPE.THRESHOLD),
      getRebalanceOption(REBALANCE_TYPE.PERIOD),
    ],
    [],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  useEffect(() => {
    if (!template) return

    shared
      .getPortfolioService()
      .populatePortfolios([{ ...template, currency: PRIMARY_CURRENCY }])
      .then(result => setPortfolio(first(result)))
  }, [template])

  const handleUpdateAssetsAllocationErrors = useCallback((asset, action) => {
    if (action === 'add') {
      setAssetsAllocationErrors(prev => ({ ...prev, [asset]: true }))
      return
    }

    setAssetsAllocationErrors(prev => {
      const nextState = { ...prev }
      delete nextState[asset]
      return nextState
    })
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      const method = template ? update : add

      try {
        await method({
          variables: {
            id: template?.id,
            name: values[FIELDS.NAME],
            tip: values[FIELDS.TIP],
            risk: parseInt(values[FIELDS.RISK], 10),
            profitPeriod: values[FIELDS.PROFIT_PERIOD],
            profitPercentage: parseInt(values[FIELDS.PROFIT_PERCENTAGE], 10),
            rebalanceValue: parseInt(values[FIELDS.REBALANCE_VALUE], 10),
            rebalanceType: values[FIELDS.REBALANCE_TYPE]?.value,
            assets: map(assets, asset => ({
              asset: asset.base,
              percentage: parseFloat(asset.percentage),
              previousPercentage: parseFloat(asset.previousPercentage),
            })),
            portfolioGroupIds: map(
              values[FIELDS.PORTFOLIO_GROUPS],
              group => group.value,
            ),
            expectedReturn: parseInt(values[FIELDS.EXPECTED_RETURN], 10),
            timeHorizon: parseInt(values[FIELDS.TIMEHORIZON], 10),
            description: values[FIELDS.DESCRIPTION],
            withdrawFrequency: values[FIELDS.WITHDRAW_FREQUENCY]?.value,
            performanceFee: parseFloat(values[FIELDS.PERFORMANCE_FEE]),
            managementFee: parseFloat(values[FIELDS.MANAGEMENT_FEE]),
            categories: map(
              values[FIELDS.CATEGORIES],
              category => category.label,
            ),
            marketCapitalization: values[FIELDS.MARKET_CAPITALIZATION],
            averageMarketCapitalization:
              values[FIELDS.AVERAGE_MARKET_CAPITALIZATION],
            returnYtd: values[FIELDS.RETURN_YTD],
            minimumInvestment: parseFloat(values[FIELDS.MINIMUM_INVESTMENT]),
          },
        })

        toast.success('Success')

        setLoading(false)

        if (!template) {
          onRefetch()
        }

        close.current(true)
      } catch (error) {
        // TODO: API error formatter here
        toast.error(error?.message)

        setLoading(false)
      }
    },
    [template, update, add, assets, onRefetch],
  )

  const initialValues = useMemo(
    () => ({
      [FIELDS.NAME]: template?.name || '',
      [FIELDS.TIP]: template?.tip || '',
      [FIELDS.RISK]: template?.risk || '',
      [FIELDS.PROFIT_PERIOD]: template?.profitPeriod || 0,
      [FIELDS.PROFIT_PERCENTAGE]: template?.profitPercentage || 0,
      [FIELDS.REBALANCE_VALUE]: template?.rebalanceValue || '',
      [FIELDS.REBALANCE_TYPE]: getRebalanceOption(
        template?.rebalanceType || REBALANCE_TYPE.THRESHOLD,
      ),
      [FIELDS.PORTFOLIO_GROUPS]: map(template?.portfolioGroups, group => ({
        label: group.name,
        value: group.id,
      })),
      [FIELDS.EXPECTED_RETURN]: template?.expectedReturn,
      [FIELDS.TIMEHORIZON]: template?.timeHorizon,
      [FIELDS.DESCRIPTION]: template?.description,
      [FIELDS.WITHDRAW_FREQUENCY]: WITHDRAW_FREQUENCY_OPTIONS.find(
        item => item.value === template?.withdrawFrequency,
      ),
      [FIELDS.CATEGORIES]: map(template?.categories, category => ({
        label: category,
        value: category,
      })),
      [FIELDS.MINIMUM_INVESTMENT]: template?.minimumInvestment,
      [FIELDS.PERFORMANCE_FEE]: template?.performanceFee,
      [FIELDS.MANAGEMENT_FEE]: template?.managementFee,
      [FIELDS.MARKET_CAPITALIZATION]: template?.marketCapitalization,
      [FIELDS.AVERAGE_MARKET_CAPITALIZATION]:
        template?.averageMarketCapitalization,
      [FIELDS.RETURN_YTD]: template?.returnYtd,
    }),
    [template],
  )

  const isAllocationValidationError = useMemo(() => {
    return Object.keys(assetsAllocationErrors).length > 0
  }, [assetsAllocationErrors])

  const initialAssets = useMemo(() => {
    return portfolio?.assets || []
  }, [portfolio])

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={template ? 'Edit template' : 'Add template'}
      onMount={handleMount}
    >
      <Form
        initialValues={initialValues}
        render={({ handleSubmit, form, values }) => {
          let rebalanceValueAccessory
          const rebalanceType = values[FIELDS.REBALANCE_TYPE]?.value
          if (rebalanceType === REBALANCE_TYPE.PERIOD) {
            rebalanceValueAccessory = _('general.days')
          }
          if (rebalanceType === REBALANCE_TYPE.THRESHOLD) {
            rebalanceValueAccessory = '%'
          }

          // TODO: rewrite in a manner similar to goals form
          let rebalanceError = null
          const rebalanceState = form.getFieldState(FIELDS.REBALANCE_VALUE)
          if (rebalanceState) {
            const { hasError, error } = Utils.Form.hasError(rebalanceState)
            if (hasError) {
              rebalanceError = error
            }
          }

          // --

          return (
            <>
              <Content>
                <Row fullWidth mt={2}>
                  <InputLabels title={_('portfolio.info.name')}>
                    <InputField
                      input={{
                        placeholder: 'Type portfolio name',
                        small: true,
                      }}
                      name={FIELDS.NAME}
                    />
                  </InputLabels>

                  <InputLabels ml={2} title="Portfolio Group">
                    <Field name={FIELDS.PORTFOLIO_GROUPS}>
                      {({ input: { value, onChange: handleChange } }) => (
                        <PortfolioGroupSelect
                          small
                          value={value}
                          withPortal
                          onChange={handleChange}
                        />
                      )}
                    </Field>
                  </InputLabels>
                </Row>
                <Row fullWidth mt={2}>
                  <InputLabels title="Tip">
                    <InputField
                      input={{
                        placeholder: 'Type portfolio tip',
                        small: true,
                      }}
                      name={FIELDS.TIP}
                    />
                  </InputLabels>
                </Row>
                <Row fullWidth mt={2}>
                  <InputLabels title={_('portfolio.info.rebalance')}>
                    <Row>
                      <Field
                        defaultValue={rebalanceOptions[0]}
                        name={FIELDS.REBALANCE_TYPE}
                      >
                        {({ input: { value, onChange: handleChange } }) => (
                          <Select
                            options={rebalanceOptions}
                            small
                            value={value}
                            withPortal
                            onChange={handleChange}
                          />
                        )}
                      </Field>
                      <InputField
                        displayError={false}
                        input={{
                          accessory: rebalanceValueAccessory,
                          accessoryWidth: 34,
                          small: true,
                        }}
                        ml={1}
                        name={FIELDS.REBALANCE_VALUE}
                        width={130}
                      />
                    </Row>
                    {rebalanceError && (
                      <ErrorText mt={1}>{rebalanceError}</ErrorText>
                    )}
                  </InputLabels>
                </Row>

                <Row fullWidth mt={2}>
                  <InputLabels title="Profit percentage">
                    <InputField
                      input={{
                        placeholder: 'Type profit percentage',
                        small: true,
                      }}
                      name={FIELDS.PROFIT_PERCENTAGE}
                    />
                  </InputLabels>
                  <InputLabels ml={2} title="Profit period">
                    <InputField
                      input={{
                        placeholder: 'Type profit period (1 year)',
                        small: true,
                      }}
                      name={FIELDS.PROFIT_PERIOD}
                    />
                  </InputLabels>
                </Row>

                <Row fullWidth mt={2}>
                  <InputLabels title="Risk">
                    <InputField
                      input={{
                        type: 'number',
                        placeholder: 'Type portfolio risk (numeric)',
                        small: true,
                      }}
                      name={FIELDS.RISK}
                    />
                  </InputLabels>
                  <InputLabels ml={2} title="Expected return">
                    <InputField
                      input={{
                        type: 'number',
                        placeholder: 'Type expected return (numeric)',
                        small: true,
                      }}
                      name={FIELDS.EXPECTED_RETURN}
                    />
                  </InputLabels>
                  <InputLabels ml={2} title="Horizon level">
                    <InputField
                      input={{
                        type: 'number',
                        placeholder: 'Type horizon level (numeric)',
                        small: true,
                      }}
                      name={FIELDS.TIMEHORIZON}
                    />
                  </InputLabels>
                </Row>

                <Row fullWidth mt={2}>
                  <InputLabels title="Description">
                    <EditorField name={FIELDS.DESCRIPTION} />
                  </InputLabels>
                </Row>

                <Row fullWidth mt={2}>
                  <InputLabels title="Withdrawal Frequency">
                    <Field name={FIELDS.WITHDRAW_FREQUENCY}>
                      {({ input: { value, onChange: handleChange } }) => (
                        <Select
                          defaultValue={WITHDRAW_FREQUENCY_OPTIONS[0]}
                          options={WITHDRAW_FREQUENCY_OPTIONS}
                          small
                          value={value}
                          withPortal
                          onChange={handleChange}
                        />
                      )}
                    </Field>
                  </InputLabels>
                  <InputLabels ml={2} title="Categories">
                    <Field name={FIELDS.CATEGORIES}>
                      {({ input: { value, onChange: handleChange } }) => (
                        <Select
                          creatable
                          isMulti
                          isSearchable
                          placeholder="Type and add category"
                          small
                          value={value}
                          withPortal
                          onChange={handleChange}
                        />
                      )}
                    </Field>
                  </InputLabels>
                </Row>

                <Row fullWidth mt={2}>
                  <InputLabels title="Minimum Investment">
                    <InputField
                      input={{
                        type: 'number',
                        placeholder: 'Type Minimum Investment (numeric)',
                        small: true,
                      }}
                      name={FIELDS.MINIMUM_INVESTMENT}
                    />
                  </InputLabels>
                  <InputLabels ml={2} title="Performance Fee">
                    <InputField
                      input={{
                        type: 'number',
                        placeholder: 'Type Performance Fee (numeric)',
                        small: true,
                      }}
                      name={FIELDS.PERFORMANCE_FEE}
                    />
                  </InputLabels>
                  <InputLabels ml={2} title="Management Fee">
                    <InputField
                      input={{
                        type: 'number',
                        placeholder: 'Type Management Fee (numeric)',
                        small: true,
                      }}
                      name={FIELDS.MANAGEMENT_FEE}
                    />
                  </InputLabels>
                </Row>

                <Column fullWidth mt={2}>
                  <InputLabels title="Market Capitalization">
                    <InputField
                      input={{
                        placeholder: 'Type Market Capitalization',
                        small: true,
                      }}
                      name={FIELDS.MARKET_CAPITALIZATION}
                    />
                  </InputLabels>

                  <InputLabels mt={2} title="Average Market Capitalization">
                    <InputField
                      input={{
                        placeholder: 'Type Average Market Capitalization',
                        small: true,
                      }}
                      name={FIELDS.AVERAGE_MARKET_CAPITALIZATION}
                    />
                  </InputLabels>

                  <InputLabels mt={2} title="Return YTD">
                    <InputField
                      input={{
                        placeholder: 'Type Return YTD',
                        small: true,
                      }}
                      name={FIELDS.RETURN_YTD}
                    />
                  </InputLabels>
                </Column>

                <Row fullWidth mt={6}>
                  <InputLabels ml={2} title="Assets">
                    <AssetsSelectList
                      initialAssets={initialAssets}
                      mt={4}
                      template={template}
                      onChange={setAssets}
                      onUpdateAssetsAllocationErrors={
                        handleUpdateAssetsAllocationErrors
                      }
                    />
                  </InputLabels>
                </Row>
              </Content>

              <Row borderTop height={100} pt="20px">
                {loading ? (
                  <>
                    <Loader />
                  </>
                ) : (
                  <>
                    <Button
                      secondary
                      width={130}
                      onClick={() => (close.current ? close.current() : noop())}
                    >
                      {_('general.cancel')}
                    </Button>

                    <Row ml="auto">
                      {template && (
                        <Column>
                          <InputField
                            input={{
                              placeholder: 'Enter Template name to allow edit',
                            }}
                            name={FIELDS.CONFIRM_EDIT}
                            width="300px"
                          />
                        </Column>
                      )}

                      <Button
                        disabled={isAllocationValidationError}
                        ml={3}
                        width={130}
                        onClick={handleSubmit}
                      >
                        {template ? 'Edit' : 'Add'}
                      </Button>
                    </Row>
                  </>
                )}
              </Row>
            </>
          )
        }}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

AddEditPortfolioTemplateModal.defaultProps = {
  template: null,
  onRefetch: noop,
}

AddEditPortfolioTemplateModal.propTypes = {
  template: PropTypes.object,
  onRefetch: PropTypes.func,
}

export default AddEditPortfolioTemplateModal
