import styled from 'styled-components'
import { margin } from 'styled-system'
import themeGet from '@styled-system/theme-get'

import copyGlyph from 'iconoir/icons/copy.svg'
import infoEmptyGlyph from 'iconoir/icons/info-empty.svg'

import { OutlineContainer } from '../styles'

export const BankDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  padding: ${themeGet('space.4')}px;
  background-color: ${themeGet('colors.brightGray')};
`

export const InstructionsContainer = styled(OutlineContainer)`
  border-color: ${themeGet('colors.warningSecondaryPrimary')};
  background-color: ${themeGet('colors.warningSecondaryBackground')};
  padding: ${themeGet('space.3')}px;

  ${margin}
`

export const CopyIcon = styled(copyGlyph)`
  margin-left: 8px;
`

export const InfoEmptyIcon = styled(infoEmptyGlyph)`
  color: ${themeGet('colors.warningSecondaryPrimary')};
  margin-right: 8px;
`
