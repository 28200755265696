import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { useStoreon } from 'storeon/react'

import { Column, Select, Text } from 'Components/UI'

import { LANGUAGE } from 'Constants/ids'
import { I18N_ACTIONS, I18N_STATE } from 'Constants/store'

import _ from 'Services/I18n'

import Utils from 'Utils'

function LanguageDropdown({ showTitle, gap, ...rest }) {
  const { dispatch, i18n } = useStoreon(I18N_STATE)
  const [currentValue, setCurrentValue] = useState(null)

  const option = useCallback(
    language => ({
      label: _(Utils.Language.name(language)),
      value: language,
    }),
    [],
  )

  const options = useMemo(
    () => [option(LANGUAGE.EN), option(LANGUAGE.FR), option(LANGUAGE.KO)],
    [option],
  )

  useEffect(() => {
    setCurrentValue(option(i18n?.locale))
  }, [i18n, option])

  const handleLanguageChange = useCallback(
    language => {
      dispatch(I18N_ACTIONS.SET, { locale: language.value })
    },
    [dispatch],
  )

  return (
    <Column {...pick(rest)} gap={gap}>
      {showTitle && <Text heading6>{_('profile.language.title')}</Text>}
      <Select
        options={options}
        small
        value={currentValue}
        onChange={handleLanguageChange}
      />
    </Column>
  )
}

LanguageDropdown.defaultProps = {
  showTitle: true,
  gap: null,
}

LanguageDropdown.propTypes = {
  gap: PropTypes.number,
  showTitle: PropTypes.bool,
}

export default LanguageDropdown
