import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'
import checkGlyph from 'iconoir/icons/check.svg'
import warningCircleGlyph from 'iconoir/icons/warning-circle.svg'

import { hourGlassGlyph } from 'Assets/Svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const errorIconContainerCss = ({ error }) =>
  error &&
  css`
    background-color: ${themeGet('colors.bg.danger')};
  `

export const IconContainer = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary10')};

  ${errorIconContainerCss}
`

export const CheckIcon = styled(checkGlyph)`
  color: ${themeGet('colors.successPrimary')};
`

export const WarningIcon = styled(warningCircleGlyph)`
  color: ${themeGet('colors.dangerPrimary')};
`

export const HourGlassIcon = styled(hourGlassGlyph)`
  color: ${themeGet('colors.primary70')};
`

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`
