import React, { useMemo } from 'react'

import numeral from 'numeral'

import get from 'lodash/get'

import CurrencyIcon from 'Components/Blocks/CurrencyIcon'
import { Row } from 'Components/UI'
import Text from 'Components/UI/Text'

import { useScopedI18n } from 'Services/I18n'

import { theme } from 'Theme'

export function useColumns() {
  const s = useScopedI18n('blocks.expertPortfolio.assets.holdings')

  return useMemo(() => {
    return [
      {
        Header: () => (
          <Text
            color={theme.colors.font.secondary}
            extraSmall
            fontWeight={0}
            px={3}
          >
            {s('name')}
          </Text>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const base = get(row, ['original', 'base'])
          return (
            <Row center flexShrink={1} px={3}>
              <CurrencyIcon currency={base} />
              <Text fontWeight={1} ml={2}>
                {get(row, ['original', 'baseName'])}
              </Text>
              <Text fontWeight={1} ml={1}>
                {base}
              </Text>
            </Row>
          )
        },
        accessor: 'baseName',
        width: 128,
        disableSortBy: true,
      },
      {
        Header: () => (
          <Text
            color={theme.colors.font.secondary}
            extraSmall
            fontWeight={0}
            px={3}
          >
            {s('ticker')}
          </Text>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return (
            <Row center flexShrink={1} px={3}>
              <Text fontWeight={1} ml={1}>
                {value}
              </Text>
            </Row>
          )
        },
        accessor: 'asset',
        width: 128,
        disableSortBy: true,
      },
      {
        Header: () => (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {s('weight')}
          </Text>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return <Text>{numeral(value).format('0.00')}%</Text>
        },
        accessor: 'percentage',
        width: 10,
        headerCenter: true,
        cellCenter: true,
        disableSortBy: true,
      },
    ]
  }, [s])
}
