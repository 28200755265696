import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import arrowLeftGlyph from 'iconoir/icons/arrow-left.svg'
import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'
import bankGlyph from 'iconoir/icons/bank.svg'
import cardWalletGlyph from 'iconoir/icons/card-wallet.svg'
import coinGlyph from 'iconoir/icons/coin.svg'
import warningGlyph from 'iconoir/icons/warning-triangle.svg'

import { arrowsGlyph } from 'Assets/Svg'

import { gap } from 'Theme/system'

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${margin};
  ${gap}
`

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary50')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ArrowsIcon = styled(arrowsGlyph)``

export const WalletIcon = styled(cardWalletGlyph)`
  color: white;
`

export const BankIcon = styled(bankGlyph)`
  color: white;
`

export const BrokerIcon = styled(coinGlyph)`
  color: white;
`

export const Inform = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${themeGet('colors.warningSecondaryPrimary')};
  background: ${themeGet('colors.warningSecondaryBackground')};
  padding: ${themeGet('space.3')}px;
  gap: ${themeGet('space.3')}px;
  align-self: stretch;
`

export const WarningIcon = styled(warningGlyph)`
  flex-shrink: 0;
  color: ${themeGet('colors.warningSecondaryPrimary')};
`

export const ArrowRightIcon = styled(arrowRightGlyph)``

export const ArrowLeftIcon = styled(arrowLeftGlyph)``

export const DescriptionLabel = styled.div`
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: ${themeGet('colors.white')};
  background-color: ${themeGet('colors.text100')};
  text-align: center;

  ${margin}
`
