import { useMemo } from 'react'

import { ActionsCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'Ticker',
        accessor: 'ticker',
        width: 20,
      },
      {
        Header: 'Kind',
        accessor: 'kind',
        width: 20,
      },
      {
        Header: 'Actions',
        Cell: ActionsCell,
        width: 25,
      },
    ],
    [],
  )
}
