import React from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

const OVERLAY_BACKGROUND_COLOR = 'transparent'
const MODAL_SCALE = '0.5, 0.5, 0.5'

function ReactModalAdapter({
  className,
  modalClassName,
  overlayClassName,
  ...props
}) {
  return (
    <ReactModal
      className={modalClassName}
      overlayClassName={overlayClassName}
      portalClassName={className}
      {...props}
    />
  )
}

ReactModalAdapter.propTypes = {
  className: PropTypes.string.isRequired,
  modalClassName: PropTypes.object.isRequired,
  overlayClassName: PropTypes.object.isRequired,
}

export const StyledModal = styled(ReactModalAdapter).attrs({
  modalClassName: {
    base: 'Modal',
    afterOpen: 'Modal--after-open',
    beforeClose: 'Modal--before-close',
  },
  overlayClassName: {
    base: 'Overlay',
    afterOpen: 'Overlay--after-open',
    beforeClose: 'Overlay--before-close',
  },
})`
  .Overlay {
    position: fixed;
    ${Styles.flexCenter};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${OVERLAY_BACKGROUND_COLOR};
    transition: all ${themeGet('transitionTime.modal')} ease-in;
    z-index: 999;

    &--after-open {
      background-color: rgba(0, 0, 0, 0.14);
    }

    &--before-close {
      background-color: ${OVERLAY_BACKGROUND_COLOR};
    }
  }

  .Modal {
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: ${themeGet('colors.bg.main')};
    border-radius: 12px;
    outline: none;
    transform: scale3d(${MODAL_SCALE});
    transition: all ${themeGet('transitionTime.modal')} ease-in;
    max-height: calc(100vh - 16px);
    padding: ${themeGet('space.6')}px;

    &--after-open {
      transform: scale3d(1, 1, 1);
    }

    &--before-close {
      transform: scale3d(${MODAL_SCALE});
      opacity: 0;
    }
  }
`

export const CloseButton = styled.div`
  ${Styles.flexCenter};
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  align-self: flex-start;

  ${margin}
`
