import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import numeral from 'numeral'

import CurrencyIcon from 'Components/Blocks/CurrencyIcon'
import { Text } from 'Components/UI'

import { IconWrapper } from './styles'

function formatNumber(number = 0) {
  return numeral(number).format('0,0.00')
}

function AssetInfo({ asset }) {
  const theme = useTheme()

  const groupName = asset?.groupName
  const name = asset?.name || groupName

  return (
    <>
      {groupName ? (
        <>
          <IconWrapper
            dark={groupName === 'Altcoins'}
            light={groupName === 'Stablecoins'}
          >
            <Text color={theme.colors.white} fontWeight={1} heading6>
              {groupName.charAt(0)}
            </Text>
          </IconWrapper>

          <Text bold mt={2}>
            {name}
          </Text>
        </>
      ) : (
        <>
          <CurrencyIcon currency={asset.id} size={40} />
          <Text bold mt={2}>
            {name} ({asset.id})
          </Text>
        </>
      )}

      <Text mt="2px" secondary>
        {formatNumber(asset?.value || 0)}%
      </Text>
      <Text mt="2px">${formatNumber(asset?.cost || 0)}</Text>
    </>
  )
}

AssetInfo.propTypes = {
  asset: PropTypes.object.isRequired,
}

export default AssetInfo
