import { useMemo } from 'react'

import { DateTime } from 'luxon'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'Portfolio Template Name',
        accessor: 'portfolioTemplate.name',
        width: 30,
      },
      {
        Header: 'User Name',
        accessor: 'user.profile',
        Cell: ({ value }) =>
          `${value?.firstName || '—'} ${value?.lastName || '—'}`,
        width: 30,
      },
      {
        Header: 'Created',
        Cell: ({ value }) =>
          value
            ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
            : '—',
        accessor: 'createdAt',
        width: 30,
      },
    ],
    [],
  )
}
