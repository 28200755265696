import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import CurrencyIcon from 'Components/Blocks/CurrencyIcon'
import { Row, Text } from 'Components/UI'

function Name({ row }) {
  const theme = useTheme()

  const original = row?.original
  const base = original?.base || original?.currency
  const baseName = original?.baseName

  return (
    <Row center flexShrink={1} pl={3} pr={3}>
      <CurrencyIcon currency={base} />
      <Text fontWeight={1} ml={2}>
        {baseName}
      </Text>
      <Text color={theme.colors.text70} fontWeight={1} ml={1}>
        {base}
      </Text>
    </Row>
  )
}

Name.defaultProps = {
  row: {},
}

Name.propTypes = {
  row: PropTypes.object,
}

export default Name
