import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'
import plusGlyph from 'iconoir/icons/plus.svg'

export const Container = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 40px;
  border-radius: 12px;
  background: ${themeGet('colors.bg.main')};
  flex-basis: 33%;
`

export const ArrowRightIcon = styled(arrowRightGlyph)``

export const PlusIcon = styled(plusGlyph)``

export const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  span {
    font-weight: 400;
  }
`
