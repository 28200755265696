import React from 'react'

import { UserKYCTable } from 'Components/Blocks/Admin'
import { Row } from 'Components/UI'

function UserKYC() {
  return (
    <Row p={2}>
      <UserKYCTable />
    </Row>
  )
}

export default UserKYC
