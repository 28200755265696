import React from 'react'

import { Row } from 'Components/UI'

import { Board, Main } from './styles'

function Root() {
  return (
    <>
      <Board>
        <Main>
          <Row>Dashboard</Row>
        </Main>
      </Board>
    </>
  )
}

export default Root
