export const ROOT = `/`

export const TERMS_OF_SERVICE = `${ROOT}terms-of-service`

export const AUTH_ROOT = `${ROOT}auth`
export const AUTH_SIGN_UP = `${AUTH_ROOT}/sign-up`
export const AUTH_VERIFY_EMAIL = `${AUTH_ROOT}/verify-email`
export const AUTH_RESET_PASSWORD = `${AUTH_ROOT}/reset-password`
export const AUTH_CHANGE_PASSWORD = `${AUTH_ROOT}/change-password`

export const SIGN_OUT = `${ROOT}sign-out`

export const ONBOARDING_ROOT = `${ROOT}onboarding`
export const ONBOARDING_PORTFOLIO = `${ONBOARDING_ROOT}/portfolio`
export const ONBOARDING_RISK_ASSESSMENT = `${ONBOARDING_ROOT}/risk-assessment`
export const ONBOARDING_KYC = `${ONBOARDING_ROOT}/kyc`

export const KYC = `${ROOT}kyc`

export const APP_ROOT = `${ROOT}app`

export const ASSETS = `${APP_ROOT}/assets`

export const TRANSACTIONS = `${APP_ROOT}/transactions`

export const ADD_NET_WORTH = `${APP_ROOT}/add-net-worth`

export const FUNDING = `${APP_ROOT}/funding`
export const FUNDING_SUCCESS = `${FUNDING}/success`

export const CREATE_PORTFOLIO_SELECTION = `${APP_ROOT}/create-portfolio-selection`
export const PORTFOLIO_CREATE = `${APP_ROOT}/create-portfolio`
export const PORTFOLIO = `${APP_ROOT}/portfolio`
export const PORTFOLIO_ROOT = id =>
  `${PORTFOLIO}/default/${id || ':portfolioId'}`
export const PORTFOLIO_REBALANACE = id => `${PORTFOLIO_ROOT(id)}/rebalance`
export const PORTFOLIO_BACKTESTING = id => `${PORTFOLIO_ROOT(id)}/backtest`
export const PORTFOLIO_EXCHANGE_ROOT = id =>
  `${PORTFOLIO}/exchange/${id || ':exchangeId'}`
export const PORTFOLIO_WALLET_ROOT = id =>
  `${PORTFOLIO}/wallet/${id || ':walletId'}`

export const EXPERT_PORTFOLIO = `${APP_ROOT}/expert-portfolio`
export const EXPERT_PORTFOLIO_ROOT = id =>
  `${EXPERT_PORTFOLIO}/${id || ':expertPortfolioId'}`
export const EXPERT_PORTFOLIO_BACKTEST = id =>
  `${EXPERT_PORTFOLIO_ROOT(id)}/backtest`

export const PROFILE = `${APP_ROOT}/profile`

export const ACCOUNTS = `${APP_ROOT}/accounts`

export const WITHDRAWAL = `${APP_ROOT}/withdrawal`
export const WITHDRAWAL_CRYPTO = `${WITHDRAWAL}/crypto`

export const DEPOSIT = `${APP_ROOT}/deposit`

export const OAUTH_ROOT = `${ROOT}oauth`

export const DEV_ROOT = `${ROOT}dev`
export const DEV_INTEGRATIONS = `${DEV_ROOT}/integrations`
export const DEV_BACKTESTING = `${DEV_ROOT}/backtesting`

export const ADMIN_ROOT = `${ROOT}admin`
export const ADMIN_USERS = `${ADMIN_ROOT}/users`
export const ADMIN_USER_TRANSACTIONS = `${ADMIN_ROOT}/user-transactions`
export const ADMIN_USER_KYC = `${ADMIN_ROOT}/userKYC`
export const ADMIN_USER_PORTFOLIO_TEMPLATE_LOGS = userId =>
  `${ADMIN_USERS}/${userId || ':userId'}/portfolio-templates/logs`
export const ADMIN_TRANSACTIONS = `${ADMIN_ROOT}/transactions`
export const ADMIN_ORDERS = `${ADMIN_ROOT}/orders`
export const ADMIN_PORTFOLIO_TEMPLATES = `${ADMIN_ROOT}/portfolio-templates`
export const ADMIN_PORTFOLIO_TEMPLATE_LOGS = portfolioTemplateId =>
  `${ADMIN_PORTFOLIO_TEMPLATES}/logs/${
    portfolioTemplateId || ':portfolioTemplateId'
  }`

export const ADMIN_PORTFOLIO_TEMPLATE_GROUPS = `${ADMIN_ROOT}/portfolio-template-groups`
export const ADMIN_SETTINGS = `${ADMIN_ROOT}/settings`
export const ADMIN_CRYPTO_TOKENS = `${ADMIN_ROOT}/crypto-tokens`
export const ADMIN_ASSET_STATS = `${ADMIN_ROOT}/asset-stats`
export const ADMIN_USER_PORTFOLIOS = `${ADMIN_ROOT}/user-portfolios`
