import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, Modal, Row } from 'Components/UI'

import _ from 'Services/I18n'

import { Content } from './styles'

function DialogModal({
  contentWidth,
  cancelText,
  confirmText,
  content,
  disabledConfirm,
  onFinish,
  ...rest
}) {
  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const handleFinish = useCallback(
    confirmation => {
      onFinish(confirmation)

      if (close.current) {
        close.current()
      }
    },
    [onFinish],
  )

  return (
    <Modal {...Modal.pickProps(rest)} onMount={handleMount}>
      <Content mt={3} width={contentWidth}>
        {content || _('modal.dialog.content')}
      </Content>
      <Row center justifyCenter mt={5}>
        <Button flex={1} outline small onClick={() => handleFinish(false)}>
          {cancelText || _('modal.dialog.cancel')}
        </Button>
        <Button
          disabled={disabledConfirm}
          flex={1}
          ml={3}
          small
          onClick={() => handleFinish(true)}
        >
          {confirmText || _('modal.dialog.confirm')}
        </Button>
      </Row>
    </Modal>
  )
}

DialogModal.defaultProps = {
  contentWidth: 400,
  cancelText: null,
  confirmText: null,
  content: null,
  title: null,
  disabledConfirm: false,
  onFinish: noop,
}

DialogModal.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  content: PropTypes.any,
  contentWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabledConfirm: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onFinish: PropTypes.func,
}

export default DialogModal
