import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import { signOut } from 'Services/SignOut'

function SignOut() {
  const history = useHistory()

  useEffect(() => {
    async function asyncSignOut() {
      await signOut(history)
    }

    asyncSignOut().then()
  }, [history])
  return <div />
}

export default SignOut
