import styled from 'styled-components'
import { margin } from '@styled-system/space'

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-radius: 4px;
  font-weight: bold;
  padding: 0 4px;
  font-size: 12px;
`

export const Link = styled.a`
  ${margin}
`
