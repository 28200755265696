import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Form, FormSpy } from 'react-final-form'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import validate from 'validate.js'

import noop from 'lodash/noop'

import { Row } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms/Input'

import _ from 'Services/I18n'

const FIELDS = {
  NAME: 'name',
  AMOUNT: 'amount',
}

function PortfolioInfoForm({
  disableAmountEditing,
  portfolio,
  minimumAmount,
  onChange,
  onFinish,
  onMount,
  ...rest
}) {
  const formRef = useRef(null)

  const initialValues = useMemo(() => {
    if (!portfolio) return undefined

    return {
      [FIELDS.NAME]: portfolio.name,
      [FIELDS.AMOUNT]: portfolio.amount,
    }
  }, [portfolio])

  const renderForm = useCallback(
    ({ form }) => {
      if (!formRef.current) {
        formRef.current = form
      }

      return (
        <>
          <FormSpy
            subscription={{ valid: true, values: true }}
            onChange={onChange}
          />

          <Row gap="20px" width={1}>
            <InputLabels title={_('portfolio.info.name')}>
              <InputField
                checkErrorIfDirty
                input={{
                  placeholder: _('portfolio.info.form.namePlaceholder'),
                  small: true,
                }}
                name={FIELDS.NAME}
              />
            </InputLabels>
            <InputLabels title={_('portfolio.info.amount')}>
              <InputField
                checkErrorIfDirty
                input={{
                  disabled: disableAmountEditing,
                  placeholder: _('portfolio.info.form.amountPlaceholder'),
                  small: true,
                }}
                name={FIELDS.AMOUNT}
              />
            </InputLabels>
          </Row>
        </>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disableAmountEditing, onChange, onMount],
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.NAME]: {
        presence: {
          presence: true,
          message: `^${_('portfolio.info.form.nameRequired')}`,
        },
      },
      [FIELDS.AMOUNT]: {
        presence: {
          presence: true,
          message: `^${_('portfolio.info.form.amountRequired')}`,
        },
        numericality: {
          greaterThanOrEqualTo: minimumAmount || 0,
        },
      },
    }),
    [minimumAmount],
  )

  useEffect(() => {
    onMount(formRef.current)
  }, [onMount])

  return (
    <Row {...pick(rest)}>
      <Form
        initialValues={initialValues}
        render={renderForm}
        validate={values => validate(values, formConstraints)}
        onSubmit={onFinish}
      />
    </Row>
  )
}

PortfolioInfoForm.defaultProps = {
  disableAmountEditing: false,
  minimumAmount: null,
  portfolio: null,
  onChange: noop,
  onFinish: noop,
  onMount: noop,
}

PortfolioInfoForm.propTypes = {
  disableAmountEditing: PropTypes.bool,
  minimumAmount: PropTypes.number,
  portfolio: PropTypes.object,
  onChange: PropTypes.func,
  onFinish: PropTypes.func,
  onMount: PropTypes.func,
}

export default PortfolioInfoForm
