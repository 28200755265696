import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { Table } from 'Components/UI'
import Text from 'Components/UI/Text'

import { theme } from 'Theme'

import { useColumns } from './columns'
import { Content } from './styles'

function HoldingsTable({ assets, ...rest }) {
  const columns = useColumns()

  return (
    <Content {...pick(rest)}>
      <Table
        columns={columns}
        data={assets}
        noData={
          <Text color={theme.colors.text70} fontWeight={1} heading6 p={4}>
            No data to display
          </Text>
        }
        rowGap={theme.space[3]}
        rowHeight={44}
        sortable
      />
    </Content>
  )
}

HoldingsTable.defaultProps = {
  assets: null,
}

HoldingsTable.propTypes = {
  assets: PropTypes.array,
}

export default HoldingsTable
