import React from 'react'
import { Field, useFormState } from 'react-final-form'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import RadioButton from './RadioButton'
import { Description, EnhancedRadioButtonContainer } from './styles'

function EnhancedRadioButtonField({
  description,
  id,
  name,
  validate,
  children,
  value,
  disabled,
  ...rest
}) {
  const formState = useFormState()

  return (
    <Field name={name} type="radio" validate={validate} value={value}>
      {({ input }) => (
        <EnhancedRadioButtonContainer
          active={formState.values[name] === input.value}
          disabled={disabled}
        >
          <RadioButton
            id={id}
            label={children}
            {...pick(rest)}
            disabled={disabled}
            {...input}
          />
          {description && <Description htmlFor={id}>{description}</Description>}
        </EnhancedRadioButtonContainer>
      )}
    </Field>
  )
}

EnhancedRadioButtonField.defaultProps = {
  caption: undefined,
  checkErrorIfDirty: undefined,
  children: null,
  defaultValue: undefined,
  description: null,
  disabled: false,
  displayError: undefined,
  id: undefined,
  validate: undefined,
  value: null,
}

EnhancedRadioButtonField.propTypes = {
  caption: PropTypes.string,
  checkErrorIfDirty: PropTypes.bool,
  children: PropTypes.node,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.node,
  disabled: PropTypes.bool,
  displayError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  validate: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default EnhancedRadioButtonField
