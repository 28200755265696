import loadable from '@loadable/component'

const PhoneInput = loadable(() =>
  import('./Input' /* webpackChunkName: "phone-input" */),
)

const PhoneInputField = loadable(() =>
  import('./InputField' /* webpackChunkName: "phone-input" */),
)

export default PhoneInput
export { PhoneInputField }
