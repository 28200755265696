import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import arrowLeftGlyph from 'iconoir/icons/arrow-left.svg'
import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'
import plusGlyph from 'iconoir/icons/plus.svg'
import warningGlyph from 'iconoir/icons/warning-triangle.svg'

import { gap } from 'Theme/system'

export const Inform = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${themeGet('colors.warningSecondaryPrimary')};
  background: ${themeGet('colors.warningSecondaryBackground')};
  padding: ${themeGet('space.3')}px;
  gap: ${themeGet('space.3')}px;
  align-self: stretch;
`

export const WarningIcon = styled(warningGlyph)`
  flex-shrink: 0;
  color: ${themeGet('colors.warningSecondaryPrimary')};
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid ${themeGet('colors.primary50')};
  border-radius: 8px;
  padding: 17px 25px;
`

export const Logo = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`

export const ConnectContainer = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${themeGet('colors.primary50')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PlusIcon = styled(plusGlyph)`
  color: ${themeGet('colors.white')};
`

export const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 370px;
  width: 100%;

  ${gap};
`

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`
export const ArrowLeftIcon = styled(arrowLeftGlyph)`
  margin-right: 8px;
`
