import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import plusGlyph from 'iconoir/icons/plus.svg'
import searchGlyph from 'iconoir/icons/search.svg'

import LogoGlyph from 'Assets/Svg/logo.svg'

import { Input } from 'Components/UI/Forms'

import { gap } from 'Theme/system'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${themeGet('colors.bg.main')};
  box-shadow: 0 6px 18px 0 rgba(112, 220, 220, 0.07);
  padding: 20px 8px;
  width: 300px;
  flex-shrink: 0;
  overflow: auto;

  ${margin};
`

export const PlusIcon = styled(plusGlyph)``

export const SearchIcon = styled(searchGlyph)`
  margin-left: 12px;
`

export const SearchInput = styled(Input)`
  background-color: ${themeGet('colors.bg.light')};
`

export const TabsContainer = styled.div`
  display: flex;
  width: 100%;

  ${margin}
  ${gap}
`

const tabActiveCss = ({ active }) =>
  active &&
  css`
    color: ${themeGet('colors.text100')};
    border-bottom-color: ${themeGet('colors.primary50')};
  `

export const Tab = styled.button`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: ${themeGet('space.3')}px 0;
  border-bottom: 2px solid transparent;
  flex-basis: 33%;
  color: ${themeGet('colors.text70')};
  display: flex;
  justify-content: center;
  cursor: pointer;

  :hover {
    color: ${themeGet('colors.text100')};
    border-bottom-color: ${themeGet('colors.primary50')};
  }

  ${tabActiveCss}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${margin};
`

const menuItemActiveCss = ({ active }) =>
  active &&
  css`
    background: ${themeGet('colors.primary10')};
  `

export const MenuItem = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  width: 100%;
  border-radius: 12px;
  cursor: pointer;

  :hover {
    background: ${themeGet('colors.primary10')};
  }

  ${menuItemActiveCss};
`

export const MenuIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
`

const activeMenuIconStatusCss = ({ active }) =>
  active &&
  css`
    background-color: ${themeGet('colors.primary50')};
  `
export const MenuIconStatus = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  background-color: ${themeGet('colors.dangerPrimary')};
  right: -6px;
  bottom: -6px;

  ${activeMenuIconStatusCss}
`

export const LogoImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`

export const LogoIcon = styled(LogoGlyph)``
