import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router'

import { useQuery } from '@apollo/client'

import Backtest from 'Components/Blocks/Backtesting/Backtest'
import { BackToPortfolio } from 'Components/Blocks/Portfolio'
import { Loader } from 'Components/UI'

import portfolioQuery from 'GraphQL/Queries/Portfolios/portfolio.graphql'

import { FUNDING } from 'Router/routes'

import { Content } from './styles'

function Backtesting() {
  const params = useParams()
  const history = useHistory()

  const { portfolioId } = params

  const { data, loading } = useQuery(portfolioQuery, {
    variables: { id: portfolioId },
  })

  const handleInvestNowClick = useCallback(() => {
    history.push(FUNDING)
  }, [history])

  return (
    <Content>
      {loading ? (
        <Loader />
      ) : (
        <>
          <BackToPortfolio portfolioId={portfolioId} />
          <Backtest
            initialPortfolio={data?.portfolio}
            showInvest={false}
            onInvestNowClick={handleInvestNowClick}
          />
        </>
      )}
    </Content>
  )
}

export default Backtesting
