import styled from 'styled-components'
import { margin } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import bankGlyph from 'iconoir/icons/bank.svg'

import { buyCryptoGlyph } from 'Assets/Svg'

export const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  border-radius: 12px;
  background: ${themeGet('colors.white')};
  width: 670px;

  ${margin}
`

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary50')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DescriptionLabel = styled.div`
  display: flex;
  padding: 2px ${themeGet('space.4')}px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: ${themeGet('colors.white')};
  background-color: ${themeGet('colors.text100')};
  text-align: center;

  ${margin}
`

export const BuyCryptoGlyphIcon = styled(buyCryptoGlyph)`
  color: white;
`

export const BankGlyphIcon = styled(bankGlyph)`
  color: white;
`
