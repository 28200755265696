import React from 'react'
import PropTypes from 'prop-types'

import numeral from 'numeral'

import get from 'lodash/get'

import { theme } from 'Theme'

const Y_OFFSET_FIX = 10

function YTick({
  height,
  index,
  payload,
  visibleTicksCount,
  width,
  x,
  xOffset,
  y,
  yOffset,
}) {
  if (index === 0) return null

  const internalYOffset =
    (index === visibleTicksCount - 1 ? 0 : Y_OFFSET_FIX) + yOffset

  return (
    <text
      fill={theme.colors.font.secondary}
      height={height}
      width={width}
      x={x + xOffset}
      y={y + internalYOffset}
    >
      ${numeral(get(payload, 'value') || 0).format('0,0')}
    </text>
  )
}

YTick.defaultProps = {
  height: undefined,
  index: undefined,
  payload: undefined,
  visibleTicksCount: undefined,
  width: undefined,
  x: undefined,
  xOffset: 0,
  y: undefined,
  yOffset: 0,
}

YTick.propTypes = {
  height: PropTypes.number,
  index: PropTypes.number,
  payload: PropTypes.object,
  visibleTicksCount: PropTypes.number,
  width: PropTypes.number,
  x: PropTypes.number,
  xOffset: PropTypes.number,
  y: PropTypes.number,
  yOffset: PropTypes.number,
}

export default YTick
