import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { useTheme } from 'styled-components'

import { useQuery } from '@apollo/client'

import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'

import Item from 'Components/Blocks/Portfolio/Menu/Item'
import { Button, Column, Divider, Loader, Row, Text } from 'Components/UI'

import portfoliosQuery from 'GraphQL/Queries/Portfolios/portfolios.graphql'

import { useExchanges, useWallets } from 'Hooks'

import { ADD_NET_WORTH, PORTFOLIO, PORTFOLIO_ROOT } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import {
  Container,
  Content,
  PlusIcon,
  SearchIcon,
  SearchInput,
  Tab,
  TabsContainer,
} from './styles'

const TAB = {
  ALL: 'all',
  UW: 'uw',
  EXTERNAL: 'external',
}

const TABS = [
  {
    title: 'all',
    value: TAB.ALL,
  },
  // {
  //   title: 'wealthSquare',
  //   value: TAB.WS,
  // },
  {
    title: 'external',
    value: TAB.EXTERNAL,
  },
]

function mapExchangeToItem(exchange) {
  return (
    <Item
      id={exchange.id}
      key={exchange.id}
      kind={Item.KIND.EXCHANGE}
      logoUrl={exchange.logo}
      name={capitalize(exchange.exchange)}
    />
  )
}

function mapWalletToItem(wallet) {
  return (
    <Item
      id={wallet.id}
      key={wallet.id}
      kind={Item.KIND.WALLET}
      logoUrl={wallet.logo}
      name={capitalize(wallet.name)}
    />
  )
}

function Menu() {
  const s = useScopedI18n('portfolio.menu')
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()

  const [tab, setTab] = useState(TAB.ALL)
  const [search, setSearch] = useState(null)

  const { data: portfoliosData, loading: isPortfoliosLoading } = useQuery(
    portfoliosQuery,
  )
  const portfolios = useMemo(() => portfoliosData?.portfolios?.rows || [], [
    portfoliosData,
  ])

  // eslint-disable-next-line no-unused-vars
  const [, exchanges, isExchangesLoading] = useExchanges()
  const [, wallets, isWalletsLoading] = useWallets()

  useEffect(() => {
    const latestPortfolioId = portfolios.at(-1)?.id
    if (latestPortfolioId && location.pathname === PORTFOLIO)
      history.push(PORTFOLIO_ROOT(latestPortfolioId))
  }, [portfolios, history, location])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(value => {
      setSearch(value)
    }, 300),
    [],
  )

  const handleSearch = useCallback(
    ({ target: { value } }) => {
      debouncedSearch(value)
    },
    [debouncedSearch],
  )

  const handleCreate = useCallback(() => {
    history.push(ADD_NET_WORTH)
  }, [history])

  const tabs = useMemo(() => {
    return TABS.map(item => (
      <Tab
        active={item.value === tab}
        key={item.value}
        onClick={() => setTab(item.value)}
      >
        {s(item.title)}
      </Tab>
    ))
  }, [s, tab])

  const content = useMemo(() => {
    if (isPortfoliosLoading || isExchangesLoading || isWalletsLoading)
      return (
        <Column center justifyCenter>
          <Loader />
        </Column>
      )

    const filteredPortfolios = search
      ? portfolios.filter(portfolio =>
          portfolio.name.toLowerCase().includes(search.toLowerCase()),
        )
      : portfolios

    const filteredExchanges = search
      ? exchanges.filter(exchange =>
          exchange.exchange.toLowerCase().includes(search.toLowerCase()),
        )
      : exchanges

    const filteredWallets = search
      ? wallets.filter(wallet =>
          wallet.name.toLowerCase().includes(search.toLowerCase()),
        )
      : wallets

    switch (tab) {
      case TAB.ALL:
        return (
          <>
            <Column>
              {filteredPortfolios?.length > 0 && (
                <>
                  <Text color={theme.colors.text70} mb={3} px={3} small>
                    {s('upWealth')}
                  </Text>

                  <Column>
                    {filteredPortfolios?.map(portfolio => (
                      <Item
                        id={portfolio.id}
                        key={portfolio.id}
                        kind={Item.KIND.PORTFOLIO}
                        name={portfolio.name}
                        state={portfolio.state}
                      />
                    ))}
                  </Column>
                </>
              )}
            </Column>

            {(filteredExchanges?.length > 0 || filteredWallets?.length > 0) && (
              <>
                <Divider my={3} />

                <Column>
                  <Text color={theme.colors.text70} mb={3} px={3} small>
                    {s('external')}
                  </Text>

                  <Column>{filteredExchanges?.map(mapExchangeToItem)}</Column>
                  <Column>{filteredWallets?.map(mapWalletToItem)}</Column>
                </Column>
              </>
            )}
          </>
        )

      case TAB.EXTERNAL:
        return (
          <>
            <Column>
              <Text color={theme.colors.text70} mb={3} px={3} small>
                {s('external')}
              </Text>

              {filteredExchanges?.length > 0 && (
                <Column>{filteredExchanges?.map(mapExchangeToItem)}</Column>
              )}
              {filteredWallets?.length > 0 && (
                <Column>{filteredWallets?.map(mapWalletToItem)}</Column>
              )}
            </Column>
          </>
        )
      default:
        return null
    }
  }, [
    isPortfoliosLoading,
    isExchangesLoading,
    isWalletsLoading,
    search,
    portfolios,
    exchanges,
    wallets,
    tab,
    theme,
    s,
  ])

  return (
    <Container>
      <Row center px={3}>
        <Text fontWeight={2}>{s('portfolios')}</Text>
        <Text color={theme.colors.text70} ml={1}>
          ({portfolios?.length})
        </Text>
        <Button icon ml="auto" xSmall onClick={handleCreate}>
          <PlusIcon height={20} viewBox="0 0 24 24" width={20} />
        </Button>
      </Row>

      <Row mt={4} px={3}>
        <SearchInput
          placeholder={s('searchPlaceholder')}
          renderBefore={
            <SearchIcon height={16} viewBox="0 0 24 24" width={16} />
          }
          onChange={handleSearch}
        />
      </Row>

      <TabsContainer gap={2} mt={3}>
        {tabs}
      </TabsContainer>

      <Content mt={3}>{content}</Content>
    </Container>
  )
}

export default Menu
