import React from 'react'

import { OrdersTable } from 'Components/Blocks/Admin'
import { Row } from 'Components/UI'

function Orders() {
  return (
    <Row p={2}>
      <OrdersTable />
    </Row>
  )
}

export default Orders
