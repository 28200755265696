import React from 'react'
import { useLocation } from 'react-router'

import { useTheme } from 'styled-components'

import PortfolioFunding from 'Components/Blocks/PortfolioFunding'
import { Column, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { Container } from './styles'

const Funding = () => {
  const theme = useTheme()
  const s = useScopedI18n('funding.root')
  const location = useLocation()

  const portfolio = location?.state?.portfolio
  const type = location?.state?.type

  return (
    <Container>
      <Column center fullWidth>
        <Text as="h2" fontWeight={2} heading2 mt="70px">
          {s('title')}
        </Text>
        <Text
          as="h5"
          color={theme.colors.text70}
          fontWeight={2}
          heading5
          mt={4}
        >
          {s('subTitle')}
        </Text>

        <PortfolioFunding mt={6} portfolio={portfolio} type={type} />
      </Column>
    </Container>
  )
}

export default Funding
