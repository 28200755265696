import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import OtpInput from 'react-otp-input'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'

import ProgressBar from 'Components/Blocks/Onboarding/ProgressBar'
import { Button, Column, Loader, Row, Text } from 'Components/UI'
import { CheckboxField, InputField, InputLabels } from 'Components/UI/Forms'

import { PASSWORD_REGEX } from 'Constants/strings'

import resendVerificationCodeMutation from 'GraphQL/Mutations/Auth/resendVerificationCode.graphql'
import signUpByEmailMutation from 'GraphQL/Mutations/Auth/signUpByEmail.graphql'
import verifyCodeMutation from 'GraphQL/Mutations/Auth/verifyCode.graphql'

import { APP_ROOT } from 'Router/routes'

import _ from 'Services/I18n'
import { setAuth } from 'Services/Store/auth'

import Utils from 'Utils'

import {
  CheckboxText,
  Content,
  EyeIcon,
  EyeOffIcon,
  FormContent,
  IconButton,
  OtpCodeInput,
  OtpInputWrapper,
  SubTitle,
  TermsLink,
  TextDescription,
} from './styles'

const OTP_CODE_LENGTH = 6

const FIELDS = {
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  AGREEMENT: 'agreement',
  PASSWORD: 'password',
}

function SignUp() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [isResendLoading, setResendLoading] = useState(false)
  const [isShowPassword, setShowPassword] = useState(false)
  const [showConfirmCode, setShowConfirmCode] = useState(false)
  const [email, setEmail] = useState(null)
  const [otp, setOtp] = useState('')

  const [signUp] = useMutation(signUpByEmailMutation)
  const [verifyCode] = useMutation(verifyCodeMutation)
  const [resendVerificationCode] = useMutation(resendVerificationCodeMutation)

  const isSendCodeDisabled = otp.length < OTP_CODE_LENGTH

  const handleToggleShowPassword = useCallback(
    () => setShowPassword(prevState => !prevState),
    [],
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.FIRST_NAME]: {
        presence: {
          presence: true,
          message: `^${_('auth.signUp.firstNameRequired')}`,
        },
      },
      [FIELDS.LAST_NAME]: {
        presence: {
          presence: true,
          message: `^${_('auth.signUp.lastNameRequired')}`,
        },
      },
      [FIELDS.EMAIL]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.emailRequired')}`,
        },
        email: {
          email: true,
          message: `^${_('auth.shared.emailInvalid')}`,
        },
      },
      [FIELDS.PASSWORD]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.passwordRequired')}`,
        },
        format: {
          pattern: PASSWORD_REGEX,
          flags: 'i',
          message: `^${_('auth.shared.passwordInvalid')}`,
        },
      },
      [FIELDS.AGREEMENT]: Utils.Form.checkboxConstraint(
        `^${_('auth.shared.termsRequired')}`,
      ),
    }),
    [],
  )

  const submit = useCallback(
    async values => {
      try {
        setLoading(true)

        await signUp({
          variables: {
            email: values[FIELDS.EMAIL],
            firstName: values[FIELDS.FIRST_NAME],
            lastName: values[FIELDS.LAST_NAME] || '',
            password: values[FIELDS.PASSWORD],
          },
        })

        setEmail(values[FIELDS.EMAIL])
        setShowConfirmCode(true)
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
      } finally {
        setLoading(false)
      }
    },
    [signUp],
  )

  const handleVerifyCode = useCallback(async () => {
    try {
      setLoading(true)
      const response = await verifyCode({
        variables: {
          code: otp,
          email,
        },
      })
      setAuth(response?.data?.verifyCode)
      history.push(APP_ROOT)
    } catch (error) {
      toast.error(get(error, 'message') || _('error.generic'))
    } finally {
      setLoading(false)
    }
  }, [email, otp, verifyCode, history])

  const handleResendVerificationCode = useCallback(async () => {
    setResendLoading(true)

    try {
      await resendVerificationCode({
        variables: {
          email,
        },
      })
    } catch (error) {
      toast.error(get(error, 'message') || _('error.generic'))
    } finally {
      setResendLoading(false)
    }
  }, [email, resendVerificationCode])

  const renderForm = useCallback(
    ({ handleSubmit }) => {
      return (
        <Column width={1}>
          <Column center>
            <Text fontWeight={3} heading1>
              {_('auth.signUp.mainTitle')}
            </Text>
            <TextDescription fontWeight={2} heading5 mt={4}>
              {_('auth.signUp.mainSubTitle')}
            </TextDescription>
          </Column>

          <FormContent mt={6}>
            <Column>
              <Text fontWeight={2} heading3>
                {_('auth.signUp.title')}
              </Text>
            </Column>

            <InputLabels mt={4} title={`${_('auth.signUp.firstName')}*`}>
              <InputField
                input={{ placeholder: _('auth.signUp.firstNamePlaceholder') }}
                name={FIELDS.FIRST_NAME}
              />
            </InputLabels>
            <InputLabels mt={4} title={`${_('auth.signUp.lastName')}*`}>
              <InputField
                input={{ placeholder: _('auth.signUp.lastNamePlaceholder') }}
                name={FIELDS.LAST_NAME}
              />
            </InputLabels>

            <InputLabels mt={4} title={`${_('auth.shared.email')}*`}>
              <InputField
                input={{ placeholder: _('auth.shared.emailPlaceholder') }}
                name={FIELDS.EMAIL}
              />
            </InputLabels>

            <InputLabels mt={4} title={`${_('auth.shared.password')}*`}>
              <InputField
                input={{
                  placeholder: _('auth.shared.passwordPlaceholder'),
                  type: isShowPassword ? 'text' : 'password',
                  renderAfter: isShowPassword ? (
                    <IconButton onClick={handleToggleShowPassword}>
                      <EyeOffIcon height={16} viewBox="0 0 24 24" width={16} />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleToggleShowPassword}>
                      <EyeIcon height={16} viewBox="0 0 24 24" width={16} />
                    </IconButton>
                  ),
                }}
                name={FIELDS.PASSWORD}
              />
            </InputLabels>

            <CheckboxField
              label={
                <CheckboxText extraSmall>
                  {_('auth.shared.terms.text')[0]}{' '}
                  <TermsLink
                    href="https://www.upwealth.io/terms-of-use/"
                    target="_blank"
                  >
                    {_('auth.shared.terms.termsLink')}
                  </TermsLink>
                  ,{' '}
                  <TermsLink
                    href="https://www.upwealth.io/privacy-policy/"
                    target="_blank"
                  >
                    {_('auth.shared.terms.privacyPolicyLink')}
                  </TermsLink>
                  , <br />
                  <TermsLink
                    href="https://www.upwealth.io/regulatory-compliance/"
                    target="_blank"
                  >
                    {_('auth.shared.terms.regulatoryComplianceLink')}
                  </TermsLink>{' '}
                  {_('auth.shared.terms.text')[1]}{' '}
                  <TermsLink
                    href="https://www.upwealth.io/legal-disclaimer/"
                    target="_blank"
                  >
                    {_('auth.shared.terms.legalDisclaimerLink')}
                  </TermsLink>{' '}
                  {_('auth.shared.terms.text')[2]}{' '}
                </CheckboxText>
              }
              mt={4}
              name={FIELDS.AGREEMENT}
            />
            <Button
              disabled={loading}
              mt={6}
              width={200}
              onClick={handleSubmit}
            >
              {_('auth.signUp.action')} {loading && <Loader ml={1} />}
            </Button>
          </FormContent>
        </Column>
      )
    },
    [handleToggleShowPassword, isShowPassword, loading],
  )

  return (
    <Column pb="80px" width={1}>
      <Content>
        {showConfirmCode ? (
          <Row gap="20px" mt={6}>
            <ProgressBar mt="76px" />

            <Column width={1}>
              <Column center>
                <Text fontWeight={3} heading1>
                  {_('auth.confirmEmail.title')}
                </Text>
              </Column>

              <FormContent mt={6}>
                <Column>
                  <Text fontWeight={2} heading3>
                    {_('auth.confirmEmail.enterTheCode')}
                  </Text>
                  <SubTitle mt={2} small>
                    {_('auth.confirmEmail.weSentEmail')} {email}
                  </SubTitle>
                </Column>

                <OtpInputWrapper mt={5}>
                  <OtpInput
                    numInputs={OTP_CODE_LENGTH}
                    renderInput={props => <OtpCodeInput {...props} />}
                    value={otp}
                    onChange={setOtp}
                  />
                </OtpInputWrapper>

                <Button
                  disabled={isResendLoading}
                  mt={4}
                  text
                  xSmall
                  onClick={handleResendVerificationCode}
                >
                  {_('auth.confirmEmail.sendCodeAgain')}
                </Button>

                <Button
                  disabled={isSendCodeDisabled || loading}
                  mt={4}
                  onClick={handleVerifyCode}
                >
                  {_('auth.confirmEmail.confirmMyEmail')}
                </Button>
              </FormContent>
            </Column>
          </Row>
        ) : (
          <Row gap="20px" mt={6}>
            <ProgressBar mt="112px" />
            <Form
              render={renderForm}
              validate={values => validate(values, formConstraints)}
              onSubmit={submit}
            />
          </Row>
        )}
      </Content>
    </Column>
  )
}

export default SignUp
