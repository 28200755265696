import { LANGUAGE } from 'Constants/ids'

const LANGUAGE_NAMES = {
  [LANGUAGE.EN]: 'locale.en',
  [LANGUAGE.FR]: 'locale.fr',
  [LANGUAGE.KO]: 'locale.ko',
}

function name(language) {
  return LANGUAGE_NAMES[language] || 'general.unknown'
}

export default {
  name,
}
