import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import arrowDownGlyph from 'iconoir/icons/arrow-down.svg'
import arrowUpGlyph from 'iconoir/icons/arrow-up.svg'

const negativeBalanceValueCss = ({ negative }) =>
  negative &&
  css`
    color: ${themeGet('colors.dangerLight')};
  `
const positiveBalanceValueCss = ({ positive }) =>
  positive &&
  css`
    color: ${themeGet('colors.primary')};
  `

export const BalanceValue = styled.div`
  font-size: ${themeGet('fontSizes.2')}px;
  font-style: normal;
  font-weight: ${themeGet('fontWeights.2')};
  line-height: 20px;
  display: flex;
  align-items: center;

  ${negativeBalanceValueCss};
  ${positiveBalanceValueCss};
`

export const ArrowUpIcon = styled(arrowUpGlyph)`
  stroke-width: 2px;
`

export const ArrowDownIcon = styled(arrowDownGlyph)`
  stroke-width: 2px;
`
