import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout, space, width } from 'styled-system'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const StyledRadioButton = styled.div`
  border-style: solid;
  border-radius: ${mapToTheme('radioButtons.borderRadius')}px;
  width: ${mapToTheme('radioButtons.size')}px;
  height: ${mapToTheme('radioButtons.size')}px;
  flex-shrink: 0;
`

export const InvisibleRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  background: none;
  border: none;
  opacity: 0;
  pointer-events: none;
`

export const Container = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  width: max-content;
  gap: 10px;

  :hover {
    cursor: pointer;
  }

  & > input {
    :not(:checked) ~ ${StyledRadioButton} {
      border-width: ${themeGet('radioButtons.borderWidth.unselected')}px;
    }

    :enabled ~ ${StyledRadioButton} {
      cursor: pointer;
      border-color: ${mapToTheme('radioButtons.enabled.borderColor')};
      background-color: ${mapToTheme('radioButtons.enabled.backgroundColor')};
    }

    :checked ~ ${StyledRadioButton} {
      border-width: ${mapToTheme('radioButtons.selected.borderWidth')}px;
      background-color: ${mapToTheme('radioButtons.selected.backgroundColor')};
    }

    :enabled:checked ~ ${StyledRadioButton} {
      border-color: ${mapToTheme('radioButtons.selected.borderColor')};
    }

    :enabled:hover ~ ${StyledRadioButton} {
      border-color: ${mapToTheme('radioButtons.enabled.hover.borderColor')};
    }

    :enabled:focus ~ ${StyledRadioButton} {
      border-color: ${mapToTheme('radioButtons.enabled.focus.borderColor')};
      box-shadow: 0 0 0 3px
        ${mapToTheme('radioButtons.enabled.focus.boxShadow')};
    }

    :disabled ~ ${StyledRadioButton} {
      cursor: default;
      background-color: ${mapToTheme('radioButtons.disabled.backgroundColor')};
      border-color: ${mapToTheme('radioButtons.disabled.borderColor')};
    }

    :disabled:checked ~ ${StyledRadioButton} {
      background-color: ${mapToTheme(
        'radioButtons.selected.disabled.backgroundColor',
      )};
      border-color: ${mapToTheme('radioButtons.selected.disabled.borderColor')};
    }
  }

  ${space}
  ${layout}
`

const activeEnhancedRadioButtonContainerCss = ({ active }) =>
  active &&
  css`
    border-color: ${themeGet('colors.primary50')};
  `

const disabledEnhancedRadioButtonContainerCss = ({ disabled }) =>
  disabled &&
  css`
    opacity: 0.5;
  `

export const EnhancedRadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${themeGet('colors.text20')};
  background: ${themeGet('colors.cultured')};
  z-index: 100;

  :hover {
    cursor: pointer;
    border-color: ${themeGet('colors.primary50')};
  }

  > label {
    background: ${themeGet('colors.white')};
    padding: 17px 25px;
    border-radius: 8px;

    > div {
      display: flex;
      align-items: center;
    }
  }

  .radio-button {
    margin-left: auto;
  }

  ${activeEnhancedRadioButtonContainerCss};
  ${disabledEnhancedRadioButtonContainerCss};

  ${width};
  ${margin};
`

export const Description = styled.label`
  cursor: pointer;
  border-radius: 0 0 12px 12px;
  padding: ${themeGet('space.4')}px ${themeGet('space.5')}px;
  background: transparent !important;
`
