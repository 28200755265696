import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { themeGet } from '@styled-system/theme-get'

import LogoGlyph from 'Assets/Svg/logo.svg'

export const Container = styled.div`
  flex-shrink: 0;
  overflow: hidden;

  ${layout.size}
`

const logoIconInverseCss = ({ inverse }) =>
  inverse &&
  css`
    --background: ${themeGet('colors.white')};
    --color: ${themeGet('colors.bg.secondary')};
  `

export const LogoIcon = styled(LogoGlyph)`
  --background: ${themeGet('colors.bg.secondary')};
  --color: ${themeGet('colors.white')};

  ${logoIconInverseCss}
`
