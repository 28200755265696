import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, Loader, Modal, Row } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms'

import adminCreatePortfolioGroupMutation from 'GraphQL/Mutations/Admin/adminCreatePortfolioGroup.graphql'
import adminUpdatePortfolioGroupMutation from 'GraphQL/Mutations/Admin/adminUpdatePortfolioGroup.graphql'

import _, { useScopedI18n } from 'Services/I18n'

import { Content } from './styles'

const FIELDS = {
  NAME: 'name',
}

function AddEditPortfolioGroupModal({ group, onRefetch, ...rest }) {
  const close = useRef(null)
  const s = useScopedI18n('admin.modal.addEditPortfolioGroup')

  const [loading, setLoading] = useState(false)

  const [adminCreatePortfolioGroup] = useMutation(
    adminCreatePortfolioGroupMutation,
  )
  const [adminUpdatePortfolioGroup] = useMutation(
    adminUpdatePortfolioGroupMutation,
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.NAME]: {
        presence: true,
      },
    }),
    [],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      const isEdit = !!group

      const query = isEdit
        ? adminUpdatePortfolioGroup
        : adminCreatePortfolioGroup

      const variables = {
        name: values[FIELDS.NAME],
      }

      if (isEdit) {
        variables.id = group.id
      }

      try {
        await query({
          variables,
        })

        toast.success('Success')

        setLoading(false)

        if (!isEdit) {
          onRefetch()
        }

        close.current()
      } catch (error) {
        toast.error(error?.message)

        setLoading(false)
      }
    },
    [group, adminUpdatePortfolioGroup, adminCreatePortfolioGroup, onRefetch],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        initialValues={group}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <InputLabels mt={3} title={s('name')}>
                <InputField
                  input={{ placeholder: s('namePlaceholder') }}
                  name={FIELDS.NAME}
                />
              </InputLabels>
            </Content>

            <Row borderTop center height={56} justifyCenter>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button
                    secondary
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {_('general.cancel')}
                  </Button>
                  <Button ml={3} width={130} onClick={handleSubmit}>
                    {s('action')}
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

AddEditPortfolioGroupModal.defaultProps = {
  group: null,
  onRefetch: noop,
}

AddEditPortfolioGroupModal.propTypes = {
  group: PropTypes.object,
  onRefetch: PropTypes.func,
}

export default AddEditPortfolioGroupModal
