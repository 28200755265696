import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${themeGet('space.3')}px;
  background-color: ${themeGet('colors.bg.main')};
  border: 1px solid ${themeGet('colors.divider.light')};
  box-shadow: 0 4px 10px 0 rgba(116, 123, 134, 0.1);
`
