import palette from 'Theme/Main/palette'

export default {
  height: {
    default: 44,
    small: 36,
    big: 56,
  },
  placeholder: {
    default: palette.text60,
  },
  color: {
    default: palette.text100,
  },
  backgroundColor: {
    default: palette.white,
  },
  borderRadius: {
    default: 8,
  },
  borderWidth: {
    default: 1,
  },
  borderColor: {
    default: palette.text20,
  },
  fontSize: {
    default: 14,
    big: 16,
  },

  hover: {
    borderColor: {
      default: palette.primary50,
    },
  },
  focus: {
    borderColor: {
      default: palette.primary50,
    },
    boxShadow: { default: palette.aquaMist },
  },
  active: {
    borderColor: {
      default: palette.primary50,
    },
    boxShadow: { default: palette.aquaMist },
  },
  disabled: {
    color: {
      default: palette.gray50,
    },
    borderColor: {
      default: palette.gray20,
    },
    backgroundColor: {
      default: palette.gray10,
    },
  },
  success: {
    borderColor: {
      default: palette.successPrimary,
    },
  },
  error: {
    borderColor: {
      default: palette.dangerPrimary,
    },
  },

  label: {
    fontSize: {
      default: 14,
    },
    fontWeight: {
      default: 500,
    },
    color: {
      default: palette.text100,
    },
  },

  info: {
    fontSize: 12,
    color: {
      default: palette.text70,
    },
  },

  textarea: {
    height: {
      default: 180,
    },
  },
}
