import { ROOT } from 'Router/routes'

import { notifyLinks } from 'Services/Apollo/notifyLinks'
import shared from 'Services/Shared'
import { clearAuth } from 'Services/Store/auth'

export async function signOut(history) {
  clearAuth()

  const client = shared.getClient()

  await client.clearStore()

  notifyLinks()

  history.push(ROOT)
}
