import React, { useCallback, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import OtpInput from 'react-otp-input'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'

import { Button, Column, Link, Loader } from 'Components/UI'
import { Label } from 'Components/UI/Forms/styles'

import verifyCodeMutation from 'GraphQL/Mutations/Auth/verifyCode.graphql'

import { useLocationQueryParams } from 'Hooks'

import { ROOT } from 'Router/routes'

import _, { useScopedI18n } from 'Services/I18n'

import Utils from 'Utils'

import {
  Card,
  ControlsRow,
  FormContent,
  IconWrapper,
  Info,
  OtpCodeInput,
  OtpInputWrapper,
  SendMailIcon,
  SubText,
  Text,
  Title,
} from '../styles'

const FIELDS = {
  CODE: 'code',
}

const OTP_CODE_LENGTH = 6

function VerifyEmail() {
  const s = useScopedI18n('auth.verifyEmail')

  const [loading, setLoading] = useState(false)
  const [isVerified, setVerified] = useState(false)

  const [verifyCode] = useMutation(verifyCodeMutation)

  const params = useLocationQueryParams()

  const formConstraints = useMemo(
    () => ({
      [FIELDS.CODE]: {
        presence: {
          presence: true,
        },
        length: {
          is: OTP_CODE_LENGTH,
        },
      },
    }),
    [],
  )

  const submit = useCallback(
    async values => {
      try {
        const emailValue = params.email

        setLoading(true)

        await verifyCode({
          variables: {
            email: emailValue.replace(' ', '+'),
            code: values[FIELDS.CODE],
          },
        })

        setVerified(true)
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
      } finally {
        setLoading(false)
      }
    },
    [verifyCode, params],
  )

  const renderForm = useCallback(
    ({ handleSubmit }) => {
      return (
        <FormContent>
          <Title>{s('title')}</Title>
          <SubText mt={2}>{s('enterTheCode')}</SubText>

          <OtpInputWrapper mt={5}>
            <Field name={FIELDS.CODE}>
              {({ input: { value, onChange: handleChange }, meta }) => {
                const { hasError, error } = Utils.Form.hasError(meta)

                return (
                  <>
                    <OtpInput
                      numInputs={OTP_CODE_LENGTH}
                      renderInput={props => <OtpCodeInput {...props} />}
                      value={value}
                      onChange={handleChange}
                    />

                    {hasError && (
                      <Label error mt={1}>
                        {error}
                      </Label>
                    )}
                  </>
                )
              }}
            </Field>
          </OtpInputWrapper>

          <Button disabled={loading} mt={5} onClick={handleSubmit}>
            {s('action')}
            {loading && <Loader ml={1} />}
          </Button>
        </FormContent>
      )
    },
    [loading, s],
  )

  return (
    <>
      <ControlsRow>
        <Text>
          {s('backTo')}{' '}
          <Link fontWeight={600} to={ROOT}>
            {s('signIn')}
          </Link>
        </Text>
      </ControlsRow>

      {isVerified ? (
        <Column center height="100%" justifyCenter width={1}>
          <Card>
            <IconWrapper>
              <SendMailIcon height={24} viewBox="0 0 24 24" width={24} />
            </IconWrapper>

            <Info mt={5}>
              <Title>{s('successTitle')}</Title>
              <SubText mt={2}>
                {s('success')[0]}{' '}
                <Link fontSize="14px" to={ROOT}>
                  {s('signIn').toLowerCase()}
                </Link>{' '}
                {s('success')[1]}
              </SubText>
            </Info>
          </Card>
        </Column>
      ) : (
        <Column center height="100%" justifyCenter width={1}>
          <Card>
            <Form
              render={renderForm}
              validate={values => validate(values, formConstraints)}
              onSubmit={submit}
            />
          </Card>
        </Column>
      )}
    </>
  )
}

export default VerifyEmail
