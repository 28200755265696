import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import numeral from 'numeral'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import size from 'lodash/size'
import valuesLodash from 'lodash/values'

import CurrencyIcon from 'Components/Blocks/CurrencyIcon'
import PercentageInput from 'Components/Blocks/PercentageInput'
import { Row, Table } from 'Components/UI'
import Text from 'Components/UI/Text'

import _ from 'Services/I18n'

import { theme } from 'Theme'

import { Content, DeleteButton, TrashIcon } from './styles'

function AssetsFormTable({
  amount,
  assets,
  colors,
  highlightedAssetBase,
  showDeleteButton,
  onDelete,
  ...rest
}) {
  const columns = useMemo(() => {
    const colorsSize = size(colors)

    const result = [
      {
        Header: () => (
          <Text
            color={theme.colors.font.secondary}
            extraSmall
            fontWeight={0}
            px={3}
          >
            {_('portfolio.assets.table.name')}
          </Text>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const base = get(row, ['original', 'base'])
          return (
            <Row center flexShrink={1} px={3}>
              <CurrencyIcon currency={base} />
              <Text fontWeight={1} ml={2}>
                {get(row, ['original', 'baseName'])}
              </Text>
              <Text color={theme.colors.text70} fontWeight={1} ml={1}>
                {base}
              </Text>
            </Row>
          )
        },
        accessor: 'baseName',
        width: 128,
      },
      {
        Header: () => (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {_('portfolio.assets.table.balance')}
          </Text>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const balance =
            get(row, ['original', 'amount']) ||
            (((amount || 0) / (get(row, ['original', 'ask']) || 0)) *
              (get(row, ['original', 'percentage']) || 0)) /
              100

          return (
            <Row center>
              <Text fontWeight={1}>
                {numeral(balance).format('0.00[0000]')}{' '}
                {get(row, ['original', 'base'])}
              </Text>
            </Row>
          )
        },
        accessor: 'rate',
        width: 100,
      },
      {
        Header: () => (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {_('portfolio.assets.table.price')}
          </Text>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const assetCost = get(row, ['original', 'cost'])
          const assetAmount = get(row, ['original', 'amount'])

          const value =
            assetCost && assetAmount
              ? assetCost / assetAmount
              : get(row, ['original', 'ask'])

          return (
            <Text fontWeight={1}>${numeral(value).format('0.00[0000]')}</Text>
          )
        },
        accessor: 'ask',
        width: 90,
      },
      {
        id: 'total',
        Header: () => (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {_('portfolio.assets.table.total')}
          </Text>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const total =
            get(row, ['original', 'cost']) ||
            ((amount || 0) * (get(row, ['original', 'percentage']) || 0)) / 100

          return <Text fontWeight={1}>${numeral(total).format('0.00')}</Text>
        },
        width: 74,
      },
      {
        Header: () => (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {_('portfolio.assets.table.allocation')}
          </Text>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row, value }) => {
          return (
            <Row justifyCenter width={1}>
              <PercentageInput
                color={get(colors, get(row, 'index') % colorsSize)}
                disabled
                showLine
                small
                value={value}
              />
            </Row>
          )
        },
        accessor: 'percentage',
        width: 120,
        headerCenter: true,
      },
    ]

    if (assets.length > 1 && showDeleteButton) {
      result.push({
        id: 'delete',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          <Row px={3}>
            <DeleteButton onClick={() => onDelete(get(row, ['original']))}>
              <TrashIcon height={20} viewBox="0 0 24 24" width={20} />
            </DeleteButton>
          </Row>
        ),
        width: 32,
        cellRight: true,
      })
    }

    return result
  }, [amount, assets.length, colors, onDelete, showDeleteButton])

  return (
    <Content {...pick(rest)}>
      <Table
        columns={columns}
        data={isEmpty(assets) ? [] : valuesLodash(assets)}
        noData={
          <Text color={theme.colors.text70} fontWeight={1} heading6 p={4}>
            No data to display
          </Text>
        }
        rowGap={theme.space[3]}
        rowHeight={50}
        sortable
      />
    </Content>
  )
}

AssetsFormTable.defaultProps = {
  amount: 0,
  assets: null,
  colors: theme.colors.assets,
  highlightedAssetBase: null,
  initialAssets: [],
  showDeleteButton: true,
  onDelete: noop,
}

AssetsFormTable.propTypes = {
  amount: PropTypes.number,
  assets: PropTypes.array,
  colors: PropTypes.arrayOf(PropTypes.string),
  highlightedAssetBase: PropTypes.string,
  initialAssets: PropTypes.array,
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
}

export default AssetsFormTable
