import React, { useMemo } from 'react'

import { DateTime } from 'luxon'

import map from 'lodash/map'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'user',
        Cell: ({ value }) =>
          `${value?.profile?.firstName} ${value?.profile?.lastName}`,
        width: 2,
      },
      {
        Header: 'Assets',
        accessor: 'assets',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <>
            {map(value, asset => (
              <>
                {`${asset.amount || ''} ${asset.asset} (${asset.percentage}%)`}
                <br />
              </>
            ))}
            ————————
          </>
        ),
        width: 2,
      },
      {
        Header: 'State',
        accessor: 'state',
        width: 1,
      },
      {
        Header: 'Initial amount',
        accessor: 'amount',
        width: 1,
        Cell: ({ value }) => `${value.toFixed(2)} USDT`,
      },
      {
        Header: 'Current amount',
        id: 'current-amount',
        accessor: 'assets',
        width: 1,
        Cell: ({ value }) =>
          `${value.reduce((acc, item) => acc + item.cost, 0).toFixed(2)} USDT`,
      },
      {
        Header: 'Rebalance Type',
        accessor: 'rebalanceType',
        width: 1,
      },
      {
        Header: 'Rebalance Value',
        accessor: 'rebalanceValue',
        width: 1,
      },
      {
        Header: 'Created',
        Cell: ({ value }) =>
          value
            ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
            : '—',
        accessor: 'createdAt',
        width: 1,
      },
    ],
    [],
  )
}
