import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'

import { useTheme } from 'styled-components'

import { useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import numeral from 'numeral'

import capitalize from 'lodash/capitalize'
import first from 'lodash/first'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'

import {
  ExternalAssetsList,
  InfoPie,
  PortfolioBalance,
  PortfolioPerformance,
} from 'Components/Blocks/Portfolio'
import { Column, Loader, Row, Text } from 'Components/UI'

import userCryptoWalletQuery from 'GraphQL/Queries/UserCryptoWallets/userCryptoWallet.graphql'
import userCryptoWalletDataQuery from 'GraphQL/Queries/UserCryptoWallets/userCryptoWalletData.graphql'

import { useScopedI18n } from 'Services/I18n'
import shared from 'Services/Shared'

import { Content, Divider, Info, Wrapper } from './styles'

const DATE_FORMAT = 'd MMMM yyyy'

function Exchange() {
  const { params: routeParams } = useRouteMatch()
  const s = useScopedI18n('portfolio')
  const theme = useTheme()

  const [portfolio, setPortfolio] = useState(null)

  const walletId = routeParams?.walletId

  const { data: walletData } = useQuery(userCryptoWalletQuery, {
    variables: { id: walletId },
  })

  const { data: userCryptoWalletData } = useQuery(userCryptoWalletDataQuery, {
    variables: { id: walletId },
  })

  useEffect(() => {
    const loadedPortfolio = walletData?.userCryptoWallet

    if (!loadedPortfolio) return

    const totalAssetsCost = sumBy(
      loadedPortfolio?.userAssets,
      asset => asset.cost,
    )

    const assets = (loadedPortfolio?.userAssets || []).reduce(
      (acc, userAsset) => {
        if (userAsset.cost > 0.01) {
          acc.push({
            ...userAsset,
            asset: userAsset.currency,
            percentage: (userAsset.cost / totalAssetsCost) * 100,
          })
        }
        return acc
      },
      [],
    )

    shared
      .getPortfolioService()
      .populatePortfolios([
        {
          ...loadedPortfolio,
          assets: orderBy(assets, ['percentage'], ['desc']),
        },
      ])
      .then(result => setPortfolio(first(result)))
  }, [walletData])

  const userCryptoWalletsDataRows =
    userCryptoWalletData?.userCryptoWalletData?.rows || []

  const initialValue =
    userCryptoWalletsDataRows.at(0)?.amount || portfolio?.cost
  const portfolioGain = portfolio?.cost - initialValue
  const portfolioGainPercentage = (portfolioGain / initialValue) * 100

  if (!portfolio) {
    return <Loader fullHeight fullWidth />
  }

  return (
    <>
      <Content>
        <Wrapper>
          <PortfolioBalance
            amount={portfolio.cost}
            gainAmount={portfolioGain}
            gainPercent={portfolioGainPercentage}
          />

          <Divider my="20px" />

          <Row>
            <InfoPie
              initialValue={numeral(initialValue).format(`0,0.[00]`)}
              name={capitalize(portfolio.name)}
            />

            <Column flexGrow={1} ml="40px">
              <Text firstCapital fontWeight={2} heading4>
                {portfolio.name}
              </Text>

              <Divider my={3} />

              <Row>
                <Column flexBasis="33%">
                  <Text color={theme.colors.text70} extraSmall>
                    {s('info.initialValue')}
                  </Text>
                  <Text fontWeight={2} heading6 mt={1}>
                    ${numeral(initialValue).format('0,0.[00]')}
                  </Text>
                </Column>
              </Row>

              <Divider my={3} />

              <Info>
                <Row>
                  <Text color={theme.colors.text70} extraSmall>
                    {s('info.creationDate')}:
                  </Text>
                  <Text extraSmall fontWeight={1} ml={1}>
                    {DateTime.fromISO(portfolio.createdAt).toFormat(
                      DATE_FORMAT,
                    )}
                  </Text>
                </Row>
              </Info>
            </Column>
          </Row>

          <Divider my="20px" />

          <PortfolioPerformance
            id={portfolio.id}
            kind={PortfolioPerformance.KIND.WALLET}
          />

          <ExternalAssetsList mt="20px" portfolio={portfolio} />
        </Wrapper>
      </Content>
    </>
  )
}

export default Exchange
