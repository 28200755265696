import { alphasigmaLogo2020Image } from 'Assets/Image'

export const REBALANCE_TYPE = {
  PERIOD: 'period',
  THRESHOLD: 'threshold',
}

export const STATE = {
  INITIAL: 'initial',
  FUNDED: 'funded',
  FILLED: 'filled',
  CLOSING: 'closing',
  CLOSED: 'closed',
}

export const WITHDRAW_FREQUENCY = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
}

export const PORTFOLIO_LOCAL_DATA = {
  '017b2c1b-e83c-44c9-85ec-ba09d8cb3f6b': {
    logo: alphasigmaLogo2020Image,
    factSheetPdfUrl: '/fact-sheet/ASC_AI_Index_Fact_Sheet_2024_07_10.pdf',
  },
}
