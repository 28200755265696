import { useEffect } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import PropTypes from 'prop-types'

function PlaidHolder({ token, onSuccess, onExit }) {
  const config = {
    token,
    onSuccess,
    onExit,
  }

  const { open: handleOpen, ready } = usePlaidLink(config)

  useEffect(() => {
    if (ready) {
      handleOpen()
    }
  }, [handleOpen, ready])

  return null
}

PlaidHolder.propTypes = {
  token: PropTypes.string.isRequired,
  onExit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default PlaidHolder
