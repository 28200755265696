import React from 'react'
import PropTypes from 'prop-types'

import { Container, Label } from './styles'

function InputLabels({ children, width, title, ...rest }) {
  return (
    <Container width={width} {...rest}>
      <Label mb={1}>{title}</Label>
      {children}
    </Container>
  )
}

InputLabels.defaultProps = {
  children: null,
  width: 1,
}

InputLabels.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default InputLabels
