import React from 'react'
import PropTypes from 'prop-types'

import { omit, pick } from '@styled-system/props'

import { Background, Container, Icon, Text, TextContent } from './styles'

function Checkbox({ checked, error, label, meta, ...rest }) {
  return (
    <Container {...pick(rest)}>
      <input checked={checked} type="checkbox" {...omit(rest)} />
      <Background />
      <Icon />
      {(label || error) && (
        <TextContent ml={2}>
          {label && <Text>{label}</Text>}
          {error && <Text error>{error}</Text>}
        </TextContent>
      )}
    </Container>
  )
}

Checkbox.defaultProps = {
  checked: null,
  error: null,
  label: null,
  meta: {},
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  meta: PropTypes.object,
}

export default Checkbox
