import React from 'react'

import { AccountsList } from 'Components/Blocks/NetWorth'
import { Column } from 'Components/UI'

function Accounts() {
  return (
    <Column center flexGrow={1} fullWidth>
      <AccountsList mt={3} />
    </Column>
  )
}

export default Accounts
