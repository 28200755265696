import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { useQuery } from '@apollo/client'

import noop from 'lodash/noop'
import sumBy from 'lodash/sumBy'

import { Row } from 'Components/UI'

import { ADD_TO_NET_WORTH_KIND } from 'Constants/netWorth'
import { STATE } from 'Constants/portfolios'

import portfoliosQuery from 'GraphQL/Queries/Portfolios/portfolios.graphql'

import { useExchanges, useWallets } from 'Hooks'

import { ADD_NET_WORTH, PORTFOLIO } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'
import shared from 'Services/Shared'

import NetWorthButton from './NetWorthButton'

function Balance({ onAdd, ...rest }) {
  const history = useHistory()
  const s = useScopedI18n('balance')

  const [portfolios, setPortfolios] = useState([])

  const { data: portfoliosData } = useQuery(portfoliosQuery)

  const [exchangesTotal] = useExchanges()
  const [walletsTotal] = useWallets()

  useEffect(() => {
    const loadedPortfolios = portfoliosData?.portfolios?.rows || []
    if (!loadedPortfolios.length) return

    shared
      .getPortfolioService()
      .populatePortfolios(loadedPortfolios)
      .then(result => setPortfolios(result))
  }, [portfoliosData])

  // const {
  //   result: { total: plaidTotal },
  //   loading: isPlaidLoading,
  // } = useUserPlaid()

  // const handleOpenBankNetWorth = useCallback(() => {
  //   history.push({
  //     pathname: ADD_NET_WORTH,
  //     state: {
  //       netWorthKind: ADD_TO_NET_WORTH_KIND.BANK,
  //     },
  //   })
  // }, [history])

  const handleOpenPortfolios = useCallback(() => {
    history.push(PORTFOLIO)
  }, [history])

  const handleOpenExchangeNetWorth = useCallback(() => {
    history.push({
      pathname: ADD_NET_WORTH,
      state: {
        netWorthKind: ADD_TO_NET_WORTH_KIND.EXCHANGE,
      },
    })
  }, [history])

  const handleOpenWalletNetWorth = useCallback(() => {
    history.push({
      pathname: ADD_NET_WORTH,
      state: {
        netWorthKind: ADD_TO_NET_WORTH_KIND.WALLET,
      },
    })
  }, [history])

  const netWorth = useMemo(() => {
    return {
      portfoliosBalance:
        sumBy(portfolios, portfolio =>
          portfolio.state === STATE.FUNDED || portfolio.state === STATE.FILLED
            ? portfolio.cost
            : 0,
        ) || 0,
      walletsTotal,
      exchangesTotal,
      // plaidTotal,
    }
  }, [portfolios, exchangesTotal, walletsTotal])

  return (
    <Row {...pick(rest)} gap="20px" width={1}>
      <NetWorthButton
        amount={netWorth.portfoliosBalance?.toFixed(2)}
        iconVariant="arrow"
        title={
          <>
            {s('upWealth')} <span>{s('portfolios')}</span>
          </>
        }
        onClick={handleOpenPortfolios}
      />

      <NetWorthButton
        amount={netWorth.exchangesTotal?.toFixed(2)}
        title={
          <>
            {s('exchange')} <span>{s('accounts')}</span>
          </>
        }
        onClick={handleOpenExchangeNetWorth}
      />

      <NetWorthButton
        amount={netWorth.walletsTotal?.toFixed(2)}
        title={<>{s('wallets')}</>}
        onClick={handleOpenWalletNetWorth}
      />

      {/* <NetWorthButton */}
      {/*  amount={netWorth.plaidTotal.toFixed(2)} */}
      {/*  loading={isPlaidLoading} */}
      {/*  title={ */}
      {/*    <> */}
      {/*      {s('bankAndBrokerage')} <span>{s('accounts')}</span> */}
      {/*    </> */}
      {/*  } */}
      {/*  onClick={handleOpenBankNetWorth} */}
      {/* /> */}
    </Row>
  )
}

Balance.defaultProps = {
  onAdd: noop,
}

Balance.propTypes = {
  onAdd: PropTypes.func,
}

export default Balance
