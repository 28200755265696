import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

const accessoryErrorCss = ({ error }) =>
  error &&
  css`
    border-color: ${mapToTheme('inputs.error.borderColor')};
  `

const accessoryWidthCss = ({ width }) =>
  width &&
  css`
    flex-shrink: 0;
    width: ${width}px;
  `

export const Accessory = styled.div`
  ${Styles.flexCenter};
  height: 100%;
  padding: 0 ${themeGet('space.1')}px;
  ${Styles.text()};
  border-left: solid 1px ${themeGet('colors.divider.light')};

  ${accessoryWidthCss}
  ${accessoryErrorCss}
`

Accessory.defaultProps = {
  error: false,
}

Accessory.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

const containerErrorCss = ({ error }) =>
  error &&
  css`
    border-color: ${mapToTheme('inputs.error.borderColor')} !important;
  `

const containerHeightCss = ({ textarea }) => css`
  height: ${textarea
    ? mapToTheme('inputs.textarea.height')
    : mapToTheme('inputs.height')}px;
`

const containerDisabledCss = ({ disabled }) =>
  disabled &&
  css`
    background-color: ${mapToTheme(
      'inputs.disabled.backgroundColor',
    )} !important;
    color: ${mapToTheme('inputs.disabled.color')} !important;
    border-color: ${mapToTheme('inputs.disabled.borderColor')} !important;
  `

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${mapToTheme('inputs.backgroundColor')};
  border: solid ${mapToTheme('inputs.borderWidth')}px
    ${mapToTheme('inputs.borderColor')};
  border-radius: ${mapToTheme('inputs.borderRadius')}px;
  transition: all ease ${themeGet('transitionTime.default')};

  :focus-within {
    border-color: ${mapToTheme('inputs.focus.borderColor')};
    box-shadow: 0 0 0 3px ${mapToTheme('inputs.focus.boxShadow')};

    > ${Accessory} {
      border-color: ${mapToTheme('inputs.focus.borderColor')};
    }
  }

  ${space}
  ${layout}
  ${containerErrorCss}
  ${containerHeightCss}
  ${containerDisabledCss}
`

Container.defaultProps = {
  error: false,
  small: false,
}

Container.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  small: PropTypes.bool,
}

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 ${themeGet('space.3')}px;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  appearance: none;
  color: inherit;

  ::placeholder {
    color: ${themeGet('colors.font.placeholder')};
  }
`

export const StyledTextarea = styled.textarea`
  padding: ${themeGet('space.3')}px ${themeGet('space.3')}px;
  margin: 0;
  background-color: transparent;
  color: ${mapToTheme('inputs.color')};
  border: none;
  outline: none;
  box-shadow: none;
  appearance: none;
  resize: none;
  width: 100%;

  ::placeholder {
    color: ${themeGet('colors.font.placeholder')};
  }
`

StyledInput.defaultProps = {
  autoComplete: 'on',
  autoFocus: false,
  disabled: false,
  placeholder: '',
  type: 'text',
}

StyledInput.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}
