import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import { DateTime } from 'luxon'
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import forEach from 'lodash/forEach'
import get from 'lodash/get'
import last from 'lodash/last'

import { YTick } from 'Components/Blocks/Charts/Ticks'

import TooltipComponent from './Tooltip'

function BacktestingChart({
  currency,
  data,
  height,
  yAxisDomainPadding,
  yAxisWidth,
}) {
  const theme = useTheme()

  const processedData = useMemo(() => {
    const result = {
      data: [],
      maxValue: 0,
      ticks: [],
      dateFormat: {
        month: 'short',
      },
    }

    const initialValue = get(data, [0, 'value'])
    const initialHold = get(data, [0, 'hold'])
    let prevTime = DateTime.fromISO('1970-01-01')

    forEach(data, (item, index) => {
      result.data.push({
        change: ((item.value - initialValue) / initialValue) * 100,
        time: item.time,
        value: item.value,
        hold: item.hold,
        holdChange: ((item.hold - initialHold) / initialHold) * 100,
      })

      result.maxValue = Math.max(result.maxValue, item.value, item.hold)

      const currentDateTime = DateTime.fromSeconds(item.time)
      if (index === 0 || !prevTime.hasSame(currentDateTime, 'month')) {
        result.ticks.push(item.time)
        prevTime = currentDateTime
      }
    })

    if (result.ticks.length === 1) {
      result.ticks.push(last(data)?.time)
      result.dateFormat = {
        day: 'numeric',
        month: 'short',
      }
    }

    return result
  }, [data])

  return (
    <ResponsiveContainer debounce={300} height={height}>
      <LineChart
        data={processedData.data}
        margin={{ top: 1, right: 0, bottom: 0, left: 0 }}
      >
        <YAxis
          axisLine={false}
          dataKey="value"
          domain={[0, processedData.maxValue + yAxisDomainPadding]}
          tick={<YTick xOffset={2} yOffset={4} />}
          tickLine={{ stroke: theme.colors.white }}
          tickSize={yAxisWidth}
          width={yAxisWidth}
        />
        <XAxis
          allowDuplicatedCategory={false}
          axisLine={false}
          dataKey="time"
          domain={['dataMin', 'dataMax']}
          height={22}
          padding={{ right: 20, left: 50 }}
          tick={{ fill: theme.colors.font.secondary }}
          tickFormatter={tick =>
            DateTime.fromSeconds(tick).toLocaleString(processedData.dateFormat)
          }
          tickLine={false}
          ticks={processedData.ticks}
          type="number"
        />
        <Tooltip
          content={<TooltipComponent currency={currency} />}
          cursor={{
            stroke: theme.colors.primary30,
            strokeDasharray: '6, 4',
          }}
          useTranslate3d
        />
        <Line
          activeDot={{
            fill: theme.colors.bg.text30,
            r: 2,
            stroke: theme.colors.text30,
            strokeWidth: 1,
          }}
          dataKey="hold"
          dot={false}
          stroke={theme.colors.text30}
          strokeWidth={2}
          type="monotone"
        />
        <Line
          activeDot={{
            fill: theme.colors.primary50,
            r: 2,
            stroke: theme.colors.primary50,
            strokeWidth: 1,
          }}
          dataKey="value"
          dot={false}
          stroke={theme.colors.primary50}
          strokeWidth={2}
          type="monotone"
        />
        {/* -- */}
      </LineChart>
    </ResponsiveContainer>
  )
}

BacktestingChart.defaultProps = {
  currency: '$',
  data: [],
  height: 300,
  yAxisDomainPadding: 100,
  yAxisWidth: 40,
}

BacktestingChart.propTypes = {
  currency: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.number,
      value: PropTypes.number,
      hold: PropTypes.number,
    }),
  ),
  height: PropTypes.number,
  yAxisDomainPadding: PropTypes.number,
  yAxisWidth: PropTypes.number,
}

export default BacktestingChart
