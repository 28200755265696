import styled from 'styled-components'
import styledMap from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

export const StateContainer = styled.div`
  background: ${styledMap('state', {
    approved: themeGet('colors.positive'),
    declined: themeGet('colors.negative'),
    default: themeGet('colors.neutral'),
  })};

  color: white;
  border-radius: 4px;
  font-weight: bold;
  padding: 0 4px;
  font-size: 12px;
`

export const ExternalLink = styled.a``
