import concat from 'lodash/concat'
import filter from 'lodash/filter'
import get from 'lodash/get'

function updateList(store, query, object, key) {
  if (!object) return

  const options = {
    query,
  }
  const readData = store.readQuery(options)

  store.writeQuery({
    ...options,
    data: {
      ...readData,
      [key]: {
        ...(get(readData, key) || []),
        // TODO: what about pages/count calculations?
        rows: concat(get(readData, [key, 'rows']) || [], object),
      },
    },
  })
}

function removeFromList(store, query, id, key) {
  if (!id) return

  const options = {
    query,
  }
  const readData = store.readQuery(options)

  store.writeQuery({
    ...options,
    data: {
      ...readData,
      [key]: {
        ...(get(readData, key) || []),
        rows: filter(
          get(readData, [key, 'rows']) || [],
          item => item.id !== id,
        ),
      },
    },
  })
}

export default { updateList, removeFromList }
