import styled from 'styled-components'
import { margin } from '@styled-system/space'

import { gap } from 'Theme/system'

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${margin};
  ${gap}
`

export const Content = styled.div`
  width: 425px;
`
