import React, { useMemo } from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import values from 'lodash/values'

import { Column, Select, Text } from 'Components/UI'
import {
  EnhancedCheckboxField,
  EnhancedRadioButtonField,
} from 'Components/UI/Forms'

import countriesList from 'Constants/countries'

import _ from 'Services/I18n'

import { theme } from 'Theme'

import { QUESTIONS, STEPS } from './constants'
import { Answers, DescriptionContainer, SelectWrapper } from './styles'

const COUNTRIES_OPTIONS = countriesList.map(country => ({
  value: country.name,
  label: country.name,
}))

function Questions({ step }) {
  const answers = useMemo(() => {
    const question = QUESTIONS[step]

    if (step === STEPS.TAX_RESIDENCE) {
      return (
        <Column mb={4} width={1}>
          <Field name={question.name}>
            {({ input: { value, onChange: handleChange } }) => (
              <SelectWrapper>
                <Select
                  isSearchable
                  options={COUNTRIES_OPTIONS}
                  value={value}
                  withPortal
                  onChange={handleChange}
                />
              </SelectWrapper>
            )}
          </Field>
        </Column>
      )
    }

    return map(question.answers, (answer, index) => {
      const key = `${question.name}-${index}`

      return (
        <Column key={key} mb={4} width={1}>
          {step === STEPS.FUND_SOURCE ? (
            <EnhancedCheckboxField
              id={key}
              label={
                <Column>
                  <Text fontWeight={2} heading4>
                    {_(answer.label)}
                  </Text>
                </Column>
              }
              name={question.name}
              value={answer.value}
            />
          ) : (
            <EnhancedRadioButtonField
              id={key}
              name={question.name}
              value={answer.value}
              width={1}
            >
              <Column>
                <Text fontWeight={2} heading4>
                  {_(answer.label)}
                </Text>

                {answer?.shortDescription && (
                  <Text color={theme.colors.text70} large>
                    {_(answer.shortDescription)}
                  </Text>
                )}
              </Column>
            </EnhancedRadioButtonField>
          )}

          {answer?.description && (
            <DescriptionContainer>
              <Text preWrap small>
                {_(answer.description)}
              </Text>
            </DescriptionContainer>
          )}
        </Column>
      )
    })
  }, [step])

  return <Answers mt={5}>{answers}</Answers>
}

Questions.STEPS = STEPS

Questions.defaultProps = {
  step: STEPS.RISK_TOLERANCE,
}

Questions.propTypes = {
  step: PropTypes.oneOf(values(STEPS)),
}

export default Questions
