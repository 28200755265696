import React, { useMemo } from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { useSubscription } from '@apollo/client'

import { Link, Row } from 'Components/UI'

import { KYC_STATUS } from 'Constants/ids'

import userKYCStateSubscription from 'GraphQL/Subscriptions/userKYCState.graphql'

import { useAppContext, useLocationQueryParams } from 'Hooks'

import { APP_ROOT, ROOT, SIGN_OUT } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import Utils from 'Utils'

import {
  Container,
  Content,
  Info,
  LogoExtendedIcon,
  LogoutIcon,
} from './styles'

function KYC({ route }) {
  const s = useScopedI18n('general')
  const { me, refetchMe } = useAppContext()
  const queryParams = useLocationQueryParams()
  const redirectStatus = queryParams?.status

  useSubscription(userKYCStateSubscription, {
    onData({ data }) {
      const state = data?.data?.userKYCState?.state

      if (state) refetchMe()
    },
  })

  const showSkipKyc = useMemo(() => {
    const kycStatus =
      redirectStatus === 'aborted'
        ? KYC_STATUS.FAILURE
        : Utils.UserKYC.getStatus(me?.kyc?.state)

    if (kycStatus !== KYC_STATUS.FAILURE) return false

    return true
  }, [me, redirectStatus])

  return (
    <Container>
      <Info>
        <Row center spaceBetween width={1}>
          <Link to={ROOT}>
            <LogoExtendedIcon />
          </Link>

          <Row>
            {showSkipKyc && (
              <Link fontWeight={600} mr={4} to={APP_ROOT}>
                {s('skipKyc')}
              </Link>
            )}

            <Row center>
              <LogoutIcon height={14} viewBox="0 0 24 24" width={14} />
              <Link fontWeight={600} to={SIGN_OUT}>
                {s('logout')}
              </Link>
            </Row>
          </Row>
        </Row>
      </Info>

      <Content width={1}>{renderRoutes(route.routes)}</Content>
    </Container>
  )
}

KYC.propTypes = {
  route: PropTypes.object.isRequired,
}

export default KYC
