import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import checkGlyph from 'iconoir/icons/check.svg'

import { gap } from 'Theme/system'

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${margin};
  ${gap}
`

export const Content = styled.div`
  width: 425px;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: ${themeGet('colors.primary20')};
  border-radius: 50%;
`

export const CheckIcon = styled(checkGlyph)`
  color: ${themeGet('colors.primary60')};
`
