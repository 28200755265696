import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import forEach from 'lodash/forEach'
import get from 'lodash/get'
import noop from 'lodash/noop'
import size from 'lodash/size'

import { Select } from 'Components/UI'

import shared from 'Services/Shared'

function AssetsSelect({ excludeAssets, onSelect, disabled }) {
  const [tickers, setTickers] = useState(null)

  useEffect(() => {
    shared
      .getPortfolioService()
      .getTickers()
      .then(result => {
        // eslint-disable-next-line no-console
        console.log({ result })
        setTickers(result)
      })
  }, [])

  const assetOptions = useMemo(
    () => {
      const result = []

      forEach(tickers, ticker => {
        const base = get(ticker, 'base')
        if (excludeAssets.has(base)) {
          return
        }

        result.push({
          label: `${get(ticker, 'baseName')} (${get(ticker, 'base')})`,
          value: base,
          ticker,
        })
      })

      return result
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [size(excludeAssets), tickers],
  )

  const handleSelect = useCallback(
    value => {
      onSelect(value.ticker)
    },
    [onSelect],
  )

  return (
    <Select
      components={{
        ClearIndicator: null,
        MultiValueContainer: () => <></>,
      }}
      controlShouldRenderValue={false}
      isDisabled={disabled}
      isSearchable
      options={assetOptions}
      placeholder="Search coins and tokens"
      onChange={handleSelect}
    />
  )
}

AssetsSelect.defaultProps = {
  disabled: false,
  excludeAssets: null,
  onSelect: noop,
}

AssetsSelect.propTypes = {
  disabled: PropTypes.bool,
  excludeAssets: PropTypes.instanceOf(Set),
  onSelect: PropTypes.func,
}

export default AssetsSelect
