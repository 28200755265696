import styled from 'styled-components'
import { margin } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import arrowLeftGlyph from 'iconoir/icons/arrow-left.svg'
import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'

export const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  border-radius: 12px;
  background: ${themeGet('colors.white')};

  ${margin}
`

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`
export const ArrowLeftIcon = styled(arrowLeftGlyph)`
  margin-right: 8px;
`

export const DescriptionIndustryContainer = styled.div`
  padding: ${themeGet('space.4')}px ${themeGet('space.5')}px;
  background-color: ${themeGet('colors.cultured')};
  border-radius: 8px;
  overflow: auto;
  max-height: 350px;

  ${margin}
`
