import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${themeGet('space.1')}px;
  padding: ${themeGet('space.2')}px;
  width: 100%;

  ${margin};

  .table {
    width: 100%;
    padding-bottom: 10px;
    .header {
      padding: 10px 0;
      border-radius: 6px 6px 0 0;
      margin-bottom: 12px;

      .row {
        padding-bottom: 10px;
      }
    }
    .row {
      border-bottom: 1px solid ${themeGet('colors.divider.light')};
    }
  }
`
