import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

import { FiUpload } from 'react-icons/fi'

import { pick } from '@styled-system/props'

import head from 'lodash/head'
import noop from 'lodash/noop'

import { Column } from 'Components/UI/Flex'
import Loader from 'Components/UI/Loader'

import { useScopedI18n } from 'Services/I18n'

import { Container, Overlay } from './styles'

import Button from '../Button'
import Text from '../Text'

function Dropzone({ disabled, imageURL, loading, onChange, ...rest }) {
  const s = useScopedI18n('dropzone')
  const [internalImageURL, setInternalImageURL] = useState(null)

  useEffect(() => {
    setInternalImageURL(imageURL)
  }, [imageURL])

  const handleDrop = useCallback(
    files => {
      const file = head(files)
      if (!file) return

      const url = URL.createObjectURL(file)
      setInternalImageURL(url)

      onChange({
        file,
        url,
      })
    },
    [onChange],
  )

  const { getRootProps, getInputProps, open: openFileDialog } = useDropzone({
    onDrop: handleDrop,
    disabled: disabled || loading,
  })

  const handleDelete = useCallback(() => {
    setInternalImageURL(null)
    onChange({})
  }, [onChange])

  return (
    <Column center>
      <Container
        {...getRootProps()}
        {...pick(rest)}
        image={!loading ? internalImageURL : null}
      >
        <input {...getInputProps()} />
        {loading && <Loader />}
        {internalImageURL && !disabled && !loading && (
          <Overlay onDelete={handleDelete} onUpload={openFileDialog} />
        )}
        {!internalImageURL && (
          <Column center p={2}>
            <FiUpload size={20} strokeWidth={1} />
            <Text color="text50" extraSmall>
              {s('dragNDrop')}
            </Text>
          </Column>
        )}
      </Container>

      <Button mt={3} text onClick={openFileDialog}>
        {s('uploadPhoto')}
      </Button>
    </Column>
  )
}

Dropzone.defaultProps = {
  disabled: false,
  imageURL: null,
  loading: false,
  onChange: noop,
}

Dropzone.propTypes = {
  disabled: PropTypes.bool,
  imageURL: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Dropzone
