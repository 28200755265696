import { RootLayout } from 'Components/Layout'

import { IS_PRODUCTION } from 'Config'

import { USER_ROLE } from 'Constants/ids'
import { SUPER_ADMIN_MAIN_EMAILS } from 'Constants/values'

import NotFound from 'Pages/NotFound'
import Public from 'Pages/Public'
import SignOut from 'Pages/SignOut'

import Utils from 'Utils'

import { ADMIN_FUND_MANAGERS_ROUTES, ADMIN_ROUTES } from './admin'
import { APP_ROUTES } from './app'
import { AUTH_ROUTES } from './auth'
import Director from './Director'
import { KYC_ROUTES } from './kyc'
import { OAUTH_ROUTES } from './oauth'
import { ONBOARDING_ROUTES } from './onboarding'
import * as ROUTES from './routes'

export default function router(user) {
  const allRoutes = [
    {
      component: Public.Root,
      path: ROUTES.ROOT,
      exact: true,
    },
    {
      component: Public.TermsOfService,
      path: ROUTES.TERMS_OF_SERVICE,
      exact: true,
    },
  ]

  if (user) {
    switch (user.role) {
      case USER_ROLE.ADMIN:
      case USER_ROLE.SUPER_ADMIN: {
        const isFundManager =
          !SUPER_ADMIN_MAIN_EMAILS.includes(user?.emailCredentials[0]?.email) &&
          IS_PRODUCTION

        const adminRoutes = isFundManager
          ? ADMIN_FUND_MANAGERS_ROUTES
          : ADMIN_ROUTES

        allRoutes.push(adminRoutes)
        break
      }

      case USER_ROLE.USER:
        // -- KYC route
        allRoutes.push(KYC_ROUTES)
        // --

        if (Utils.Me.isOnboardingPassed(user)) {
          allRoutes.push(APP_ROUTES)
          allRoutes.push(OAUTH_ROUTES)
        } else {
          allRoutes.push(ONBOARDING_ROUTES)
        }

        break
      default:
        break
    }
  } else {
    allRoutes.push(AUTH_ROUTES)
  }

  allRoutes.push({
    component: SignOut,
    path: ROUTES.SIGN_OUT,
    exact: true,
  })

  allRoutes.push({
    component: NotFound,
  })

  return [
    {
      component: RootLayout,
      routes: allRoutes,
    },
  ]
}

export { Director }
