import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import RadioButton from './RadioButton'

function RadioButtonField({ name, validate, ...rest }) {
  return (
    <Field name={name} type="radio" validate={validate}>
      {({ input }) => <RadioButton {...pick(rest)} {...input} />}
    </Field>
  )
}

RadioButtonField.defaultProps = {
  caption: undefined,
  checkErrorIfDirty: undefined,
  defaultValue: undefined,
  displayError: undefined,
  validate: undefined,
}

RadioButtonField.propTypes = {
  caption: PropTypes.string,
  checkErrorIfDirty: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  displayError: PropTypes.bool,
  name: PropTypes.string.isRequired,
  validate: PropTypes.string,
}

export default RadioButtonField
