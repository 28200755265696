import React from 'react'
import PropTypes from 'prop-types'

import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

import { pick } from '@styled-system/props'

import { ExpanderContainer } from './styles'

function ExpanderCell({ children, iconSize, row, ...rest }) {
  if (!row.canExpand) return null

  return (
    <ExpanderContainer {...row.getToggleRowExpandedProps()} {...pick(rest)}>
      {row.isExpanded ? (
        <FiChevronUp size={iconSize} />
      ) : (
        <FiChevronDown size={iconSize} />
      )}
      {children}
    </ExpanderContainer>
  )
}

ExpanderCell.defaultProps = {
  children: undefined,
  iconSize: 16,
}

ExpanderCell.propTypes = {
  children: PropTypes.any,
  iconSize: PropTypes.number,
  row: PropTypes.object.isRequired,
}

export default ExpanderCell
