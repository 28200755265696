import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { infoPieChartGlyph, logoIconGlyph } from 'Assets/Svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 240px;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`

export const InfoPieChartIcon = styled(infoPieChartGlyph)`
  position: absolute;
`

export const LogoIcon = styled(logoIconGlyph)`
  width: 24px;
  height: 24px;
`

const lightCircleCss = ({ light }) =>
  light &&
  css`
    background-color: ${themeGet('colors.primary20')};
  `

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary50')};

  ${lightCircleCss};
`
