import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFlexLayout, usePagination, useTable } from 'react-table'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useLazyQuery, useMutation } from '@apollo/client'

import debounce from 'lodash/debounce'
import get from 'lodash/get'

import { AddEditCryptoToken } from 'Components/Blocks/Admin/Modals'
import { Dialog } from 'Components/Blocks/Modals'
import { Button, Flex, Row } from 'Components/UI'
import { Pagination, Table } from 'Components/UI/Admin'
import { Input, InputLabels } from 'Components/UI/Forms'

import { USER_ROLE } from 'Constants/ids'

import adminDeleteCryptoTokenMutation from 'GraphQL/Mutations/Admin/adminDeleteCryptoToken.graphql'
import adminCryptoTokensQuery from 'GraphQL/Queries/Admin/adminCryptoTokens.graphql'

import { useAppContext } from 'Hooks'

import { useColumns } from './columns'
import TableContext from './context'
import { Container, Content } from './styles'

const sort = { column: 'lastLoginAt', order: 'asc' }

function CryptoTokensTable() {
  const { me } = useAppContext()

  const [addEditModal, setAddEditModal] = useState({ isOpen: false })
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    entity: null,
  })
  const [search, setSearch] = useState('')

  const [loadCryptoTokens, { data, loading, refetch }] = useLazyQuery(
    adminCryptoTokensQuery,
  )
  const [adminDeleteCryptoToken] = useMutation(adminDeleteCryptoTokenMutation)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cryptoTokens = get(data, 'adminCryptoTokens') || {}

  const tableData = useMemo(() => get(cryptoTokens, 'rows') || [], [
    cryptoTokens,
  ])

  const columns = useColumns()

  const tableProps = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: get(cryptoTokens, 'pages') || 0,
    },
    usePagination,
    useFlexLayout,
  )

  const {
    state: { pageIndex, pageSize },
  } = tableProps

  const fetchData = useCallback(() => {
    loadCryptoTokens({
      variables: { page: pageIndex, limit: pageSize, sort, search },
    })
  }, [search, pageSize, pageIndex, loadCryptoTokens])

  useEffect(
    () => {
      tableProps.gotoPage(0)
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search],
  )

  useEffect(
    () => {
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, pageSize],
  )

  const debouncedSearch = useCallback(
    debounce(value => {
      setSearch(value)
    }, 700),
    [],
  )

  const handleChangeSearch = useCallback(
    ({ target: { value } }) => {
      debouncedSearch(value)
    },
    [debouncedSearch],
  )

  const handleEditRow = useCallback(entity => {
    setAddEditModal({ isOpen: true, entity })
  }, [])
  const handleCloseEditModal = useCallback(() => {
    setAddEditModal({ isOpen: false, entity: null })
  }, [])

  const handleDeleteRow = useCallback(entity => {
    setDeleteModal({ isOpen: true, entity })
  }, [])
  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModal({ isOpen: false, entity: null })
  }, [])

  const handleDeleteCryptoToken = useCallback(
    async success => {
      if (success) {
        try {
          await adminDeleteCryptoToken({
            variables: { ticker: deleteModal.entity?.ticker },
          })

          await refetch()
        } catch (error) {
          toast.error(error.message)
        }
      }
      handleCloseDeleteModal()
    },
    [adminDeleteCryptoToken, deleteModal, handleCloseDeleteModal, refetch],
  )

  return (
    <TableContext.Provider
      value={{
        onEditRow: handleEditRow,
        onDeleteRow: handleDeleteRow,
      }}
    >
      <Container>
        <Content>
          <Row borderBottom center mb={2} pb={3} spaceBetween>
            <Flex>
              <InputLabels title="Search" width={200}>
                <Input
                  name="search"
                  placeholder="Search by ticker..."
                  small
                  width={300}
                  onChange={handleChangeSearch}
                />
              </InputLabels>
            </Flex>

            <Flex>
              {me.role === USER_ROLE.SUPER_ADMIN && (
                <Button
                  outline
                  small
                  onClick={() => setAddEditModal({ isOpen: true })}
                >
                  Add token
                </Button>
              )}
            </Flex>
          </Row>

          <Table {...tableProps} loading={loading} />

          <Row borderTop mt={2} pt={3}>
            <Pagination
              {...tableProps}
              total={get(cryptoTokens, ['count']) || 0}
            />
          </Row>
        </Content>
      </Container>

      <AddEditCryptoToken
        cryptoToken={addEditModal.entity}
        isOpen={addEditModal.isOpen}
        onClose={handleCloseEditModal}
        onRefetch={refetch}
      />

      <Dialog
        content={`Are you sure you want to delete ${deleteModal.entity?.ticker}`}
        isOpen={deleteModal.isOpen}
        title="Delete token"
        onClose={handleCloseDeleteModal}
        onFinish={handleDeleteCryptoToken}
      />
    </TableContext.Provider>
  )
}

CryptoTokensTable.defaultProps = {
  search: '',
}

CryptoTokensTable.propTypes = {
  search: PropTypes.string,
}

export default CryptoTokensTable
