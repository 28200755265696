import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'

const containerNoShrinkCss = ({ noShrink }) =>
  noShrink &&
  css`
    flex-shrink: 0;
  `

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  ${layout.height}
  ${layout.maxHeight}
  ${margin}
  ${containerNoShrinkCss}
`
