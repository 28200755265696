import { useMemo } from 'react'

import { DateTime } from 'luxon'

import { ActionsCell, LinkCell, StateCell } from './Cells'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'State',
        accessor: 'state',
        Cell: StateCell,
        width: 20,
      },
      {
        Header: 'User name',
        accessor: 'user',
        Cell: ({ value }) =>
          value?.profile
            ? `${value.profile?.firstName} ${value.profile?.lastName}`
            : '-',
        width: 20,
      },
      {
        Header: 'Link',
        accessor: 'url',
        Cell: LinkCell,
        width: 40,
      },
      {
        Header: 'Requests count',
        accessor: 'count',
        width: 20,
      },
      {
        Header: 'Created',
        Cell: ({ value }) =>
          value
            ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
            : '—',
        accessor: 'createdAt',
        width: 20,
      },
      {
        Header: 'Action',
        Cell: ActionsCell,
        width: 10,
      },
    ],
    [],
  )
}
