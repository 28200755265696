import { KYCLayout } from 'Components/Layout'

import KYC from 'Pages/KYC'

import * as ROUTES from './routes'

export const KYC_ROUTES = {
  component: KYCLayout,
  path: ROUTES.KYC,
  routes: [{ component: KYC.Root, path: ROUTES.KYC, exact: true }],
}
