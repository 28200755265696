import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { margin } from '@styled-system/space'
import themeGet from '@styled-system/theme-get'

import Styles from 'Components/Styles'

const Container = styled.div`
  ${Styles.text(10)};

  display: flex;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background: ${themeGet('colors.primary')};
  color: ${themeGet('colors.white')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-bottom: 2px;

  ${margin};
`

function Badge({ count, ...rest }) {
  if (!count) return null

  return <Container {...rest}>{count}</Container>
}

Badge.defaultProps = {
  count: 0,
}

Badge.propTypes = {
  count: PropTypes.number,
}

export default Badge
