import React, { useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

import CancelIcon from 'iconoir/icons/cancel.svg'

import noop from 'lodash/noop'
import pick from 'lodash/pick'
import replace from 'lodash/replace'
import toNumber from 'lodash/toNumber'

import { Row } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import { theme } from 'Theme'

import { CloseButton, StyledModal } from './styles'

ReactModal.setAppElement('body')

const TIMEOUT = toNumber(replace(theme.transitionTime.modal, 'ms', ''))

function Modal({
  children,
  isOpen,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  title,
  onClose,
  onMount,
}) {
  const [internalOpen, setInternalOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setInternalOpen(true)
    }
  }, [isOpen])

  const handleClose = useCallback(() => {
    setInternalOpen(false)
  }, [])

  useEffect(
    () => {
      onMount({ handleClose })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <StyledModal
      closeTimeoutMS={TIMEOUT}
      isOpen={internalOpen}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onAfterClose={onClose}
      onRequestClose={handleClose}
    >
      <Row center spaceBetween>
        <Text fontWeight={2} heading3>
          {title}
        </Text>
        <CloseButton ml={3} onClick={handleClose}>
          <CancelIcon height={14} viewBox="0 0 24 24" width={14} />
        </CloseButton>
      </Row>
      {children}
    </StyledModal>
  )
}

Modal.defaultProps = {
  children: undefined,
  isOpen: false,
  shouldCloseOnEsc: true,
  shouldCloseOnOverlayClick: true,
  title: null,
  onClose: noop,
  onMount: noop,
}

Modal.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
  onMount: PropTypes.func,
}

const PROPS = [
  'isOpen',
  'shouldCloseOnEsc',
  'shouldCloseOnOverlayClick',
  'title',
  'onClose',
  'onMount',
]

Modal.pickProps = props => pick(props, PROPS)

export default Modal
