import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import arrowDownGlyph from 'iconoir/icons/arrow-down.svg'
import arrowUpGlyph from 'iconoir/icons/arrow-up.svg'

import Styles from 'Components/Styles'

export const Container = styled.div`
  position: relative;
`

export const InfoWrapper = styled.div`
  position: absolute;
  ${Styles.flexCenter};
  flex-direction: column;
  ${Styles.absoluteFullSize()};
  pointer-events: none;
`

export const ArrowUpIcon = styled(arrowUpGlyph)`
  stroke-width: 2px;
`
export const ArrowDownIcon = styled(arrowDownGlyph)`
  stroke-width: 2px;
`

const negativeValueCss = ({ negative }) =>
  negative &&
  css`
    color: ${themeGet('colors.dangerLight')};
  `
const positiveValueCss = ({ positive }) =>
  positive &&
  css`
    color: ${themeGet('colors.primary')};
  `

export const Value = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;

  ${negativeValueCss};
  ${positiveValueCss};
`
