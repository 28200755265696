import { Observable } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import forEach from 'lodash/forEach'
import get from 'lodash/get'

import { refresh } from 'Services/Api/Queries/auth'
import { signOut } from 'Services/SignOut'
import { getAccessToken, getRefreshToken, setAuth } from 'Services/Store/auth'

import { notifyLinks } from '../notifyLinks'

let isFetchingToken = false
let subscribers = []

const subscribeTokenRefresh = cb => {
  subscribers.push(cb)
}
const onTokenRefreshed = err => {
  subscribers.map(cb => cb(err))
}

/* eslint-disable no-console, consistent-return */
const errorLink = history =>
  onError(({ response, graphQLErrors, networkError, operation, forward }) => {
    if (networkError) console.error(`[Network error]: ${networkError}`)

    if (graphQLErrors) {
      forEach(graphQLErrors, ({ message, location, path }) => {
        if (message === 'PersistedQueryNotFound') return

        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`,
        )
      })
    }

    if (operation.operationName === 'Me' && response) {
      response.errors = null
    }

    const isUnauthorizedError =
      get(networkError, 'statusCode') === 401 ||
      get(graphQLErrors, [0, 'extensions', 'code']) === 401

    if (isUnauthorizedError) {
      const refreshToken = getRefreshToken()

      if (refreshToken) {
        return new Observable(async observer => {
          try {
            const retryRequest = () => {
              operation.setContext(({ headers = {} }) => {
                const accessToken = getAccessToken()

                return {
                  headers: {
                    ...headers,
                    Authorization: accessToken ? `Bearer ${accessToken}` : null,
                  },
                }
              })

              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              }

              return forward(operation).subscribe(subscriber)
            }

            if (!isFetchingToken) {
              isFetchingToken = true

              try {
                const result = await refresh({ refreshToken })

                if (get(result, 'ok')) {
                  setAuth({
                    accessToken: get(result, ['refreshed', 'accessToken']),
                  })
                } else {
                  throw new Error('Refresh token expired')
                }

                isFetchingToken = false
                onTokenRefreshed(null)
                subscribers = []

                notifyLinks()

                return retryRequest()
              } catch (e) {
                onTokenRefreshed(new Error('Unable to refresh access token'))

                subscribers = []
                isFetchingToken = false

                await signOut(history)
                await notifyLinks()
              }
            }

            return new Promise(resolve => {
              subscribeTokenRefresh(errRefreshing => {
                if (!errRefreshing) return resolve(retryRequest())
              })
            })
          } catch (e) {
            observer.error(e)
          }
        })
      }

      signOut(history)
      notifyLinks()

      return forward(operation)
    }
  })

export default errorLink
