import { useCallback } from 'react'

import i18n from 'i18n-js'

import get from 'lodash/get'

import { LANGUAGE } from 'Constants/ids'
import { I18N_STATE } from 'Constants/store'

import mainEn from './translations/en.json'
import mainFr from './translations/fr.json'
import mainKo from './translations/ko.json'

export const validLanguages = [LANGUAGE.EN, LANGUAGE.FR, LANGUAGE.KO]

const translationsSet = {
  [LANGUAGE.EN]: mainEn,
  [LANGUAGE.FR]: mainFr,
  [LANGUAGE.KO]: mainKo,
}

export function initTranslations({ store }) {
  const i18nState = store.get()[I18N_STATE]

  let lang

  const storedLand = get(i18nState, 'locale')

  if (storedLand) {
    lang = storedLand
  } else {
    lang = window.navigator.language || window.navigator.userLanguage
    lang = lang ? lang.substring(0, 2) : ''
  }

  if (!validLanguages.includes(lang)) {
    lang = LANGUAGE.EN
  }

  i18n.locale = lang

  i18n.translations = translationsSet
  i18n.missingTranslation = key => `![${key}]`

  return i18n.locale
}

function _(key, options) {
  return i18n.t(key, options)
}

export function useScopedI18n(key) {
  return useCallback((subKey, opts) => _(`${key}.${subKey}`, opts), [key])
}

export function LocaleRenderer({ children }) {
  return children
}

export default _
