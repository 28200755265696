import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import arrowDownGlyph from 'iconoir/icons/arrow-down.svg'
import arrowUpGlyph from 'iconoir/icons/arrow-up.svg'
import cancelGlyph from 'iconoir/icons/cancel.svg'
import checkGlyph from 'iconoir/icons/check.svg'
import editPencilGlyph from 'iconoir/icons/edit-pencil.svg'

import { Button } from 'Components/UI'

import { gap } from 'Theme/system'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.bg.main')};
  padding: 20px;
  border-radius: 12px;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${themeGet('colors.divider.light')};

  ${margin};
`

const negativeBalanceValueCss = ({ negative }) =>
  negative &&
  css`
    color: ${themeGet('colors.dangerLight')};
  `
const positiveBalanceValueCss = ({ positive }) =>
  positive &&
  css`
    color: ${themeGet('colors.primary')};
  `

export const BalanceValue = styled.div`
  font-size: ${themeGet('fontSizes.2')}px;
  font-style: normal;
  font-weight: ${themeGet('fontWeights.2')};
  line-height: 20px;
  display: flex;
  align-items: center;

  ${negativeBalanceValueCss};
  ${positiveBalanceValueCss};
`

export const Controls = styled.div`
  display: flex;

  ${margin};
  ${gap};
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;

  ${margin}
`

export const ArrowUpIcon = styled(arrowUpGlyph)`
  stroke-width: 2px;
`

export const ArrowDownIcon = styled(arrowDownGlyph)`
  stroke-width: 2px;
`

export const EditPencilIcon = styled(editPencilGlyph)`
  color: ${themeGet('colors.primary50')};
`

export const ButtonNoBorder = styled(Button)`
  border: unset;
`

export const CheckIcon = styled(checkGlyph)`
  color: ${themeGet('colors.primary50')};
  cursor: pointer;
`

export const CancelIcon = styled(cancelGlyph)`
  cursor: pointer;
`

export const RebalanceStatus = styled.div`
  padding: 2px 4px;
  border-radius: 4px;
  background: ${themeGet('colors.primary50')};

  ${margin}
`
