function acronym(str) {
  return str?.match(/\b(\w)/g)?.join('')
}

function decode(str) {
  return str.replace(/&#(\d+);/g, function replace(match, dec) {
    return String.fromCharCode(dec)
  })
}

function trimMiddle(str, symbolsLeft = 8, minLength = 20) {
  if (str.length < minLength) return str

  return `${str.substr(0, symbolsLeft)}...${str.substr(
    str.length - symbolsLeft,
    str.length,
  )}`
}

export default { acronym, decode, trimMiddle }
