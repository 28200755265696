import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import Styles from 'Components/Styles'

export const StyledTable = styled.div`
  width: 100%;
`

const cellInfoCss = ({ info }) =>
  info &&
  css`
    width: 100%;
    padding: 8px 0;
  `
const cellRightCss = ({ right }) =>
  right &&
  css`
    justify-content: flex-end;
  `

const cellCenterCss = ({ center }) =>
  center &&
  css`
    justify-content: center;
  `

const headerContentOffsetCss = ({ contentOffset }) =>
  contentOffset &&
  css`
    margin-bottom: ${contentOffset}px;
  `

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 6px;

  ${headerContentOffsetCss}
`

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  ${Styles.text()};
  font-weight: 700;
  user-select: none;

  ${cellRightCss}
`

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 0;

  ${layout.height}
`

export const RowCell = styled.div`
  display: flex;
  align-items: center;

  ${cellInfoCss}
  ${cellRightCss}
  ${cellCenterCss}
`

const bodyRowGapCss = ({ rowGap }) =>
  rowGap &&
  css`
    > ${TableRow}:not(:first-child) {
      margin-top: ${rowGap}px;
    }
  `

export const Body = styled.div`
  ${bodyRowGapCss}
`

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${space}
`

export const NavButtons = styled.div`
  display: flex;
`

export const NavButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  height: 24px;
  width: 32px;

  border: 1px solid ${themeGet('colors.border.primary')};
  outline: none;
  background: ${themeGet('colors.white')};

  :disabled {
    cursor: initial;
  }

  ${space}
`

export const PageSize = styled.select`
  height: 24px;
  width: 140px;

  border-radius: 4px;
  border: 1px solid ${themeGet('colors.border.primary')};
  outline: none;
  background: ${themeGet('colors.white')};
`
