import { useMemo } from 'react'

import { DateTime } from 'luxon'

export function useColumns() {
  return useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        width: 1,
      },
      {
        Header: 'Kind',
        accessor: 'kind',
        width: 1,
      },
      {
        Header: 'Side',
        accessor: 'side',
        width: 1,
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
        width: 2,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        width: 2,
      },
      {
        Header: 'Price',
        accessor: 'price',
        width: 2,
      },
      {
        Header: 'Cost',
        accessor: 'cost',
        width: 2,
        Cell: ({ value }) => `$${value}`,
      },
      {
        Header: 'Fee',
        accessor: 'fee',
        width: 2,
      },
      {
        Header: 'System Fee',
        accessor: 'systemFee',
        width: 2,
        Cell: ({ value, row }) =>
          `$${(value * row?.values?.price)?.toFixed(4)}`,
      },
      {
        Header: 'Final Amount',
        accessor: 'finalAmount',
        width: 2,
      },
      {
        Header: 'User name',
        accessor: 'user',
        Cell: ({ value }) =>
          `${value.profile?.firstName} ${value.profile?.lastName}`,
        width: 2,
      },
      {
        Header: 'Created',
        Cell: ({ value }) =>
          value
            ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
            : '—',
        accessor: 'createdAt',
        width: 2,
      },
      {
        Header: 'Updated',
        Cell: ({ value }) =>
          value
            ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
            : '—',
        accessor: 'updatedAt',
        width: 2,
      },
    ],
    [],
  )
}
