import React from 'react'
import PropTypes from 'prop-types'

import { Column, Row } from 'Components/UI'

function WalletsCell({ value }) {
  return (
    <Column>
      {value?.map(wallet => (
        <Row key={wallet.id}>{wallet.address}</Row>
      ))}
    </Column>
  )
}

WalletsCell.propTypes = {
  value: PropTypes.object.isRequired,
}

export default WalletsCell
