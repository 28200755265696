import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { Container } from './styles'

function OAuth({ route }) {
  return <Container>{renderRoutes(route.routes)}</Container>
}

OAuth.propTypes = {
  route: PropTypes.object.isRequired,
}

export default OAuth
