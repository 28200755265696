import { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export default css`
  .toast-container {
    top: ${themeGet('app.header.height')}px;

    .toast {
      border-radius: 4px;
      font-family: ProximaNova, sans-serif !important;
      background: white;
      color: ${themeGet('colors.font.primary')};
      padding-left: 16px;

      .Toastify__progress-bar {
        height: 4px;
        background: ${themeGet('colors.primary')};
      }

      &.Toastify__toast--error {
        border-color: #fa8a68;
        background-color: #ffe7e2;

        .Toastify__progress-bar {
          background: #fa8a68;
        }
      }

      &.Toastify__toast--success {
        border-color: #84c788;
        background-color: #e8f4e8;

        .Toastify__progress-bar {
          background: #84c788;
        }
      }

      &.Toastify__toast--warning {
        border-color: #fcb757;
        background-color: #fff2dc;

        .Toastify__progress-bar {
          background: #fcb757;
        }
      }

      &.Toastify__toast--info {
        background: white;
        border-color: ${themeGet('colors.primary')};
        color: ${themeGet('colors.primary')};

        .Toastify__progress-bar {
          background: ${themeGet('colors.primary')};
        }
      }

      &.Toastify__toast--default {
        border-color: ${themeGet('colors.primary')};
        color: ${themeGet('colors.primary')};

        .Toastify__progress-bar {
          background: ${themeGet('colors.primary')};
        }
      }
    }
  }
`
