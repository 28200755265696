import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFlexLayout, usePagination, useTable } from 'react-table'

import { useLazyQuery } from '@apollo/client'

import get from 'lodash/get'
import map from 'lodash/map'

import { UserKYCModal } from 'Components/Blocks/Admin/Modals'
import { Row, Select } from 'Components/UI'
import { Pagination, Table } from 'Components/UI/Admin'
import { InputLabels } from 'Components/UI/Forms'

import { STATE } from 'Constants/kyc'

import adminUserKYCListQuery from 'GraphQL/Queries/Admin/adminUserKYCList.graphql'

import { useColumns } from './columns'
import TableContext from './context'
import { Container, Content } from './styles'

const ALL = 'all'

function valueToOption(value) {
  return {
    label: value,
    value,
  }
}

function buildOptions(values) {
  return map([ALL, ...Object.values(values)], valueToOption)
}

const STATE_OPTIONS = buildOptions(STATE)

function UserKYCTable() {
  const [approveModal, setApproveModal] = useState({
    isOpen: false,
    entityId: null,
  })

  const [state, setState] = useState(valueToOption(STATE.REQUESTED))

  const [loadData, { data, loading }] = useLazyQuery(adminUserKYCListQuery)

  const items = get(data, 'adminUserKYCList') || {}

  const tableData = useMemo(() => get(items, 'rows') || [], [items])

  const columns = useColumns()

  const tableProps = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: get(items, 'pages') || 0,
    },
    usePagination,
    useFlexLayout,
  )

  const {
    state: { pageIndex, pageSize },
  } = tableProps

  const fetchData = useCallback(() => {
    const variables = { page: pageIndex, limit: pageSize }

    if (state.value !== ALL) {
      variables.state = [state.value]
    }

    loadData({ variables })
  }, [loadData, state, pageIndex, pageSize])

  useEffect(
    () => {
      tableProps.gotoPage(0)
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  )

  useEffect(
    () => {
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, pageSize],
  )

  const handleOpenApproveModal = useCallback(entity => {
    setApproveModal({ isOpen: true, entity })
  }, [])

  const handleCloseApproveModal = useCallback(() => {
    setApproveModal({ isOpen: false, entity: null })
  }, [])

  return (
    <TableContext.Provider value={{ onApprove: handleOpenApproveModal }}>
      <Container>
        <Content>
          <Row borderBottom mb={2} pb={3}>
            <InputLabels title="State" width={160}>
              <Select
                options={STATE_OPTIONS}
                small
                value={state}
                onChange={setState}
              />
            </InputLabels>
          </Row>

          <Table {...tableProps} loading={loading} />

          <Row borderTop mt={2} pt={3}>
            <Pagination {...tableProps} total={get(items, ['count']) || 0} />
          </Row>

          <UserKYCModal
            isOpen={approveModal.isOpen}
            kyc={approveModal.entity}
            onClose={handleCloseApproveModal}
          />
        </Content>
      </Container>
    </TableContext.Provider>
  )
}

export default UserKYCTable
