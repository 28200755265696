import Envs from 'Services/Envs'

export const APP_ENV = Envs.get('ENV')

export const IS_PRODUCTION = APP_ENV === 'production'
export const IS_STAGING = APP_ENV === 'staging'
export const IS_DEVELOPMENT = APP_ENV === 'development'

export const appearance = {
  theme: 'main',
}

const apiProtocol = Envs.get('API_SSL') === 'true' ? 'https' : 'http'
const socketProtocol = Envs.get('API_SSL') === 'true' ? 'wss' : 'ws'

export const APP_NAME = Envs.get('NAME')

export const FRONTEND_URL = Envs.get('FRONTEND_URL')

const API_URL = `${apiProtocol}://${Envs.get('API_URL')}`

export const API = {
  URL: API_URL,
  WS: `${socketProtocol}://${Envs.get('API_URL')}`,
  PROXY: `${API_URL}/proxy/`,
}

export const SENTRY = {
  DSN: Envs.get('SENTRY_DSN'),
}

export const BINANCE = {
  WALLET: Envs.get('BINANCE_WALLET'),
}

export const TD_AMERITRADE = {
  CONSUMER_KEY: Envs.get('TD_AMERITRADE_CONSUMER_KEY'),
}

export const IG = {
  API_KEY: Envs.get('IG_API_KEY'),
}

export const CALENDLY = {
  URL: Envs.get('CALENDLY_URL'),
}

export const VEZGO = {
  CLIENT_ID: Envs.get('VEZGO_CLIENT_ID'),
}

export const SUPPORT_WIDGET = {
  PROJECT_ID: Envs.get('SUPPORT_WIDGET_PROJECT_ID'),
}
