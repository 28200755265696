import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import { useMutation } from '@apollo/client'

import noop from 'lodash/noop'

import { Column, Select, Text } from 'Components/UI'

import { RISK_TOLERANCE } from 'Constants/ids'

import updateRiskAssessmentMutation from 'GraphQL/Mutations/User/updateRiskAssessment.graphql'

import _ from 'Services/I18n'

import Utils from 'Utils'

function RiskTolerance({ field, value, onChange, ...rest }) {
  const [currentValue, setCurrentValue] = useState(null)

  const [updateRiskAssessment] = useMutation(updateRiskAssessmentMutation)

  const option = useCallback(
    priority => ({
      label: _(Utils.Data.riskToleranceText(priority)),
      value: priority,
    }),
    [],
  )

  const options = useMemo(
    () => [
      option(RISK_TOLERANCE.CONSERVATIVE),
      option(RISK_TOLERANCE.MODERATE),
      option(RISK_TOLERANCE.AGGRESSIVE),
    ],
    [option],
  )

  useEffect(() => {
    setCurrentValue(option(value || RISK_TOLERANCE.CONSERVATIVE))
  }, [value, option])

  const handleChange = useCallback(
    async selectedOption => {
      setCurrentValue(selectedOption)

      await updateRiskAssessment({
        variables: {
          riskTolerance: selectedOption?.value,
        },
      })

      toast.success(_('profile.toasts.success'))
    },
    [updateRiskAssessment],
  )

  return (
    <Column {...pick(rest)} width="100%">
      <Text>{_('profile.riskAssessment.title')}</Text>
      <Select
        mt={1}
        options={options}
        value={currentValue}
        onChange={handleChange}
      />
    </Column>
  )
}

RiskTolerance.defaultProps = {
  onChange: noop,
}

RiskTolerance.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default RiskTolerance
