import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import { OnboardingConnectAccount } from 'Components/Blocks/Modals'
import ProgressBar from 'Components/Blocks/Onboarding/ProgressBar'
import { Button, Column, Loader, Row, Text } from 'Components/UI'
import { EnhancedRadioButtonField } from 'Components/UI/Forms'

import updateOnboardingStepsMutation from 'GraphQL/Mutations/User/updateOnboardingSteps.graphql'

import { APP_ROOT, ONBOARDING_RISK_ASSESSMENT } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import {
  ArrowRightIcon,
  DescriptionLabel,
  FieldDescription,
  FormContent,
  IconContainer,
  PositionIcon,
  StarIcon,
} from './styles'

const FIELDS = {
  PLAN: 'plan',
}

const MANAGE_VALUES = {
  INVEST: 'invest',
  MANAGE_ONLY: 'manageOnly',
}

const formConstraints = {
  [FIELDS.PLAN]: {
    presence: true,
  },
}

function Plan() {
  const s = useScopedI18n('onboarding.plan')
  const history = useHistory()

  const [isLoading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const [updateOnboardingSteps] = useMutation(updateOnboardingStepsMutation)

  const handleInvestClick = useCallback(async () => {
    setLoading(true)

    await updateOnboardingSteps({
      variables: {
        plan: true,
      },
    })
    history.push(ONBOARDING_RISK_ASSESSMENT)
  }, [history, updateOnboardingSteps])

  const submit = useCallback(
    async values => {
      const selected = values[FIELDS.PLAN]

      if (selected === MANAGE_VALUES.INVEST) {
        handleInvestClick()
      } else {
        setOpenModal(true)
      }
    },
    [handleInvestClick],
  )

  const handleSkipOnboarding = useCallback(
    async confirmation => {
      if (!confirmation) {
        return
      }

      await updateOnboardingSteps({
        variables: {
          plan: true,
          riskAssessment: true,
          portfolio: true,
          kyc: true,
        },
      })

      history.push(APP_ROOT)
    },
    [updateOnboardingSteps, history],
  )

  const renderForm = useCallback(
    ({ handleSubmit, values }) => {
      return (
        <Column>
          <Row justifyCenter>
            <Text fontWeight={2} heading2>
              {s('title')}
            </Text>
          </Row>

          <FormContent mt={6}>
            <Column width={1}>
              <EnhancedRadioButtonField
                id={MANAGE_VALUES.INVEST}
                name={FIELDS.PLAN}
                value={MANAGE_VALUES.INVEST}
                width={1}
              >
                <IconContainer>
                  <StarIcon height={21} viewBox="0 0 24 24" width={21} />
                </IconContainer>

                <Text fontWeight={2} heading4 ml={3}>
                  {s('form.invest.title')}
                </Text>
              </EnhancedRadioButtonField>
              <FieldDescription>
                <Text small>{s('form.invest.subtitle')}</Text>
                <Row gap="10px" ml="auto">
                  <DescriptionLabel>{s('form.mostPopular')}</DescriptionLabel>
                  <DescriptionLabel>{s('form.freemium')}</DescriptionLabel>
                </Row>
              </FieldDescription>
            </Column>

            <Column mt={4} width={1}>
              <EnhancedRadioButtonField
                id={MANAGE_VALUES.MANAGE_ONLY}
                name={FIELDS.PLAN}
                value={MANAGE_VALUES.MANAGE_ONLY}
                width={1}
              >
                <IconContainer>
                  <PositionIcon height={21} viewBox="0 0 24 24" width={21} />
                </IconContainer>
                <Text fontWeight={2} heading4 ml={3}>
                  {s('form.manageOnly.title')}
                </Text>
              </EnhancedRadioButtonField>
              <FieldDescription>
                <Text small>{s('form.manageOnly.subtitle')}</Text>
                <Row gap="10px" ml="auto">
                  <DescriptionLabel>{s('form.freemium')}</DescriptionLabel>
                </Row>
              </FieldDescription>
            </Column>

            <Row justifyEnd mt={5}>
              <Button disabled={!values[FIELDS.PLAN]} onClick={handleSubmit}>
                {s('form.actions.continue')}
                <ArrowRightIcon height={16} viewBox="0 0 24 24" width={16} />
                {isLoading && <Loader ml={1} />}
              </Button>
            </Row>
          </FormContent>
        </Column>
      )
    },
    [isLoading, s],
  )

  return (
    <Column width={1}>
      <Row gap="20px">
        <ProgressBar mt="72px" />
        <Form
          initialValues={{
            [FIELDS.PLAN]: null,
          }}
          render={renderForm}
          validate={values => validate(values, formConstraints)}
          onSubmit={submit}
        />
      </Row>

      <OnboardingConnectAccount
        isOpen={openModal}
        onAction={handleInvestClick}
        onClose={() => setOpenModal(false)}
        onSkip={handleSkipOnboarding}
      />
    </Column>
  )
}

export default Plan
