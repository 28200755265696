import React from 'react'
import { NavLink } from 'react-router-dom'

import { ROOT } from 'Router/routes'

export default function NotFound() {
  return (
    <div>
      Not found <NavLink to={ROOT}>Go to root</NavLink>
    </div>
  )
}
