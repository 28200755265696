/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import numeral from 'numeral'

import get from 'lodash/get'
import values from 'lodash/values'

import CurrencyIcon from 'Components/Blocks/CurrencyIcon'
import { Column, Row, Table, Text } from 'Components/UI'

import { STATE } from 'Constants/portfolios'

import { ArrowDownIcon, ArrowUpIcon } from 'Pages/Portfolio/Root/styles'

import _ from 'Services/I18n'

import { theme } from 'Theme'

import { BalanceValue, Content } from './styles'

function arrow(value) {
  if (value > 0) {
    return (
      <ArrowUpIcon
        color={theme.colors.primary}
        height={14}
        viewBox="0 0 24 24"
        width={14}
      />
    )
  }
  if (value < 0) {
    return (
      <ArrowDownIcon
        color={theme.colors.dangerLight}
        height={14}
        viewBox="0 0 24 24"
        width={14}
      />
    )
  }

  return null
}

function AssetsTable({ portfolio, currency }) {
  const assets = useMemo(() => {
    return values(get(portfolio, 'assets'))
  }, [portfolio])

  const isFilled = portfolio?.state === STATE.FILLED

  const columns = useMemo(() => {
    return [
      {
        Header: () => (
          <Text
            color={theme.colors.font.secondary}
            extraSmall
            fontWeight={0}
            px={3}
          >
            {_('portfolio.assets.table.name')}
          </Text>
        ),

        Cell: ({ row }) => {
          const base = get(row, ['original', 'base'])
          return (
            <Row center flexShrink={1} px={3}>
              <CurrencyIcon currency={base} />
              <Text fontWeight={1} ml={2}>
                {get(row, ['original', 'baseName'])}
              </Text>
              <Text color={theme.colors.text70} fontWeight={1} ml={1}>
                {base}
              </Text>
            </Row>
          )
        },
        accessor: 'baseName',
        width: 90,
      },
      {
        Header: (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {_('portfolio.assets.table.holding')}
          </Text>
        ),

        Cell: ({ row }) => {
          const base = get(row, ['original', 'base'])
          const cost = get(row, ['original', 'cost'], 0)
          const amount = get(row, ['original', 'amount'], 0)

          return (
            <Column alignEnd>
              <Text fontWeight={1} heading6>
                {currency}
                {numeral(cost?.toFixed(2)).format('0,0.[00]')}
              </Text>
              <Text extraSmall>
                {amount ? amount.toFixed(8) : 0} {base}
              </Text>
            </Column>
          )
        },
        accessor: 'cost',
        width: 70,
        cellRight: true,
        headerRight: true,
      },
      {
        Header: (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {_('portfolio.assets.table.gain')}
          </Text>
        ),

        Cell: ({ row }) => {
          const currentCost = get(row, ['original', 'cost'])
          const initialPrice = get(row, ['original', 'initialPrice'])
          const amount = get(row, ['original', 'amount'])

          const initialAssetCost = initialPrice * amount
          const gainAmount = isFilled ? currentCost - initialAssetCost : 0
          const gainPercentage = isFilled
            ? (gainAmount / initialAssetCost) * 100
            : 0

          return (
            <Column alignEnd>
              <BalanceValue
                negative={gainPercentage < 0}
                positive={gainPercentage > 0}
              >
                {numeral(gainAmount).format(`${currency}0,0.00`)}
              </BalanceValue>
              <BalanceValue
                negative={gainPercentage < 0}
                positive={gainPercentage > 0}
                small
              >
                {arrow(gainAmount)} {numeral(gainPercentage).format('0.00')}%
              </BalanceValue>
            </Column>
          )
        },
        accessor: 'gain',
        width: 70,
        cellRight: true,
        headerRight: true,
      },
      {
        Header: (
          <Text color={theme.colors.font.secondary} extraSmall fontWeight={0}>
            {_('portfolio.assets.table.allocation')}
          </Text>
        ),

        Cell: ({ value }) => (
          <Text heading6>{numeral(value).format('0.00')}%</Text>
        ),
        accessor: 'percentage',
        width: 72,
        headerCenter: true,
        cellCenter: true,
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, portfolio, isFilled])

  return (
    <Content>
      <Table
        columns={columns}
        data={assets}
        noData={
          <Text color={theme.colors.text70} fontWeight={1} heading6 p={4}>
            {_('general.noData')}
          </Text>
        }
        rowHeight={44}
        sortable
      />
    </Content>
  )
}

AssetsTable.defaultProps = {
  currency: '$',
}

AssetsTable.propTypes = {
  currency: PropTypes.string,
  portfolio: PropTypes.object.isRequired,
}

export default AssetsTable
