import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { Button, Column, Row, Text } from 'Components/UI'
import { EnhancedRadioButtonField } from 'Components/UI/Forms'

import { useScopedI18n } from 'Services/I18n'

import { FIELD, WITHDRAWAL_KIND } from './constants'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BankIcon,
  CryptoIcon,
  DescriptionLabel,
  FormContainer,
  IconContainer,
} from './styles'

function FormFields({ handleSubmit, valid, form }) {
  const history = useHistory()
  const s = useScopedI18n('withdrawal.form')

  const handleBack = useCallback(
    event => {
      event.preventDefault()
      history.goBack()
    },
    [history],
  )

  const { getState } = form

  const state = getState()
  const { values } = state

  return (
    <FormContainer gap={4}>
      <Column>
        <EnhancedRadioButtonField
          id="crypto"
          name={FIELD.KIND}
          value={WITHDRAWAL_KIND.CRYPTO}
          width={1}
        >
          <Row center>
            <IconContainer>
              <CryptoIcon height={21} width={21} />
            </IconContainer>
            <Column gap={1} ml={3}>
              <Text fontWeight={2} heading4>
                {s('cryptoTitle')}
              </Text>
              <Text maxWidth="320px" small>
                {s('cryptoSubTitle')}
              </Text>
            </Column>
          </Row>

          <DescriptionLabel ml={6}>{s('crypto')}</DescriptionLabel>
        </EnhancedRadioButtonField>
      </Column>

      <Column>
        <EnhancedRadioButtonField
          id="bank"
          name={FIELD.KIND}
          value={WITHDRAWAL_KIND.BANK}
          width={1}
        >
          <Row center>
            <IconContainer>
              <BankIcon />
            </IconContainer>
            <Column gap={1} ml={3}>
              <Text fontWeight={2} heading4>
                {s('bankTitle')}
              </Text>
              <Text maxWidth="320px" small>
                {s('bankSubTitle')}
              </Text>
            </Column>
          </Row>

          <DescriptionLabel ml={6}>{s('comingSoon')}</DescriptionLabel>
        </EnhancedRadioButtonField>
      </Column>

      <Row fullWidth spaceBetween>
        <Button gap={3} outline onClick={handleBack}>
          <ArrowLeftIcon height={14} viewBox="0 0 24 24" width={14} />
          {s('back')}
        </Button>
        <Button
          disabled={!valid || values[FIELD.KIND] === WITHDRAWAL_KIND.BANK}
          gap={3}
          onClick={handleSubmit}
        >
          {s('action')}
          <ArrowRightIcon height={14} viewBox="0 0 24 24" width={14} />
        </Button>
      </Row>
    </FormContainer>
  )
}

FormFields.propTypes = {
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
}

export default FormFields
