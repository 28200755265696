import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { PORTFOLIO_TIPS_DEFAULTS } from 'Constants/defaults'
import { PORTFOLIO_TIPS_ACTIONS, PORTFOLIO_TIPS_STATE } from 'Constants/store'

export default function portfolioTipsState(store) {
  store.on('@init', () => ({
    [PORTFOLIO_TIPS_STATE]: PORTFOLIO_TIPS_DEFAULTS,
  }))

  store.on(PORTFOLIO_TIPS_ACTIONS.SET, (state, variables) => {
    const values = pick(variables, Object.keys(PORTFOLIO_TIPS_DEFAULTS))

    const nextState = { ...state[PORTFOLIO_TIPS_DEFAULTS], ...values }

    if (isEqual(nextState, state[PORTFOLIO_TIPS_STATE])) return null

    return { [PORTFOLIO_TIPS_STATE]: nextState }
  })
}
