import { useEffect } from 'react'

import invariant from 'tiny-invariant'

const INSTANCES = new Set()

function useSingleInstance(id) {
  useEffect(() => {
    invariant(
      !INSTANCES.has(id),
      `Only one component with id "${id}" is allowed`,
    )

    INSTANCES.add(id)

    return () => {
      INSTANCES.delete(id)
    }
  }, [id])
}

export default useSingleInstance
