import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import Tippy from '@tippyjs/react/headless'
import { DateTime } from 'luxon'

import noop from 'lodash/noop'

import { DateRangePicker, Row, Select } from 'Components/UI'
import { InputLabels } from 'Components/UI/Forms'

import { DATE_RANGE } from 'Constants/transactions'

import { useScopedI18n } from 'Services/I18n'

const DATE_FORMAT = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

function DateRangeSelect({ option, onChange }) {
  const s = useScopedI18n('transactions.table.filters.dateRange')

  const [showTippy, setShowTippy] = useState(false)

  const valueToOption = useCallback(
    value => ({
      label: s(`values.${value}`),
      value,
    }),
    [s],
  )
  const options = useMemo(
    () => [
      valueToOption(DATE_RANGE.PAST_7DAYS),
      valueToOption(DATE_RANGE.PAST_30DAYS),
      valueToOption(DATE_RANGE.PAST_90DAYS),
      {
        ...(!option.dates ? valueToOption(DATE_RANGE.CUSTOMIZED) : option),
        display: 'calendar',
      },
    ],
    [option, valueToOption],
  )

  const handleFinish = useCallback(
    pickedDates => {
      const customizedDateRange = `${DateTime.fromISO(
        pickedDates.from,
      ).toLocaleString(DATE_FORMAT)} - ${DateTime.fromISO(
        pickedDates.to,
      ).toLocaleString(DATE_FORMAT)}`

      onChange({
        label: customizedDateRange,
        value: customizedDateRange,
        dates: pickedDates,
        display: 'calendar',
      })

      setShowTippy(false)
    },
    [onChange],
  )

  const handleChangeOption = useCallback(
    value => {
      if (value.display === 'calendar') {
        setShowTippy(true)
        return
      }

      if (value.display !== 'calendar' && showTippy) {
        setShowTippy(false)
      }
      onChange(value)
    },
    [onChange, showTippy],
  )

  const handleClickOutside = useCallback(() => {
    setShowTippy(false)
  }, [])

  return (
    <Tippy
      interactive
      render={() =>
        (showTippy || option.dates) && (
          <DateRangePicker onFinish={handleFinish} />
        )
      }
      visible={showTippy}
      onClickOutside={handleClickOutside}
    >
      <Row>
        <InputLabels title={s('label')} width="216px">
          <Select
            options={options}
            value={option}
            onChange={handleChangeOption}
          />
        </InputLabels>
      </Row>
    </Tippy>
  )
}

DateRangeSelect.defaultProps = {
  option: null,
  onChange: noop,
}

DateRangeSelect.propTypes = {
  option: PropTypes.object,
  onChange: PropTypes.func,
}

export default DateRangeSelect
