import React, { useCallback, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Column, Loader, Modal, Text } from 'Components/UI'

import createTOTPFactorMutation from 'GraphQL/Mutations/2FA/createTOTPFactor.graphql'
import enableTOTPVerificationMutation from 'GraphQL/Mutations/2FA/enableTOTPVerification.graphql'

import _, { useScopedI18n } from 'Services/I18n'

import { FIELD } from './constants'
import FormFields from './FormFields'
import { Content } from './styles'
import Success from './Success'

const FORM_CONSTRAINTS = {
  [FIELD.CODE]: {
    presence: true,
  },
}

function Add({ isOpen, onFinish, ...rest }) {
  const s = useScopedI18n('modal.2fa.enable')

  const [totpFactor, setTOTPFactor] = useState(null)
  const [isShowSuccess, setShowSuccess] = useState(false)

  const [createTOTPFactor, { loading }] = useMutation(createTOTPFactorMutation)
  const [
    enableTOTPVerification,
    { loading: enableTOTPVerificationLoading },
  ] = useMutation(enableTOTPVerificationMutation)

  useEffect(() => {
    async function loadTOTP() {
      try {
        const result = await createTOTPFactor()
        setTOTPFactor(result?.data?.createTOTPFactor)
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
      }
    }

    loadTOTP().then()
  }, [createTOTPFactor])

  const handleSubmit = useCallback(
    async values => {
      try {
        await enableTOTPVerification({
          variables: {
            code: values[FIELD.CODE],
            factorId: totpFactor.factorId,
          },
        })
        setShowSuccess(true)
      } catch (error) {
        toast.error(get(error, 'message') || _('error.generic'))
      }
    },
    [enableTOTPVerification, totpFactor],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      isOpen={isOpen}
      title={isShowSuccess ? null : s('title')}
    >
      <Content>
        {loading || !totpFactor ? (
          <Column center fullWidth justifyCenter mt={5}>
            <Loader />
            <Text mt={3}>{_('general.pleaseWait')}</Text>
          </Column>
        ) : (
          <>
            {isShowSuccess ? (
              <Success onClick={() => onFinish(true)} />
            ) : (
              <Form
                render={props => (
                  <FormFields
                    {...props}
                    loading={enableTOTPVerificationLoading}
                    totpURI={totpFactor?.uri}
                  />
                )}
                validate={values => validate(values, FORM_CONSTRAINTS)}
                onSubmit={handleSubmit}
              />
            )}
          </>
        )}
      </Content>
    </Modal>
  )
}

Add.defaultProps = {
  ...Modal.defaultProps,
  onFinish: noop,
}

Add.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  onMount: PropTypes.func,
}

export default Add
