import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { Divider, Modal, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import Utils from 'Utils'

import RiskAssessment from './RiskAssessment'
import { Content } from './styles'

function ProfileInfoModal({ user, ...rest }) {
  const close = useRef(null)

  const s = useScopedI18n('admin.modal.profileInfo')

  const handleMount = useCallback(instance => {
    close.current = instance?.handleClose
  }, [])

  return (
    <Modal {...Modal.pickProps(rest)} title={s('title')} onMount={handleMount}>
      <Content mt={4}>
        <Text fontWeight={3} medium>
          {Utils.UserProfiles.fullName(user?.profile)}
        </Text>
        <Divider my={2} />
        <RiskAssessment user={user} />
      </Content>
    </Modal>
  )
}

ProfileInfoModal.defaultProps = {
  user: {},
}

ProfileInfoModal.propTypes = {
  user: PropTypes.shape({ profile: PropTypes.object }),
}

export default ProfileInfoModal
