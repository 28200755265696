import React, { useCallback, useMemo } from 'react'

import debounce from 'lodash/debounce'

import Select from 'Components/UI/Select'

import adminPortfolioGroupsQuery from 'GraphQL/Queries/Admin/adminPortfolioGroups.graphql'

import shared from 'Services/Shared'

function PortfolioGroupSelect({ ...rest }) {
  const loadOptions = useCallback(async (inputValue, callback) => {
    const result = await shared.getClient().query({
      query: adminPortfolioGroupsQuery,
      fetchPolicy: 'network-only',
      variables: {
        search: inputValue,
        page: 0,
        limit: 100,
      },
    })

    return callback(
      result?.data?.adminPortfolioGroups?.rows?.map(item => ({
        label: item.name,
        value: item.id,
      })),
    )
  }, [])

  const debouncedLoadOptions = useMemo(() => {
    return debounce(loadOptions, 300)
  }, [loadOptions])

  return (
    <Select
      async
      defaultOptions
      isMulti
      isSearchable
      loadOptions={debouncedLoadOptions}
      {...rest}
    />
  )
}

export default PortfolioGroupSelect
