import React from 'react'
import QRCode from 'react-qr-code'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import { Button, Column, Loader, Row, Text } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms'

import { useScopedI18n } from 'Services/I18n'

import { FIELD } from './constants'
import { FormContainer } from './styles'

function FormFields({ handleSubmit, totpURI, loading }) {
  const theme = useTheme()

  const s = useScopedI18n('modal.2fa.enable')

  return (
    <FormContainer gap={5} mt={5}>
      <Column>
        <Text color={theme.colors.text70} extraSmall>
          {s('step1')}
        </Text>
        <Text fontWeight={1} heading6>
          {s('step1Text')}
        </Text>
      </Column>

      <Column center fullWidth>
        <QRCode size={135} value={totpURI} />
      </Column>

      <Column>
        <Text color={theme.colors.text70} extraSmall>
          {s('step2')}
        </Text>
        <Text fontWeight={1} heading6>
          {s('step2Text')}
        </Text>
      </Column>

      <Column>
        <InputLabels mt={4} title={s('digitCode')}>
          <InputField name={FIELD.CODE} />
        </InputLabels>
      </Column>

      <Row fullWidth mt={5} spaceBetween>
        <Button disabled={loading} gap={3} width="100%" onClick={handleSubmit}>
          {s('action')} {loading && <Loader />}
        </Button>
      </Row>
    </FormContainer>
  )
}

FormFields.defaultProps = {
  loading: false,
  totpURI: null,
}

FormFields.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  totpURI: PropTypes.string,
}

export default FormFields
