import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, Loader, Modal, Row, Select } from 'Components/UI'
import { InputField, InputLabels } from 'Components/UI/Forms'

import { CRYPTO_TOKEN_KIND } from 'Constants/cryptoTokens'

import adminAddCryptoTokenMutation from 'GraphQL/Mutations/Admin/adminAddCryptoToken.graphql'
import adminUpdateCryptoTokenMutation from 'GraphQL/Mutations/Admin/adminUpdateCryptoToken.graphql'

import _ from 'Services/I18n'

import { Content } from './styles'

const FIELDS = {
  TICKER: 'ticker',
  KIND: 'kind',
}

const CRYPTO_TOKEN_OPTIONS = [
  { label: 'coin', value: CRYPTO_TOKEN_KIND.COIN },
  {
    label: 'stable coin',
    value: CRYPTO_TOKEN_KIND.STABLE_COIN,
  },
  {
    label: 'alt coin',
    value: CRYPTO_TOKEN_KIND.ALT_COIN,
  },
]

function AddEditCryptoToken({ cryptoToken, onRefetch, ...rest }) {
  const close = useRef(null)

  const [loading, setLoading] = useState(false)

  const [aminAddCryptoToken] = useMutation(adminAddCryptoTokenMutation)
  const [adminUpdateCryptoToken] = useMutation(adminUpdateCryptoTokenMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELDS.TICKER]: {
        presence: true,
      },
      [FIELDS.KIND]: {
        presence: true,
      },
    }),
    [],
  )

  const isEdit = !!cryptoToken

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      const query = isEdit ? adminUpdateCryptoToken : aminAddCryptoToken

      const variables = {
        ticker: values[FIELDS.TICKER],
        kind: values[FIELDS.KIND].value,
      }

      try {
        await query({
          variables,
        })

        toast.success('Success')

        setLoading(false)

        if (!isEdit) {
          onRefetch()
        }

        close.current()
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    },
    [adminUpdateCryptoToken, aminAddCryptoToken, onRefetch, isEdit],
  )

  const initialValues = useMemo(() => {
    return {
      [FIELDS.TICKER]: cryptoToken?.ticker,
      [FIELDS.KIND]: CRYPTO_TOKEN_OPTIONS.find(
        item => item.value === cryptoToken?.kind,
      ),
    }
  }, [cryptoToken])

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title="Crypto token"
      onMount={handleMount}
    >
      <Form
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <InputLabels mt={3} title="Ticker">
                <InputField
                  input={{
                    placeholder: 'Enter ticker ex: BTC',
                    disabled: isEdit,
                  }}
                  name={FIELDS.TICKER}
                />
              </InputLabels>

              <InputLabels mt={3} title="Kind">
                <Field
                  defaultValue={CRYPTO_TOKEN_OPTIONS[0]}
                  name={FIELDS.KIND}
                >
                  {({ input: { value, onChange: handleChange } }) => (
                    <Select
                      options={CRYPTO_TOKEN_OPTIONS}
                      small
                      value={value}
                      withPortal
                      onChange={handleChange}
                    />
                  )}
                </Field>
              </InputLabels>
            </Content>

            <Row borderTop center height={56} justifyCenter>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button
                    secondary
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {_('general.cancel')}
                  </Button>
                  <Button ml={3} width={130} onClick={handleSubmit}>
                    {_('general.save')}
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

AddEditCryptoToken.defaultProps = {
  cryptoToken: null,
  onRefetch: noop,
}

AddEditCryptoToken.propTypes = {
  cryptoToken: PropTypes.object,
  onRefetch: PropTypes.func,
}

export default AddEditCryptoToken
