import { DateTime } from 'luxon'

function exampleData() {
  return [
    {
      date: DateTime.utc().minus({ month: 2 }).toSeconds(),
      value: 8000,
      change: 0,
    },
    {
      date: DateTime.utc().minus({ month: 1 }).toSeconds(),
      value: 22000,
      change: 175,
    },
    {
      date: DateTime.utc().toSeconds(),
      value: 12000,
      change: -45.45,
    },
    {
      date: DateTime.utc().plus({ month: 1 }).toSeconds(),
      value: 232000,
      change: 1833.33,
    },
    {
      date: DateTime.utc().plus({ month: 2 }).toSeconds(),
      value: 122000,
      change: -47.41,
    },
  ]
}

export { exampleData }
