import React from 'react'
import PropTypes from 'prop-types'

import { omit, pick } from '@styled-system/props'

import { Background, Icon } from './styles'

import { Container, Text, TextContent } from '../styles'

function CheckboxInput({ checked, error, label, meta, ...rest }) {
  return (
    <Container {...pick(rest)} width="100%">
      {(label || error) && (
        <TextContent>
          {label && <Text>{label}</Text>}
          {error && <Text error>{error}</Text>}
        </TextContent>
      )}
      <input checked={checked} type="checkbox" {...omit(rest)} />
      <Background />
      <Icon />
    </Container>
  )
}

CheckboxInput.defaultProps = {
  checked: null,
  error: null,
  label: null,
  meta: {},
}

CheckboxInput.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  meta: PropTypes.object,
}

export default CheckboxInput
