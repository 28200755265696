import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import get from 'lodash/get'

import { Button, Column, Flex, Link, Loader, Row } from 'Components/UI'
import { CheckboxField, InputField, InputLabels } from 'Components/UI/Forms'

import signInByEmailMutation from 'GraphQL/Mutations/Auth/signInByEmail.graphql'

import { APP_ROOT, AUTH_RESET_PASSWORD, AUTH_SIGN_UP } from 'Router/routes'

import _ from 'Services/I18n'
import { setAuth } from 'Services/Store/auth'

import {
  Card,
  Container,
  Content,
  EyeIcon,
  EyeOffIcon,
  ForgotPasswordLink,
  FormContent,
  FormTitle,
  IconButton,
  Info,
  Lines,
  LogoExtendedIcon,
  MailIcon,
  Slogan,
  Text,
} from './styles'

const FIELDS = {
  EMAIL: 'email',
  PASSWORD: 'password',
  REMEMBER_ME: 'rememberMe',
}

function Root() {
  const history = useHistory()

  const [signIn] = useMutation(signInByEmailMutation)

  const [loading, setLoading] = useState(false)
  const [isShowPassword, setShowPassword] = useState(false)

  const handleToggleShowPassword = useCallback(() => {
    setShowPassword(prevState => !prevState)
  }, [])

  const submit = useCallback(
    async values => {
      try {
        setLoading(true)

        const result = await signIn({
          variables: {
            email: get(values, FIELDS.EMAIL),
            password: get(values, FIELDS.PASSWORD),
            withRefresh: get(values, FIELDS.REMEMBER_ME),
          },
        })

        setAuth(get(result, ['data', 'signInByEmail']))
        history.push(APP_ROOT)
      } catch (error) {
        // TODO: implement helper function to map graphql errors to error descriptions
        toast.error(get(error, 'message') || _('error.generic'))
        setLoading(false)
      }
    },
    [history, signIn],
  )

  const renderForm = useCallback(
    ({ handleSubmit }) => (
      <FormContent>
        <FormTitle>{_('auth.signIn.title')}</FormTitle>

        <InputLabels mt={5} title={`${_('auth.shared.email')}*`}>
          <InputField
            input={{
              placeholder: _('auth.shared.emailPlaceholder'),
              renderBefore: (
                <MailIcon height={16} viewBox="0 0 24 24" width={16} />
              ),
            }}
            name={FIELDS.EMAIL}
          />
        </InputLabels>
        <InputLabels mt={4} title={`${_('auth.shared.password')}*`}>
          <InputField
            input={{
              placeholder: _('auth.shared.passwordPlaceholder'),
              type: isShowPassword ? 'text' : 'password',
              renderAfter: isShowPassword ? (
                <IconButton onClick={handleToggleShowPassword}>
                  <EyeOffIcon height={16} viewBox="0 0 24 24" width={16} />
                </IconButton>
              ) : (
                <IconButton onClick={handleToggleShowPassword}>
                  <EyeIcon height={16} viewBox="0 0 24 24" width={16} />
                </IconButton>
              ),
            }}
            name={FIELDS.PASSWORD}
          />
        </InputLabels>

        <Row center mt={3} spaceBetween width={1}>
          <CheckboxField
            label={_('auth.shared.rememberMe')}
            name={FIELDS.REMEMBER_ME}
          />
          <Row>
            <ForgotPasswordLink to={AUTH_RESET_PASSWORD}>
              {_('auth.signIn.forgotPassword')}
            </ForgotPasswordLink>
          </Row>
        </Row>

        <Button disabled={loading} mt={5} onClick={handleSubmit}>
          {_('auth.signIn.action')}
          {loading && <Loader ml={1} />}
        </Button>
      </FormContent>
    ),
    [handleToggleShowPassword, isShowPassword, loading],
  )

  const formConstraints = useMemo(
    () => ({
      [FIELDS.EMAIL]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.emailRequired')}`,
        },
        email: {
          email: true,
          message: `^${_('auth.shared.emailInvalid')}`,
        },
      },
      [FIELDS.PASSWORD]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.passwordRequired')}`,
        },
      },
    }),
    [],
  )

  return (
    <Container>
      <Info>
        <Row center>
          <LogoExtendedIcon />
        </Row>

        <Row mt="auto">
          <Slogan>{_('general.slogan')}</Slogan>
        </Row>
        <Lines />
      </Info>

      <Content>
        <Flex
          alignItems="center"
          flexDirection="column"
          height="100%"
          width={1}
        >
          <Row justifyEnd width={1}>
            <Text mr={2}>New user?</Text>
            <Link fontWeight={600} to={AUTH_SIGN_UP}>
              Create an account
            </Link>
          </Row>

          <Column height="100%" justifyCenter>
            <Card>
              <Form
                render={renderForm}
                validate={values => validate(values, formConstraints)}
                onSubmit={submit}
              />
            </Card>
          </Column>
        </Flex>
      </Content>
    </Container>
  )
}

export default Root
