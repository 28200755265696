export const KIND = {
  initial: 'initial',
  rebalance: 'rebalance',
}

export const SIDE = {
  Buy: 'buy',
  Sell: 'sell',
}

export const STATUS = {
  Open: 'Open',
  Closed: 'Closed',
  Rejected: 'Rejected',
  Canceled: 'Canceled',
}
