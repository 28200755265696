import palette from 'Theme/Main/palette'

export default {
  height: { default: 20 },

  width: { default: 36 },

  handleSize: { default: 12 },

  handleOffset: { default: 4 },

  borderRadius: { default: 16 },

  buttonColor: {
    default: palette.white,
  },

  unselected: {
    backgroundColor: {
      default: palette.text30,
    },
    hover: {
      backgroundColor: {
        default: palette.text100,
      },
    },
    focus: {
      backgroundColor: {
        default: palette.primary50,
      },
      boxShadow: {
        default: palette.aquaMist,
      },
    },

    disabled: {
      backgroundColor: {
        default: palette.gray10,
      },
    },
  },

  selected: {
    backgroundColor: {
      default: palette.primary50,
    },
    hover: {
      backgroundColor: {
        default: palette.primary60,
      },
    },
    focus: {
      backgroundColor: {
        default: palette.primary50,
      },
      boxShadow: {
        default: palette.aquaMist,
      },
    },
  },

  disabled: {
    backgroundColor: {
      default: palette.primary30,
    },
  },
}
