import React from 'react'
import PropTypes from 'prop-types'

import { noop } from 'lodash'

import { Button, Column, Loader, Row, Text } from 'Components/UI'
import { EnhancedRadioButtonField } from 'Components/UI/Forms'

import { useScopedI18n } from 'Services/I18n'

import {
  BankGlyphIcon,
  BuyCryptoGlyphIcon,
  DescriptionLabel,
  FormContent,
  IconContainer,
} from './styles'

import { FIELDS, VALUES } from '../constants'
import { ArrowLeftIcon, ArrowRightIcon } from '../styles'

function DepositFields({ values, loading, onBack, onSubmit }) {
  const s = useScopedI18n('blocks.deposit')

  return (
    <>
      <Column center>
        <Text fontWeight={2} heading2>
          {s('title')}
        </Text>

        <Text color="text70" fontWeight={2} heading5 mt={4}>
          {s('subtitle')}
        </Text>
      </Column>

      <FormContent mt={6}>
        <Column width={1}>
          <EnhancedRadioButtonField
            id="cryptoDeposit"
            name={FIELDS.DEPOSIT}
            value={VALUES.CRYPTO_DEPOSIT}
            width={1}
          >
            <Row center>
              <IconContainer>
                <BuyCryptoGlyphIcon
                  height={21}
                  viewBox="0 0 24 24"
                  width={21}
                />
              </IconContainer>
              <Column gap={1} ml={3}>
                <Text large>{s('cryptoDeposit.title')}</Text>
                <Text maxWidth="336px" small>
                  {s('cryptoDeposit.info')}
                </Text>
              </Column>
            </Row>

            <DescriptionLabel ml={6}>{s('crypto')}</DescriptionLabel>
          </EnhancedRadioButtonField>
        </Column>

        <Column mt={4} width={1}>
          <EnhancedRadioButtonField
            id="bankDeposit"
            name={FIELDS.DEPOSIT}
            value={VALUES.BANK_DEPOSIT}
            width={1}
          >
            <Row center>
              <IconContainer>
                <BankGlyphIcon height={21} viewBox="0 0 24 24" width={21} />
              </IconContainer>
              <Column gap={1} ml={3}>
                <Text large>{s('bankDeposit.title')}</Text>
                <Text maxWidth="336px" small>
                  {s('bankDeposit.info')}
                </Text>
              </Column>
            </Row>

            <DescriptionLabel ml={6}>{s('fiat')}</DescriptionLabel>
          </EnhancedRadioButtonField>
        </Column>

        <Row mt={5} spaceBetween>
          <Button outline onClick={onBack}>
            <ArrowLeftIcon height={14} viewBox="0 0 24 24" width={14} />
            {s('actions.back')}
          </Button>

          <Button
            disabled={!values[FIELDS.DEPOSIT] || loading}
            onClick={onSubmit}
          >
            {s('actions.continue')}
            <ArrowRightIcon height={16} viewBox="0 0 24 24" width={14} />
            {loading && <Loader ml={1} />}
          </Button>
        </Row>
      </FormContent>
    </>
  )
}

DepositFields.defaultProps = {
  values: null,
  loading: false,
  onBack: noop,
  onSubmit: noop,
}

DepositFields.propTypes = {
  loading: PropTypes.bool,
  values: PropTypes.object,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default DepositFields
