import React, { useLayoutEffect } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import CreatePortfolioSelectionComponent from 'Components/Blocks/CreatePortfolioSelection'
import { Column } from 'Components/UI'

import { STATE as KYC_STATE } from 'Constants/kyc'

import { useAppContext } from 'Hooks'

import { APP_ROOT } from 'Router/routes'

import _ from 'Services/I18n'

function CreatePortfolioSelection() {
  const history = useHistory()
  const { me } = useAppContext()

  const isKYCApproved = me?.kyc?.state === KYC_STATE.APPROVED

  useLayoutEffect(() => {
    if (isKYCApproved) return

    history.push(APP_ROOT)
    toast.warning(_('general.kycVerifiedWarning'))
  }, [isKYCApproved, history])

  return (
    <Column center flexGrow={1} fullWidth justifyCenter>
      <CreatePortfolioSelectionComponent />
    </Column>
  )
}

export default CreatePortfolioSelection
