import { DateTime } from 'luxon'

import { SIDE, STATUS } from 'Constants/orders'
import { DATE_RANGE, KIND } from 'Constants/transactions'

import _ from 'Services/I18n'

import Utils from 'Utils'

const WALLET_NAME = 'Upwealth wallet'
const ALL = 'all'

const ORDER_SIDE_TEXT = {
  [SIDE.Buy]: 'data.transactions.orderSide.buy',
  [SIDE.Sell]: 'data.transactions.orderSide.sell',
}

const ORDER_STATUS_TEXT = {
  [STATUS.Open]: 'data.transactions.orderStatus.open',
  [STATUS.Closed]: 'data.transactions.orderStatus.closed',
  [STATUS.Rejected]: 'data.transactions.orderStatus.rejected',
  [STATUS.Canceled]: 'data.transactions.orderStatus.canceled',
}

const KIND_TEXT = {
  [KIND.DEPOSIT]: 'data.transactions.kind.deposit',
  [KIND.WITHDRAW]: 'data.transactions.kind.withdraw',
  [KIND.BUY_ORDER_FILLED]: 'data.transactions.kind.buyOrderFilled',
  [KIND.SELL_ORDER_FILLED]: 'data.transactions.kind.sellOrderFilled',
  [KIND.REBALANCE_BUY_ORDER_FILLED]:
    'data.transactions.kind.rebalanceBuyOrderFilled',
  [KIND.REBALANCE_SELL_ORDER_FILLED]:
    'data.transactions.kind.rebalanceSellOrderFilled',
  [KIND.FUND_PORTFOLIO]: 'data.transactions.kind.fundPortfolio',
  [KIND.CLOSE_PORTFOLIO]: 'data.transactions.kind.closePortfolio',
}

function orderSideText(transaction) {
  return (
    ORDER_SIDE_TEXT[transaction?.order?.side] ||
    'data.transactions.orderSide.unknown'
  )
}

function orderStatusText(transaction) {
  return (
    ORDER_STATUS_TEXT[transaction?.order?.status] ||
    'data.transactions.orderStatus.unknown'
  )
}

function kindText(transaction) {
  return KIND_TEXT[transaction?.kind] || 'data.transactions.kind.unknown'
}

function getTransactionStatus(transaction) {
  if (
    transaction.kind === KIND.FUND_PORTFOLIO ||
    transaction.kind === KIND.CLOSE_PORTFOLIO
  ) {
    return _(Utils.UserPortfolios.stateText(transaction.portfolio))
  }

  if (transaction.kind === KIND.DEPOSIT || transaction.kind === KIND.WITHDRAW) {
    return _(Utils.UserTransactions.stateText(transaction.userTransaction))
  }

  return _(orderStatusText(transaction))
}

export function processTransactionsData({ data }) {
  if (!data?.transactions) return []

  return data.transactions.rows.map(transaction => ({
    date: transaction.createdAt,
    kind: _(kindText(transaction)),
    depositWallet: WALLET_NAME,
    withdrawWallet: WALLET_NAME,
    portfolioName: transaction?.portfolio?.name || null,
    portfolioId: transaction?.portfolio?.id || null,
    pair: transaction?.order?.symbol || null,
    side: _(orderSideText(transaction)),
    price: transaction?.order?.price || null,
    executed: transaction?.order?.amount || null,
    fee: `${transaction.fee} ${transaction.currency}`,
    total: transaction?.order?.total || null,
    asset: transaction.currency,
    amount: transaction.amount,
    destination: transaction?.userTransaction?.address,
    txid: transaction?.id,
    status: getTransactionStatus(transaction),
  }))
}

export function getDateRangeData(dateRange) {
  if (dateRange.dates) {
    return { from: dateRange.dates.from, to: dateRange.dates.to }
  }

  const currentDate = DateTime.now()
  if (dateRange.value === DATE_RANGE.PAST_7DAYS) {
    return {
      from: currentDate.minus({ days: 7 }).toISO(),
      to: currentDate.toISO(),
    }
  }

  if (dateRange.value === DATE_RANGE.PAST_30DAYS) {
    return {
      from: currentDate.minus({ days: 30 }).toISO(),
      to: currentDate.toISO(),
    }
  }

  if (dateRange.value === DATE_RANGE.PAST_90DAYS) {
    return {
      from: currentDate.minus({ days: 30 }).toISO(),
      to: currentDate.toISO(),
    }
  }

  return null
}

export function getStatusData({ kind, status }) {
  if (status === ALL) {
    return null
  }

  switch (kind) {
    case KIND.FUND_PORTFOLIO:
    case KIND.CLOSE_PORTFOLIO: {
      return {
        portfolioState: status,
      }
    }

    case KIND.DEPOSIT:
    case KIND.WITHDRAW: {
      return {
        userTransactionState: status,
      }
    }

    case KIND.BUY_ORDER_FILLED:
    case KIND.SELL_ORDER_FILLED:
    case KIND.REBALANCE_BUY_ORDER_FILLED:
    case KIND.REBALANCE_SELL_ORDER_FILLED: {
      return {
        orderStatus: status,
      }
    }

    default:
      return null
  }
}
