import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { pick } from '@styled-system/props'

import Add2FACoordinator from 'Coordinators/Add2FA'

import LanguageDropdown from 'Components/Blocks/LanguageDropdown'
import { Button, Popover, Row, Text } from 'Components/UI'

import { STATE as KYC_STATE } from 'Constants/kyc'

import { useAppContext } from 'Hooks'

import { DEPOSIT, KYC, WITHDRAWAL } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import { Divider } from './styles'

function DepositAndWithdraw({ ...rest }) {
  const s = useScopedI18n('blocks.depositAndWithdraw')

  const history = useHistory()
  const { me } = useAppContext()

  const isKYCApproved = me?.kyc?.state === KYC_STATE.APPROVED

  const handleDeposit = useCallback(() => {
    if (!isKYCApproved) {
      history.push(KYC)
      return
    }

    if (!me?.verificationTOTPEnabled) {
      Add2FACoordinator.start(success => {
        if (success) history.push(DEPOSIT)
      })
      return
    }

    history.push(DEPOSIT)
  }, [me, isKYCApproved, history])

  const handleWithdraw = useCallback(() => {
    if (!isKYCApproved) {
      history.push(KYC)
      return
    }

    history.push(WITHDRAWAL)
  }, [isKYCApproved, history])

  return (
    <Row center gap={6} {...pick(rest)}>
      <Row gap={4}>
        <Popover
          content={isKYCApproved ? null : s('depositRestricted')}
          offset={[10, 10]}
          showArrow
        >
          <Button
            restricted={!isKYCApproved}
            width="110px"
            xSmall
            onClick={handleDeposit}
          >
            {s('deposit')}
          </Button>
        </Popover>

        <Popover
          content={isKYCApproved ? null : s('withdrawRestricted')}
          offset={[10, 10]}
          showArrow
        >
          <Button
            outline
            restricted={!isKYCApproved}
            width="110px"
            xSmall
            onClick={handleWithdraw}
          >
            {s('withdraw')}
          </Button>
        </Popover>
      </Row>

      <Row center gap={3}>
        <Text fontWeight={1} heading6>
          USD
        </Text>
        <Divider />
        <LanguageDropdown showTitle={false} width="200px" />
      </Row>
    </Row>
  )
}

export default DepositAndWithdraw
