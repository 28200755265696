import React, { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { useMutation } from '@apollo/client'

import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'

import { Column, Row, Text } from 'Components/UI'

import { PRIMARY_CURRENCY } from 'Constants/currencies'
import { REBALANCE_TYPE } from 'Constants/portfolios'
import { MIN_ASSET_VALUE } from 'Constants/values'

import addPortfolioMutation from 'GraphQL/Mutations/Portfolios/addPortfolio.graphql'
import backtestQuery from 'GraphQL/Queries/Backtesting/backtest.graphql'
import Portfolios from 'GraphQL/Updaters/Portfolios'

import { PORTFOLIO } from 'Router/routes'

import _, { useScopedI18n } from 'Services/I18n'
import shared from 'Services/Shared'

import Utils from 'Utils'

import Build from './Build'
import { STEPS } from './constants'
import { Wrapper } from './styles'

function Create() {
  const s = useScopedI18n('portfolio.create')

  const history = useHistory()

  const infoFormInstance = useRef(null)
  const assetsFormInstance = useRef(null)
  const rebalanceFormInstance = useRef(null)

  const [loading, setLoading] = useState(false)
  const [isBacktestingLoading, setBacktestingLoading] = useState(false)
  const [step, setStep] = useState(STEPS.INFO)
  const [amount, setAmount] = useState(0)
  const [portfolioAssets, setPortfolioAssets] = useState(null)
  const [backtestResult, setBacktestResult] = useState(null)

  const [addPortfolio] = useMutation(addPortfolioMutation)

  const handleInfoMount = useCallback(instance => {
    infoFormInstance.current = instance
  }, [])

  const handleAssetsMount = useCallback(instance => {
    assetsFormInstance.current = instance
  }, [])

  const handleRebalanceMount = useCallback(instance => {
    rebalanceFormInstance.current = instance
  }, [])

  const handleInfoChange = useCallback(values => {
    setAmount(get(values, ['values', 'amount']) || 0)
  }, [])

  const handleBack = useCallback(() => {
    setStep(STEPS.INFO)
  }, [])

  const handleSubmitBacktest = useCallback(async () => {
    const infoForm = infoFormInstance.current
    const assetsForm = assetsFormInstance.current
    const rebalanceForm = rebalanceFormInstance.current

    if (!assetsForm || !rebalanceForm) {
      return
    }

    const infoFormState = infoForm.getState()
    const rebalanceFormState = rebalanceForm.getState()

    // -- Assets
    if (isEmpty(portfolioAssets)) {
      toast.error(_('backtesting.errors.noAssets'))
      return
    }

    const queryAssets = []
    let totalPercentage = 0
    forEach(portfolioAssets, asset => {
      const percentage = toNumber(asset.percentage) || 0

      queryAssets.push({
        asset: asset.base,
        percentage,
      })
      totalPercentage += percentage
    })

    if (!Utils.Numbers.isEqual(totalPercentage, 100)) {
      toast.error(_('backtesting.errors.incorrectAllocation'))
      return
    }

    // -- Query
    const variables = {
      value: toNumber(infoFormState.values?.amount),
      currency: PRIMARY_CURRENCY,
      assets: queryAssets,
    }

    const rebalanceValue = rebalanceFormState.values?.rebalanceValue
    if (rebalanceValue !== undefined) {
      const rebalanceType = rebalanceFormState.values?.rebalanceType?.value
      if (rebalanceType === REBALANCE_TYPE.THRESHOLD) {
        variables.rebalancePercentage = toNumber(rebalanceValue)
      }
      if (rebalanceType === REBALANCE_TYPE.PERIOD) {
        variables.rebalanceDays = toNumber(rebalanceValue)
      }
    }

    try {
      setBacktestingLoading(true)
      const result = await shared.getClient().query({
        query: backtestQuery,
        variables,
      })

      setBacktestResult(result?.data?.backtest)
    } catch (error) {
      toast.error(error?.message || _('error.generic'))
    } finally {
      setBacktestingLoading(false)
    }
  }, [portfolioAssets])

  const handleContinue = useCallback(() => {
    const infoForm = get(infoFormInstance, ['current'])
    infoForm.submit()
    const infoFormState = infoForm.getState()

    if (!infoFormState.valid) {
      return
    }

    setStep(STEPS.BUILD)
  }, [])

  const handleCreatePortfolio = useCallback(async () => {
    const infoForm = infoFormInstance.current
    const assetsForm = assetsFormInstance.current
    const rebalanceForm = rebalanceFormInstance.current

    const rebalanceFormState = rebalanceForm.getState()

    const infoFormState = infoForm.getState()
    if (!infoFormState.valid) {
      return
    }

    const assetsFormState = assetsForm.getState()
    if (isEmpty(assetsFormState.values)) {
      toast.error(_('modal.createPortfolio.noAssets'))
      return
    }

    const portfolioAmount = toNumber(get(infoFormState.values, 'amount')) || 0
    let percentageSum = 0
    let minAssetValueError = false
    forEach(assetsFormState.values, asset => {
      percentageSum += asset.percentage

      if (!minAssetValueError) {
        minAssetValueError =
          asset.percentage * portfolioAmount * 0.01 < MIN_ASSET_VALUE
      }
    })

    if (!Utils.Numbers.isEqual(percentageSum, 100)) {
      toast.error(_('modal.createPortfolio.incorrectAllocation'))
      return
    }

    if (minAssetValueError) {
      toast.error(_('error.minAssetValue', { value: MIN_ASSET_VALUE }))
      return
    }

    try {
      const variables = {
        name: get(infoFormState.values, 'name'),
        amount: portfolioAmount,
        currency: PRIMARY_CURRENCY,
        assets: map(assetsFormState.values, asset => {
          return {
            asset: get(asset, 'base'),
            percentage: toNumber(get(asset, 'percentage')) || 0,
          }
        }),
      }

      const rebalanceValue = get(rebalanceFormState.values, 'rebalanceValue')
      if (!isNil(rebalanceValue)) {
        variables.rebalanceType = get(rebalanceFormState.values, [
          'rebalanceType',
          'value',
        ])
        variables.rebalanceValue = toNumber(rebalanceValue)
      }

      setLoading(true)

      await addPortfolio({
        variables,
        update: Portfolios.add,
      })

      setLoading(false)

      await history.push(PORTFOLIO)
    } catch (error) {
      toast.error(get(error, 'message') || _('error.generic'))
      setLoading(false)
    }
  }, [addPortfolio, history])

  return (
    <section>
      <Row justifyCenter mt={6} width={1}>
        <Text as="h2" fontWeight={2} heading2>
          {s('title')}
        </Text>
      </Row>

      <Wrapper mt="33px">
        <Column>
          <Build
            amount={amount}
            backtestResult={backtestResult}
            isBacktestingLoading={isBacktestingLoading}
            isCreateLoading={loading}
            step={step}
            onAssetsChange={setPortfolioAssets}
            onAssetsMount={handleAssetsMount}
            onBack={handleBack}
            onContinue={handleContinue}
            onCreatePortfolio={handleCreatePortfolio}
            onHandleChangeInfo={handleInfoChange}
            onMountHandleInfo={handleInfoMount}
            onRebalanceMount={handleRebalanceMount}
            onSubmitBacktest={handleSubmitBacktest}
          />
        </Column>
      </Wrapper>
    </section>
  )
}

export default Create
