import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'
import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import { Button, Row, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { CancelIcon, ChatIcon, IconWrapper, Wrapper } from './styles'

function Tip({ content, onClose, ...rest }) {
  const theme = useTheme()
  const s = useScopedI18n('blocks.portfolio.tip')

  return (
    <Wrapper {...pick(rest)}>
      <Row center flex={1}>
        <IconWrapper>
          <ChatIcon height={14} viewBox="0 0 24 24" width={14} />
        </IconWrapper>

        <Text fontSize={1} ml={3}>
          <Text color={theme.colors.primary50} fontSize={1} fontWeight={1}>
            {s('title')}
          </Text>
          : {content}
        </Text>
      </Row>

      <Row>
        <Button icon outline secondary onClick={onClose}>
          <CancelIcon height={14} viewBox="0 0 24 24" width={14} />
        </Button>
      </Row>
    </Wrapper>
  )
}

Tip.defaultProps = {
  content: null,
  onClose: noop,
}

Tip.propTypes = {
  content: PropTypes.node,
  onClose: PropTypes.func,
}

export default Tip
