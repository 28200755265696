import React from 'react'

import { useStoreon } from 'storeon/react'

import Balance from 'Components/Blocks/Balance'
import DepositAndWithdraw from 'Components/Blocks/DepositAndWithdraw/DepositAndWithdraw'
import { NetWorth, NetWorthChart } from 'Components/Blocks/Widgets'
import { Row, Text } from 'Components/UI'

import { DASHBOARD_STATE } from 'Constants/store'

import { useAppContext } from 'Hooks'

import _ from 'Services/I18n'

import Actions from './Actions'
import { Board, Main } from './styles'

function Root() {
  const { me } = useAppContext()
  const { dashboard } = useStoreon(DASHBOARD_STATE)

  return (
    <Board>
      <Row fullWidth mt="20px" spaceBetween>
        <Text as="h3" fontWeight={2} heading3>
          {_('general.welcome')} {me?.profile?.firstName}!
        </Text>

        <DepositAndWithdraw />
      </Row>

      {dashboard.showActions && <Actions mt="20px" />}

      <Balance mt="20px" />

      <Main>
        <NetWorth mt={2} />
        <NetWorthChart mt="22px" />
      </Main>

      {/* <Info ml={2}> */}
      {/*  <PersonalAdvisor noShrink /> */}
      {/*  /!* <Goals mt={2} noShrink /> *!/ */}
      {/*  <MarketMovers mt={2} noShrink /> */}
      {/*  <News count={5} mt={2} noShrink /> */}
      {/* </Info> */}
    </Board>
  )
}

export default Root
