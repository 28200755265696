import AssetStats from './AssetStats'
import CryptoTokens from './CryptoTokens'
import Orders from './Orders'
import PortfolioTemplateGroups from './PortfolioTemplateGroups'
import PortfolioTemplateLogs from './PortfolioTemplateLogs'
import PortfolioTemplates from './PortfolioTemplates'
import Root from './Root'
import Settings from './Settings'
import Transactions from './Transactions'
import UserKYC from './UserKYC'
import UserPortfolios from './UserPortfolios'
import UserPortfolioTemplateLogs from './UserPortfolioTemplateLogs'
import Users from './Users'
import UserTransactions from './UserTransactions'

export default {
  AssetStats,
  Orders,
  PortfolioTemplates,
  PortfolioTemplateGroups,
  PortfolioTemplateLogs,
  Root,
  Settings,
  Transactions,
  UserKYC,
  UserPortfolios,
  Users,
  UserTransactions,
  UserPortfolioTemplateLogs,
  CryptoTokens,
}
