import numeral from 'numeral'

function formatWithSpaces(value, format = '0,0') {
  return numeral(value).format(format).replace(/,/g, ' ')
}

function isEqual(first, second, epsilon = 1e-3) {
  return Math.abs(first - second) <= epsilon
}

function iterativeAverage(value, count, add) {
  return (value * count + add) / (count + 1)
}

function weightedIterativeAverage(value, weight, add, addWeight) {
  return (value * weight + add * addWeight) / (weight + addWeight)
}

export default {
  formatWithSpaces,
  isEqual,
  iterativeAverage,
  weightedIterativeAverage,
}
