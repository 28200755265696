
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"KYCDataFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"KYCData"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"taxResidence"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isUSCitizen"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isIndustryPresent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"profession"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"netMonthlyIncome"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"from"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"to"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"fundSource"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":154}};
    doc.loc.source = {"body":"fragment KYCDataFields on KYCData {\n  taxResidence\n  isUSCitizen\n  isIndustryPresent\n  profession\n  netMonthlyIncome {\n    from\n    to\n  }\n  fundSource\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
