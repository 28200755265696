import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${layout.width}
  ${margin}
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  min-height: 15px;
  font-size: ${mapToTheme('inputs.label.fontSize')}px;
  font-weight: ${mapToTheme('inputs.label.fontWeight')};
  color: ${mapToTheme('inputs.label.color')};
  line-height: normal;

  ${margin}
`

export const QuillContainer = styled.div`
  display: flex;
  flex-direction: column;
  .quill {
    display: flex;
    flex-direction: column;
    min-height: 200px;
  }
`
