import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import Utils from 'Utils'

import { Acronym, Image, Wrapper } from './styles'

function Avatar({ size, userProfile, ...rest }) {
  const content = useMemo(() => {
    if (userProfile?.photoUrl) {
      return <Image src={userProfile.photoUrl} />
    }

    return (
      <Acronym>
        {Utils.Strings.acronym(Utils.UserProfiles.fullName(userProfile))}
      </Acronym>
    )
  }, [userProfile])

  return (
    <Wrapper {...pick(rest)} size={size}>
      {content}
    </Wrapper>
  )
}

Avatar.defaultProps = {
  size: 36,
  userProfile: {},
}

Avatar.propTypes = {
  size: PropTypes.number,
  userProfile: PropTypes.shape({
    photoUrl: PropTypes.string,
  }),
}

export default Avatar
