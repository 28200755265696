import React, { useCallback, useEffect, useMemo } from 'react'
import { useFlexLayout, usePagination, useTable } from 'react-table'
import PropTypes from 'prop-types'

import { useLazyQuery } from '@apollo/client'

import get from 'lodash/get'

import { Row } from 'Components/UI'
import { Pagination, Table } from 'Components/UI/Admin'

import adminPortfolioTemplateUserLogsQuery from 'GraphQL/Queries/Admin/adminPortfolioTemplateUserLogs.graphql'

import { useColumns } from './columns'
import { Container, Content } from './styles'

function PortfolioTemplateLogsTable({ portfolioTemplateId, userId }) {
  const [loadData, { data, loading }] = useLazyQuery(
    adminPortfolioTemplateUserLogsQuery,
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = data?.adminPortfolioTemplateUserLogs || {}

  const tableData = useMemo(() => get(items, 'rows') || [], [items])

  const columns = useColumns()

  const tableProps = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: get(items, 'pages') || 0,
    },
    usePagination,
    useFlexLayout,
  )

  const {
    state: { pageIndex, pageSize },
  } = tableProps

  const fetchData = useCallback(() => {
    const variables = {
      portfolioTemplateId,
      userId,
      page: pageIndex,
      limit: pageSize,
      sort: [{ column: 'createdAt', order: 'desc' }],
    }

    loadData({ variables, fetchPolicy: 'cache-and-network' })
  }, [loadData, pageIndex, pageSize, portfolioTemplateId, userId])

  useEffect(
    () => {
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, pageSize],
  )

  return (
    <Container>
      <Content>
        <Table {...tableProps} loading={loading} />

        <Row borderTop mt={2} pt={3}>
          <Pagination {...tableProps} total={get(items, ['count']) || 0} />
        </Row>
      </Content>
    </Container>
  )
}

PortfolioTemplateLogsTable.defaultProps = {
  portfolioTemplateId: null,
  userId: null,
}

PortfolioTemplateLogsTable.propTypes = {
  portfolioTemplateId: PropTypes.string,
  userId: PropTypes.string,
}

export default PortfolioTemplateLogsTable
