import { AuthLayout } from 'Components/Layout'

import Auth from 'Pages/Auth'

import * as ROUTES from './routes'

export const AUTH_ROUTES = {
  component: AuthLayout,
  path: ROUTES.AUTH_ROOT,
  routes: [
    {
      component: Auth.ChangePassword,
      path: ROUTES.AUTH_CHANGE_PASSWORD,
      exact: true,
    },
    {
      component: Auth.ResetPassword,
      path: ROUTES.AUTH_RESET_PASSWORD,
      exact: true,
    },
    {
      component: Auth.SignUp,
      path: ROUTES.AUTH_SIGN_UP,
      exact: true,
    },
    {
      component: Auth.VerifyEmail,
      path: ROUTES.AUTH_VERIFY_EMAIL,
      exact: true,
    },
  ],
}
