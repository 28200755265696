import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import numeral from 'numeral'
import { Pie, PieChart, ResponsiveContainer } from 'recharts'

import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import noop from 'lodash/noop'
import size from 'lodash/size'

import CurrencyIcon from 'Components/Blocks/CurrencyIcon'
import { Row, Text } from 'Components/UI'

import _ from 'Services/I18n'

import { theme } from 'Theme'

import {
  ArrowDownIcon,
  ArrowUpIcon,
  Container,
  InfoWrapper,
  Value,
} from './styles'

const EMPTY_DATA_ID = 'assetsPieChartEmptyData'

function formatNumber(number = 0) {
  return numeral(number).format('0,0.00')
}

function arrow(value) {
  if (value > 0) {
    return (
      <ArrowUpIcon
        color={theme.colors.primary}
        height={14}
        viewBox="0 0 24 24"
        width={14}
      />
    )
  }
  if (value < 0) {
    return (
      <ArrowDownIcon
        color={theme.colors.dangerLight}
        height={14}
        viewBox="0 0 24 24"
        width={14}
      />
    )
  }

  return null
}

function AssetsPieChart({
  amount,
  percentageChange,
  initialAmount,
  assetInfo,
  assets,
  colors,
  currency,
  defaultInfo,
  innerRadius,
  radius,
  onAssetEnter,
  onAssetLeave,
}) {
  const [focusedAsset, setFocusedAsset] = useState(null)

  const data = useMemo(() => {
    const result = []
    let totalPercentage = 0

    forEach(assets, asset => {
      const percentage = asset?.weight || 0
      if (percentage === 0) {
        return
      }

      const groupNameColors = {
        Stablecoins: theme.colors.primary70,
        Altcoins: theme.colors.primary60,
      }

      result.push({
        value: percentage,
        id: asset?.base || asset?.currency,
        name: asset?.baseName,
        groupName: asset?.groupName,
        cost: asset?.cost || 0,
        fill:
          groupNameColors[asset?.groupName] ||
          colors[asset?.base || asset?.currency],
      })

      totalPercentage += percentage
    })

    if (totalPercentage < 1) {
      result.push({
        value: 1 - totalPercentage,
        id: EMPTY_DATA_ID,
      })
    }

    return result
  }, [assets, colors])

  const handleMouseEnter = useCallback(
    value => {
      const id = get(value, 'id')
      if (id === EMPTY_DATA_ID) {
        return
      }

      setFocusedAsset(value)
      onAssetEnter(id)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetInfo, onAssetEnter],
  )

  const handleMouseLeave = useCallback(() => {
    setFocusedAsset(null)
    onAssetLeave()
  }, [onAssetLeave])

  const info = useMemo(() => {
    const gainAmount = amount - initialAmount

    if (focusedAsset) {
      if (isFunction(assetInfo)) {
        return assetInfo(focusedAsset)
      }

      return (
        <>
          <CurrencyIcon currency={focusedAsset.id} size={40} />
          <Text bold mt={2}>
            {focusedAsset?.name} ({focusedAsset.id})
          </Text>
          <Text mt="2px" secondary>
            {formatNumber(focusedAsset?.value || 0)}%
          </Text>
          <Text mt="2px">${formatNumber(focusedAsset?.cost || 0)}</Text>
        </>
      )
    }

    if (defaultInfo) {
      return defaultInfo
    }

    return (
      <>
        <Text fontWeight={2} heading5>
          {_('chart.portfolio.assets.netWorth')}
        </Text>
        <Text fontWeight={2} heading3>
          {currency}
          {formatNumber(amount)}
        </Text>

        <Row mt={1}>
          <Value
            negative={percentageChange < 0}
            positive={percentageChange > 0}
          >
            {arrow(gainAmount)} {numeral(percentageChange).format('0.00')}% /{' '}
            {percentageChange > 0 && '+'}
            {numeral(gainAmount).format(`${currency}0,0.00`)}
          </Value>
        </Row>
      </>
    )
  }, [
    amount,
    percentageChange,
    assetInfo,
    currency,
    defaultInfo,
    focusedAsset,
    initialAmount,
  ])

  return (
    <Container>
      <ResponsiveContainer aspect={1} width="100%">
        <PieChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <Pie
            animationBegin={0}
            animationDuration={300}
            cornerRadius={4}
            data={data}
            dataKey="value"
            endAngle={-270}
            innerRadius={innerRadius}
            outerRadius={radius}
            paddingAngle={size(data) > 1 ? 2 : 0}
            startAngle={90}
            stroke="none"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </PieChart>
      </ResponsiveContainer>
      <InfoWrapper>{info}</InfoWrapper>
    </Container>
  )
}

AssetsPieChart.defaultProps = {
  amount: 0,
  assetInfo: null,
  assets: null,
  colors: theme.colors.assets,
  currency: '$',
  defaultInfo: null,
  initialAmount: 0,
  innerRadius: '83%',
  percentageChange: 0,
  radius: '100%',
  onAssetEnter: noop,
  onAssetLeave: noop,
}

AssetsPieChart.propTypes = {
  amount: PropTypes.number,
  assetInfo: PropTypes.func,
  assets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  colors: PropTypes.arrayOf(PropTypes.string),
  currency: PropTypes.string,
  defaultInfo: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  initialAmount: PropTypes.number,
  innerRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  percentageChange: PropTypes.number,
  radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onAssetEnter: PropTypes.func,
  onAssetLeave: PropTypes.func,
}

export default AssetsPieChart
