import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'

import { pick } from '@styled-system/props'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import { InputField, InputLabels } from 'Components/UI/Forms'

import { STATE as KYC_STATE } from 'Constants/kyc'

import updateProfileMutation from 'GraphQL/Mutations/User/updateProfile.graphql'

import { useAppContext } from 'Hooks'

import _, { useScopedI18n } from 'Services/I18n'

import { Container } from './styles'

const FIELDS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email,',
}

const FORM_CONSTRAINTS = {
  [FIELDS.FIRST_NAME]: {
    presence: {
      allowEmpty: false,
    },
  },
}

function ProfileForm({ ...rest }) {
  const [loading, setLoading] = useState(false)

  const [updateProfile] = useMutation(updateProfileMutation)
  const s = useScopedI18n('profile')
  const { me } = useAppContext()

  const renderForm = useCallback(
    ({ handleSubmit }) => {
      return (
        <>
          <InputLabels title={s('form.firstName')}>
            <InputField
              input={{
                disabled: loading || me?.kyc?.state === KYC_STATE.APPROVED,
                placeholder: s('form.firstNamePlaceholder'),
              }}
              name={FIELDS.FIRST_NAME}
              onBlur={handleSubmit}
            />
          </InputLabels>
          <InputLabels mt={3} title={s('form.surname')}>
            <InputField
              input={{
                disabled: loading || me?.kyc?.state === KYC_STATE.APPROVED,
                placeholder: s('form.surnamePlaceholder'),
              }}
              name={FIELDS.LAST_NAME}
              onBlur={handleSubmit}
            />
          </InputLabels>

          <InputLabels mt={3} title={s('form.email')}>
            <InputField
              input={{
                disabled: true,
                placeholder: s('form.emailPlaceholder'),
              }}
              name={FIELDS.EMAIL}
            />
          </InputLabels>
        </>
      )
    },
    [loading, s, me],
  )

  const handleSubmit = useCallback(
    async values => {
      try {
        setLoading(true)

        await updateProfile({
          variables: {
            firstName: values[FIELDS.FIRST_NAME]?.trim() || null,
            lastName: values[FIELDS.LAST_NAME]?.trim() || null,
          },
        })

        toast.success(s('toasts.success'))
      } catch (error) {
        toast.error(error?.message || _('error.generic'))
      } finally {
        setLoading(false)
      }
    },
    [updateProfile, s],
  )

  return (
    <Container {...pick(rest)}>
      <Form
        initialValues={{
          [FIELDS.FIRST_NAME]: me?.profile?.firstName,
          [FIELDS.LAST_NAME]: me?.profile?.lastName,
          [FIELDS.EMAIL]: me?.emailCredentials?.[0]?.email,
        }}
        render={renderForm}
        validate={values => validate(values, FORM_CONSTRAINTS)}
        onSubmit={handleSubmit}
      />
    </Container>
  )
}

export default ProfileForm
