import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { Input as BaseInput } from 'Components/UI/Forms'

const containerBeforeColorCss = ({ color }) =>
  color &&
  css`
    background-color: ${color}4f;
  `

const containerBeforeWidthCss = ({ percentage }) =>
  percentage &&
  css`
    width: calc(${percentage}% - 2px);
  `

const colorCss = ({ color }) =>
  color &&
  css`
    background-color: ${color}2f;
  `

export const Container = styled.div`
  position: relative;

  :before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 3px;
    top: 1px;
    bottom: 1px;
    left: 1px;
    width: 0;
    transition: width ${themeGet('transitionTime.default')};

    ${containerBeforeColorCss}
    ${containerBeforeWidthCss}
  }

  ${margin}
  ${layout.width}
`

export const Input = styled(BaseInput)`
  background-color: transparent;

  > input {
    text-align: center;
  }
`

export const PercentageLine = styled.div`
  position: relative;
  height: 8px;
  border-radius: 100px;

  ${colorCss};

  :before {
    display: block;
    position: absolute;
    content: '';
    top: 1px;
    bottom: 1px;
    left: 1px;
    width: 0;
    transition: width ${themeGet('transitionTime.default')};

    border-radius: 100px;
    background: ${themeGet('colors.primary50')};

    ${containerBeforeColorCss}
    ${containerBeforeWidthCss}
  }

  ${margin}
  ${layout.width}
`

export const AbsoluteContainer = styled.div`
  position: absolute;
  right: 0;
`
