import { useMemo } from 'react'

import { useScopedI18n } from 'Services/I18n'

export function useColumns() {
  const s = useScopedI18n('blocks.adminAssetStats.table')

  return useMemo(
    () => [
      {
        Header: s('rows.asset'),
        accessor: 'asset',
        width: 2,
      },
      {
        Header: s('rows.amount'),
        Cell: ({ row }) => `${row?.original?.amount?.toFixed(4)} `,
        width: 2,
      },
      {
        Header: s('rows.cost'),
        Cell: ({ row }) => `${row?.original?.cost?.toFixed(2)} USDT`,
        width: 2,
      },
    ],
    [s],
  )
}
