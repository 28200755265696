import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'

import noop from 'lodash/noop'

import { Button, Column, Loader, Row, Text } from 'Components/UI'

import { PRIMARY_CURRENCY } from 'Constants/currencies'
import { KIND, PAYMENT_METHOD } from 'Constants/userTransactions'

import addUserTransactionMutation from 'GraphQL/Mutations/UserTransactions/addUserTransaction.graphql'
import UserTransactions from 'GraphQL/Updaters/UserTransactions'

import { PORTFOLIO } from 'Router/routes'

import _, { useScopedI18n } from 'Services/I18n'

import {
  BankDetailsHeader,
  CopyIcon,
  InfoEmptyIcon,
  InstructionsContainer,
} from './styles'

import { BANK_DETAILS } from '../constants'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Card,
  OutlineContainer,
} from '../styles'

const MINIMUM_AMOUNT = 50

function BankDeposit({ onBack }) {
  const history = useHistory()

  const s = useScopedI18n('blocks.deposit.bankDeposit')

  const [addUserTransaction, { loading }] = useMutation(
    addUserTransactionMutation,
  )

  const handleCopyToClipboard = useCallback(async () => {
    if (!navigator.clipboard) return
    try {
      let text = `${s('bankDetails.beneficiary')} ${BANK_DETAILS.BENEFICIARY}\n`
      text += `${s('bankDetails.beneficiaryAddress')} ${
        BANK_DETAILS.BENEFICIARY_ADDRESS
      }\n`
      text += `${s('bankDetails.bankName')} ${BANK_DETAILS.BANK_NAME}\n`
      text += `${s('bankDetails.bankAddress')} ${BANK_DETAILS.BANK_ADDRESS}\n`
      text += `${s('bankDetails.account')} ${BANK_DETAILS.ACCOUNT}\n`
      text += `${s('bankDetails.swift')} ${BANK_DETAILS.SWIFT}\n`
      text += `${s('bankDetails.iban')} ${BANK_DETAILS.IBAN}\n`
      text += `${s('bankDetails.reference')}`

      await navigator.clipboard.writeText(text)
      toast.success(s('toasts.success.copy'))
    } catch (err) {
      toast.error(s('toasts.errors.copy'))
    }
  }, [s])

  const handleCreateDeposit = useCallback(async () => {
    try {
      await addUserTransaction({
        variables: {
          amount: 0,
          currency: PRIMARY_CURRENCY,
          kind: KIND.DEPOSIT,
          paymentMethod: PAYMENT_METHOD.BANK,
        },
        update: UserTransactions.add,
      })

      history.push(PORTFOLIO)
    } catch (error) {
      toast.error(error?.message || _('error.generic'))
    }
  }, [history, addUserTransaction])

  return (
    <>
      <Column center mb={6}>
        <Text fontWeight={2} heading2>
          {s('title')}
        </Text>

        <Text color="text70" fontWeight={2} heading5 mt={4}>
          {s('subtitle')}
        </Text>
      </Column>

      <Card p="20px" width="630px">
        <Text extraLarge fontWeight={2} heading2>
          {s('title')}
        </Text>

        <Text mb={5} mt={4} small>
          {s('sendOnlyEuros')}
        </Text>

        <OutlineContainer>
          <BankDetailsHeader>
            <Text fontWeight={2} heading5>
              {s('bankDetails.title')}
            </Text>

            <Button color="text70" text onClick={handleCopyToClipboard}>
              <Text fontSize="10px" fontWeight={1}>
                {s('bankDetails.copy')}
              </Text>

              <CopyIcon height={16} viewBox="0 0 24 24" width={16} />
            </Button>
          </BankDetailsHeader>

          <Row gap={5} p={4}>
            <Column gap={3}>
              <Text color="text80" small>
                {s('bankDetails.beneficiary')}
              </Text>
              <Text color="text80" small>
                {s('bankDetails.beneficiaryAddress')}
              </Text>
              <Text color="text80" small>
                {s('bankDetails.bankName')}
              </Text>
              <Text color="text80" small>
                {s('bankDetails.bankAddress')}
              </Text>
              <Text color="text80" small>
                {s('bankDetails.account')}
              </Text>
              <Text color="text80" small>
                {s('bankDetails.swift')}
              </Text>
              <Text color="text80" small>
                {s('bankDetails.iban')}
              </Text>
            </Column>

            <Column gap={3}>
              <Text small>{BANK_DETAILS.BENEFICIARY}</Text>
              <Text small>{BANK_DETAILS.BENEFICIARY_ADDRESS}</Text>
              <Text small>{BANK_DETAILS.BANK_NAME}</Text>
              <Text small>{BANK_DETAILS.BANK_ADDRESS}</Text>
              <Text small>{BANK_DETAILS.ACCOUNT}</Text>
              <Text small>{BANK_DETAILS.SWIFT}</Text>
              <Text small>{BANK_DETAILS.IBAN}</Text>
            </Column>
          </Row>
        </OutlineContainer>

        <InstructionsContainer mt={4}>
          <Row center>
            <InfoEmptyIcon height={21} viewBox="0 0 24 24" width={21} />
            <Text fontWeight={3} heading6>
              {s('instructions.title')}
            </Text>
          </Row>

          <Column gap={4} ml={5} mt={4}>
            <Text small>&#183; {s('instructions.sendOnlyEuros')}</Text>
            <Text small>
              &#183; {s('instructions.dontForgetInsertReference')}
            </Text>
            <Text small>&#183; {s('instructions.matchNameAccount')}</Text>
            <Text small>&#183; {s('instructions.eurosSent')}</Text>
            <Text small>&#183; {s('instructions.businessDays')}</Text>
          </Column>
        </InstructionsContainer>

        <InstructionsContainer mt={4}>
          <Row center>
            <InfoEmptyIcon height={21} viewBox="0 0 24 24" width={21} />
            <Text fontWeight={3} heading6>
              {s('minimumAmount')} &#8364;{MINIMUM_AMOUNT}
            </Text>
          </Row>
        </InstructionsContainer>

        <Row mt={5} spaceBetween>
          <Button disabled={loading} outline onClick={onBack}>
            <ArrowLeftIcon height={14} viewBox="0 0 24 24" width={14} />
            {s('actions.back')}
          </Button>

          <Row center gap={3}>
            <Text extraSmall maxWidth="187px" textAlign="right">
              {s('afterMadeDeposit')}
            </Text>

            <Button disabled={loading} onClick={handleCreateDeposit}>
              {s('actions.checkPortfolios')}
              <ArrowRightIcon height={16} viewBox="0 0 24 24" width={14} />
              {loading && <Loader ml={1} />}
            </Button>
          </Row>
        </Row>
      </Card>
    </>
  )
}

BankDeposit.defaultProps = {
  onBack: noop,
}

BankDeposit.propTypes = {
  onBack: PropTypes.func,
}

export default BankDeposit
