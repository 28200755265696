import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { toast } from 'react-toastify'

import { useMutation, useQuery } from '@apollo/client'

import first from 'lodash/first'
import get from 'lodash/get'
import toNumber from 'lodash/toNumber'

import { BackToPortfolio, InfoPie } from 'Components/Blocks/Portfolio'
import { AllocationList } from 'Components/Blocks/Portfolio/Assets'
import PortfolioRebalanceForm from 'Components/Blocks/Portfolio/Rebalance/Form'
import { Button, Column, Loader, Row, Text } from 'Components/UI'
import { Input, InputLabels } from 'Components/UI/Forms'

import removePortfolioAssetMutation from 'GraphQL/Mutations/Portfolios/removePortfolioAsset.graphql'
import updatePortfolioMutation from 'GraphQL/Mutations/Portfolios/updatePortfolio.graphql'
import portfolioQuery from 'GraphQL/Queries/Portfolios/portfolio.graphql'

import _, { useScopedI18n } from 'Services/I18n'
import shared from 'Services/Shared'

import { Content, Wrapper } from './styles'

function Rebalance() {
  const rebalanceFormInstance = useRef()

  const s = useScopedI18n('portfolio.rebalance')
  const { params: routeParams } = useRouteMatch()

  const [portfolio, setPortfolio] = useState(null)

  const portfolioId = routeParams?.portfolioId

  const { data: portfolioData, refetch: refetchPortfolio } = useQuery(
    portfolioQuery,
    {
      variables: { id: portfolioId },
      fetchPolicy: 'cache-and-network',
    },
  )

  const [removePortfolioAsset] = useMutation(removePortfolioAssetMutation)
  const [updatePortfolio] = useMutation(updatePortfolioMutation)

  useEffect(() => {
    const loadedPortfolio = get(portfolioData, 'portfolio')
    if (!loadedPortfolio) return

    shared
      .getPortfolioService()
      .populatePortfolios([loadedPortfolio])
      .then(result => setPortfolio(first(result)))
  }, [portfolioData])

  const handleRebalanceMount = useCallback(instance => {
    rebalanceFormInstance.current = instance
  }, [])

  const handleUpdatePortfolio = useCallback(async () => {
    const form = rebalanceFormInstance.current

    if (!form || !portfolio) return

    form.submit()
    const formState = form.getState()
    if (formState.invalid) {
      return
    }

    try {
      const variables = {
        id: portfolio.id,
        name: portfolio.name,
        rebalanceType: formState.values?.rebalanceType?.value,
        rebalanceValue: toNumber(formState.values?.rebalanceValue),
      }

      await updatePortfolio({
        variables,
      })
      toast.success(_('portfolio.rebalance.updateSuccess'))
    } catch (error) {
      toast.error(error?.message || _('error.generic'))
    }
  }, [portfolio, updatePortfolio])

  const handleReset = useCallback(() => {
    rebalanceFormInstance.current.reset()
  }, [])

  const handleDeleteAsset = useCallback(
    async asset => {
      try {
        await removePortfolioAsset({ variables: { id: asset?.id } })
      } catch (error) {
        toast.error(error?.message || _('error.generic'))
      } finally {
        refetchPortfolio().then()
      }
    },
    [refetchPortfolio, removePortfolioAsset],
  )

  return (
    <Content>
      <BackToPortfolio portfolioId={portfolioId} />

      <Wrapper mt="20px">
        <Text as="h3" fontWeight={2} heading3>
          {s('title')}
        </Text>

        {!portfolio ? (
          <Loader />
        ) : (
          <>
            <Row gap="40px" mt="20px" width={1}>
              <InfoPie initialValue={portfolio.amount} name={portfolio.name} />

              <Column flexGrow={1}>
                <InputLabels title={s('name')}>
                  <Input disabled value={portfolio.name} />
                </InputLabels>

                <InputLabels mt={3} title={s('amount')}>
                  <Input disabled value={portfolio.amount} />
                </InputLabels>

                <PortfolioRebalanceForm
                  disabled={!!portfolio?.portfolioTemplate?.id}
                  portfolio={portfolio}
                  width={1}
                  onMount={handleRebalanceMount}
                />

                {!portfolio?.portfolioTemplate?.id && (
                  <Row gap="20px" mt="20px" width={1}>
                    <Button flexGrow={1} outline small onClick={handleReset}>
                      {s('cancel')}
                    </Button>
                    <Button flexGrow={1} small onClick={handleUpdatePortfolio}>
                      {s('action')}
                    </Button>
                  </Row>
                )}

                <AllocationList
                  amount={portfolio.amount}
                  assets={portfolio.assets}
                  showDeleteButton={!portfolio?.portfolioTemplate?.id}
                  onDeleteAsset={handleDeleteAsset}
                />
              </Column>
            </Row>
          </>
        )}
      </Wrapper>
    </Content>
  )
}

export default Rebalance
