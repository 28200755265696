export const FIELD = {
  FUND_METHOD: 'fundMethod',
  AMOUNT: 'amount',
}

export const FUND_METHOD_KIND = {
  BALANCE: 'balance',
  SWAP: 'swap',
  DEPOSIT: 'deposit',
}

export const PORTFOLIO_FUNDING = {
  PORTFOLIO: 'portfolio',
  EXPERT_PORTFOLIO: 'expertPortfolio',
}
