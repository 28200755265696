import React, { useCallback } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'

import validate from 'validate.js'

import { Button, Column, Row, Text } from 'Components/UI'
import { EnhancedRadioButtonField } from 'Components/UI/Forms'

import { EXPERT_PORTFOLIO, PORTFOLIO_CREATE } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  FieldDescription,
  FormContent,
  IconContainer,
  PlusIcon,
  StarIcon,
} from './styles'

const FIELDS = {
  PORTFOLIO_SELECTION: 'portfolioSelection',
}

const PORTFOLIO_SELECTION_VALUES = {
  MANAGED: 'managed',
  CUSTOM: 'custom',
}

const formConstraints = {
  [FIELDS.PORTFOLIO_SELECTION]: {
    presence: true,
  },
}

function CreatePortfolioSelection() {
  const history = useHistory()
  const s = useScopedI18n('blocks.portfolioSelection')

  const handleBack = useCallback(
    event => {
      event.preventDefault()
      history.goBack()
    },
    [history],
  )

  const submit = useCallback(
    async values => {
      const selected = values[FIELDS.PORTFOLIO_SELECTION]

      if (selected === PORTFOLIO_SELECTION_VALUES.MANAGED) {
        history.push(EXPERT_PORTFOLIO)
      } else {
        history.push(PORTFOLIO_CREATE)
      }
    },
    [history],
  )

  const renderForm = useCallback(
    ({ handleSubmit, values }) => {
      return (
        <Column>
          <Row justifyCenter>
            <Text fontWeight={2} heading2>
              {s('title')}
            </Text>
          </Row>

          <FormContent mt={5}>
            <Column width={1}>
              <EnhancedRadioButtonField
                id={PORTFOLIO_SELECTION_VALUES.MANAGED}
                name={FIELDS.PORTFOLIO_SELECTION}
                value={PORTFOLIO_SELECTION_VALUES.MANAGED}
                width={1}
              >
                <IconContainer>
                  <StarIcon height={21} viewBox="0 0 24 24" width={21} />
                </IconContainer>

                <Text fontWeight={2} heading4 ml={3}>
                  {s('form.managedPortfolio.title')}
                </Text>
              </EnhancedRadioButtonField>
              <FieldDescription>
                <Text small>{s('form.managedPortfolio.subtitle')}</Text>
              </FieldDescription>
            </Column>

            <Column mt={4} width={1}>
              <EnhancedRadioButtonField
                id={PORTFOLIO_SELECTION_VALUES.CUSTOM}
                name={FIELDS.PORTFOLIO_SELECTION}
                value={PORTFOLIO_SELECTION_VALUES.CUSTOM}
                width={1}
              >
                <IconContainer>
                  <PlusIcon height={21} viewBox="0 0 24 24" width={21} />
                </IconContainer>
                <Text fontWeight={2} heading4 ml={3}>
                  {s('form.customPortfolio.title')}
                </Text>
              </EnhancedRadioButtonField>
              <FieldDescription>
                <Text small>{s('form.customPortfolio.subtitle')}</Text>
              </FieldDescription>
            </Column>

            <Row mt={5} spaceBetween>
              <Button outline width="130px" onClick={handleBack}>
                <ArrowLeftIcon height={16} viewBox="0 0 24 24" width={16} />
                {s('form.actions.back')}
              </Button>

              <Button
                disabled={!values[FIELDS.PORTFOLIO_SELECTION]}
                width="130px"
                onClick={handleSubmit}
              >
                {s('form.actions.continue')}
                <ArrowRightIcon height={16} viewBox="0 0 24 24" width={16} />
              </Button>
            </Row>
          </FormContent>
        </Column>
      )
    },
    [handleBack, s],
  )

  return (
    <Column>
      <Form
        initialValues={{
          [FIELDS.PORTFOLIO_SELECTION]: null,
        }}
        render={renderForm}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Column>
  )
}

export default CreatePortfolioSelection
