import { isValidPhoneNumber } from 'react-phone-number-input'

import validate from 'validate.js'

import first from 'lodash/first'
import forEach from 'lodash/forEach'
import get from 'lodash/get'

import _ from 'Services/I18n'

function checkboxConstraint(message) {
  return {
    inclusion: {
      within: [true],
      message,
    },
    presence: {
      presence: true,
      message,
    },
  }
}

function hasError(meta, checkDirty = false) {
  const { dirty, touched, error, submitError, dirtySinceLastSubmit } = meta
  const isError =
    (touched || (checkDirty && dirty)) &&
    (error || (submitError && !dirtySinceLastSubmit))

  return { hasError: isError, error: isError ? first(error) : null }
}

function errors(form, fields, { checkDirty } = {}) {
  const result = {}

  forEach(fields, field => {
    const fieldState = form.getFieldState(field)
    if (!fieldState) return

    const { hasError: fieldHasError, error } = hasError(fieldState, checkDirty)
    if (fieldHasError) {
      result[field] = error
    }
  })

  return result
}

const PHONE_VALIDATOR = 'phoneValidator'
function phoneValidator() {
  validate.validators[PHONE_VALIDATOR] = function isValid(value, options) {
    if (!value || isValidPhoneNumber(value)) {
      return null
    }

    return get(options, 'message') || _('form.validate.isInvalid')
  }

  return PHONE_VALIDATOR
}

export default {
  checkboxConstraint,
  errors,
  hasError,
  phoneValidator,
}
