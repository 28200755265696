import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'

import { barChartGlyph, moneyCircleGlyph, settingsUserGlyph } from 'Assets/Svg'

const containerVariantCss = ({ variant }) => {
  switch (variant) {
    case 'invest':
      return css`
        background: linear-gradient(235deg, #1697c0 -3.43%, #0a5b5e 92.8%);
      `
    case 'connect':
      return css`
        background: linear-gradient(236deg, #27bdac -3.9%, #087e6f 86.97%);
      `
    case 'market':
      return css`
        background: linear-gradient(238deg, #35effb -41.99%, #089499 74.64%);
      `
    default:
      return null
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  flex-basis: 33%;
  padding: 20px;
  box-shadow: 0 6px 18px 0 rgba(112, 220, 220, 0.07);

  ${containerVariantCss};
`

export const IconContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${themeGet('colors.white')}33;
  border-radius: 12px;
`

export const MoneyIcon = styled(moneyCircleGlyph)``

export const BarChartIcon = styled(barChartGlyph)``

export const SettingsUserIcon = styled(settingsUserGlyph)``

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`
