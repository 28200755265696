import app from './app'
import assets from './assets'
import buttons from './buttons'
import checkboxes from './checkboxes'
import inputs from './inputs'
import managedPortfolios from './managedPortfolios'
import palette from './palette'
import radioButtons from './radioButtons'
import selects from './selects'
import switches from './switches'

import defaultTheme from '../defaultTheme'

const theme = {
  ...defaultTheme,

  name: 'Wealth Square main theme',
  font: '"Inter", Arial, Helvetica, sans-serif;',
  webfonts: ['Inter:400,600,700,900'],

  shadow: '12px 8px 44px -12px rgba(210, 210, 210, 0.75)',

  transitionTime: {
    default: '250ms',
    modal: '150ms',
  },

  colors: {
    ...palette,

    primary: palette.primary50,
    white: palette.white,
    black: palette.black,

    bg: {
      main: palette.white,
      primary: palette.cultured,
      secondary: palette.primary50,
      disabled: palette.gray10,
      light: palette.paperWhite20,
      lightColored: palette.jade40,
      tooltip: palette.text100,
      overlayPopup: palette.slateBlue,
      overlayDark: palette.brightGray,
      overlayLight: palette.cultured,
      danger: palette.dangerBackground,
      success: palette.successBackground,
    },
    divider: {
      hover: palette.text40,
      dark: palette.text30,
      light: palette.text20,
    },

    border: {
      primary: palette.abbey40,
      colored: palette.jade80,
      lightColored: palette.jade40,
    },

    positive: palette.positive,
    negative: palette.negative,
    neutral: palette.charcoal60,

    font: {
      primary: palette.text100,
      secondary: palette.text70,
      placeholder: palette.abbey60,
      colored: palette.jade80,
    },

    assets,
    managedPortfolios,
  },

  app,
  buttons,
  inputs,
  radioButtons,
  switches,
  checkboxes,
  selects,
}

export default theme
