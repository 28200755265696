import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFlexLayout, usePagination, useTable } from 'react-table'

import { useLazyQuery } from '@apollo/client'

// import { DateTime } from 'luxon'
import get from 'lodash/get'
import map from 'lodash/map'

import { DateRangeInput, Row, Select } from 'Components/UI'
import { Pagination, Table } from 'Components/UI/Admin'
import { InputLabels } from 'Components/UI/Forms'

import { KIND } from 'Constants/transactions'

import adminTransactionsQuery from 'GraphQL/Queries/Admin/adminTransactions.graphql'

import { useScopedI18n } from 'Services/I18n'

import { useColumns } from './columns'
import { Container, Content } from './styles'

const ALL = 'all'

function valueToOption(value) {
  return {
    label: value,
    value,
  }
}

function buildOptions(values) {
  return map([ALL, ...Object.values(values)], valueToOption)
}
// TODO: add a translation for the select options
const KIND_OPTIONS = buildOptions(KIND)

function TransactionsTable() {
  const s = useScopedI18n('blocks.adminTransactions.table')

  const [kind, setKind] = useState(KIND_OPTIONS[0])
  const [range, setRange] = useState(
    null /* {
    from: DateTime.local().startOf('day'),
    to: DateTime.local().minus({ week: 1 }).startOf('day'),
  } */,
  )

  const [loadTransactions, { data, loading }] = useLazyQuery(
    adminTransactionsQuery,
  )

  const transactions = get(data, 'adminTransactions') || {}

  const tableData = useMemo(() => get(transactions, 'rows') || [], [
    transactions,
  ])

  const columns = useColumns()

  const tableProps = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: get(transactions, 'pages') || 0,
    },
    usePagination,
    useFlexLayout,
  )

  const {
    state: { pageIndex, pageSize },
  } = tableProps

  const fetchData = useCallback(() => {
    const variables = {
      page: pageIndex,
      limit: pageSize,
      sort: [{ column: 'createdAt', order: 'desc' }],
    }

    if (kind.value !== ALL) {
      variables.kind = [kind.value]
    }

    if (range) {
      variables.from = range.from.toISO()
      variables.to = range.to.toISO()
    }

    loadTransactions({ variables })
  }, [loadTransactions, kind, range, pageIndex, pageSize])

  useEffect(
    () => {
      tableProps.gotoPage(0)
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kind, range],
  )

  useEffect(
    () => {
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageIndex, pageSize],
  )

  return (
    <Container>
      <Content>
        <Row borderBottom mb={2} pb={3}>
          <InputLabels title={s('filters.kind.label')} width={160}>
            <Select
              options={KIND_OPTIONS}
              small
              value={kind}
              onChange={setKind}
            />
          </InputLabels>

          <InputLabels ml={3} title={s('filters.dates.label')} width={160}>
            <DateRangeInput
              placeholder={s('filters.dates.placeholder')}
              small
              value={range}
              onChange={setRange}
            />
          </InputLabels>
        </Row>

        <Table {...tableProps} loading={loading} />

        <Row borderTop mt={2} pt={3}>
          <Pagination
            {...tableProps}
            total={get(transactions, ['count']) || 0}
          />
        </Row>
      </Content>
    </Container>
  )
}

export default TransactionsTable
