import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'

import { Container as BaseContainer } from '../styles'

export const Container = styled(BaseContainer)`
  overflow: hidden;
`

export const Content = styled.div`
  flex-direction: column;
  overflow: hidden;
`

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 246px;
`

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${margin}
`

export const Divider = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${themeGet('colors.divider.light')};
`

export const ArrowRightIcon = styled(arrowRightGlyph)`
  margin-left: 8px;
`

const darkIconWrapperCss = ({ dark }) =>
  dark &&
  css`
    background-color: ${themeGet('colors.primary70')};
  `

const lightIconWrapperCss = ({ light }) =>
  light &&
  css`
    background-color: ${themeGet('colors.primary60')};
  `

export const IconWrapper = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  ${darkIconWrapperCss};
  ${lightIconWrapperCss};
`
