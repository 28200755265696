import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import numeral from 'numeral'

import { Button, Column, Row, Text } from 'Components/UI'

import { TAB } from 'Pages/App/Assets/constants'
import { Tab, TabsContainer } from 'Pages/App/Assets/styles'

import { ACCOUNTS } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

const INFO_TITLE = {
  [TAB.ALL]: 'estimatedBalance',
  [TAB.BALANCE]: 'platformBalance',
  [TAB.PORTFOLIO]: 'upwealthBalance',
}

const TABS_OPTIONS = [
  { label: 'tabs.all', value: TAB.ALL },
  { label: 'tabs.balance', value: TAB.BALANCE },
  { label: 'tabs.portfolio', value: TAB.PORTFOLIO },
]

function Info({ balance, currency, tab, onSelectTab }) {
  const theme = useTheme()
  const s = useScopedI18n('assets.info')
  const history = useHistory()

  const handleConnectAccounts = useCallback(() => {
    history.push(ACCOUNTS)
  }, [history])

  return (
    <Column>
      <Column>
        <Text color={theme.colors.primary50} fontWeight={2} heading2>
          {currency}
          {numeral(balance).format(`0,0.[00]`)}
        </Text>

        <Text fontWeight={1} heading6>
          {s(INFO_TITLE[tab])}
        </Text>
      </Column>

      <Row fullWidth mt={3} spaceBetween>
        <TabsContainer gap={5}>
          {TABS_OPTIONS.map(option => {
            return (
              <Tab
                active={option.value === tab}
                key={option.value}
                onClick={() => onSelectTab(option.value)}
              >
                {s(option.label)}
              </Tab>
            )
          })}
        </TabsContainer>

        <Button xSmall onClick={handleConnectAccounts}>
          {s('connectAccounts')}
        </Button>
      </Row>
    </Column>
  )
}

Info.defaultProps = {
  balance: 0,
  currency: '$',
  tab: TAB.ALL,
}

Info.propTypes = {
  balance: PropTypes.number,
  currency: PropTypes.string,
  tab: PropTypes.string,
  onSelectTab: PropTypes.func.isRequired,
}
export default Info
