import userCryptoWalletsQuery from 'GraphQL/Queries/UserCryptoWallets/userCryptoWallets.graphql'

import Utils from 'Utils'

function add(store, data) {
  try {
    Utils.GraphQL.updateList(
      store,
      userCryptoWalletsQuery,
      data?.data?.addUserCryptoWallet,
      'userCryptoWallets',
    )
  } catch (error) {
    //
  }
}

function remove(id) {
  return function update(store) {
    try {
      Utils.GraphQL.removeFromList(
        store,
        userCryptoWalletsQuery,
        id,
        'userCryptoWallets',
      )
    } catch (error) {
      //
    }
  }
}

export default { add, remove }
