import superagent from 'superagent'

import { API } from 'Config'

import { getAccessToken } from 'Services/Store/auth'

class FileService {
  static async uploadFile(url, file) {
    const result = await superagent
      .post(`${API.URL}${url}`)
      .set('Authorization', `Bearer ${getAccessToken()}`)
      .attach('file', file)

    return result?.body
  }

  static async uploadGoalImage(file) {
    return this.uploadFile('/v1/private/uploadGoalPhoto', file)
  }

  static async uploadProfileImage(file) {
    return this.uploadFile('/v1/private/uploadProfilePhoto', file)
  }
}

export default FileService
