import React from 'react'

import { SUPPORT_EMAIL } from 'Constants/values'

import { Container, Header, MailLink, Paragraph, Text, Title } from './styles'

function TermsOfService() {
  return (
    <Container>
      <Header>
        <Title>TERMS OF USE</Title>The website Wealth Square, which is
        accessible online at https://www.upwealth.io (hereafter referred to as
        the “Website”), is edited by Wealth Square SAS, a simplified joint stock
        company with a share capital of EUR 50.000, the registered office of
        which is located at 31 avenue de Ségur, 75007 Paris, France. Any use of
        the Website by a Client or User (as defined below) involves full
        application of these Terms and Conditions of Use (hereafter referred to
        as the “TCU”). Before any use of the Website, each Client or User must
        take notice of these TCU.
      </Header>
      <Paragraph mt={6}>
        <Title>1. DEFINITIONS</Title>
        <Text mt={4}>
          The following terms, when used in these TCU, shall have the following
          meaning, it being noted that the definition given for a term shall
          apply whether the term is used in the singular or in the plural : •
          “Client(s)” : means any natural adult person or legal person who holds
          cryptocurrency assets and a Client Account and who uses the Services.
          All “U.S. Person” (within the meaning of “Regulation S” of the
          Securities Act 1933 under U.S. law) are excluded. • “Client Account”:
          means the Client’s personalised online space which allows them to
          access the Services available via the Website. • “Crypto-currency”:
          means a digital representation of value that is not issued or
          guaranteed by a central bank or a public authority, is not necessarily
          attached to a legally established currency and does not possess a
          legal status of currency or money, but is accepted by natural or legal
          persons as a means of exchange and which can be transferred, stored
          and traded electronically. • “Services”: means the services described
          in Wealth Square’s Terms and Conditions of Service.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>2. AGREEMENT TO TERMS OF USE</Title>
        <Text mt={4}>
          By accessing this website and any materials presented herein (the
          “Site”), you accept in their entirety and without modification the
          following terms and conditions pertaining to the use of the Site
          (“Terms of Use”). Wealth Square platform (collectively, “Wealth
          Square”) reserves the right to change the terms, conditions and
          notices under which this Site is offered without notice at any time.
          You acknowledge and agree that Wealth Square may restrict, suspend or
          terminate the Terms of Use or your access to, and use of, all or any
          part of the Site, including any links to third-party sites, at any
          time, with or without cause, including but not limited to any breach
          of the Terms of Use as determined in Wealth Square’s absolute
          discretion, and without prior notice or liability. Each use of the
          Site constitutes your agreement to be bound by the then-current terms
          and conditions set forth in these Terms of Use. If you do not agree
          with the Terms of Use, you are not granted permission to access or
          otherwise use this Site.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>3. CREATION OF A CLIENT ACCOUNT</Title>
        <Text mt={4}>
          In order to use the Services via the Website, the Client must first
          create a Client Account by providing their name, email address, a
          password and must subsequently login every time they access the
          Website. When creating the Client Account, the Client must ensure that
          the data they provide is accurate and complete. The Client undertakes
          to promptly update any information related to them on the Website if
          such information is no longer relevant or becomes outdated. After the
          creation of the Client Account, the Client will need to provide all
          additional information through the KYC-AML process and wait until he
          gets approval before using the Service. The creation of the Client
          Account by the relevant Client on the Website shall be approved by
          WEALTH SQUARE after the Client Account creation form completed and
          full KYC-AML check has been made. The Client shall receive an email
          confirming their registration. The Client undertakes to protect their
          login information and password in order to maintain their
          confidentiality as regards third parties. The Client is solely
          responsible for their login information and for the safety of the
          access to their Client Account. Wealth Square may not be held liable
          in the case of negligence on part of the Client in this regard or in
          the case of a fraudulent access to a Client Account.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>4. FUNDING LIMITS</Title>
        <Text mt={4}>
          Individuals who have verified their accounts following the aforesaid
          procedure will be allowed to carry out deposit and/or withdrawals
          under the following limits, above which they will have to get in touch
          with Wealth Square support staff (
          <MailLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</MailLink>
          ): As for the verified companies, they will be allowed to carry out
          deposits or withdrawals with the following limits, above which they
          will have to get in touch with Wealth Square’s support staff:
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>5. OBLIGATIONS OF THE USERS</Title>
        <Text mt={4}>
          When using the Website, Clients and/or Users undertake not to: •
          provide misleading and/or false or defamatory information and data; •
          disturb the proper operation of the Website; • provide viruses or
          other technology that may harm the Website or the interests or
          property of Website Users; • use bots, scraping or any other automated
          process to access the features of the Website without being authorised
          to do so; • export or re-export a Wealth Square application or tool
          without prior, formal and express consent from Wealth Square; • copy,
          change or distribute the contents of the Website and trademarks
          without Wealth Square’s prior, formal and express consent from Wealth
          Square; • copy, reproduce, reverse-engineer, change, create derivative
          works from, distribute or disclose to the public any content (except
          their own information) from the Website without prior, formal and
          express consent from Wealth Square or from the third parties involved,
          where relevant; • market a solution developed by Wealth Square or
          software or information related to this application ; • usurp the
          identity of another person; • gather, combine, send to other media,
          make public, publish or disclose data related to Website Clients
          and/or Users or related to their activities on the Website, including
          data related to transactions, their number, type, price, etc. In the
          event of a breach of these TCU by a Client and/or User, Wealth Square
          may, at its sole discretion, temporarily or definitively suspend the
          relevant User’s access to the Website and to their Client Account in
          particular. Wealth Square may, at any time and at its sole discretion,
          reorganise the Website, the advertising spaces or any information on
          the Services.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>6. ACCESS TO THE WEBSITE</Title>
        <Text mt={4}>
          The Website is provided “as-is” and is accessible depending on its
          availability. Wealth Square uses its best efforts to ensure the
          Website is available at all times, independently from scheduled
          maintenance, but cannot in any way guarantee such availability. Wealth
          Square does not provide any guarantees, explicit or implied, on the
          quality and compatibility of the Website, the networks, connections
          and services provided with the computer equipment used by Clients
          and/or Users. The nature of crypto-currencies may lead to a higher
          risk of fraud or cyber-attack, and means that the technological
          difficulties encountered by Wealth Square may prevent use of or access
          to the Website.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>7. INTELLECTUAL PROPERTY</Title>
        <Text mt={4}>
          Wealth Square holds all intellectual property rights related to the
          Website. Wealth Square owns and maintains this Site. All information
          contained in this Site is confidential and intended solely for the use
          of those persons that have been granted permission to use this Site.
          Except as specifically permitted below, nothing contained in these
          Terms of Use or the Site itself either grants or will be construed to
          grant to you or any third party, by implication, estoppel or
          otherwise, any title or interest in, or any license or right to use or
          reproduce, any image, text, software, code, trademark, logo or service
          mark contained in the Site, including without limitation the name or
          logo of Wealth Square, any of its affiliates and/or any other entity
          described on the Site. No act of downloading or otherwise copying from
          this Site will transfer title to any software or material to any user
          hereof. Any data that you transmit to the Site (including without
          limitation personally-identifiable information) becomes the property
          of Wealth Square and may be used by Wealth Square for any lawful
          purpose without restriction, subject to the privacy policy (the
          “Privacy Policy”) set forth below. Wealth Square reserves, and will
          enforce to the fullest extent possible, all rights that it may have
          with respect to copyright and trademark ownership of all material
          contained in the Site. Wealth Square grants you a limited,
          nonexclusive license to display and otherwise use portions of the Site
          solely for your own private, non-commercial, informational purposes
          only, and to print pages from the Site only in connection with that
          use. You may not copy, store, modify, distribute, transmit, perform,
          reproduce, publish, license, create derivative works from, transfer or
          sell any text, graphics, logos and other source-identifying symbols,
          designs, icons, images, or other information, software or code
          obtained from the Site without prior express written permission from
          Wealth Square which may be withheld for any or no reason.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>8. DO YOUR OWN RESEARCH</Title>
        <Text mt={4}>
          Investors should research and base their investments on facts, current
          and past reports and should NEVER invest assets that they are highly
          dependable on.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>9. ANALYTICS</Title>
        <Text mt={4}>
          What rights you have over your data If you create an account, the
          information and its metadata are retained indefinitely. This is so we
          can recognize and approve any follow-up information automatically.For
          users that register on our website (if any), we also store the
          personal information they provide in their user profile. All users can
          see, edit, or delete their personal information at any time. Website
          administrators can also see and edit that information. Who we share
          your data with. How long we retain your data If you have an account on
          this site, you can request to receive an exported file of the personal
          data we hold about you, including any data you have provided to us.
          You can also request that we erase any personal data we hold about
          you. This does not include any data we are obliged to keep for
          administrative, legal, or security purposes.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>10. PRIVACY POLICY</Title>
        <Text mt={4}>
          In using portions of the Site, you may be asked to provide Wealth
          Square with personal information about you, your relatives and/or
          other persons affiliated or designated by you. Personal information is
          any information that can be used to identify or contact a specific
          individual or company. Wealth Square may use personally-identifiable
          information for its own internal purposes, or on behalf of its
          affiliates. Wealth Square will not transfer, sell or disclose such
          personal information to any other third party without the applicable
          person’s prior consent, except as may be required by law (such as a
          subpoena, search warrant or other court order, or pursuant to the
          order of any regulatory body having jurisdiction over Wealth Square,
          its affiliates or funds managed by Wealth Square or its affiliates) or
          to protect the integrity of the Site. In general, Wealth Square’s
          business practices limit access to personal information obtained
          through use of the Site to those members of its personnel who need to
          know such information to conduct the operations of Wealth Square, its
          affiliates or funds managed by Wealth Square or its affiliates. Wealth
          Square maintains physical, electronic and procedural safeguards
          designed to protect such information from unauthorized access or use.
          Wealth Square will use personal information obtained through your use
          of the Site only in accordance with the privacy policy in effect at
          the time such information was provided. Wealth Square reserves the
          right to change this Privacy Policy at any time and to apply such
          changes retroactively. The use of personal data by Wealth Square is
          governed by Regulation (EU) 2016/679 called “General Data Protection
          Regulation” or “GDPR”, implemented on 25 May 2018 and Act n°2018-493
          dated 20 June 2018 on the Protection of Personal Data and their
          implementing decrees.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>11. NO RELIANCE</Title>
        <Text mt={4}>
          While Wealth Square uses reasonable efforts to update the information
          contained on upwealth.io Site, Wealth Square makes no representations
          or warranties as to the accuracy, reliability or completeness of any
          information at the Site. Any content of the Site is subject to change
          without notice. As noted above, permission by Wealth Square to access
          this Site should not be construed as, or relied upon as, investment,
          financial, legal, regulatory, accounting, tax or similar advice. All
          statements in this website, including statements in blog posts, are
          the opinions of Wealth Square unless otherwise specified.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>12. API KEYS</Title>
        <Text mt={4}>
          The Client has the option to link cryptocurrency exchanges accounts,
          bank accounts and brokerage accounts through API keys. Wealth Square
          safely stores the API keys and uses them exclusively to read the
          Client’s balances. It is the Client’s responsibility to ensure that
          the API keys provided have read-only permissions level on the
          respective exchange account, this is not required for bank and
          brokerage accounts as it is by default. The Client is not allowed to
          use API keys on behalf of others or use API keys of an Exchange, bank
          or brokerage account of which he is not the legitimate holder. In no
          event shall Wealth Square be liable for any type of (A)loss, (B)cost,
          (C)expense as a result of improper use of the API keys.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>13. LINKS TO THIRD PARTY WEBSITES</Title>
        <Text mt={4}>
          Wealth Square has not reviewed any of the websites that may be linked
          to the Site, and is not responsible for the content of off-site pages
          or any other website linked or linking to this Site. Wealth Square is
          not responsible for the privacy practices of such other websites. Your
          linking to or use of any off-site pages or other websites is at your
          own risk. Wealth Square inclusion of links to other websites does not
          imply any endorsement of the material located on or linked to by such
          websites. In addition, the inclusion of any linked websites on the
          Site does not imply an endorsement of any kind of Wealth Square or its
          affiliates by any person or entity and should not be deemed as such by
          any user of the Site.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>14. MATERIALS TO BE CONSULTED IN THEIR ENTIRETY</Title>
        <Text mt={4}>
          All materials at the Site are meant to be reviewed in their entirety,
          including any footnotes, legal disclaimers, restrictions, disclosures,
          hedge clauses, and any copyright or proprietary notices, including
          those contained in this Terms of Use. Any disclaimers, restrictions,
          disclosures or hedge clauses apply to any partial document or material
          in the same manner as they do the whole, and will be deemed
          incorporated in the portion of any material or document that you
          consult or download.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>15. EMBEDDED CONTENT FROM OTHER WEBSITES</Title>
        <Text mt={4}>
          Articles on this site may include embedded content (e.g. videos,
          images, articles, etc.). Embedded content from other websites behaves
          in the exact same way as if the visitor has visited the other website.
          These websites may collect data about you, use cookies, embed
          additional third-party tracking, and monitor your interaction with
          that embedded content, including tracking your interaction with the
          embedded content if you have an account and are logged in to that
          website.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>16. NO WARRANTY</Title>
        <Text mt={4}>
          Wealth Square AFFILIATES AND THEIR OFFICERS, DIRECTORS, MEMBERS,
          PRINCIPALS, INVESTORS, AGENTS AND EMPLOYEES MAKE NO REPRESENTATIONS OR
          WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SUITABILITY OR OTHER
          CHARACTERISTICS OF THE INFORMATION AND MATERIALS CONTAINED ON OR
          PRESENTED THROUGH THE SITE. ALL SUCH INFORMATION AND MATERIALS ARE
          PROVIDED “AS IS”, WITHOUT ANY WARRANTY OF ANY KIND. Wealth Square
          AFFILIATES HEREBY FURTHER DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
          REGARD TO SUCH INFORMATION AND MATERIALS, INCLUDING ALL IMPLIED
          WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE, NON-INFRINGEMENT AND AVAILABILITY. You acknowledge
          that you are aware that by using this Site you may be subject to
          security and privacy exploitations, including, eavesdropping,
          sniffing, spoofing, hacking, breaking passwords, harassment, exposure
          to objectionable material, posturing, and/or other security or privacy
          hazards. Wealth Square AFFILIATES AND THEIR OFFICERS, DIRECTORS,
          MEMBERS, PRINCIPALS, INVESTORS, AGENTS AND EMPLOYEES FURTHER ASSUME NO
          RESPONSIBILITY FOR, AND MAKE NO WARRANTIES THAT, FUNCTIONS CONTAINED
          AT THIS SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
          CORRECTED, OR THAT THE SITE OR THE SERVER THAT MAKES IT AVAILABLE WILL
          BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. Wealth Square, ITS
          AFFILIATES AND THEIR OFFICERS, DIRECTORS, MEMBERS, PRINCIPALS,
          INVESTORS, AGENTS AND EMPLOYEES SHALL NOT BE LIABLE FOR ANY DAMAGES OF
          ANY TYPE OF KIND TO, VIRUSES THAT MAY INFECT, OR SERVICES REPAIRS OR
          CORRECTIONS THAT MUST BE PERFORMED, ON YOUR COMPUTER OR OTHER
          PROPERTY, ON ACCOUNT OF YOUR ACCESSING OR USE OF THIS SITE.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>17. LIABILITY AND INDEMNIFICATION</Title>
        <Text mt={4}>
          IN NO EVENT SHALL Wealth Square AFFILIATES OR THEIR OFFICERS,
          DIRECTORS, MEMBERS, PRINCIPALS, INVESTORS, AGENTS AND EMPLOYEES BE
          LIABLE FOR ANY CLAIMS, LIABILITIES, LOSSES, COSTS OR DAMAGES,
          INCLUDING DIRECT, INDIRECT, PUNITIVE, EXEMPLARY, INCIDENTAL, SPECIAL
          OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN ANY WAY CONNECTED WITH
          (I) THE USE OF OR INABILITY TO USE THE SITE OR WITH ANY DELAY IN USING
          THE SITE, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUE, TRADING,
          PROFITS, ANTICIPATED PROFITS, BUSINESS, SAVINGS, GOODWILL, USE,
          OPPORTUNITY OR DATA; (II) ANY INFORMATION AND MATERIALS OBTAINED
          THROUGH THE SITE; OR (III) OTHERWISE ARISING OUT OF THE USE OF THE
          SITE; IN ANY CASE WHETHER BASED ON THEORIES ARISING IN CONTRACT, TORT,
          STRICT LIABILITY OR OTHERWISE. SUCH LIMITATIONS APPLY EVEN IF Wealth
          Square AFFILIATES OR ANY OF THEIR OFFICERS, DIRECTORS, MEMBERS,
          PRINCIPALS, INVESTORS, AGENTS OR EMPLOYEES HAVE BEEN ADVISED OF THE
          POSSIBILITY OF DAMAGES OR COULD HAVE FORESEEN THE DAMAGES. FURTHER,
          Wealth Square AFFILIATES WILL NOT BE RESPONSIBLE FOR ANY FAILURE TO
          COMPLY, BY YOU OR ANY THIRD PARTY, WITH THESE TERMS OF USE OR WITH
          APPLICABLE EUROPEAN, STATE AND/OR LOCAL LAWS. These limitations of
          liability will apply regardless of: (i) any negligence or gross
          negligence of any Wealth Squares Affiliates or (ii) whether the
          liability arises in negligence, gross negligence, strict liability,
          contract, tort (including negligence or gross negligence) or any other
          theory of legal liability; and will remain in effect even if any
          remedy fails of its essential purpose. Your sole remedy for
          dissatisfaction with your use of the Site is to stop using the Site.
          You hereby agree to indemnify, defend and hold Wealth Square
          Affiliates and their officers, directors, members, principals,
          investors, agents and employees harmless from and against any and all
          liability costs incurred by these parties in connection with any claim
          arising out of any breach by you of these Terms of Use or any of the
          representations, warranties and covenants made by you herein,
          including, without limitation, attorneys’ fees and costs. You shall
          cooperate as fully as reasonably required or requested in the defense
          of any claim. Wealth Square Affiliates reserves the right, at its own
          expense, to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by you and you shall not in any
          event settle any matter without the written consent of Wealth Square
          Affiliates. Given current French regulation, Wealth Square draws the
          Users and the Clients’ attention to the risks related to the
          subscription, purchase, sale and exchange of cryptocurrencies. A
          crypto-currency is not a “currency” (“monnaie”) within the meaning of
          article L. 111-1 of the French Monetary and Financial Code and may not
          be called a “electronic money” (“monnaie électronique”) within the
          meaning of article L. 315-1 of the French Monetary and Financial Code.
          A crypto-currency is generally not considered to be a financial
          instrument, which means that its owners cannot benefit from the
          protection offered under the provisions of Directive 2014/65/EU of the
          European Parliament and of the Council of 15 May 2014 on markets in
          financial instruments (MiFID2) and its delegated directive, as
          implemented into French law, nor from its implementing regulations. A
          crypto-currency does not benefit from the deposit guarantee and the
          securities guarantee offered by the French Fonds de Garantie des
          Dépôts et de Résolution (FGDR). Furthermore, Wealth Square makes no
          guarantee and shall not be liable for any damages or losses related
          (i) to the elements and information on the Website, to the use or the
          results of the use of the Website’s functions, to their truthfulness,
          accuracy, reliability or other, or (ii) to the protection of any data
          or information provided on the Website, or (iii) to the accessibility
          and availability of the Website and, concurrently, of the Services.
        </Text>
      </Paragraph>
      <Paragraph mt={6}>
        <Title>18. GENERAL</Title>
        <Text mt={4}>
          Any dispute, claim or controversy arising out of or relating to this
          Site will be determined by arbitration. By accessing this Site, you
          agree to have all disputes, claims or controversies arising out of or
          relating to this Site decided by neutral binding arbitration, giving
          up any rights you might possess to have those matters litigated in a
          court or jury trial. By using this Site, you confirm your agreement to
          this arbitration provision is voluntary. These Terms of Use are
          governed by the internal laws of France, without reference to its
          conflicts of laws provisions. If any provision of these Terms of Use
          is held to be invalid or unenforceable in any jurisdiction, such
          provision shall be deemed modified to the minimum extent necessary so
          that such provision shall no longer be held to be invalid or
          unenforceable, and these Terms of Use shall be interpreted so as to
          achieve the intent expressed herein to the greatest extent possible in
          the jurisdiction in question. Any such modification, invalidity or
          unenforceability shall be strictly limited both to such provision and
          to such jurisdiction. For additional inquiries, email{' '}
          <MailLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</MailLink>.
        </Text>
      </Paragraph>
    </Container>
  )
}

export default TermsOfService
