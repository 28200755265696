import { AppLayout } from 'Components/Layout'

import App from 'Pages/App'
import Portfolio from 'Pages/Portfolio'

import { EXPERT_PORTFOLIO_ROUTES } from 'Router/expertPortfolio'
import { PORTFOLIO_ROUTES } from 'Router/portfolio'

import * as ROUTES from './routes'

export const APP_ROUTES = {
  component: AppLayout,
  path: ROUTES.APP_ROOT,
  routes: [
    // -- App
    {
      component: App.Root,
      path: ROUTES.APP_ROOT,
      exact: true,
    },
    {
      component: App.AddNetWorth,
      path: ROUTES.ADD_NET_WORTH,
      exact: true,
    },

    // Portfolio
    {
      component: Portfolio.Create,
      path: ROUTES.PORTFOLIO_CREATE,
      exact: true,
    },
    {
      component: Portfolio.CreatePortfolioSelection,
      path: ROUTES.CREATE_PORTFOLIO_SELECTION,
      exact: true,
    },
    PORTFOLIO_ROUTES,
    EXPERT_PORTFOLIO_ROUTES,

    // -- Assets
    {
      component: App.Assets,
      path: ROUTES.ASSETS,
      exact: true,
    },

    // -- Transactions
    {
      component: App.Transactions,
      path: ROUTES.TRANSACTIONS,
      exact: true,
    },

    // -- Profile
    {
      component: App.Profile,
      path: ROUTES.PROFILE,
      exact: true,
    },

    // -- Accounts
    {
      component: App.Accounts,
      path: ROUTES.ACCOUNTS,
      exact: true,
    },

    // Funding
    {
      component: App.Funding.Root,
      path: ROUTES.FUNDING,
      exact: true,
    },
    {
      component: App.Funding.Success,
      path: ROUTES.FUNDING_SUCCESS,
      exact: true,
    },

    // Deposit
    {
      component: App.Deposit,
      path: ROUTES.DEPOSIT,
      exact: true,
    },

    // Withdrawal
    {
      component: App.Withdrawal.Root,
      path: ROUTES.WITHDRAWAL,
      exact: true,
    },
    {
      component: App.Withdrawal.CryptoWithdraw,
      path: ROUTES.WITHDRAWAL_CRYPTO,
      exact: true,
    },
  ],
}
