import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import arrowLeftGlyph from 'iconoir/icons/arrow-left.svg'
import arrowRightGlyph from 'iconoir/icons/arrow-right.svg'
import bankGlyph from 'iconoir/icons/bank.svg'

import { buyCryptoGlyph } from 'Assets/Svg'

import { gap } from 'Theme/system'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${themeGet('colors.bg.main')};
  padding: 20px;
  max-width: 670px;
  width: 100%;
  flex-shrink: 0;

  ${margin};
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${margin};
  ${gap}
`

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${themeGet('colors.primary50')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

export const ArrowRightIcon = styled(arrowRightGlyph)``

export const ArrowLeftIcon = styled(arrowLeftGlyph)``

export const BankIcon = styled(bankGlyph)`
  color: ${themeGet('colors.white')};
`

export const CryptoIcon = styled(buyCryptoGlyph)``

export const DescriptionLabel = styled.div`
  display: flex;
  padding: 2px ${themeGet('space.4')}px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: ${themeGet('colors.white')};
  background-color: ${themeGet('colors.text100')};
  text-align: center;

  ${margin}
`
