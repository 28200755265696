import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import ConnectedAccounts from 'Components/Blocks/ConnectedAccounts'
import { Row, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { Card, ErrorTriangleIcon, InstructionsContainer } from '../styles'

function AccountsList({ onConnect, onBack, onContinue, ...rest }) {
  const s = useScopedI18n('blocks.deposit.accountsList')

  return (
    <Card {...pick(rest)} gap={5} p={6} width="570px">
      <ConnectedAccounts
        content={
          <InstructionsContainer>
            <Row>
              <ErrorTriangleIcon height={21} viewBox="0 0 24 24" width={21} />
              <Text extraSmall>{s('instructions')}</Text>
            </Row>
          </InstructionsContainer>
        }
        showContinue
        onBack={onBack}
        onConnect={onConnect}
        onContinue={onContinue}
      />
    </Card>
  )
}

AccountsList.defaultProps = {
  onBack: noop,
  onConnect: noop,
  onContinue: noop,
}

AccountsList.propTypes = {
  onBack: PropTypes.func,
  onConnect: PropTypes.func,
  onContinue: PropTypes.func,
}

export default AccountsList
